const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Panel, Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    PanelCollapse = require("components/widgets/PanelCollapse"),
    PaymentElements = require("components/payment/PaymentElements"),
    CheckboxInput = require("components/form/CheckboxInput"),
    TournamentInfoHeader = require("components/tournament/TournamentInfoHeader"),
    messages = require("i18n/messages");

const tournamentStore = require("stores/TournamentStore"),
    tournamentActions = require("actions/TournamentActions"),
    tournamentUserStore = require("stores/TournamentUserStore"),
    notificationActions = require("components/notification/NotificationActions"),
    userStore = require("stores/UserStore"),
    userActions = require("actions/UserActions");

const paymentElementsStore = require("stores/PaymentElementsStore"),
    paymentElementsActions = require("actions/PaymentElementsActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class TournamentCheckoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.handlePayEleStoreChange = this.handlePayEleStoreChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);

        this.state = {
            tournamentStore: tournamentStore.getState(),
            tournamentUserStore: tournamentUserStore.getState(),
            paymentElementsStore: paymentElementsStore.getState(),
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        tournamentStore.listen(this.handleTournamentChange);
        tournamentUserStore.listen(this.handleTournamentUserChange);
        paymentElementsStore.listen(this.handlePayEleStoreChange);
        notificationActions.reset();
        tournamentActions.showProgress();
        console.log("mount tournament " + this.props.tournamentId);
        tournamentActions.showCheckoutDetails(this.props.tournamentId, parcoursOnlineID => {
            paymentElementsActions.initBasket(parcoursOnlineID);
        });
        userActions.fetchUserDetail(this.state.userStore.user);

    }
    componentWillUnmount() {
        tournamentActions.resetTournamentDetails();
        userStore.unlisten(this.handleUserChange);
        tournamentStore.unlisten(this.handleTournamentChange);
        tournamentUserStore.unlisten(this.handleTournamentUserChange);
        paymentElementsStore.unlisten(this.handlePayEleStoreChange);
    }
    handlePayEleStoreChange(state) {
        this.setState({paymentElementsStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleTournamentChange(state) {
        this.setState({tournamentStore: state});
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserStore: state});
    }
    render() {
        const {userStore, tournamentStore, tournamentUserStore, paymentElementsStore} = this.state;
        console.log("render TournamentCheckoutPage");
        if (tournamentStore.tournamentDetail == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </Grid>)
        } else if (tournamentStore.tournamentDetail != null && tournamentStore.tournamentDetail.isDeleted()) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>)
        }
        return (<TournamentDetails
            user={userStore.user}
            userDetailLoading={userStore.userDetailLoading}
            userDetail={userStore.userDetail}
            tournament={tournamentStore.tournamentDetail}
            tournamentUserStore={tournamentUserStore}
            articles={paymentElementsStore.articles}
            sum={paymentElementsStore.sum}/>)
    }
}

class TournamentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        document.title = this.props.tournament.getName();
    }
    render() {
        const {user, userDetail, userDetailLoading, tournament, tournamentUserStore, articles, sum} = this.props;
        let detail = null;
        if (tournament.isClosed()) {
            detail = <Alert bsStyle="danger">{messages.get("tournament.status.finished")}</Alert>
        } else {
            if (tournament.isOnlinePaymentEnabled()) {
                if (tournamentUserStore.loading) {
                    detail = <Loading/>
                } else {
                    detail =  <TournamentCheckout
                        user={user}
                        userDetailLoading={userDetailLoading}
                        userDetail={userDetail}
                        tournament={tournament}
                        tournamentUsers={tournamentUserStore.tournamentUsers}
                        articles={articles}
                        sum={sum}
                    />
                }
            } else {
                detail = <Alert bsStyle="danger">{messages.get("tournament.register.panel.info.paymentDisabled")}</Alert>
            }
        }
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                                <Breadcrumb link={"/tournament/" + tournament.id} code={tournament.getName()}/>
                                <Breadcrumb code="checkout" active/>
                            </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <TournamentInfo tournament={tournament} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {detail}
                    </Col>
                </Row>
            </Grid>)
    }
}
TournamentDetails.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUserStore:PropTypes.object.isRequired,
    user:PropTypes.object,
    userDetail:PropTypes.object,
    userDetailLoading:PropTypes.bool.isRequired
};
const TournamentInfo = ({tournament}) => {
    const tPrice = tournament.getTournamentPriceID();
    return (
        <Panel>
            <Panel.Heading>
                <TournamentInfoHeader tournament={tournament}/>
            </Panel.Heading>
            <Panel.Body>
                <Row>
                    <Col md={6}>
                        {
                            tPrice ? <React.Fragment>
                                <p><FontAwesome icon="envelope"/><a href={"mailto:"+tPrice.getEmail()}>{tPrice.getEmail()}</a></p>
                                <p><FontAwesome icon="euro-sign"/>{tPrice.getPaymentCondition()}</p>
                            </React.Fragment> : null
                        }
                    </Col>
                    <Col lg={6}>
                        <Alert>
                            <h4>{messages.get("tournament.register.panel.info.payDesc")}</h4>
                            <p>{messages.get("tournament.register.panel.info.payInfo")}</p>
                            <p>{messages.get("tournament.register.panel.info.payInvoice")}</p>
                        </Alert>
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
TournamentInfo.propTypes = {
    tournament: PropTypes.object.isRequired
};
const TournamentCheckout = ({tournament, tournamentUsers, user, userDetail, userDetailLoading, articles, sum}) => {
    const counter = Object.keys(articles).length;
    let selfRegisteredUsers = [];
    let otherRegisteredUsers = [];
    tournamentUsers.map(tUser => {
        if (tUser.isSelfRegisteredUsers(user)) {
            selfRegisteredUsers.push(tUser);
        } else {
            otherRegisteredUsers.push(tUser);
        }
    });
    return (<React.Fragment>
        <PanelCollapse header={<h3><I18n code="tournament.register.panel.title"/></h3>}>
            <TournamentAttendeeList tournament={tournament}
                                    tournamentUsers={selfRegisteredUsers}/>
        </PanelCollapse>
        <PanelCollapse  header={<h3><I18n code="tournament.attendees.panel.title"/></h3>}>
            <TournamentAttendeeList tournament={tournament}
                                    tournamentUsers={otherRegisteredUsers}/>
        </PanelCollapse>
        <Panel>
            <Panel.Body>
                <PaymentElements parcours={tournament.getParcoursID()}
                                 user={user}
                                 userDetailLoading={userDetailLoading}
                                 userDetail={userDetail}
                                 tournament={tournament}
                                 articleElement={
                                     <Row>
                                         <Col xs={12} md={8} mdOffset={2}>
                                             <h2><FontAwesome icon="shopping-cart"/>{messages.get("tournament.register.panel.cartTU")}</h2>
                                             <p><strong>{counter} {messages.get("tournament.register.panel.selectedTU")} - {messages.get("tournament.register.label.price") + ": "}
                                                 {sum.toFixed(2) + " " + tournament.getTournamentPriceID().getCurrency()}</strong></p>
                                             {
                                                 counter > 0 ? null :
                                                     <Alert><FontAwesome icon="check-square">{messages.get("tournament.register.panel.selectTUser")}</FontAwesome></Alert>
                                             }
                                             <br/>
                                         </Col></Row>
                                 }
                                 ruleImageID={null}/>
            </Panel.Body>
        </Panel>
    </React.Fragment>)
};
TournamentCheckout.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUsers: PropTypes.array,
    articles: PropTypes.object.isRequired,
    sum: PropTypes.number.isRequired,
    user: PropTypes.object,
    userDetail: PropTypes.object,
    userDetailLoading: PropTypes.bool.isRequired
};
const TournamentAttendeeList = ({tournament, tournamentUsers}) => {
    if (tournamentUsers.length <= 0) {
        return (<div><I18n code="tournament.register.panel.info.nopayableregistration"/></div>);
    }
    const currency = tournament.getTournamentPriceID().getCurrency();
    return (
        <React.Fragment>
            <Table striped className="hidden-xs">
                <thead>
                <tr>
                    <th><I18n code="tournament.table.column.name"/></th>
                    <th><I18n code="tournament.table.column.union"/></th>
                    <th><I18n code="tournament.class.bow"/>/<I18n code="tournament.class.age"/></th>
                    <th><I18n code="parcours.articles.form.price"/></th>
                </tr>
                </thead>
                <tbody>
                {tournamentUsers.map(tournamentUser => {
                    return (<TournamentAttendeeTableRow key={"TATR_" + tournamentUser.id}
                                                        currency={currency}
                                                        tournament={tournament}
                                                        tournamentUser={tournamentUser}/>);
                })}
                </tbody>
            </Table>

            <div className="hidden-sm hidden-md hidden-lg">
                {tournamentUsers.map(tournamentUser => {
                    return (<TournamentAttendeeListRow key={"TALR_" + tournamentUser.id}
                                                       currency={currency}
                                                       tournament={tournament}
                                                       tournamentUser={tournamentUser}/>);
                })}
            </div>
        </React.Fragment>)
};
TournamentAttendeeList.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUsers: PropTypes.array.isRequired
};
const TournamentAttendeeListRow = ({tournament, tournamentUser, currency}) => {
    const handleChange = (value) => {
        if (value) {
            paymentElementsActions.updateTransactionArticle(tournamentUser.id, 1);
            paymentElementsActions.updateSum(tournamentUser.getPayAmount());
        } else {
            paymentElementsActions.updateTransactionArticle(tournamentUser.id, 0);
            paymentElementsActions.updateSum(tournamentUser.getPayAmount() * -1);
        }
    };
    return (
        <Row>
            <Col xs={12}>
                <Panel>
                    <Panel.Body>
                        <p className="pull-left">
                            <CheckboxInput onAfterChange={handleChange}
                                           message={tournamentUser.getFullName()} />
                        </p>
                        <Table style={{margin: "0"}}>
                            <tbody>
                            <tr>
                                <th></th>
                                <td>{tournamentUser.getTournamentConfigBowCodeName()}/ {tournamentUser.getTournamentConfigAgeCodeName()}</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td><I18n code="parcours.articles.form.price"/>: {tournamentUser.getPayAmount()} {currency}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
    )
};
TournamentAttendeeListRow.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUser: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired
};
const TournamentAttendeeTableRow = ({tournament, tournamentUser, currency}) => {
    const handleChange = (value) => {
        if (value) {
            paymentElementsActions.updateTransactionArticle(tournamentUser.id, 1);
            paymentElementsActions.updateSum(tournamentUser.getPayAmount());
        } else {
            paymentElementsActions.updateTransactionArticle(tournamentUser.id, 0);
            paymentElementsActions.updateSum(tournamentUser.getPayAmount() * -1);
        }
    };
    return (
        <tr>
            <td>
                <CheckboxInput onAfterChange={handleChange}
                               message={tournamentUser.getFullName()} />
            </td>
            <td style={{"verticalAlign": "middle"}}>{tournamentUser.getUnion()}</td>
            <td style={{"verticalAlign": "middle"}}>{tournamentUser.getTournamentConfigBowCodeName() + "/ " + tournamentUser.getTournamentConfigAgeCodeName()}</td>
            <td style={{"verticalAlign": "middle"}}>{tournamentUser.getPayAmount()} {currency}</td>
        </tr>)
};
TournamentAttendeeTableRow.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUser: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired
};
module.exports = TournamentCheckoutPage;
