const React = require("react"),
    PropTypes = require('prop-types'),
    ReactDOM = require("react-dom");
const {Row, Col,  Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput"),
    Loading = require("components/widgets/Loading"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SelectInputTypeAhead = require("components/form/SelectInputTypeAhead"),
    TournamentSexSelect = require("components/tournamentregister/TournamentSexSelect"),
    TournamentConfigAgeCalculator = require("components/tournamentregister/TournamentConfigAgeCalculator"),
    TournamentConfigAgeSelect = require("components/tournamentregister/TournamentConfigAgeSelect"),
    TournamentConfigBowSelect = require("components/tournamentregister/TournamentConfigBowSelect"),
    TournamentRegSlotSelect = require("components/tournamentregister/TournamentRegSlotSelect"),
    TournamentOptionSelect = require("components/tournamentregister/TournamentOptionSelect"),
    Utility = require("util/Utility"),
    messages = require("i18n/messages");

const TournamentUser = require("parse/TournamentUser"),
    ParseError = require("parse/ParseError");
const {BaseContext} = require("context/BaseContext");
const tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions");

class TournamentRegistrationFormSingle extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refMail = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refUnion = React.createRef();
        this.refSex = React.createRef();
        this.refLicense = React.createRef();
        this.refNote = React.createRef();

        this.setError = this.setError.bind(this);
        this.validRegistration = this.validRegistration.bind(this);
        this.handleSubmitRegistration = this.handleSubmitRegistration.bind(this);
        this.noEmail = this.noEmail.bind(this);
        this.buildTournamentUserInit = this.buildTournamentUserInit.bind(this);
        this.buildUserBowUnionLicObject = this.buildUserBowUnionLicObject.bind(this);
        this.fillFormWithTournamentUser = this.fillFormWithTournamentUser.bind(this);
        this.showPaymentCondition = this.showPaymentCondition.bind(this);
        this.getUserDetailDefault = this.getUserDetailDefault.bind(this);
        this.getUserMailDefault = this.getUserMailDefault.bind(this);
        this.getSexSelectValue = this.getSexSelectValue.bind(this);
        this.configBowSelected = this.configBowSelected.bind(this);
        this.configAgeSelected = this.configAgeSelected.bind(this);
        this.configSexSelected = this.configSexSelected.bind(this);
        this.optionSelected = this.optionSelected.bind(this);

        this.state = {
            submitDisabled: false
        }
    }
    setError(message) {
        this.refSubmitButton.current.resetLoading();
        this.setState({
            submitDisabled: false,
            error: message
        });
    }
    validRegistration() {
        let valid = true;
        const {tournamentRegisterModalStore} = this.props;
        const required = [
            this.refName.current.getValue(),
            this.refSurname.current.getValue()
        ];
        if (this.props.tournamentRegisterModalStore.bowUnion != null
            && this.props.tournamentRegisterModalStore.bowUnion.isLicenseNumberMandatory()) {
            required.push(this.refLicense.current.getValue())
        }
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                return false;
            }
        }
        if (this.refMail.current.getValue() == null || this.refMail.current.getValue().trim().length <= 0) {
            this.setState({error: messages.get("tournament.create.error.required") + " - " + messages.get("tournament.register.placeHolder.noemail") + this.noEmail()});
            return false;
        }
        if (tournamentRegisterModalStore.selectedBowType === null || tournamentRegisterModalStore.selectedAgeType === null) {
            this.setState({error: messages.get("tournament.create.error.required")});
            return false;
        }
        if (tournamentRegisterModalStore.tournamentRegSlots != null && tournamentRegisterModalStore.tournamentRegSlots.length > 0) {
            // check if slot was selected
            if (tournamentRegisterModalStore.selectedTRegSlotId == null) {
                this.setState({error: messages.get("tournament.create.error.required")});
                return false;
            }
        }
        if (tournamentRegisterModalStore.tOption) {
            if (tournamentRegisterModalStore.selectedOption == null) {
                this.setState({error: messages.get("tournament.create.error.required")});
                return false;
            }
        }
        return valid;
    }
    handleSubmitRegistration(e) {
        e.preventDefault();
        if (!this.validRegistration()) {
            this.refSubmitButton.current.resetLoading();
            return;
        }
        const { updateUserInfoState } = this.context;
        this.setState({submitDisabled: true});
        // validate Email
        // TODO obviously check email immediately after entering it
        const tuMail = this.refMail.current.getValue().trim().toLowerCase();
        tournamentRegisterModalActions.validateTUserEmail(this.props.tournament, tuMail, (result) => {
            if (result.valid) {
                let regTUserObject = this.buildTournamentUserInit(tuMail);
                tournamentRegisterModalActions.handleTournamentUserRegistration(this.props.tournament, regTUserObject, error => {
                    this.setError(messages.get(error.message));
                });
                if (this.props.user != null && this.props.tournamentRegisterModalStore.selfRegistration) {
                    // only call for self registration
                    let userBowUnionLicObject = this.buildUserBowUnionLicObject(regTUserObject);
                    if (this.props.tournamentRegisterModalStore.userDetail == null || userBowUnionLicObject.saveLic) {
                        // only call if user has no userDetail or a new license need to be saved
                        tournamentRegisterModalActions.handleTournamentUserAfterRegistration(userBowUnionLicObject, callbackUserInfoObject => {
                            updateUserInfoState(callbackUserInfoObject)
                        });
                    }
                }
            } else if (result.error) {
                this.setError(messages.get("parse.error.unexpected") + ParseError.getTranslatedErrorMessage(result.error));
            } else {
                this.setError(messages.get("tournament.error.emailInUse") + " " + tuMail + ". " + messages.get("tournament.register.placeHolder.noemail") + this.noEmail());
            }
        });
    }
    noEmail() {
        return Utility.buildNoEmail(this.refName.current.getValue(), this.refSurname.current.getValue());
    }
    buildTournamentUserInit(tuMail) {
        console.log("handleTournamentRegistration:" + tuMail);
        let regTUserObject = {};
        regTUserObject.name = this.refName.current.getValue().trim();
        regTUserObject.surname = this.refSurname.current.getValue().trim();
        regTUserObject.tuMail = tuMail;
        regTUserObject.sex = parseInt(ReactDOM.findDOMNode(this.refSex.current).value);
        regTUserObject.tConfigAge = this.props.tournamentRegisterModalStore.selectedAgeType.id;
        regTUserObject.tConfigBow = this.props.tournamentRegisterModalStore.selectedBowType.id;
        if (this.refUnion.current.getValue() && this.refUnion.current.getValue().length > 0) {
            regTUserObject.union = this.refUnion.current.getValue().trim();
        }
        if (this.props.tournamentRegisterModalStore.selectedOption != null) {
            regTUserObject.option = this.props.tournamentRegisterModalStore.selectedOption;
        }
        if (this.props.tournamentRegisterModalStore.selectedTRegSlotId != null) {
            regTUserObject.regSlotId = this.props.tournamentRegisterModalStore.selectedTRegSlotId;
        }
        if (this.refLicense.current && this.refLicense.current.getValue()) {
            regTUserObject.licNumber = this.refLicense.current.getValue().trim();
        }
        if (this.props.tournamentRegisterModalStore.bowUnion != null
            && this.props.tournamentRegisterModalStore.bowUnion.isLicenseNumberMandatory()) {
            regTUserObject.licUnionCode = this.props.tournamentRegisterModalStore.bowUnion.getCode();
        }

        if (this.refNote.current.getValue() && this.refNote.current.getValue().length > 0) {
            regTUserObject.note = this.refNote.current.getValue().trim();
        }
        regTUserObject.price = this.props.tournamentRegisterModalStore.price;
        regTUserObject.parcoursID = this.props.tournament.getParcoursID().id;
        if (this.props.tournament.getCupGroup()) {
            regTUserObject.cupGroup = this.props.tournament.getCupGroup();
        }
        regTUserObject.tournamentID = this.props.tournament.id;
        regTUserObject.status = TournamentUser.prototype.status.registered;
        regTUserObject.selfRegistration = this.props.tournamentRegisterModalStore.selfRegistration;
        return regTUserObject;
    }
    buildUserBowUnionLicObject(regTUserObject) {
        let userBowUnionLicObject = {};
        userBowUnionLicObject.bowUnionID= this.props.tournamentRegisterModalStore.bowUnion.id;
        if (this.props.tournamentRegisterModalStore.bowUnion != null && this.props.tournamentRegisterModalStore.selectedUserLicense == null) {
            // user has no stored license object for this type of bow union - generate it
            userBowUnionLicObject.saveLic= true;
            userBowUnionLicObject.bowID = this.props.tournamentRegisterModalStore.selectedBowType.getTypeID();
            userBowUnionLicObject.ageID= this.props.tournamentRegisterModalStore.selectedAgeType.getTypeID();
            if (regTUserObject.licNumber) {
                userBowUnionLicObject.licNumber=regTUserObject.licNumber;
            }
        } else {
            userBowUnionLicObject.saveLic= false;
        }
        userBowUnionLicObject.name= regTUserObject.name;
        userBowUnionLicObject.surname= regTUserObject.surname;
        userBowUnionLicObject.sex= regTUserObject.sex;
        if (regTUserObject.union) {
            userBowUnionLicObject.union= regTUserObject.union;
        }
        return userBowUnionLicObject;
    }
    fillFormWithTournamentUser(tUser) {
        console.log("fill form with tUser" + tUser.id);
        if (tUser.getSurname() != null) {
            this.refName.current.setValue(tUser.getName());
            this.refSurname.current.setValue(tUser.getSurname());
        } else {
            // handling for older entries without one name column
            const fullname = tUser.getName().trim();
            const fullNames = fullname.split(' ');
            if (fullNames != null && fullNames.length > 1) {
                const name = fullNames[0];
                let surname = null;
                if (fullNames.length > 2) {
                    fullNames.shift();
                    surname = fullNames.join(' ');
                } else {
                    surname = fullNames[1];
                }
                this.refName.current.setValue(name);
                this.refSurname.current.setValue(surname);
            } else {
                // very unlikely
                this.refName.current.setValue(fullname);
            }
        }
        this.refMail.current.setValue(tUser.getPlayerEmail());
        ReactDOM.findDOMNode(this.refSex.current).value = tUser.getSex();
        if (tUser.getUnion() != null) {
            this.refUnion.current.setValue(tUser.getUnion());
        }
        if (this.props.tournamentRegisterModalStore.bowUnion != null
            && this.props.tournamentRegisterModalStore.bowUnion.isLicenseNumberMandatory()) {
            if (tUser.getLicenseUnionCode() == this.props.tournamentRegisterModalStore.bowUnion.getCode()) {
                this.refLicense.current.setValue(tUser.getLicenseNumber());
            }
        }
    }
    showPaymentCondition() {
        const tPrice = this.props.tournament.getTournamentPriceID();
        if (tPrice) {
            return tPrice.getPaymentCondition();
        }
    }
    getUserDetailDefault(key) {
        if (this.props.tournamentRegisterModalStore.selfRegistration) {
            if (this.props.tournamentRegisterModalStore.userDetail != null) {
                return this.props.tournamentRegisterModalStore.userDetail.get(key);
            }
        }
        return "";
    }
    getUserMailDefault() {
        if (this.props.tournamentRegisterModalStore.selfRegistration) {
            if (this.props.user) {
                return this.props.user.get("email");
            }
        }
        return "";
    }
    getSexSelectValue() {
        if (this.props.tournamentRegisterModalStore.selfRegistration) {
            if (this.props.tournamentRegisterModalStore.userDetail != null) {
                return this.props.tournamentRegisterModalStore.userDetail.getSex();
            }
        }
        // return male
        return 0;
    }
    configBowSelected(configBowType) {
        tournamentRegisterModalActions.updateSelectedConfigBow(configBowType);
    }
    configAgeSelected(configAgeType) {
        tournamentRegisterModalActions.updateSelectedConfigAge(configAgeType);
    }
    configSexSelected(sex) {
        // not needed now
    }
    optionSelected(option) {
        tournamentRegisterModalActions.updateSelectedOption(option);
    }
    slotSelected(slotId) {
        tournamentRegisterModalActions.updateSelectedRegSlot(slotId);
    }
    render() {
        const {tournament, tournamentRegisterModalStore, cancelRegistration} = this.props;
        const validateNow = this.state.error && this.state.error.length > 0;
        let curr = "E";
        if (tournament != null) {
            const tPrice = tournament.getTournamentPriceID();
            if (tPrice) {
                curr = tPrice.getCurrency();
            }
        }
        return (<Row>
                <Col xs={12}>
                    {
                        tournamentRegisterModalStore.selfRegistration ?
                            <h4><I18n code="tournament.register.description.self"/></h4>
                            :
                            <h4><I18n code="tournament.register.description.foreign"/></h4>

                    }
                    <br/>
                </Col>
                {
                    tournamentRegisterModalStore.loadingUserDetail === true || tournament == null ?
                        <Loading/>
                        :
                        <form action="#">
                            {
                                tournamentRegisterModalStore.registeredTournamentUsers != null ?
                                    <Col  sm={12}>
                                        <SelectInputTypeAhead
                                            objectList={tournamentRegisterModalStore.registeredTournamentUsers}
                                            handleSelectedObject={this.fillFormWithTournamentUser}
                                            placeholder={messages.get("tournament.register.search4name")}/>
                                        <hr/>
                                    </Col> : null
                            }
                            <Col sm={6}>
                                <ValidInput ref={this.refName}
                                            valid={{maxLength: 30, check: ['isRequired']}}
                                            validateNow={validateNow}
                                            placeholder={messages.get("tournament.register.name")}
                                            default={this.getUserDetailDefault(TournamentUser.prototype.col.name)}/>
                            </Col>
                            <Col sm={6}>
                                <ValidInput ref={this.refSurname}
                                            valid={{maxLength: 30, check: ['isRequired']}}
                                            validateNow={validateNow}
                                            placeholder={messages.get("tournament.register.surname")}
                                            default={this.getUserDetailDefault(TournamentUser.prototype.col.surname)}/>
                            </Col>
                            <Col sm={12}>
                                <ValidInput ref={this.refMail}
                                            valid={{maxLength: 50, check: ['isRequired','isMail']}}
                                            validateNow={validateNow}
                                            placeholder={messages.get("tournament.register.email.unique")}
                                            addonBefore={<FontAwesome icon="envelope"/>}
                                            default={this.getUserMailDefault()}/>

                                <TournamentSexSelect inputRef={this.refSex}
                                                     defaultValue={this.getSexSelectValue()}
                                                     selectCallback={this.configSexSelected} />

                                <TournamentConfigBowSelect label={messages.get("tournament.class.bow")}
                                                           defaultValue={tournamentRegisterModalStore.selectedBowTypeID}
                                                           tournament={tournament} selectCallback={this.configBowSelected}/>

                                {
                                    tournamentRegisterModalStore.bowUnion && tournamentRegisterModalStore.bowUnion.showAgeConfigCalc() ?
                                        <TournamentConfigAgeCalculator tournament={tournament} selectCallback={this.configAgeSelected}/> :null
                                }

                                <TournamentConfigAgeSelect label={messages.get("tournament.class.age")}
                                                           value={tournamentRegisterModalStore.selectedAgeTypeID}
                                                           tournament={tournament} selectCallback={this.configAgeSelected}/>
                                {
                                    tournamentRegisterModalStore.tOption != null ?
                                        <TournamentOptionSelect tOption={tournamentRegisterModalStore.tOption}
                                                                tournament={tournament} preSelect={false}
                                                                selectedAgeType={tournamentRegisterModalStore.selectedAgeType}
                                                                selectCallback={this.optionSelected} /> : null
                                }
                                {
                                    tournamentRegisterModalStore.tournamentRegSlots != null && tournamentRegisterModalStore.tournamentRegSlots.length > 0 ?
                                       <TournamentRegSlotSelect tournamentRegSlots={tournamentRegisterModalStore.tournamentRegSlots} showAll={false}
                                                                preSelect={false} selectCallback={this.slotSelected}/> : null
                                }
                                <ValidInput ref={this.refUnion}
                                            valid={{maxLength: 40}}
                                            validateNow={validateNow}
                                            placeholder={messages.get("tournament.register.union")}
                                            addonBefore={<FontAwesome icon="university"/>}
                                            default={tournamentRegisterModalStore.selfRegistration ? tournamentRegisterModalStore.userLicenseUnion : null}/>

                                {
                                    tournamentRegisterModalStore.bowUnion && tournamentRegisterModalStore.bowUnion.isLicenseNumberMandatory() ?
                                        <ValidInput ref={this.refLicense} constDefault={tournamentRegisterModalStore.bowUnion.isAutoLicenseNumber()}
                                                    valid={{maxLength: 30, check: ['isRequired']}}
                                                    label={tournamentRegisterModalStore.bowUnion.getLicenseLabel() ?
                                                        tournamentRegisterModalStore.bowUnion.getLicenseLabel() + " #" : messages.get("modal.license")}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.register.mandatory.license")}
                                                    addonBefore={<FontAwesome icon="id-card"/>}
                                                    default={tournamentRegisterModalStore.selfRegistration || tournamentRegisterModalStore.bowUnion.isAutoLicenseNumber() ? tournamentRegisterModalStore.userLicenseNumber : null}/> : null
                                }

                                <ValidInput ref={this.refNote}
                                            componentClass="textarea"
                                            valid={{maxLength: 1500}}
                                            validateNow={validateNow}
                                            placeholder={messages.get("tournament.register.note")}/>

                                <br/><label>{messages.get("tournament.register.label.price")}: </label>{curr + " " + tournamentRegisterModalStore.price}
                                <br/><label>{messages.get("tournament.register.label.payCondition")}: </label>{this.showPaymentCondition()}
                                {this.state.error ?
                                    <Alert bsStyle="warning">{this.state.error}</Alert> : ""}
                                <br/>
                            </Col>
                            <Col xs={6}>
                                <SubmitButton ref={this.refSubmitButton} block
                                              onClick={this.handleSubmitRegistration} disabled={this.state.submitDisabled}
                                              icon={<FontAwesome icon="check"/>}
                                              text={messages.get("tournament.register.submit")}
                                              bsStyle="primary"/>
                            </Col>
                            <Col xs={6}>
                                <Button block onClick={cancelRegistration } disabled={this.state.submitDisabled}
                                        bsStyle="default"><FontAwesome icon="times"/>{messages.get("modal.button.cancel")}</Button>

                            </Col>
                        </form>
                }
            </Row>
        )
    }
}
TournamentRegistrationFormSingle.contextType = BaseContext;
TournamentRegistrationFormSingle.propTypes = {
    user: PropTypes.object.isRequired,
    tournament: PropTypes.object.isRequired,
    tournamentRegisterModalStore: PropTypes.object.isRequired,
    cancelRegistration: PropTypes.func.isRequired
};
module.exports = TournamentRegistrationFormSingle;
