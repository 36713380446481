const alt = require("AltInit");
const notificationActions = require("components/notification/NotificationActions");

const parseDao = require("actdao/ParseBaseDao");
const managementDao = require("actdao/ManagementDao");
const clubDao = require("actdao/ClubDao");
const parcoursDao = require("actdao/ParcoursDao");

const msgActions = require("actions/MessageActions");

class ManagementActions {

    /**
     * Experiment: Add a initXPage action that initializes all data needed on a whole page.
     * This is the first one, lets see if this is a good approach...
     * We return (dispatch) a payload, that contains all data needed in multiple stores.
     * So each store takes from the data what it needs.
     */
    initRequestParcoursManagePage(user, parcoursOwnerId) {
        /**
         * load parcours owner
         */
        parcoursDao.getParcoursOwner(parcoursOwnerId)
            .then(parcoursOwner => {
                /**
                 * load management for user
                 */
                managementDao.getForUser(user)
                    .then(management => {
                            this.initRequestParcoursManagePageSuccess({
                                parcoursOwner: parcoursOwner,
                                management: management
                            })
                        })
                        .catch(error => {
                            error.trace = "MGMA.loadMgmt4User";
                            notificationActions.parseError(error);
                        });
            })
            .catch(error => {
                error.trace = "MGMA.getParcoursOwner";
                notificationActions.parseError(error);
            });
        return {};
    }

    initBusinessRegisterPage(user, clubID) {
        clubDao
            .loadClubDetails(clubID)
            .then(pClubDetail => {
                managementDao.getForUser(user)
                    .then(management => {
                        this.initBusinessRegisterPageSuccess({
                            management: management,
                            club: pClubDetail
                        })
                    })
                    .catch(error => {
                        error.trace = "MGMA.loadMgmt4User";
                        notificationActions.parseError(error);
                    });
            })
            .catch(error => {
                error.trace = "MGMA.showCLubDetails";
                notificationActions.parseError(error);
            });
        return {};
    }

    initBusinessRegisterPageSuccess(data) {
        return data
    }

    initRequestParcoursManagePageSuccess(data) {
        return data;
    }

    register(address, features, callback) {
        managementDao.register(address, features)
            .then(management => {
                if (callback && typeof callback === 'function') {
                    callback(management);
                } else {
                    notificationActions.success("Anmeldung erfolgreich");
                    this.registerSuccess(management)
                }
            })
            .catch(error => {
                notificationActions.parseError(error)

            });
        return {};
    }

    registerSuccess(management) {
        return {management}
    }

    requestParcoursManagement(management, parcoursOwner) {
        managementDao.requestParcoursManagement(management, parcoursOwner)
            .then(management => {
                // slack info
                msgActions.slackCustomerNotify(management.getName() + " requestParcoursManagement für " + parcoursOwner.getName(), null, null);
                this.requestParcoursManagementSuccess(management);
            })
            .catch(error => {
                notificationActions.parseError(error)
            });
        return {};
    }

    requestClubManagement(management, club) {
        managementDao.requestClubManagement(management, club)
            .then(sManagement => {
                msgActions.slackCustomerNotify(management.getName() + " requestClubManagement für " + club.getName(), null, null);
                club.setManagementID(sManagement);
                parseDao.saveObject(club)
                    .then(sClub => {
                        this.initBusinessRegisterPageSuccess({
                            management: management,
                            club: sClub
                        })
                    })
                    .catch(error => {
                        error.trace = "MGMA.addManagement2Club";
                        notificationActions.parseError(error);
                    });
            })
            .catch(error => {
                error.trace = "MGMA.addManagement2Club";
                notificationActions.parseError(error);
            });
        return {};
    }

    requestParcoursManagementSuccess(management) {
        return function (dispatch) {
            managementDao
                .get(management.id)
                .then(
                    management => dispatch(management),
                    error => notificationActions.parseError(error)
                )
        }
    }

    cancelManagementRequest(management, managementRequest) {
        managementDao.cancelManagementRequest(management, managementRequest)
            .then(management => {
                    this.cancelManagementRequestSuccess(management)
                },
                error => notificationActions.parseError(error)
            )
    }

    cancelManagementRequestSuccess(management) {
        return management;
    }

}

module.exports = alt.createActions(ManagementActions);
