const Parse = require("ParseInit");
const Utility = require("util/Utility");
const Management = Parse.Object.extend("management", {
    col: {
        ACL: "ACL",
        addEditor: "addEditor",
        aboStartDate: "aboStart",
        aboEndDate: "aboEnd",
        aboPrice: "aboPrice",
        description: "description",
        businessPartnerID: "businessPartnerID",
        createdAt: "createdAt",
        currency: "currency",
        denyWikiChanges: "denyWikiChanges",
        extendAboAnyOne: "extendAboAnyOne",
        invoiceTaxConfig: "invTaxConfig",
        invoiceNumberID: "invoiceNumberID",
        settlementTaxConfig: "settleTaxConfig",
        clubInvoiceIDs: "clubInvoiceIDs",
        features: "features",
        mainEditor: "mainEditor",
        managementRequestIDs: "managementRequestIDs",
        name: "name",
        objectId: "objectId",
        parcoursOwnerID: "parcoursOwnerID",
        clubID: "clubID",
        percentage: "percentage",
        mailConfig: "mailconfig", // email for sending parcours payment infos
        status: "status",
        tournamentID: "tournamentID",
        updatedAt: "updatedAt",
    },
    feature: {
        tournamentManagement: "TM",
        paymentSystem: "PP",
        parcours: "PA",
        unionManagement: "UM",
        voucher: "VA"
    },
    brand: {
        visa: "VISA",
        master: "MASTER",
        sofort: "SOFORT",
        paypal: "PAYPAL",
        bluecode: "BLUECODE"
    },
    minPrice: "minPrice",
    invTaxConfig: {
        positionTax: "position_tax",
        surchargeTax: "surcharge_tax",
        byPass: "byPass",
        igErwerb: "igErwerb"
    },
    settleTaxConfig: {
        positionTax: "position_tax",
        provisionTax: "provision_tax"
    },
    isActive() {
        return this.getStatus() === "A"
    },
    getDatalistText() {
        if (this.getBusinessPartnerID() != null && this.getBusinessPartnerID().getAddressID() != null) {
            let address = this.getBusinessPartnerID().getAddressID();
            return this.getName() + ", " + address.getCompany()  + ", " + address.getFullName() + ", " + address.getZipPlace()
        } else {
            return this.getName();
        }
    },
    getDatalistImage() {
        return null;
    },
    getLabels() {
        const lblArray = [this.getName()];
        if (this.getBusinessPartnerID() != null && this.getBusinessPartnerID().getAddressID() != null) {
            let address = this.getBusinessPartnerID().getAddressID();
            lblArray.push(address.getCompany());
            lblArray.push(address.getFullName());
            lblArray.push(address.getZipPlace());
        }
        return lblArray.join(" ");
    },
    hasFeature(feature) {
        const featureList = this.getFeatures();
        if (featureList != null) {
             return featureList.indexOf(feature) > -1
        }
        return false;
    },
    hasTournamentFeature() {
        return this.hasFeature(this.feature.tournamentManagement);
    },
    hasParcoursFeature() {
        return this.hasFeature(this.feature.parcours);
    },
    hasClubFeature() {
        return this.hasFeature(this.feature.unionManagement);
    },
    hasPaymentFeature() {
        return this.hasFeature(this.feature.paymentSystem);
    },
    hasVoucherFeature() {
        return this.hasFeature(this.feature.voucher);
    },
    isEditor(user) {
        let result = false;
        if (this.getMainEditor() && user) {
            if (user.id === this.getMainEditor().id) {
                result = true;
            } else {
                let addEditors = this.getAddEditor();
                if (addEditors) {
                    addEditors.map(editor =>{
                        if (user.id === editor.id) {
                            result = true;
                        }
                    });
                }
            }
        }
        return result;
    },
    allowExtendAbo(user) {
        if (this.isExtendAboAnyone()) {
            return true; // anyone can extend
        }
        return this.isEditor(user);
    },
    isExtendAboAnyone() {
      return this.get(this.col.extendAboAnyOne)
    },
    getPercentageObject() {
        return this.get(this.col.percentage);
    },
    getPercentage(brand) {
        const percentage = this.getPercentageObject();
        if (percentage != null) {
            return percentage[brand];
        }
        return "0";
    },
    hasUnpaidInvoices() {
        let invoices = this.getClubInvoiceIDs();
        if (invoices != null) {
            for (let i = 0; i < invoices.length; i++) {
                if (!invoices[i].isPaid()) {
                    return true
                }
            }
        }
        return false;
    },
    setPercentage(percentageObj) {
        return this.set(this.col.percentage, percentageObj)
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAboStartDate() {
        return this.get(this.col.aboStartDate);
    },
    getAboEndDate() {
        return this.get(this.col.aboEndDate);
    },
    getAboPrice() {
        return this.get(this.col.aboPrice);
    },
    isAboFilled() {
        return (this.getAboPrice() > 0 && (this.getAboStartDate() != null || this.getAboEndDate() != null));
    },
    isAboExpired() {
        if (this.getAboEndDate() != null) {
            return new Date().getTime() > this.getAboEndDate().getTime();
        }
        return false;
    },
    setAboPrice(aboPrice) {
        return this.set(this.col.aboPrice, aboPrice);
    },
    getAboStartDateReadable() {
        const date = this.get(this.col.aboStartDate);
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    getAboEndDateReadable() {
        const date =  this.get(this.col.aboEndDate);
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    setAboStartDate(date) {
        return this.set(this.col.aboStartDate, date);
    },
    setAboEndDate(date) {
        return this.set(this.col.aboEndDate, date);
    },
    getClubInvoiceIDs() {
        return this.get(this.col.clubInvoiceIDs)
    },
    setClubInvoiceIDs(invoices) {
        return this.set(this.col.clubInvoiceIDs, invoices)
    },
    getAddEditor() {
        return this.get(this.col.addEditor)
    },
    setAddEditor(addEditor) {
        return this.set(this.col.addEditor, addEditor)
    },
    getBusinessPartnerID() {
        return this.get(this.col.businessPartnerID)
    },
    setBusinessPartnerID(businessPartnerID) {
        return this.set(this.col.businessPartnerID, businessPartnerID)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getFeatures() {
        return this.get(this.col.features)
    },
    getCurrency() {
        return this.get(this.col.currency)
    },
    setCurrency(currency) {
        return this.set(this.col.currency, currency)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    setFeatures(features) {
        return this.set(this.col.features, features)
    },
    getMainEditor() {
        return this.get(this.col.mainEditor)
    },
    setMainEditor(mainEditor) {
        return this.set(this.col.mainEditor, mainEditor)
    },
    getManagementRequestIDs() {
        return this.get(this.col.managementRequestIDs)
    },
    setManagementRequestIDs(managementRequestIDs) {
        return this.set(this.col.managementRequestIDs, managementRequestIDs)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getParcoursOwnerID() {
        return this.get(this.col.parcoursOwnerID)
    },
    setParcoursOwnerID(parcoursOwnerID) {
        return this.set(this.col.parcoursOwnerID, parcoursOwnerID)
    },
    getClubIDs() {
        return this.get(this.col.clubID)
    },
    addClubID(clubID) {
        let clubs = this.getClubIDs();
        if (clubs == null) {
            clubs = [];
        }
        clubs.push(clubID);
        return this.setClubIDs(clubs);
    },
    setClubIDs(clubIDs) {
        return this.set(this.col.clubID, clubIDs)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getInvoiceTaxConfig() {
        return this.get(this.col.invoiceTaxConfig)
    },
    getInvoicePositionTax() {
        let invTax = this.getInvoiceTaxConfig();
        if (invTax != null) {
            return invTax[this.invTaxConfig.positionTax];
        }
        return 0
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = Management;
