const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid} = require("react-bootstrap");
const  messages = require("i18n/messages"),
    linkUtil = require("linkUtil");
const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");
const ClubLoader = require("components/club/ClubLoader");

const ArticleConfigList = require("components/form/ArticleConfigList");
const ClubUser = require("parse/ClubUser");
const clubActions = require("actions/ClubActions");

class ClubArticlePage extends React.Component {
    render() {
        return <ClubLoader clubID={this.props.clubID} clubUserRole={ClubUser.prototype.role.userHandling} loadClubUsers={false} loginMandatory={true}>
            <ClubDetails group={this.props.group}/>
        </ClubLoader>
    }
}
ClubArticlePage.propTypes = {
    clubID: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired
};
const ClubDetails = ({user, club, group}) => {
    function handleArticleStatusChange(article, status) {
        if ("D" === status) {
            console.log("Delete and remove article " + article.id);
            clubActions.deleteClubArticle(club, article);
        } else {
            console.log("Update article " + article.id +  " to " + status);
            clubActions.updateArticleStatus(club, article, status)
        }
    }
    function createClubArticle(editArticle, values) {
        console.log("create article ", values);
        clubActions.createOrEditClubArticle(club, editArticle, values);
    }
    document.title = club.getName();
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/club" code="header.nav.clubs"/>
                        <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                        {
                            group == "member" ?
                                <Breadcrumb link={"/club/" + club.id + "/members"} code="cl_role_user"/> :
                                <Breadcrumb link={"/club/" + club.id + "/invoice"} code="cl_role_invcreation"/>
                        }

                        <Breadcrumb code={"club.member.type." + group}  active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <h3>
                        <small>{messages.get("club.member.type." + group)}</small>
                        <br/>
                        {club.getName()}
                    </h3>
                </Col>
                <Col lg={12}>
                    <ArticleConfigList user={user}
                                       articles={club.getArticles()}
                                       articleTypes={[group]}
                                       handleArticleStatusChange={handleArticleStatusChange}
                                       handleSubmit={createClubArticle}/>
                </Col>
            </Row>
        </Grid>)
};
ClubDetails.propTypes = {
    club:PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    group: PropTypes.string.isRequired 
};
module.exports = ClubArticlePage;
