var Parse = require("ParseInit");
var Utility = require("util/Utility");

var ClubUser = Parse.Object.extend("clubUser", {
    status: {
        active: "A",
        deleted: "D"
    },
    reqStati: {
        accepted: "A",
        requested: "R",
        invited: "I",
        declined: "X"
    },
    role: {
        owner: "cl_role_owner",
        userHandling: "cl_role_user",
        messaging: "cl_role_msg",
        invoice: "cl_role_invoice",
        member: "cl_role_member",
        exMember: "cl_role_exMember",
        address: "cl_role_address",
    },
    col: {
        userDetailID: "userDetailID",
        articleID: "articleID",
        addressTwoID: "addressTwoID",
        paymentTargetID: "paymentTargetID",
        userID: "userID",
        userNr: "userNr",
        clubID: "clubID",
        comment: "comment",
        status: "status",
        reqStatus: "reqStatus",
        groups: "groups",
        functions: "functions",
        role: "role",
        objectId: "objectId",
        entryDate: "entryDate",
        updatedAt: "updatedAt",
        createdAt: "createdAt"


    },
    // Builder
    buildClubOwner(user) {
        let clubUser = new ClubUser();
        clubUser.setUser(user);
        if (user.get("userDetailID")) {
            clubUser.setUserDetailID(user.get("userDetailID"));
        }
        clubUser.setEntryDate(new Date());
        clubUser.setRole(this.role.owner);
        clubUser.setFunctions(["cl_role_user","cl_role_msg","cl_role_invoice"]);
        clubUser.setStatus(this.status.active);
        clubUser.setReqStatus(this.reqStati.accepted);
        return clubUser;
    },
    getDatalistText() {
        return this.getFullName() + ", " + this.getCompany() +", " + this.getFromAddress("zip") + " " + this.getFromAddress("place");
    },
    getSortColumn(colName) {
        if (colName === "fullName") {
            return this.getFullSurName()
        } else if (colName === "name") {
            return this.getSurname()
        } else if (colName === "nr") {
            return this.getUserNumber();
        }
    },
    getDatalistImage() {
        return null;
    },
    buildClubMember(user, club) {
        const clubUser = this.buildClubUser(user, club);
        clubUser.setReqStatus(this.reqStati.accepted);
        return clubUser;
    },
    buildClubUserRequest(user, club) {
        const clubUser = this.buildClubUser(user, club);
        clubUser.setReqStatus(this.reqStati.requested);
        return clubUser;
    },
    buildClubUser(user, club) {
        const clubUser = new ClubUser();
        clubUser.setClubID(club);
        if (user) {
            clubUser.setUser(user);
            if (user.get("userDetailID")) {
                clubUser.setUserDetailID(user.get("userDetailID"));
            }
        }
        clubUser.setEntryDate(new Date());
        clubUser.setRole(this.role.member);
        clubUser.setStatus(this.status.active);
        return clubUser;
    },
    getFullName() {
        let usrDetail = this.getUserDetailID();
        if (usrDetail != null && usrDetail.get("name")) {
            return usrDetail.get("name") + " " + usrDetail.get("surname");
        }
        let usr = this.getUserID();
        if (usr != null) {
            return usr.get("username");
        }
        if (this.hasClubAddressOnly() && this.getAddressTwoID()) {
            return this.getAddressTwoID().getFullName();
        }
        return "-"
    },
    getCompany() {
        if (this.hasClubAddressOnly() && this.getAddressTwoID()) {
            return this.getAddressTwoID().getCompany();
        }
        return "";
    },
    getFullSurName() {
        let usrDetail = this.getUserDetailID();
        if (usrDetail != null && usrDetail.get("name")) {
            return usrDetail.get("surname") + " " + usrDetail.get("name");
        }
        let usr = this.getUserID();
        if (usr != null) {
            return usr.get("username");
        }
        if (this.hasClubAddressOnly() && this.getAddressTwoID()) {
            return this.getAddressTwoID().getFullSurName();
        }
        return "-"
    },
    //
    // custom
    //
    getLabels() {
        return [this.getFullName(), this.getCompany(), this.getEmail(), this.getFromAddress("place"), this.getFromAddress("zip")].join(" ");
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    hasClubOwner() {
        return this.getRole() === this.role.owner;
    },
    hasClubMember() {
        return this.getRole() === this.role.member;
    },
    isActiveMember() {
        return this.hasClubOwner() || this.hasClubMember();
    },
    isExMember() {
        return this.getRole() === this.role.exMember;
    },
    hasClubAddressOnly() {
        return this.getRole() === this.role.address;
    },
    hasUserHandling() {
        return this.hasFunction(this.role.userHandling);
    },
    hasMessaging() {
        return this.hasFunction(this.role.messaging);
    },
    hasInvoice() {
        return this.hasFunction(this.role.invoice);
    },
    hasFunction(fct) {
        if (this.isActiveMember()) {
            var fctList = this.getFunctions();
            if (fctList != null) {
                return fctList.indexOf(fct) > -1
            }
        }
        return false;
    },
    isReqStatusRequested() {
        return this.reqStati.requested === this.getReqStatus();
    },
    isReqStatusAccepted() {
        return this.reqStati.accepted === this.getReqStatus();
    },
    hasGroup(group) {
        let groupList = this.getGroups();
        if (groupList != null) {
            return groupList.indexOf(group) > -1
        }
        return false;
    },

    isArticle(articleName) {
        let article = this.getArticleID();
        if (article) {
            return article.getName() == articleName;
        }
        return false;
    },
    getDate() {
        let tDate = this.getCreatedAt();
        return tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
    },
    getName() {
        let usrDetail = this.getUserDetailID();
        if (usrDetail != null && usrDetail.get("name")) {
            return usrDetail.get("name");
        }
        let usr = this.getUserID();
        if (usr != null) {
            return usr.get("username");
        }
        return "-"
    },
    getFromAddress(column) {
        let usrDetail = this.getUserDetailID();
        if (usrDetail != null && usrDetail.get("addressID")) {
            return usrDetail.get("addressID").get(column);
        }
        if (this.getAddressTwoID()) {
            return this.getAddressTwoID().get(column);
        }
        return ""
    },
    getSurname() {
        let usrDetail = this.getUserDetailID();
        if (usrDetail != null && usrDetail.get("surname")) {
            return usrDetail.get("surname");
        }
        return "-"
    },
    getBirthday() {
        let usrDetail = this.getUserDetailID();
        if (usrDetail) {
            return usrDetail.get("birthday")
        }
        return null;
    },
    getEmail() {
        let mail = null;
        let addrMail = this.getFromAddress("mail");
        if (addrMail && addrMail.length > 0) {
            mail = addrMail;
        }
        if (mail == null) {
            let usr = this.getUserID();
            if (usr != null) {
                mail = usr.get("email");
            }
        }
        return mail;
    },
    getEmail2() {
        let mail = null;
        let addrMail = this.getFromAddress("mail2");
        if (addrMail && addrMail.length > 0) {
            mail = addrMail;
        }
        return mail;
    },
    getAddressTwoID() {
        return this.get(this.col.addressTwoID)
    },
    setAddressTwoID(addressID) {
        return this.set(this.col.addressTwoID, addressID)
    },
    //
    // getter/setter
    //
    getClubID() {
        return this.get(this.col.clubID)
    },
    setClubID(clubID) {
        return this.set(this.col.clubID, clubID)
    },
    getArticleID() {
        return this.get(this.col.articleID)
    },
    setArticleID(articleID) {
        return this.set(this.col.articleID, articleID)
    },
    getUserNumber() {
        return this.get(this.col.userNr)
    },
    setUserNumber(userNr) {
        return this.set(this.col.userNr, userNr)
    },
    getComment() {
        return this.get(this.col.comment)
    },
    setComment(comment) {
        return this.set(this.col.comment, comment)
    },
    getUserDetailID() {
        return this.get(this.col.userDetailID);
    },
    setUserDetailID(userDetailID) {
        return this.set(this.col.userDetailID, userDetailID)
    },
    getUserID() {
        return this.get(this.col.userID)
    },
    setUserID(userID) {
        return this.set(this.col.userID, userID)
    },
    setUser(user) {
        this.setUserID(user);
        if (user.get("userDetailID") != null) {
            this.setUserDetailID(user.get("userDetailID"));
        }
    },
    getRole() {
        return this.get(this.col.role)
    },
    setRole(role) {
        return this.set(this.col.role, role)
    },
    getFunctions() {
        return this.get(this.col.functions)
    },
    setFunctions(fcts) {
        return this.set(this.col.functions, fcts)
    },
    addFunction(fct) {
        var fcts = this.getFunctions();
        if (fcts == null) {
            fcts = [];
        }
        fcts.push(fct);
        return this.setFunctions(fcts);
    },
    removeFunction(fct) {
        var fcts = this.getFunctions();
        if (fcts != null) {
            return this.setFunctions(fcts.filter(function (u) {
                return u != fct;
            }));
        }
        return null;
    },
    getFaIcon(fct) {
        if (this.role.userHandling == fct) {
            return "address-book";
        } else if (this.role.messaging == fct) {
            return "file-alt";
        } else if (this.role.invoice == fct) {
            return "calculator";
        }
        return "";
    },
    getGroups() {
        return this.get(this.col.groups)
    },
    setGroups(groups) {
        return this.set(this.col.groups, groups)
    },
    addGroup(group) {
        let groups = this.getGroups();
        if (groups == null) {
            groups = [];
        }
        groups.push(group);
        return this.setGroups(groups);
    },
    removeGroup(group) {
        let groups = this.getGroups();
        if (groups != null) {
            return this.setGroups(groups.filter(function (u) {
                return u != group;
            }));
        }
        return null;
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getPaymentTargetID: function () {
        return this.get(this.col.paymentTargetID)
    },
    setPaymentTargetID: function (paymentTargetID) {
        return this.set(this.col.paymentTargetID, paymentTargetID)
    },
    getReqStatus() {
        return this.get(this.col.reqStatus)
    },
    setReqStatus(status) {
        return this.set(this.col.reqStatus, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getEntryDate() {
        return this.get(this.col.entryDate)
    },
    getReadableEntryDate() {
        let tDate = this.getEntryDate();
        if (tDate != null) {
            return Utility.getReadableDate(tDate);
        }
        return null;
    },
    setEntryDate(entry) {
        return this.set(this.col.entryDate, entry)
    }
});

module.exports = ClubUser;
