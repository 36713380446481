const alt = require("AltInit"),
    Parse = require("parse");
const linkUtil = require("linkUtil");
const parseDao = require("actdao/ParseBaseDao");
const releaseDao = require("actdao/ReleasesDao");
const userDao = require("actdao/UserDao");
const notificationActions = require("components/notification/NotificationActions");

const {User, UserToken} = require("parse/_Domain");

class UserActions {
    register(user) {
        user.signUp()
            .then(sUser => {
                location.reload();
                // this.updateUser(user)
            })
            .catch(error => {
                console.log(error.message);
                this.showLoginError("parse.error." + error.code);
            });
        return {};
    };

    verifyVersion(bundleVersion) {
        releaseDao.queryLastWebReleases()
            .then(release => {
               if (!release.isLatestVersion(bundleVersion)) {
                   // invalid version
                   notificationActions.notifyNewVersion();
               }
            })
            .catch(error => {
                console.log("QueryRelease", + error);
            });
        return {};
    }

    loginUsernameOrEmail(usernameOrEmail, password) {
        if (usernameOrEmail.indexOf("@") > -1) {
            // mabye email entered
            userDao.queryEmailLogin(usernameOrEmail)
                .then(username => {
                    if (username && username.length > 0) {
                        this.login(username, password);
                    } else {
                        this.login(usernameOrEmail, password);
                    }
                })
                .catch(error => {
                    console.log("error: " + error.message);
                });
        } else {
            this.login(usernameOrEmail, password);
        }
        return {};
    };

    setTournamentRole(user) {
        userDao.setTournamentRole(user)
            .then(result => {
                console.log("Granted tournament role to " + user.get("username"));
            })
            .catch(error => {
                console.log("Error grant tournament role to " + user.get("username") + "_error: " + error.message);
            });
        return {};
    };

    login(username, password) {
        Parse.User.logIn(username, password)
            .then(user => {
                Parse.User.enableRevocableSession()
                    .catch(error => {
                        if (error && error.code == 100) {
                            console.log("CouldNotConnectToParse");
                            notificationActions.notifyParseOffline()
                        } else if(error && error.code == 209) {
                            console.log("ParseInvalidSession");
                            notificationActions.notifyInvalidSession();
                        }
                    })
                    .finally(_=> {
                        if (window.location.href.indexOf("userToken") > 0) {
                            // forward to start page as eg password was changed before
                            window.location.href = linkUtil.getLink("");
                        } else {
                            location.reload();
                        }
                    })
            })
            .catch(error => {
                if (error != null && error.code != null && error.code == 209) {
                    Parse.User.logOut();
                    window.location.reload()
                } else {
                    this.showLoginError("parse.error." + error.code);
                }
            });
        return {};
    };

    updateUserPassword(user, pwd, callback) {
        // update password
        const params = {userID: user.id, pass: pwd};
        userDao.editUserCCP(params)
            .then(result => {
                if (callback) {
                    callback(true);
                }
            })
            .catch(error => {
                error.trace="USRA.eUserPassword";
                notificationActions.parseError(error);
                if (callback) {
                    callback(false);
                }
            });
        return {};
    }


    saveUser(user, params) {
        userDao.editUserCCP(params)
            .then(result => {
                notificationActions.success("modal.login.save.success");
                this.refreshUser(user)
            })
            .catch(error => {
                error.trace = "USRA.saveUser";
                notificationActions.parseError(error);
            });
        return {};
    };

    hasRole(user, roleID, callback) {
        parseDao.hasRole(user, roleID)
            .then(hasRole => {
                callback(hasRole);
            })
            .catch(error => {
                error.trace = "USRA.hasRole";
                notificationActions.parseError(error);
                callback(false);
            });
        return {};
    }

    addRole(user, roleID, callback) {
        parseDao.addRole(user, roleID)
            .then(hasRole => {
                callback(hasRole);
            })
            .catch(error => {
                error.trace = "USRA.addRole";
                notificationActions.parseError(error);
                callback(false);
            });
        return {};
    }
    removeRole(user, roleID, callback) {
        parseDao.removeRole(user, roleID)
            .then(hasRole => {
                callback(!hasRole);
            })
            .catch(error => {
                error.trace = "USRA.removeRole";
                notificationActions.parseError(error);
                callback(false);
            });
        return {};
    }

    refreshUser(user) {
        if (user) {
            userDao.refreshUser(user)
                .then(fUser => {
                    this.updateUser(fUser);
                })
                .catch(error => {
                    error.trace = "USRA.fetchUser";
                    notificationActions.parseError(error);
                });
        }
        return {};

    }

    setVerifiedEmailFlag(user, callback) {
        const params = {userID: user.id, emailVerified: true};
        userDao.editUserCCP(params)
            .then(result => {
                this.refreshUser(user);
                callback(result)
            })
            .catch(error => {
                error.trace="USRA.vUserMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveUserImageFile(userDetail, parseFile, callback) {
        userDao.saveUserImageFile(userDetail, parseFile)
            .then(sUserDetail => {
                callback(sUserDetail)
            })
            .catch(error => {
                error.trace="USRA.saveImg";
                notificationActions.parseError(error);
                callback(false)
            })

    }

    fetchUserDetail(user) {
        this.refreshUser(user);
        if (user && user.get(User.prototype.col.userDetailID)) {
            userDao.fetchUserDetail(user)
                .then(fUserDetail => {
                    this.updateUserDetail(fUserDetail);
                })
                .catch(error => {
                    error.trace = "USRA.fetchUserDetail";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateUserDetail(null);
        }
        return {};
    };
    fetchUserAbos(user) {
        if (user && user.get(User.prototype.col.userAbos)) {
            userDao.fetchUserAbos(user)
                .then(userAbos => {
                    this.updateUserAbos(userAbos);
                })
                .catch(error => {
                    error.trace = "USRA.fetchUserAbo";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateUserAbos(null);
        }
        return {};
    }

    updateUserAboPause(userAbo, status) {
        userDao.updateUserAboPause(userAbo, status)
        .then(sUserAbo => {
            this.updateUserAbo(sUserAbo);
        })
        .catch(error => {
            error.trace = "USRA.updAbo";
            notificationActions.parseError(error);
        });
        return {};
    }


    updateParcoursFavorite(onlineID, add2Fav, user) {
        Parse.Cloud.run('userFavoriteParcours', {parcoursOnlineID: onlineID, add2Fav: add2Fav})
            .then(result => {
                this.fetchUserFavorite(user);
            })
            .catch(error => {
                error.trace = "USRA.usrFavoritParcours";
                notificationActions.parseError(error);
            });
        return {};
    };

    fetchUserFavorite(user) {
        if (user) {
            userDao.fetchUserFavorite(user)
                .then(userFavorite => {
                    this.updateUserFavorite(userFavorite);
                })
                .catch(error => {
                    error.trace = "USRA.fetchUserFavorite";
                    notificationActions.parseError(error);
                })
        }
        return {};
    }

    renameUserDetail(usrDetail, name, surname) {
        usrDetail.setFullName(name, surname);
        parseDao.saveObject(usrDetail)
            .then(sUsrDetail => {
                this.updateUserDetail(sUsrDetail);
            })
            .catch(error => {
                error.trace = "USRA.renameUserDetail.saveObject";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveObject(object) {
        parseDao.saveObject(object);
        return {}
    }

    createEmptyUserDetail(user, userDetail) {
        if (user) {
            if (userDetail != null) {
                userDao.createEmptyUserAddress(user, userDetail)
                    .then(usrDetail => {
                        this.updateUserDetail(usrDetail);
                    })
                    .catch(error => {
                        error.trace = "USRA.createEmptyUserAddress";
                        notificationActions.parseError(error);
                    });
            } else {
                // create userDetail and address
                userDao.createEmptyUserDetail(user)
                    .then(usrDetail => {
                        user.set("userDetailID", usrDetail);
                        parseDao.saveObject(user)
                            .then(sUser => {
                                this.updateUserDetail(usrDetail);
                                this.updateUser(sUser);
                            })
                            .catch(error => {
                                error.trace = "USRA.createEmptyUserDetail.saveObject";
                                notificationActions.parseError(error);
                            });
                    })
                    .catch(error => {
                        error.trace = "USRA.createEmptyUserDetail";
                        notificationActions.parseError(error);
                    });
            }
        }
        return {};
    }

    resetPasswordMail(email) {
        userDao.generateUserToken(email, UserToken.prototype.action.password)
            .then(result => {
                notificationActions.success("modal.password.reset.mail.success");
            })
            .catch(error => {
                error.trace="ADUA.rPwdMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadUserToken(userTokenID, callback) {
        userDao.loadUserToken(userTokenID)
            .then(userToken => {
                callback(userToken)
            })
            .catch(error => {
                error.trace="ADUA.lUsTo";
                notificationActions.parseError(error);
            });
        return {};
    }

    resendAuthenticationMail(user, userEmail) {
        this.refreshUser(user);
        userDao.resendAuthenticationMail(user.id, userEmail)
            .then(result => {
                notificationActions.success("contact.form.sendSuccess");
            })
            .catch(error => {
                error.trace="ADUA.rAuthMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateUserDetail(userDetail) {
        return userDetail;
    };

    updateUserAbos(userAbos) {
        return userAbos;
    }
    updateUserAbo(sUserAbo) {
        return sUserAbo
    }

    updateUserFavorite(userFavorite) {
        return userFavorite;
    };

    logout() {
        Parse.User.logOut()
            .then(result => {
                location.reload();
            })
            .catch(error => {
                notificationActions.parseError(error);
                location.reload();
            });
    };

    updateUser(user) {
        return (user);
    };

    showLoginModal() {
        return {};
    };

    showLoginCreateModal() {
        return {};
    };

    hideLoginModal() {
        return {};
    };

    showLoginError(messageCode) {
        return messageCode;
    };

    removeLoginError() {
        return {}
    }

    getIsAdmin() {
        parseDao.isUserAdmin()
            .then(response => {
                this.getIsAdminSuccess(true)
            })
            .catch(error =>{
                // user is no admin
                this.getIsAdminSuccess(false)
            });
        return {};
    }

    getIsAdminSuccess(isAdmin) {
        return isAdmin;
    }
}

module.exports = alt.createActions(UserActions);
