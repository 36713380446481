/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput"),
    DateInput = require("components/form/DateInput"),
    ModalBasic = require ("components/modals/ModalBasic"),
    messages = require("i18n/messages");

const ClubUser = require("parse/ClubUser");
const clubActions = require("actions/ClubActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

class ClubUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.editClubUser = this.editClubUser.bind(this);
        this.refModal = React.createRef();
    }
    editClubUser(clubUser) {
        this.refModal.current.openObject(clubUser, null)
    }
    render() {
        return (
            <ModalBasic ref={this.refModal}
                title={null}
                customButton={<React.Fragment></React.Fragment>}>
                <ClubUserForm club={this.props.club} />
            </ModalBasic>
        )
    }
};
ClubUserModal.propTypes = {
    club:PropTypes.object.isRequired
};

class ClubUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.refNumber = React.createRef();
        this.refEntryDate = React.createRef();
        this.valid = this.valid.bind(this);
        this.getDefault = this.getDefault.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
    }
    valid() {
        const { setErrorFct, object } = this.context;
        if (object === null) {
            setErrorFct({message: messages.get("modal.error.noObject")});
            return false;
        }
        let valid = true;
        let required = [
            this.refNumber.current.getValue().trim()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {object, closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);
        let clubUser = object;
        clubUser.setUserNumber(this.refNumber.current.getValue().trim());
        const actEntryDate = clubUser.getEntryDate();
        if (this.refEntryDate.current.getValue() != null && this.refEntryDate.current.getValue().trim().length > 0) {
            // birthday entered
            let newEntryDate = new Date(this.refEntryDate.current.getValue());
            if (actEntryDate != null) {
                // compare them
                if (newEntryDate.getTime() != actEntryDate.getTime()) {
                    // changed update it
                    clubUser.setEntryDate(newEntryDate);
                }
            } else {
                clubUser.setEntryDate(newEntryDate);
            }
        } else {
            // unset?
            if (actEntryDate != null) {
                clubUser.unset("entryDate");
            }
        }
        clubActions.saveClubUser(clubUser);
        closeFct();
    }
    getDefault(column) {
        const {object} = this.context;
        if (object != null) {
            return object.get(column);
        }
        return "";
    }
    render() {
        const {error, submitDisabled} = this.context;
        const validateNow = error && (error.message != null || error.length > 0);
        return (<React.Fragment>
            <Row>
                <Col xs={12}>
                    <ValidInput ref={this.refNumber}
                                valid={{maxLength: 10, check:['isRequired']}}
                                label={messages.get("address.userNr")}
                                default={this.getDefault(ClubUser.prototype.col.userNr)}
                                validateNow={validateNow}/>

                    <DateInput ref={this.refEntryDate} default={this.getDefault(ClubUser.prototype.col.entryDate)}
                               label={messages.get("address.entryDate")}
                               addonBefore={<FontAwesome icon="calendar-alt"/>}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                    <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                        <I18n code={"modal.button.save"}/>
                    </Button>
                </Col>
            </Row>

        </React.Fragment>)
    }
}
ClubUserForm.propTypes = {
    club:PropTypes.object.isRequired
};
ClubUserForm.contextType = ModalBasicContext;
module.exports = ClubUserModal;