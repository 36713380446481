const { UserAbo} = require("parse/_Domain");
const {isBefore, differenceInDays} = require('date-fns');

class UserFeatures {

    constructor() {
        this.userFeatures = {};
        this.maxAboEndDate = null;
        this.isFeatureEndNear = false;
    }

    setUserFeatures = function(pUserAbos) {
        let pFeat = {};
        if (pUserAbos != null && pUserAbos.length > 0) {
            pFeat[UserAbo.prototype.feature.gpsTrack] = this.isFeatureActive(pUserAbos, UserAbo.prototype.feature.gpsTrack);
            pFeat[UserAbo.prototype.feature.statistics] = this.isFeatureActive(pUserAbos, UserAbo.prototype.feature.statistics);
            pFeat[UserAbo.prototype.feature.ranking] = this.isFeatureActive(pUserAbos, UserAbo.prototype.feature.ranking);
            pFeat[UserAbo.prototype.feature.targets] = this.isFeatureActive(pUserAbos, UserAbo.prototype.feature.targets);
            pFeat[UserAbo.prototype.feature.training] = this.isFeatureActive(pUserAbos, UserAbo.prototype.feature.training);
            this.maxAboEndDate = this.getHighestActiveEndDate(pUserAbos);
            let diffDays = differenceInDays(this.maxAboEndDate, new Date())
            // console.log("Diffd", diffDays, this.maxAboEndDate)
            this.isFeatureEndNear = diffDays >= -30 && diffDays <= 30
        } else {
            pFeat[UserAbo.prototype.feature.gpsTrack] = false;
            pFeat[UserAbo.prototype.feature.statistics] = false;
            pFeat[UserAbo.prototype.feature.ranking] = false;
            pFeat[UserAbo.prototype.feature.targets] = false;
            pFeat[UserAbo.prototype.feature.training] = false;
            this.maxAboEndDate = null;
            this.isFeatureEndNear = false;
        }
        this.userFeatures = pFeat;
    };

    isFeatureActive = function(userAbodIDs, feature) {
        const lastFeatureDate = this.getFeatureEndDate(userAbodIDs, feature);
        if (lastFeatureDate) {
            return lastFeatureDate >= new Date();
        }
        return false;
    };
    getHighestActiveEndDate = function (userAboIDs) {
        let endDate = new Date(0);
        const length =  userAboIDs.length;
        for (let i = 0; i < length; i++) {
            if (UserAbo.prototype.status.active === userAboIDs[i].get(UserAbo.prototype.col.status)) {
                const aboEndDate = userAboIDs[i].get(UserAbo.prototype.col.aboEndDate);
                if (isBefore(endDate, aboEndDate)) {
                    // new highest endDate
                    endDate = aboEndDate;
                }
            }
        }
        return endDate;
    };
    getFeatureEndDate= function(userAboIDs, feature) {
        let endDate = null;
        const length =  userAboIDs.length;
        for (let i = 0; i < length; i++) {
            if (UserAbo.prototype.status.active === userAboIDs[i].get(UserAbo.prototype.col.status)) {
                const featureList = userAboIDs[i].get(UserAbo.prototype.col.features);
                if (featureList.indexOf(feature) > -1) {
                    // has feature return date
                    const aboEndDate = userAboIDs[i].get(UserAbo.prototype.col.aboEndDate);
                    if (endDate == null || isBefore(endDate, aboEndDate)) {
                        // new highest endDate
                        endDate = aboEndDate;
                    }
                }
            }
        }
        return endDate;
    };
    getMaxAboEndDate = function () {
        return this.maxAboEndDate;
    };
    isRankingFeatureActive= function () {
        return this.userFeatures[UserAbo.prototype.feature.ranking]
    };
    isStatisticFeatureActive= function () {
        return this.userFeatures[UserAbo.prototype.feature.statistics]
    };
    isGPSFeatureActive= function () {
        return this.userFeatures[UserAbo.prototype.feature.gpsTrack]
    };
    isTrainingFeatureActive= function () {
        return this.userFeatures[UserAbo.prototype.feature.training]
    };
    isTargetFeatureActive= function () {
        return this.userFeatures[UserAbo.prototype.feature.targets]
    };
}

module.exports = UserFeatures;