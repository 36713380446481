const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Alert, Well, Grid, ButtonGroup, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    LoginButton = require("components/widgets/LoginButton"),
    I18nObject = require("components/widgets/I18nObject"),
    SubmitButton = require("components/form/SubmitButton"),
    SelectInputTypeAhead  = require("components/form/SelectInputTypeAhead"),
    Loading = require("components/widgets/Loading"),
    Title = require("components/widgets/Title");
const Utility = require("util/Utility");
const PaymentElements = require("components/payment/PaymentElements");
const ExistingBusinessPartner = require("components/business/ExistingBusinessAccountInfo").ExistingBusinessPartner;

const userStore = require("stores/UserStore"),
    userActions = require("actions/UserActions"),
    voucherActions = require("actions/VoucherActions"),
    parcoursActions = require("actions/ParcoursActions"),
    parcoursStore = require("stores/ParcoursStore");

const paymentElementsStore = require("stores/PaymentElementsStore"),
    paymentElementsActions = require("actions/PaymentElementsActions"),
    accountActions = require("actions/AccountActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class SkillBoardProBuyPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handlePayEleStoreChange = this.handlePayEleStoreChange.bind(this);
        this.state = {
            userStore: userStore.getState(),
            parcoursStore: parcoursStore.getState(),
            paymentElementsStore: paymentElementsStore.getState()
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.3dskillboard.premium");
        userStore.listen(this.handleUserChange);
        parcoursStore.listen(this.handleParcoursChange);
        paymentElementsStore.listen(this.handlePayEleStoreChange);
        paymentElementsActions.initBasket(null);
        parcoursActions.queryParcoursCheckout("AT_3DSB_01");
        userActions.fetchUserAbos.defer(this.state.userStore.user);
        userActions.fetchUserDetail.defer(this.state.userStore.user);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        parcoursStore.unlisten(this.handleParcoursChange);
        paymentElementsStore.unlisten(this.handlePayEleStoreChange);
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handlePayEleStoreChange(state) {
        this.setState({paymentElementsStore: state});
    }
    render() {
        const {userStore, parcoursStore, paymentElementsStore} = this.state;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/skillboard" code="header.nav.3dskillboard"/>
                            <Breadcrumb link="/skillboard/premium" code="header.nav.3dskillboard.premium"/>
                            <Breadcrumb code="skillboard.premium.buynow" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Title code="header.nav.3dskillboard.premium"/>
                <Row>
                    <Col xs={12}>
                        <h2><I18n code="skillboard.premium.prices"/></h2>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        {
                            userStore.user && !userStore.userAboLoading && userStore.userAbos && userStore.userAbos.length > 0?
                                <Alert bsStyle="info"><h3><FontAwesome icon="thumbs-up"/><I18n code="skillboard.premium.aboAccountInfo1"/></h3><br/>
                                <I18n code="skillboard.premium.aboAccountInfo2"/> <a href={linkUtil.getLink("account")}><FontAwesome icon="cogs">{messages.get("header.nav.account")}.</FontAwesome></a><br/>
                                <I18n code="skillboard.premium.aboAccountInfo3"/><strong> {Utility.getReadableDate(userStore.userFeatures.getMaxAboEndDate())}</strong></Alert> : null
                        }
                        {
                            paymentElementsStore.premiumPriceAlert ?
                            <Alert bsStyle="success"><h3><FontAwesome icon="percent"/><I18nObject i18nObject={paymentElementsStore.premiumPriceAlert}/></h3></Alert>: null
                        }
                    </Col>

                </Row>
                {
                    parcoursStore.parcours && parcoursStore.parcoursArticles != null ?
                        <AboUserPayment parcours={parcoursStore.parcours}
                                        articles={paymentElementsStore.articles}
                                        parcoursArticles={parcoursStore.parcoursArticles}
                                        discount={paymentElementsStore.discount}
                                        user={userStore.user}
                                        userDetailLoading={userStore.userDetailLoading}
                                        userDetail={userStore.userDetail}
                                        voucherID={this.props.voucherID} index={this.props.index}/>
                        : <Loading/>
                }

            </Grid>)
    }
}
SkillBoardProBuyPage.propTypes = {
    voucherID: PropTypes.string,
    index: PropTypes.string
};
class AboUserRedeemVoucher extends React.Component {
    constructor(props) {
        super(props);
        this.searchForVoucher = this.searchForVoucher.bind(this);
        this.redeemVoucher = this.redeemVoucher.bind(this);
        this.activateVoucher = this.activateVoucher.bind(this);
        this.state = {
            redeemable: false,
            voucher: null,
            msStyle: "danger",
            message: null,
            managements: [],
        }
    }
    componentDidMount() {
        if (this.props.voucherID) {
            this.searchForVoucher(this.props.voucherID);
        }
    }
    searchForVoucher(code) {
        voucherActions.fetchVoucher.defer(code, voucher => {
            // check voucher valid
            if (voucher.isUsed()) {
                this.setState({voucher: null, redeemable: false, message: "skillboard.premium.voucher.error.used", msStyle: "danger"})
            } else if (voucher.isRegistered()) {
                this.setState({voucher: voucher, redeemable: false, message: "skillboard.premium.voucher.error.registered", msStyle: "danger"});
                accountActions.queryInvolvedManagementCallback.defer(this.props.user, managements => {
                    this.setState({managements: managements})
                });
            } else {
                this.setState({voucher: voucher, redeemable: true, message: null})
            }

        }, error => {
            this.setState({voucher: null, message: "skillboard.premium.voucher.error.invalid", msStyle: "danger"})
        })
    }
    redeemVoucher() {
        voucherActions.redeemVoucher(this.state.voucher, result => {
            let text = result;
            if (result == "UserAboSaved") {
                text ="skillboard.premium.voucher.success";
            }
            this.setState({redeemable: false, message: text, msStyle: "success"})
        })

    }
    activateVoucher(management) {
        console.log("Activate voucher with " + management.getName());
        voucherActions.activateVoucher(this.state.voucher, management, result => {
            let text = result;
            if (result == "VoucherActivated") {
                text ="skillboard.premium.voucher.success.activate";
            }
            this.setState({message: text, msStyle: "success", managements: []})
        })
    }
    render() {
        const {redeemable, voucher, message, msStyle, managements} = this.state;
        const {user} = this.props;
        return (<Row>

            {
                voucher == null ? <Col sm={12}>
                    <SelectInputTypeAhead
                        objectList={[]} defaultValue={this.props.voucherID}
                        handleNoObjectFound={this.searchForVoucher}
                        handleSelectedObject={(code) => this.searchForVoucher(code)}
                        placeholder={messages.get("skillboard.premium.voucher.entercode")}/>
                    <p><I18n code="skillboard.premium.voucher.uplowcase" /></p>
                </Col>  : <Col sm={12}>
                    <h3>{voucher.getText()}</h3>
                    <p><I18n code="modal.license.validUntil"/>: {voucher.getExpireDateReadable()}</p>
                    {redeemable ?
                        <SubmitButton bsSize="large" onClick={this.redeemVoucher}
                                      text={messages.get("profile.abo.redeem.voucher.for") + ": " + user.getUsername()}/> : null
                    }
                </Col>
            }
            {
                message ?  <Col sm={12}>
                    <Alert bsStyle={msStyle}>{messages.get(message)}</Alert>
                </Col>: null
            }
            {
                managements.length > 0 ?
                    <Col sm={12}>
                        { managements.map(management => {
                            if (management.hasVoucherFeature()) {
                                return <ExistingBusinessPartner management={management} manageCallback={this.activateVoucher}
                                                                showFeatures={false}
                                                                buttonText="skillboard.premium.voucher.activate" />
                            }
                        })}
                    </Col> : null
            }
        </Row>)
    }
}
AboUserRedeemVoucher.propTypes = {
    user:PropTypes.object.isRequired,
    voucherID: PropTypes.string.isRequired
};
class AboUserPayment extends React.Component {
    constructor(props) {
        super(props);
        this.preFilterSelectArticle = this.preFilterSelectArticle.bind(this);
        this.handleSwitchMode = this.handleSwitchMode.bind(this);
        this.state = {
            articleGroup: "single"
        }
    }
    componentDidMount() {
        if (this.props.voucherID) {
            this.handleSwitchMode("redeem");
        } else {
            this.handleSwitchMode("single");
        }
    }
    preFilterSelectArticle(articleGroup) {
        let articleList = this.props.parcoursArticles.filter( article => {
            return (articleGroup == article.getGroup())
        });
        if (this.props.index != null) {
            const idx = parseInt(this.props.index)
            if (idx < articleList.length) {
                console.log("Preselect "+ articleGroup + " / " + articleList[idx].getName());
                paymentElementsActions.setSingleTransactionArticle.defer(articleList[idx].id, articleList[idx].getPrice(), articleList[idx].getGroup());
                return;
            }
        }
        if (articleList.length > 0) {
            console.log("Preselect "+ articleGroup + " / " + articleList[0].getName());
            paymentElementsActions.setSingleTransactionArticle.defer(articleList[0].id, articleList[0].getPrice(), articleList[0].getGroup());
        }
    }
    handleSwitchMode(mode) {
        this.setState({articleGroup: mode}, () => {
            // called after setState is done
            this.preFilterSelectArticle(mode);
        });

    }
    render() {
        const {articleGroup} = this.state;
        const {user, userDetail, parcours, parcoursArticles, articles, userDetailLoading, voucherID} = this.props;
        if (user) {
            if (articleGroup != "redeem") {
                return (<React.Fragment>
                    <Well><Button bsSize="large" onClick={() => this.handleSwitchMode("redeem")}><FontAwesome icon="gift"/><I18n code="profile.abo.redeem.voucher"/></Button></Well>
                    <PaymentElements parcours={parcours}
                                     user={user}
                                     userDetailLoading={userDetailLoading}
                                     userDetail={userDetail}
                                     articleElement={
                                         <AboElements parcours={parcours} parcoursArticles={parcoursArticles} articleGroup={articleGroup}
                                                      articles={articles}/>
                                     }
                                     ruleImageID={null}/>
                </React.Fragment>)
            } else {
                return (<React.Fragment>
                    <Well><Button bsSize="lg" onClick={() => this.handleSwitchMode("single")}><FontAwesome icon="gift"/>X</Button></Well>
                        <Row>
                            <Col xsOffset={1} xs={10} mdOffset={2} md={8} >
                                <AboUserRedeemVoucher user={user} voucherID={voucherID} />
                            </Col>
                        </Row>
                    </React.Fragment>)
            }

        } else {
            return (<React.Fragment>
                    <Row>
                        <AboElements parcours={parcours} parcoursArticles={parcoursArticles} articleGroup={articleGroup}
                                     articles={articles}/>
                    </Row>
                    <Row>
                        <Col smOffset={3} sm={6}>
                            <Alert bsStyle="info">
                                <strong><I18n code="profile.abo.asklogin"/></strong><br/><br/>
                                <LoginButton/>
                            </Alert>
                        </Col>
                    </Row>

                </React.Fragment>)
        }
    }
}
AboUserPayment.propTypes = {
    user:PropTypes.object,
    userDetail:PropTypes.object,
    articles: PropTypes.object,
    parcours:PropTypes.object.isRequired,
    parcoursArticles:PropTypes.array.isRequired,
    userDetailLoading:PropTypes.bool.isRequired,
    voucherID: PropTypes.string,
    index: PropTypes.string
};
const AboElements = ({articleGroup, parcoursArticles, articles}) =>  {
    const onArticleButtonClick = (article) => {
        paymentElementsActions.setSingleTransactionArticle(article.id, article.getPrice(), article.getGroup());
    };
    return (<React.Fragment>
            <Col mdOffset={2} md={8} xsHidden>
                <ButtonGroup justified >
                    {
                        parcoursArticles ? parcoursArticles.map(article => {
                            if (articleGroup == article.getGroup()) {

                                return <AboDescLarge key={article.id}  article={article}
                                                     onArticleClick={onArticleButtonClick}
                                                     isSelected={articles[article.id] > 0}/>
                            }

                        }) : null
                    }
                </ButtonGroup>
                <br/><br/>
            </Col>
            <Col xs={12} smHidden mdHidden lgHidden>
                <ButtonGroup >
                    {
                        parcoursArticles ? parcoursArticles.map(article => {
                            if (articleGroup == article.getGroup()) {
                                return <AboDescSmall key={article.id} article={article}
                                                     onArticleClick={onArticleButtonClick}
                                                     isSelected={articles[article.id] > 0}/>
                            }
                        }) : null
                    }
                </ButtonGroup>
                <br/><br/>
            </Col>
    </React.Fragment>)
};
AboElements.propTypes = {
    parcours:PropTypes.object.isRequired,
    parcoursArticles:PropTypes.array.isRequired,
    articles: PropTypes.object.isRequired,
    articleGroup: PropTypes.string.isRequired
};

const AboDescLarge = ({article, isSelected, onArticleClick}) =>  {
    const onButtonClick = () => {
        onArticleClick(article);
    };
    return (
        // need add ButtonGroup as hack
        <ButtonGroup>
            <Button bsStyle={isSelected ? "success" : "default"} onClick={onButtonClick}>
                <h3>{article.getDescription()}</h3>
                <h3>{article.getName()}</h3>
                {
                    article.getOldPrice() ?
                        <h4><span style={{textDecoration: "line-through"}}>{article.getOldPrice().toFixed(2)}</span> <strong>{ " -> " +article.getCurrency() + " " + article.getPrice().toFixed(2)}</strong></h4>
                        :
                        <h4><strong>{article.getCurrency() + " " + article.getPrice().toFixed(2)}</strong></h4>
                }
            </Button>
        </ButtonGroup>)
};
AboDescLarge.propTypes = {
    article:PropTypes.object.isRequired,
    onArticleClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool
};
const AboDescSmall = ({article, isSelected, onArticleClick}) =>  {
    const onButtonClick = () => {
        onArticleClick(article);
    };
    return (
        // need add ButtonGroup as hack
        <Button block bsStyle={isSelected ? "success" : "default"} onClick={onButtonClick}>
            <h3>{article.getDescription()}</h3>
            <h4>{article.getName() + " ... "}
                {
                    article.getOldPrice() ?
                        <span><span style={{textDecoration: "line-through"}}>{article.getOldPrice().toFixed(2)}</span> <strong>{ " -> " +article.getCurrency() + " " + article.getPrice().toFixed(2)}</strong></span>
                        :
                        <strong>{article.getCurrency() + " " + article.getPrice().toFixed(2)}</strong>
                }
            </h4>
        </Button>);
};
AboDescSmall.propTypes = {
    article:PropTypes.object.isRequired,
    onArticleClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool
};
module.exports = SkillBoardProBuyPage;
