const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Table, Panel, Button, DropdownButton, MenuItem } = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    Loading = require("components/widgets/Loading"),
    InlineEditorWrapper = require("components/form/InlineEditorWrapper"),
    DateInput = require("components/form/DateInput"),
    ErrorSuspenseBoundary = require("components/widgets/ErrorSuspenseBoundary"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    linkUtil = require("linkUtil");
const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const ParcoursVisitForm= require("components/parcours/ParcoursVisitForm");

const {Parcours, ParcoursOwner, ParcoursVisit} = require("parse/_Domain");

const PDFPrintButton = React.lazy(() =>
    import(/* webpackChunkName: "TournamentResultPrint" */ "components/form/PDFPrintButton")
);

const {BaseContext} = require("context/BaseContext");

const parcoursActions = require("actions/ParcoursActions"),
    analyticsStore = require("stores/AnalyticsStore"),
    analyticsActions = require("actions/AnalyticsActions");
/**
 * Manage articles of parcoursowner and all its parcours.
 * start with id of parcoursowner.
 */
class ParcoursVisitListPage extends React.Component {
    constructor(props) {
        super(props);
        this.refEndDate = React.createRef();
        this.refStartDate = React.createRef();

        this.handleAnalyticsChange = this.handleAnalyticsChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.showVisitForm = this.showVisitForm.bind(this);
        this.updateParcoursVisit = this.updateParcoursVisit.bind(this);
        this.handlePrintQR = this.handlePrintQR.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.handleExport = this.handleExport.bind(this);
        let today = new Date();
        this.state = {
            analyticsStore: analyticsStore.getState(),
            searchParams: {
                startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(),23,59,59,0),
                ownerID: this.props.ownerID,
                parcoursID: null
            },
            parcoursVF: null
        }
    }
    componentDidMount() {
        const { user, checkUserAdmin } = this.context;
        analyticsStore.listen(this.handleAnalyticsChange);
        if (this.props.tstat === "admin") {
            checkUserAdmin(callbackIsAdmin => {
                analyticsActions.startUpParcoursVisitList(user, this.state.searchParams, callbackIsAdmin);
            })
        } else {
            analyticsActions.startUpParcoursVisitList(user, this.state.searchParams, false);
        }


    }
    componentWillUnmount() {
        analyticsStore.unlisten(this.handleAnalyticsChange);
    }
    handleAnalyticsChange(state) {
        this.setState({analyticsStore: state});
    }
    handleStartDateChange(value) {
        let dateVal = new Date(value);
        const pSearchParams = this.state.searchParams;
        pSearchParams.startDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate());

        if (new Date(this.refEndDate.current.getValue()) < dateVal) {
            // move endDate on the same day
            pSearchParams.endDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(),23,59,59,0);
            this.refEndDate.current.setValue(value);
        }
        this.setState({searchParams: pSearchParams});
        analyticsActions.queryParcoursVisits.defer(pSearchParams);
    }
    handleEndDateChange(value) {
        let dateVal = new Date(value);
        const pSearchParams = this.state.searchParams;
        pSearchParams.endDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(),23,59,59,0);

        if (new Date(this.refStartDate.current.getValue()) > dateVal) {
            // move startDate on the same day
            pSearchParams.startDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate());
            this.refStartDate.current.setValue(value);
        }

        this.setState({searchParams: pSearchParams});
        analyticsActions.queryParcoursVisits.defer(pSearchParams);
    }
    handleParcoursChange(parcours) {
        const pSearchParams = this.state.searchParams;
        pSearchParams.parcoursID = parcours ? parcours.id : null;
        this.setState({searchParams: pSearchParams});
        analyticsActions.queryParcoursVisits.defer(pSearchParams);
    }
    showVisitForm(value) {
        this.setState({parcoursVF: value});
    }
    updateParcoursVisit(parcoursVisit) {
        this.setState({
            parcoursVF: null,
        });
        analyticsActions.addParcoursVisit(parcoursVisit);
    }
    handlePrintQR() {
        const parcoursOwner = this.state.analyticsStore.parcoursOwner;
        let regTUserObject = {};
        regTUserObject.scale = 1.5;
        regTUserObject.line1 = messages.get("parcours.visit.header");
        regTUserObject.line2 = messages.get("parcours.visit.description.scan1");
        regTUserObject.line3 = messages.get("parcours.visit.description.scan2");
        if (parcoursOwner.getBannerImageID()) {
            regTUserObject.imageUrl = parcoursOwner.getBannerImageUrl();
        } else {
            regTUserObject.imageUrl = linkUtil.getFullLink('img/ico/ic_launcher_green.png')
        }
        regTUserObject.qrUrl = linkUtil.getFullLinkNoLanguage('parcours/') + parcoursOwner.id + '/home';
        console.log("Print ID-Card for:", regTUserObject);
        return regTUserObject;
    }
    handleExport() {
        analyticsActions.exportParcoursVisit(this.state.analyticsStore.parcoursVisits)
    }
    render() {
        const {analyticsStore, parcoursVF} = this.state;
        const { user } = this.context;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/mgmtparcours" code="profile.managedParcours.header" />
                            <Breadcrumb code="parcours.visit.list.header" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                {
                    analyticsStore.parcoursOwner != null ? <Row><Col xs={12}><h2>{analyticsStore.parcoursOwner.getName()}</h2></Col></Row> : null
                }
                <Row><Col xs={12}>
                    <Panel>
                        <Panel.Body>
                            <Row>
                                <Col xs={6} sm={3}>
                                    <DropdownButton title={messages.get("parcours.visit.button.newEntry")}
                                        disabled={analyticsStore.parcoursList == null} bsStyle="primary">
                                        {
                                            analyticsStore.parcoursList != null && analyticsStore.parcoursList.length > 0?
                                                analyticsStore.parcoursList.map(parcours => {
                                                    return <MenuItem key={parcours.id} onClick={() => this.showVisitForm(parcours)}>{parcours.getOnlineID() + ", " + parcours.getName() + ", " + messages.get("parcours.details.targets")  + ": " + parcours.getTargetAmounts()}</MenuItem>
                                                })
                                                :
                                                <MenuItem bsStyle="warning">{"Das Parcoursbuch muss in der Parcoursverwaltung für die jeweiligen Parcours aktiviert werden!"}</MenuItem>
                                        }
                                    </DropdownButton>
                                </Col>
                                <Col xs={6} sm={3}>
                                    <ErrorSuspenseBoundary>
                                        <PDFPrintButton bsStyle="primary" disabled={analyticsStore.parcoursOwner == null}
                                                        icon="print" label="QR-Code"
                                                        generatePrintData={this.handlePrintQR}/>
                                    </ErrorSuspenseBoundary>
                                </Col>
                                <Col xs={6} sm={3}>
                                    <Button bsStyle="primary" disabled={analyticsStore.parcoursVisits == null || analyticsStore.parcoursVisits.length <= 0} onClick={this.handleExport}>
                                        <FontAwesome icon="download"/>Export {messages.get("parcours.visit.header")}
                                    </Button>
                                </Col>
                            </Row>
                            <br/><br/>
                            {
                                parcoursVF ?
                                        <ParcoursVisitForm user={null} userDetail={null} address={null} editor={user}
                                                           parcours={parcoursVF} parcoursOwner={analyticsStore.parcoursOwner} callbackParcoursVisit={this.updateParcoursVisit}/> : null
                            }
                        </Panel.Body>
                    </Panel>
                </Col></Row>
                <hr/>
                <Row>
                    <Col xs={6} sm={3}>
                        <DateInput ref={this.refStartDate} default={new Date()}
                                   label={messages.get("tournament.create.date")}
                                   onAfterChange={this.handleStartDateChange}/>

                    </Col>
                    <Col xs={6} sm={3}>
                        <DateInput ref={this.refEndDate} default={new Date()}
                                   label={messages.get("tournament.create.enddate")}
                                   onAfterChange={this.handleEndDateChange}/>

                    </Col>
                    <Col xs={6} sm={3}>
                        {
                            analyticsStore.parcoursList != null && analyticsStore.parcoursList.length > 1 ?
                                <DropDownParseObject placeholder={<option key={"nullkey"}
                                                     value={"null"}>{messages.get("parcours.filter.placeholder")}</option>}
                                                     onAfterChange={this.handleParcoursChange}
                                                     label={messages.get("parcours.filter.header")}
                                                     objectList={analyticsStore.parcoursList}/>
                                : null
                        }
                    </Col>
                </Row>
                {
                    analyticsStore.loading ? <Loading /> :
                        analyticsStore.invalidUser ?
                            <Row><Col xs={12}><Alert bsStyle="warning"><I18n code="parse.error.119"/></Alert></Col></Row>
                            :
                            analyticsStore.parcoursVisits != null ?
                                <ParcoursVisitList parcoursVisits={analyticsStore.parcoursVisits}/>
                                :
                                <Row><Col xs={12}><Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert></Col></Row>
                }


            </Grid>)
    }
}
ParcoursVisitListPage.contextType = BaseContext;
ParcoursVisitListPage.propTypes = {
    ownerID: PropTypes.string.isRequired
};
const ParcoursVisitList = ({parcoursVisits}) => {
    return <Row><Col xs={12}>
        <h2>{messages.get("parcours.visit.description.amount") + parcoursVisits.length}</h2>
        <Table responsive striped hover>
            <thead>
            <tr>
                <th><I18n code="address.contactName"/></th>
                <th><I18n code="tournament.table.column.address"/></th>
                <th><I18n code="tournament.table.column.date"/></th>
                <th><I18n code="tournament.table.column.time"/></th>
                <th><I18n code="tournament.table.column.amount"/></th>
                <th><I18n code="tournament.table.column.group"/></th>
                <th>Parcours</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {parcoursVisits.map(parcoursVisit => {
                return (<VisitRow key={parcoursVisit.id} parcoursVisit={parcoursVisit}/>);
            })}
            </tbody>
        </Table>
    </Col></Row>

};
ParcoursVisitList.propTypes = {
    parcoursVisits: PropTypes.array.isRequired
};
const VisitRow = ({parcoursVisit}) => {
    function deleteVisit(doDelete) {
        if (doDelete) {
            analyticsActions.deleteParcoursVisit(parcoursVisit)
        }
    }
    function changeEndTime(value, closeWrapperCallback) {
        if (value != null && value.split(":").length === 2) {
            const changeObject = {};
            let visitDate = parcoursVisit.getStartTime();
            const endTimeParts = value.split(":");
            let visitEndTime = new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), parseInt(endTimeParts[0]), parseInt(endTimeParts[1]),0,0);
            changeObject[ParcoursVisit.prototype.col.endTime] = visitEndTime;

            if (parcoursVisit != null && changeObject != null) {
                parcoursActions.updateParcoursVisitStatus.defer(parcoursVisit, changeObject, parcoursVisit => {
                    // update element
                    analyticsActions.updateParcourVisit(parcoursVisit);
                    // close editor wrapper
                    closeWrapperCallback(true)
                })
            }
        }

    }
    const addArcherNames = parcoursVisit.getAddVisitorNames();
    return (
        <tr id={parcoursVisit.id}>
            <td>{parcoursVisit.getFullName()}<br/>
                <a href={"mailto:" + parcoursVisit.getMail()} >{parcoursVisit.getMail()}</a>
            </td>
            <td>{parcoursVisit.getStreet()}<br/>{parcoursVisit.getZipPlace()}<br/><a href={"tel:" + parcoursVisit.getPhone()} >{parcoursVisit.getPhone()}</a></td>
            <td>{parcoursVisit.getVisitDate()}</td>
            <td><FontAwesome icon="play"/>{parcoursVisit.getVisitStart()}<br/>
                <InlineEditorWrapper childrenValue={<FontAwesome icon="stop">{parcoursVisit.getVisitEnd()}</FontAwesome>}
                                     defEditValue={parcoursVisit.getVisitEnd()} type="text"
                                     label={messages.get("tournament.create.endtime")}
                                     buttonAfterCallback={changeEndTime}
                                     buttonAfterContent={<FontAwesome icon="save"/>}
                                     addonBefore={<FontAwesome icon="clock"/>}
                                     valid={{check:['isTime']}}/>

            </td>
            <td>{parcoursVisit.getVisitorAmount()}<br/>
                <PayAmountIcon icon="mobile-alt" amount={parcoursVisit.getPayMobileAmount()}/>
                <PayAmountIcon icon="money-bill-wave" amount={parcoursVisit.getPayCashAmount()}/>
                <PayAmountIcon icon="id-card" amount={parcoursVisit.getPayAnnualAmount()}/>
            </td>
            <td>{parcoursVisit.getNote() ? <p><FontAwesome icon="info-circle"> {parcoursVisit.getNote()}</FontAwesome></p> : null }
                {addArcherNames ? <React.Fragment>
                    { Object.keys(addArcherNames).map(id => {
                        return <p>{addArcherNames[id]}</p>
                    })}
                </React.Fragment> : null}</td>
            <td>{parcoursVisit.getParcoursOnlineID()}</td>
            <td><DeleteDecisionModal messageCode="parcours.visit.button.delete"
                                     tooltip={messages.get("list.entry.hdr.delete")}
                                     entryName={parcoursVisit.getFullName() + " - " + parcoursVisit.getVisitDate()} handleDelete={deleteVisit}/></td>
        </tr>)
};
VisitRow.propTypes = {
    parcoursVisit:PropTypes.object.isRequired
};
const PayAmountIcon = ({icon, amount}) => {
    if (amount != null && amount > 0) {
        return <FontAwesome icon={icon}>{amount}&nbsp;&nbsp;</FontAwesome>
    }
    return null;
}
module.exports = ParcoursVisitListPage;
