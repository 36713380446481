const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, FormControl, ControlLabel, InputGroup} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");

const TournamentUserEquipSelect = ({defaultValue, selectCallback}) =>{
    const changeOption = (event) => {
        if (event.target.value == "true") {
            selectCallback(true)
        } else if (event.target.value == "false") {
            selectCallback(false)
        } else {
            selectCallback(null)
        }
    };
    return (<FormGroup>
        <ControlLabel>{messages.get("profile.training.baProfile")}</ControlLabel>
        <InputGroup>
            <InputGroup.Addon><FontAwesome icon="clipboard-question"/></InputGroup.Addon>
            <FormControl componentClass="select" placeholder="select"
                         defaultValue={defaultValue}
                         onChange={changeOption}>
                <option value={null}>{messages.get("modal.license.choose")}</option>
                <option value={false}>{messages.get("tournament.mmtuser.tooltip.equipUnCheck")}</option>
                <option value={true}>{messages.get("tournament.mmtuser.tooltip.equipChecked")}</option>
            </FormControl>
        </InputGroup>
    </FormGroup>);
};
TournamentUserEquipSelect.propTypes = {
    defaultValue: PropTypes.string,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentUserEquipSelect;
