module.exports = {
    "description" : "Diese Datenschutzerklärung klärt Nutzer über die Art, den Umfang und Zwecke der Erhebung und Verwendung personenbezogener Daten durch den verantwortlichen Anbieter 3dturnier.com in der 3D SKill Board Android App sowie auf der 3dturnier Webseite auf.",
    "header1": "Datenschutz",
    "body1" : "Die Nutzung der App und der Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit personenbezogene Daten (beispielsweise Name oder eMail-Adressen) erhoben werden, erfolgt dies, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. " +
        "Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.",
    "header2": "Umgang mit personenbezogenen Daten",
    "body2" : "Personenbezogene Daten sind Informationen, mit deren Hilfe eine Person bestimmbar ist, also Angaben, die zurück zu einer Person verfolgt werden können. Dazu gehören der Name, die Emailadresse oder die Telefonnummer. Aber auch Daten über Vorlieben, Hobbies, Mitgliedschaften oder welche Webseiten von jemandem angesehen wurden zählen zu personenbezogenen Daten. \n" +
        "Personenbezogene Daten werden von dem Anbieter nur dann erhoben, genutzt und weiter gegeben, wenn dies gesetzlich erlaubt ist oder die Nutzer in die Datenerhebung einwilligen.",
    "header3": "Registrierfunktion",
    "body3" : "Die im Rahmen der Registrierung eingegebenen Daten werden für die Zwecke der Nutzung des Angebotes verwendet. Die Nutzer können über angebots- oder registrierungsrelevante Informationen, wie Änderungen des Angebotsumfangs oder technische Umstände per E-Mail informiert werden. Die erhobenen Daten sind aus der Eingabemaske im Rahmen der Registrierung ersichtlich. Dazu gehört der Name und die email Adresse.",
    "header4": "Kommentare, Parcours Informationen und -bilder",
    "body4" : "Wenn Nutzer einen Parcours erstellen, editieren, ein Kommentar dafür abgeben oder ein Bild dafür hochladen, werden ihre Namen und e-mail Adressen gespeichert. Das erfolgt zur Sicherheit des Anbieters, falls jemand in Kommentaren, Parcours Informationen, Bildern widerrechtliche Inhalte schreibt (Beleidigungen, verbotene politische Propaganda, etc.). " +
        " In diesem Fall kann der Anbieter selbst für den Kommentar oder Beitrag belangt werden und ist daher an der Identität des Verfassers interessiert. <br><br>Die Parcoursdaten sowie Kommentare und Bilder sind öffentlich und können jederzeit von Dritten eingesehen werden",
    "header5": "Turniere veröffentlichen",
    "body5" : "Wenn Nutzer ein Turnier, dessen Einladung oder Ergebnis veröffentlicht, werden ihre Namen und e-mail Adressen gespeichert. Das erfolgt zur Sicherheit des Anbieters, falls jemand widerrechtliche Inhalte schreibt (Beleidigungen, verbotene politische Propaganda, etc.). " +
        "In diesem Fall kann der Anbieter selbst für den Kommentar oder Beitrag belangt werden und ist daher an der Identität des Verfassers interessiert. <br><br>Die Turniereinladung sowie das Turnierergebnis sind öffentlich und können jederzeit von Dritten eingesehen werden.",
    "header6":"Turnieranmeldungen und -ergebnis",
    "body6":"Für die Verwaltung eines Turniers ist 3dturnier der Auftragsverarbeiter und der austragende Verein ist verantwortlich für die personenbezogenen Daten der Turnierteilnehmern. " +
        "Wenn ein Nutzer sich für ein Turnier anmeldet, werden in der Regel Name, Geschlecht, E-Mail Adresse und Vereinszugehörigkeit abgefragt. Diese Daten werden für die Abwicklung des Turniers benötigt und für die Wiederverwendung bei weiteren Turnieranmeldungen auf der 3dturnier Webseite. \n" +
        "<br><br>Kinder unter 14 Jahren dürfen sich nur mit der Einverständniserklärung ihrer Eltern zu einem Turnier anmelden. \n" +
        "Die eingegebenen Anmeldedaten außer der E-Mail Adresse werden öffentlich in der Anmeldeliste und im Turnierergebnis auf der 3dturnier Webseite angezeigt. Es obliegt dem ausführenden Verein ob er das Ergebnis an weitere Vereine bzw Verbände weitergibt. " +
        "<br><br>Das Veröffentlichung von Sportergebnissen ist im Sinne eines berechtigten Interesse der Verantwortlichen (Sportverbände) zulässig und daher von Antrag auf Löschung und Widerruf ausgenommen.",
    "header7":"Events hochladen und anzeigen im Ranking",
    "body7":"Wenn ein Nutzer ein Event (zb eine Runde auf einem Parcours) in der App erstellt - sind die Daten nur am jeweiligen Gerät. Erst mit dem hochladen in unsere zentrale Datenbank werden die Eventdaten (Schützenname und -email, Startzeit, Parcours sowie das Ergebnis je Schütze und Ziel gespeichert. Die teilnehmenden Schützen können das Event in ihrem Profil ansehen und auch selbst wieder löschen. "+
        "<br><br>Aus den hochgeladenen Events wird automatisch ein Ranking je Parcours erstellt und wenn vom Parcoursbetreiber das Parcourspaket erworben hat ist dies auch öffentlich auf der 3dturnier Webseite  einsehbar. Es werden von einem Event der Schützenname mit Datum und erreichten Gesamtergebnis angezeigt. ",
    "header8":"Bezahlen einer Parcours Runde oder Turnieranmeldung",
    "body8":"Wenn ein Nutzer seine Parcours Runde oder Turnieranmeldung über die App oder die 3dturnier Webseite bezahlt benötigen wir Name, E-Mail und die Adresse für das Erstellen und Versenden der Rechnung. Diese Daten werden nach den gesetzlichen Vorgaben gespeichert. "+
        "<br><br>Der Name und Wohnort des Nutzers sowie Betrag und Bezahlmethode der Transaktion werden an den Parcoursbetreiber weitergegeben um die Kontrolle der Bezahlung durch zu führen. " +
        "<br><br>Bei Zahlung mit Kreditkarte, Sofortüberweisung oder PayPal werden die eingegeben Zahlungsdaten nicht bei 3dturnier eingegeben oder gespeichert sondern über den Bezahldienst der Unzer Austria GmbH oder Stripe Inc.",
    "header9":"Vereinsverwaltung",
    "body9":"Für die Verwaltung eines Vereins ist 3dturnier der Auftragsverarbeiter und der Verein ist verantwortlich für die eingegeben Daten. Daher obliegt es dem Verein welche personenbezogene Daten der Mitglieder erfasst werden und wenn sie wieder gelöscht werden. Es können Name, Adresse, Geburtsdatum, Geschlecht, E-Mail, Foto und Telefonnummer erfasst werden.",
    "header10":"Löschen von Daten",
    "body10":"Beim Löschen von Daten (Events, Turnieranmeldungen, Vereinsmitglieder, Vereinstermine) in der zentralen Datenbank werden die Datensätze zuerst nur als gelöscht markiert und können bei Bedarf wieder hergestellt werden.  Wenn der gelöschte Datensatz 30 Tage nicht editiert wurde - wird dieser von eine Routine tatsächlich gelöscht und ist nicht wiederherstellbar.",
    "header11": "Automatisch gesammelte Informationen",
    "body11" : "Die App sammelt automatisch Gerätespezifische Informationen über den Playstore (Type, Betriebssystem,..) sowie Informationen wie sie die Applikation verwenden über Google Analytics. Die App sammelte keine genauen Standort informationen ihres mobilen Geräts.",
    "header12": "Google Analytics",
    "body12" : "Die App sammelt automatisch Gerätespezifische Informationen über den Playstore (Type, Betriebssystem,..) sowie Informationen wie sie die Applikation verwenden über Google Analytics. Die App sammelte keine genauen Standort informationen ihres mobilen Geräts. \n" +
    "Google Analytics Dieses Angebot benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Computer der Nutzer gespeichert werden und die eine Analyse der Benutzung der Website durch sie ermöglichen. " +
    "Die durch den Cookie erzeugten Informationen über Benutzung dieser Website durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird die IP-Adresse der Nutzer von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. " +
    "Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die IP-Anonymisierung ist auf dieser Website aktiv. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um die Nutzung der Website durch die Nutzer auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. " +
    "Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; Dieses Angebot weist die Nutzer jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. " +
    "Die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de. " +
    "Alternativ zum Browser-Add-On oder innerhalb von Browsern auf mobilen Geräten, klicken Sie bitte diesen Link, um die Erfassung durch Google Analytics innerhalb dieser Website zukünftig zu verhindern. Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie diesen Link erneut klicken.",
    "header13": "Widerruf, Änderungen, Berichtigungen und Aktualisierungen",
    "body13" : "Der Nutzer hat das Recht, auf Antrag unentgeltlich Auskunft zu erhalten über die personenbezogenen Daten, die über ihn gespeichert wurden. Zusätzlich hat der Nutzer das Recht auf Berichtigung unrichtiger Daten, Sperrung und Löschung seiner personenbezogenen Daten, soweit dem keine gesetzliche Aufbewahrungspflicht entgegensteht.",
};