const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid } = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    linkUtil = require("linkUtil");
const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const Parse = require("ParseInit");
const {Parcours, ParcoursOwner, ParcoursArticle} = require("parse/_Domain");

const ArticleConfigList = require("components/form/ArticleConfigList");

const Title = require("components/widgets/Title"),
    Utility = require("util/Utility");

const {BaseContext} = require("context/BaseContext");
const parcoursMgmtActions = require("actions/ParcoursMgmtActions");
/**
 * Manage articles of parcoursowner and all its parcours.
 * start with id of parcoursowner.
 */
class ParcoursArticlePage extends React.Component {
    constructor(props) {
        super(props);
        this.updateParcours = this.updateParcours.bind(this);
        this.handleParcoursOwnerUpdate = this.handleParcoursOwnerUpdate.bind(this);
        this.handleParcoursUpdate = this.handleParcoursUpdate.bind(this);

        this.state = {
            parcoursOwner: null,
            parcourss: null
        }
    }
    componentDidMount() {
        parcoursMgmtActions.getParcoursWithArticles(this.props.ownerID, (parcoursList) => {
            this.updateParcours(parcoursList);
        });
    }
    updateParcours(parcoursList) {
        if (parcoursList && parcoursList.length > 0) {
            this.setState({
                parcoursOwner: parcoursList[0].getParcoursOwnerID(),
                parcourss: parcoursList
            });
        } else {
            this.setState({
                parcoursOwner: null,
                parcourss: null
            });
        }

    }
    handleParcoursOwnerUpdate(parcoursOwner) {
        this.setState({parcoursOwner: parcoursOwner});
    }
    handleParcoursUpdate(parcours) {
        var mappedParcours = this.state.parcourss.map(function (o) {
            if (o.id == parcours.id) {
                return parcours;
            }
            return o;
        });

        this.setState({parcourss: mappedParcours});
    }
    render() {
        return (
            <div>
                <ParcoursOwnerArticles parcoursOwner={this.state.parcoursOwner}
                                       handleParcoursOwnerUpdate={this.handleParcoursOwnerUpdate}/>
                {Utility.isSet(this.state.parcourss) ?
                    this.state.parcourss.map(parcours => {
                        return <ParcoursArticles key={"PAS" + parcours.id} parcours={parcours}
                                                 handleParcoursUpdate={this.handleParcoursUpdate}/>
                    })
                    :
                    ""
                }
            </div>)
    }
}
ParcoursArticlePage.propTypes = {
    ownerID: PropTypes.string.isRequired
};
const ParcoursOwnerArticles = ({parcoursOwner, handleParcoursOwnerUpdate}) => {
    const { user } = React.useContext(BaseContext);
    if (!Utility.isSet(parcoursOwner)) {
        return <div></div>;
    }
    function handleSubmit(editArticle, values) {
        // create new article
        let article = editArticle;
        if (article == null) {
            article = new ParcoursArticle();
            article.setStatus("A");
        }
        article.setName(values.name);
        article.setPrice(values.price);
        article.setCurrency(values.currency);
        article.setGroup(values.group);
        // store article
        article.save()
            .then(sArticle => {
                if (editArticle == null) {
                    let newArticles = [];
                    if (parcoursOwner.getArticles()) {
                        parcoursOwner.getArticles().forEach(function (existArticle) {
                            newArticles.push(existArticle.id);
                        });
                    }
                    newArticles.push(sArticle.id);
                    // run cloud code change
                    Parse.Cloud.run('editParcours', {
                        objectId: parcoursOwner.id,
                        objectClass: parcoursOwner.className,
                        dirtyKey: ParcoursOwner.prototype.col.articles,
                        newValue: newArticles})
                        .then(savedObject => {
                            handleParcoursOwnerUpdate(parcoursOwner)
                        })
                        .catch(error => {
                            console.log("error: cannot add article to parcoursOwner: " + error.message);
                        });
                }
            })
            .catch(error => {
                console.log("error: cannot save article");
                console.warn(error);
            });
    }
    function handleArticleStatusChange(article, status) {
        if ("D" === status) {
            deleteArticle(article)
        } else {
            article.setStatus(status);
            article.save(null)
                .then(sArticle => {
                    handleParcoursOwnerUpdate(parcoursOwner);
                });
        }
    }
    function deleteArticle(delArticle) {
        var newArticles = [];
        if (parcoursOwner.getArticles()) {
            parcoursOwner.getArticles().forEach(function (existArticle) {
                if (existArticle.id !== delArticle.id) {
                    newArticles.push(existArticle.id);
                }
            });
        }
        // run cloud code change
        Parse.Cloud.run('editParcours', {
            objectId: parcoursOwner.id,
            objectClass: parcoursOwner.className,
            dirtyKey: ParcoursOwner.prototype.col.articles,
            newValue: newArticles})
            .then(savedObject => {
                // back to parcours wiki
                delArticle.setStatus("D");
                delArticle.save(null)
                    .then(sArticle => {
                        handleParcoursOwnerUpdate(parcoursOwner);
                    });
            })
            .catch(error => {
                console.log("error: cannot delete article to parcoursOwner: ", error);
            });
    }
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/mgmtparcours" code="profile.managedParcours.header" />
                        <Breadcrumb code="profile.managedParcours.article" active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Title code={parcoursOwner.getName()}/>
                <Row>
                    <Col xs={12}>
                        <h3>{messages.get("parcours.articles.info.allCourses")}</h3>
                        <ArticleConfigList user={user}
                                           articles={parcoursOwner.getArticles()}
                                           articleTypes={["single", "multi", "voucher"]}
                                           handleArticleStatusChange={handleArticleStatusChange}
                                           handleSubmit={handleSubmit}/>
                    </Col>
                </Row>
        </Grid>
    )
};
ParcoursOwnerArticles.propTypes = {
    parcoursOwner:PropTypes.object,
    handleParcoursOwnerUpdate:PropTypes.func.isRequired
};

const ParcoursArticles = ({parcours, handleParcoursUpdate}) => {
    const { user } = React.useContext(BaseContext);
    function handleSubmit(editArticle, values) {
        // create new article
        let article = editArticle;
        if (article == null) {
            article = new ParcoursArticle();
            article.setStatus("A");
        }
        article.setName(values.name);
        article.setPrice(values.price);
        article.setCurrency(values.currency);
        article.setGroup(values.group);
        article.save(null)
            .then(sArticle => {
                if (editArticle == null) {
                    // is new article add to parcours
                    let newArticles = [];
                    if (parcours.getArticles()) {
                        parcours.getArticles().forEach(function (existArticle) {
                            newArticles.push(existArticle.id);
                        });
                    }
                    newArticles.push(sArticle.id);
                    // run cloud code change
                    Parse.Cloud.run('editParcours', {
                        objectId: parcours.id,
                        objectClass: parcours.className,
                        dirtyKey: Parcours.prototype.col.articles,
                        newValue: newArticles})
                        .then(savedObject => {
                            // back to parcours wiki
                            handleParcoursUpdate(savedObject);
                        })
                        .catch(error => {
                            console.log("error: cannot add article to parcours", error);
                        });
                }
            })
            .catch(error => {
                console.log("error: cannot save article", error);
         });

    }
    function handleArticleStatusChange(article, status) {
        if ("D" === status) {
            deleteArticle(article)
        } else {
            article.setStatus(status);
            article.save(null)
                .then(sArticle => {
                    handleParcoursUpdate(parcours);
                });
        }
    }
    function deleteArticle(delArticle) {
        var newArticles = [];
        if (parcours.getArticles()) {
            parcours.getArticles().forEach(function (existArticle) {
                if (existArticle.id !== delArticle.id) {
                    newArticles.push(existArticle.id);
                }
            });
        }
        // run cloud code change
        Parse.Cloud.run('editParcours', {
            objectId: parcours.id,
            objectClass: parcours.className,
            dirtyKey: Parcours.prototype.col.articles,
            newValue: newArticles})
            .then(savedObject => {
                // back to parcours wiki
                delArticle.setStatus("D");
                delArticle.save(null)
                    .then(sArticle => {
                        handleParcoursUpdate(parcours);
                    });
            })
            .catch(error => {
                console.log("error: cannot delete article to parcoursOwner");
            });
    }
    return (
        <div>
            <Grid>
                <Row>
                    <Col xs={8}>
                        <h3>
                            <small>{messages.get("parcours.articles.info.singleCourse")}</small>
                            <br/>
                            {parcours.getName()}
                        </h3>
                    </Col>
                    <Col xs={4}>
                        <div className="pull-right" style={{marginTop:"52px"}}>
                            <a href={linkUtil.getLink("/parcours/" + parcours.getOnlineID() + "/checkout")}><I18n
                                code="parcours.articles.link"/></a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {Utility.isSet(parcours) ?
                            <Breadcrumbs>
                                <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                                <Breadcrumb link={"/parcours/"+ parcours.getOnlineID()} code={parcours.buildNames()} />
                                <Breadcrumb code={"tournament.table.column.article"} active/>
                            </Breadcrumbs>
                            :
                            ""
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ArticleConfigList user={user}
                                           articles={parcours.getArticles()}
                                           articleTypes={["single", "multi", "voucher"]}
                                           handleArticleStatusChange={handleArticleStatusChange}
                                           handleSubmit={handleSubmit} />
                    </Col>
                </Row>
            </Grid>
        </div>
    )
};
ParcoursArticles.propTypes = {
    parcours:PropTypes.object,
    handleParcoursUpdate:PropTypes.func.isRequired
};
module.exports = ParcoursArticlePage;
