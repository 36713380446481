const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TournamentConfigAgeSelect = require("components/tournamentregister/TournamentConfigAgeSelect"),
    TournamentConfigBowSelect = require("components/tournamentregister/TournamentConfigBowSelect"),
    TournamentSexSelect = require("components/tournamentregister/TournamentSexSelect"),
    TournamentOptionSelect = require("components/tournamentregister/TournamentOptionSelect"),
    TournamentRegSlotSelect = require("components/tournamentregister/TournamentRegSlotSelect"),
    TournamentUserPaidSelect = require("components/tournamentregister/TournamentUserPaidSelect"),
    TournamentUserEquipSelect = require("components/tournamentregister/TournamentUserEquipSelect"),
    TournamentUserStatusSelect = require("components/tournamentregister/TournamentUserStatusSelect"),
    ModalBasic = require ("components/modals/ModalBasic");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TournamentUserFilterModal = ({tournament, tournamentRegSlots, searchParams, searchParamCallback, showStatus}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code="tournament.modal.tuser.filter"/></TextCenter>}
            buttonStyle={searchParams.moreFilter == true ? "success" : "default"}
            buttonChildren={<FontAwesome icon="filter"><I18n code="tournament.modal.tuser.filter"/></FontAwesome>}>
            <TournamentUserFilterForm tournament={tournament} tournamentRegSlots={tournamentRegSlots} searchParams={searchParams}
                                      searchParamCallback={searchParamCallback} showStatus={showStatus}/>
        </ModalBasic>
    )
};
TournamentUserFilterModal.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentRegSlots: PropTypes.array,
    searchParams:PropTypes.object.isRequired,
    searchParamCallback: PropTypes.func.isRequired,
    showStatus: PropTypes.bool
};
class TournamentUserFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.configBowSelected = this.configBowSelected.bind(this);
        this.configAgeSelected = this.configAgeSelected.bind(this);
        this.configSexSelected = this.configSexSelected.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.slotSelected = this.slotSelected.bind(this);
        this.statusSelected = this.statusSelected.bind(this);
        this.paidSelected = this.paidSelected.bind(this);
        this.equipSelected = this.equipSelected.bind(this);
        this.resetParams = this.resetParams.bind(this);
    }
    configBowSelected(configBowType) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedBowType = configBowType;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    configAgeSelected(configAgeType) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedAgeType = configAgeType;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    configSexSelected(sex) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedSex = sex;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    optionSelected(option) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedOption = option;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    slotSelected(slotId) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedSlotId = slotId;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    statusSelected(status) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedStatus = status;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    paidSelected(isPaid) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedPaid = isPaid;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    equipSelected(isChecked) {
        let sParams = this.props.searchParams;
        sParams.moreFilter = true;
        sParams.selectedEquip = isChecked;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    resetParams() {
        let sParams = this.props.searchParams;
        sParams.moreFilter = false;
        sParams.selectedOption = null;
        sParams.selectedSlotId = null;
        sParams.selectedAgeType = null;
        sParams.selectedBowType = null;
        sParams.selectedStatus = null;
        sParams.selectedPaid = null;
        sParams.selectedEquip = null;
        sParams.selectedSex = -1;
        this.props.searchParamCallback(sParams);
        this.context.closeFct()
    }
    render() {
        const {error} = this.context;
        const {tournament, tournamentRegSlots, searchParams, showStatus} = this.props;
        const {moreFilter, selectedAgeType, selectedBowType, selectedOption, selectedSlotId, selectedStatus, selectedPaid, selectedEquip, selectedSex} = searchParams;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        {
                            showStatus ? <TournamentUserStatusSelect preSelect={false} defaultValue={selectedStatus} selectCallback={this.statusSelected} /> : null
                        }
                        {
                            showStatus ? <TournamentUserPaidSelect defaultValue={selectedPaid} selectCallback={this.paidSelected} /> : null
                        }
                        {
                            showStatus ? <TournamentUserEquipSelect defaultValue={selectedEquip} selectCallback={this.equipSelected} /> : null
                        }
                        <TournamentSexSelect label={messages.get("address.sex")} preSelect={false}
                                             defaultValue={selectedSex} selectCallback={this.configSexSelected} />

                        <TournamentConfigBowSelect label={messages.get("tournament.class.bow")} preSelect={false}
                                                   defaultValue={selectedBowType ? selectedBowType.id : null}
                                                   tournament={tournament} selectCallback={this.configBowSelected}/>
                        <TournamentConfigAgeSelect label={messages.get("tournament.class.age")} preSelect={false}
                                                   defaultValue={selectedAgeType ? selectedAgeType.id : null}
                                                   tournament={tournament}  selectCallback={this.configAgeSelected}/>
                        {
                            tournament.getTournamentOptionID() != null ?
                                <TournamentOptionSelect tOption={tournament.getTournamentOptionID()} preSelect={false}
                                                        tournament={tournament}
                                                        defaultValue={selectedOption}
                                                        selectCallback={this.optionSelected} /> : null
                        }
                        {
                            tournamentRegSlots != null && tournamentRegSlots.length > 0 ?
                                <TournamentRegSlotSelect tournamentRegSlots={tournamentRegSlots}  showAll={true}
                                                         selectedSlotId={selectedSlotId} preSelect={false}
                                                         selectCallback={this.slotSelected}/> : null
                        }
                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.resetParams} disabled={moreFilter !== true} block bsStyle="success">
                            <I18n code={"tournament.modal.tuser.filter.reset"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentUserFilterForm.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentRegSlots: PropTypes.array,
    searchParams:PropTypes.object.isRequired,
    searchParamCallback: PropTypes.func.isRequired,
    showStatus: PropTypes.bool
};
TournamentUserFilterForm.contextType = ModalBasicContext;
module.exports = TournamentUserFilterModal;
