const React = require("react"),
    PropTypes = require('prop-types');

const { Marker, OverlayView  } = require ('@react-google-maps/api');

const MapUtil = require("components/map/MapUtil");
const TargetMapMarker = require("components/map/TargetMapMarker");
const ptDao = require("actdao/ParcoursTargetDao");
const ErrorBoundary = require("components/widgets/ErrorBoundary");

class ParcoursMapMarker extends React.Component {
    constructor(props) {
        super(props)
        this.toggleInfoVisible = this.toggleInfoVisible.bind(this);
        this.generateMarkerContent = this.generateMarkerContent.bind(this);
        this.loadTargetList = this.loadTargetList.bind(this);
        this.state = {
            infoVisible: false,
            targetList: null
        }
    }
    toggleInfoVisible() {
        console.log("ParcoursMapMarker.toggleInfoVisible." + this.props.mapElement.name,this.props.mapElement.pOnlineIDs)
        let visible = this.state.infoVisible;
        //  POIs do not have pOnlineIDs
        if (!visible && this.state.targetList == null && this.props.mapElement.pOnlineIDs && this.props.mapElement.pOnlineIDs.length > 0) {
            // load Targets
            this.loadTargetList(this.props.mapElement.pOnlineIDs[0])
        }
        this.setState({infoVisible: !visible})
    }
    loadTargetList(onlineID) {
        ptDao.queryParcoursTargets(onlineID)
            .then(targetList => {
                console.log("Found targets for " + onlineID +": " + targetList.length);
                this.setState({targetList:targetList})
            })
            .catch(error => {
                error.trace = "PMM.qParTar";
                notificationActions.parseError(error);
            });
    }
    generateMarkerContent() {
        let mapUtil = new MapUtil();
        return mapUtil.generateMarker(this.props.mapElement)
    }
    render() {
        const {infoVisible, targetList} = this.state;
        const {mapElement, clusterer} = this.props;
        const posMarker = {lat:  mapElement.coordinate._latitude, lng: mapElement.coordinate._longitude};
        return <React.Fragment>
            <Marker position={posMarker} onClick={this.toggleInfoVisible}
                    icon={mapElement.markerIcon} clusterer={clusterer}
            />
            {
                infoVisible ?
                    <OverlayView position={posMarker} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                        <div style={{
                            background: '#FFFFFF',
                            padding: 10,
                            fontSize: 12,
                            borderRadius: 5
                        }}
                             onClick={this.toggleInfoVisible}
                            dangerouslySetInnerHTML={{__html:this.generateMarkerContent()}}/>
                    </OverlayView> : null
            }
            <ErrorBoundary>
                <TargetMap targetList={targetList} infoVisible={infoVisible} zoom={clusterer.activeMap.getZoom()}/>
            </ErrorBoundary>
        </React.Fragment>
    }
}
const TargetMap = ({infoVisible, targetList, zoom}) => {
    if (zoom > 15 && infoVisible && targetList != null) {
        return targetList.map(parcoursTarget => {
            if (parcoursTarget.getCoordinates()) {
                return <TargetMapMarker key={parcoursTarget.id} parcoursTarget={parcoursTarget} />
            }
        })
    }
    return null;
}
ParcoursMapMarker.propTypes = {
    mapElement: PropTypes.object.isRequired,
    clusterer: PropTypes.object
};
module.exports = ParcoursMapMarker;