const alt = require("AltInit");
const dao = require("actdao/UserDao");
const {User, UserToken} = require("parse/_Domain");
const notificationActions = require("components/notification/NotificationActions"),
    tournamentUserDao = require("actdao/TournamentUserDao"),
    eventDao = require("actdao/EventDao"),
    userDao = require("actdao/UserDao"),
    parseDao = require("actdao/ParseBaseDao");
class AdminUserActions {

    showProgress() {
        return {};
    };

    queryUser(searchParams) {
        if (searchParams.hasAbo == true || (searchParams.searchText != null && searchParams.searchText.length > 0)) {
            dao.queryUser(searchParams)
                .then(users => {
                    this.updateUserList(users);
                })
                .catch(error => {
                    error.trace="ADUA.qUser";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateUserList([]);
        }
        return {};
    }

    queryUserElements(searchParams) {
        if (searchParams.searchText != null && searchParams.searchText.length > 0) {
            eventDao.queryEventList(searchParams.searchText, searchParams.countTypeOnlineID)
                .then(eventPlayers => {
                    this.updateEventPlayerList(eventPlayers);
                })
                .catch(error => {
                    error.trace="ADUA.qEvUser";
                    notificationActions.parseError(error);
                });
            tournamentUserDao.queryTournamentUsers(searchParams.searchText)
                .then(tUsers => {
                    this.updateTournamentUserList(tUsers);
                })
                .catch(error => {
                    error.trace="ADUA.qTUser";
                    notificationActions.parseError(error);
                });
            userDao.fetchFavoritesHavingUsers()
                .then(userFavorites => {
                    let favorites = [];
                    userFavorites.map(userFavorite => {
                        if (userFavorite.hasUsersMail(searchParams.searchText)) {
                            favorites.push(userFavorite);
                        }
                    });
                    this.updateFavoriteList(favorites);
                })
                .catch(error => {
                    error.trace="ADUA.qTUserFav";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateUserList([]);
            this.updateTournamentUserList([]);
        }
        return {};

    }

    queryParcoursElements(searchParams) {
        if (searchParams.searchText != null && searchParams.searchText.length > 0) {
            eventDao.queryEventForParcoursList(searchParams.searchText)
                .then(events => {
                    this.updateEventPlayerList(events);
                })
                .catch(error => {
                    error.trace="ADUA.qEvParcours";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateUserList([]);
            this.updateTournamentUserList([]);
        }
        return {};
    }

    async replacePlayersMail(players, newMail, checkComment, callback) {
        if (checkComment) {
            let playerEventComment = [];
            for (let i = 0; i < players.length; i++) {
                let player = players[i];
                const evComments = await eventDao.queryEventComment(player.getEventID(), player.getPlayerEmail());
                playerEventComment = playerEventComment.concat(evComments);
            }
            playerEventComment.map(eventComment => {
                eventComment.setPlayerEmail(newMail)
            });
            parseDao.saveAll(playerEventComment)
                .then(sEventComments => {
                    notificationActions.success("Updated " + sEventComments.length + " Comment Entries");
                })
                .catch(error => {
                    error.trace = "ADUA.qUpdCommentMail";
                    notificationActions.parseError(error);
                });
        }
        players.map(player => {
            player.setPlayerEmail(newMail)
        });
        parseDao.saveAll(players)
            .then(sPlayers => {
                notificationActions.success("Updated " + sPlayers.length + " Entries");
                callback()
            })
            .catch(error => {
                error.trace = "ADUA.qUpdMail";
                notificationActions.parseError(error);
                callback()
            });
    }

    replaceEventParcours(events, newParcours, callback) {
        // set parcours and onlineID
        events.map(event => {
            event.setParcoursID(newParcours);
            event.setParcoursOnlineID(newParcours.getOnlineID());
        });
        // save list
        parseDao.saveAll(events)
            .then(sEvents => {
                notificationActions.success("Updated " + sEvents.length + " Entries");
                callback()
            })
    }

    replaceFavoritesMail(favoriteList, newMail, searchMail, callback) {
        favoriteList.map(favorite => {
            favorite.replaceUsersEntryMail(newMail, searchMail)
        });
        parseDao.saveAll(favoriteList)
            .then(sFavoriteList => {
                notificationActions.success("Updated " + sFavoriteList.length + " Favorite Entries");
                callback()
            })
            .catch(error => {
                error.trace="ADUA.qUpdMail";
                notificationActions.parseError(error);
                callback()
            });
    }

    validateEventPlayerMail(eventPlayer, newMail, validationCallback) {
        eventDao.queryEventPlayerMailValidation(eventPlayer.getEventID(), newMail)
            .then(eventPlayers => {
                validationCallback(eventPlayers && eventPlayers.length == 0);
            })
            .catch(error => {
                error.trace="ADUA.qUpdMail";
                notificationActions.parseError(error);
                validationCallback(false)
            });
    }

    loadUserMail(skbUser, mailCallback) {
        dao.queryUserMail(skbUser)
            .then(mail => {
                mailCallback(mail)
            })
            .catch(error => {
                error.trace="ADUA.qUserMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveUserAbo(user, userAbo) {
        parseDao.saveObject(userAbo)
            .then(sUserAbo => {
                this.updateUserObject(user);
            })
            .catch(error => {
                error.trace="ADUA.sUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveEditLanguage(user, editLangArray) {
        const params = {userID: user.id, editLangArray: editLangArray};
        dao.editUserCCP(params)
            .then(success => {
                notificationActions.success("parcours.edit.message.saved");
                user.set(User.prototype.col.trlLang, editLangArray);
                this.updateUserObject(user);
            })
            .catch(error => {
                error.trace="ADUA.sUserLang";
                notificationActions.parseError(error);
            });
        return {};
    }

    createUserAbo(user, userAbo) {
        parseDao.saveObject(userAbo)
            .then(sUserAbo => {
                this.updateUserAboIDs(user, sUserAbo);
            })
            .catch(error => {
                error.trace="ADUA.sUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateUserAboIDs(user, newUserABo) {
        const userAboIDs = [];
        let userAbos = user.getUserAbos();
        if (userAbos != null) {
            // add existing ones
            for (let i = 0; i < userAbos.length; i++) {
                userAboIDs.push(userAbos[i].id)
            }
        } else {
            userAbos = [];
        }
        userAboIDs.push(newUserABo.id);
        userAbos.push(newUserABo);
        const params = {userID: user.id, aboIDs: userAboIDs};
        dao.editUserCCP(params)
            .then(result => {
                user.setUserAbos(userAbos);
                this.updateUserObject(user);
            })
            .catch(error => {
                error.trace="ADUA.eCCUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    resendAuthenticationMail(user, userMail) {
        dao.resendAuthenticationMail(user.id, userMail)
            .then(result => {
                notificationActions.success("mail.sent.success");
            })
            .catch(error => {
                error.trace="ADUA.rsAuthMail";
                notificationActions.parseError(error);
            });
        return {};
    }
    setVerifiedEmailFlag(user) {
        const params = {userID: user.id, emailVerified: true};
        dao.editUserCCP(params)
            .then(result => {
                user.set(User.prototype.col.emailVerified, true);
                this.updateUserObject(user);
            })
            .catch(error => {
                error.trace="ADUA.vUserMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateUserName(user, newUsername) {
        const params = {userID: user.id, username: newUsername};
        dao.editUserCCP(params)
            .then(result => {
                user.set(User.prototype.col.username, newUsername);
                this.updateUserObject(user);
            })
            .catch(error => {
                error.trace="ADUA.eUserName";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateUserPassword(user, pwd, callback) {
        // update password
        const params = {userID: user.id, pass: pwd};
        dao.editUserCCP(params)
            .then(result => {
                user.set(User.prototype.col.password, pwd);
                this.updateUserObject(user);
                if (callback) {
                    callback(true);
                }
            })
            .catch(error => {
                error.trace="ADUA.eUserPassword";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateUserEmail(user, newMail, mailCallback) {
        const params = {userID: user.id, email: newMail};
        dao.editUserCCP(params)
            .then(result => {
                user.set(User.prototype.col.email, newMail);
                this.updateUserObject(user);
                mailCallback(newMail);
            })
            .catch(error => {
                error.trace="ADUA.eUserMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    resetPasswordMail(userMail) {
        dao.generateUserToken(userMail, UserToken.prototype.action.password)
            .then(result => {
                notificationActions.success("mail.sent.success");
            })
            .catch(error => {
                error.trace="ADUA.rPwdMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteUserAndContent(user) {
        dao.unlinkUserContent(user)
            .then(unlinkResult => {
                console.log("Unlink:", unlinkResult);
                notificationActions.success("User unlink content success");
                dao.deleteUserAndContent(user, true)
                    .then(deleteResult => {
                        console.log("Delete:", deleteResult);
                        notificationActions.success("User delete content success");
                    })
            })
            .catch(error => {
                error.trace="ADUA.delUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateUserList(users) {
        return users;
    };

    updateUserObject(user) {
        return user;
    };

    updateEventPlayerList(eventPlayers) {
        return eventPlayers;
    };

    updateTournamentUserList(tUsers) {
        return tUsers;
    };
    updateFavoriteList(favorites) {
        return favorites;
    };

}

module.exports = alt.createActions(AdminUserActions);
