const alt = require("AltInit");
const eventDao = require("actdao/EventDao");
const parseDao = require("actdao/ParseBaseDao");
const liveDao = require("actdao/TournamentLiveDao");
const notificationActions = require("components/notification/NotificationActions");
const {Event} = require("parse/_Domain");
const FileDownloadUtility =  require("util/FileDownloadUtility"),
    Utility = require("util/Utility");

class EventDetailActions {

    queryEvent(eventID) {
        const event = new Event();
        event.id = eventID;
        eventDao.queryEventPlayerDetail(event)
            .then (eventPlayers => {
                if (eventPlayers != null && eventPlayers.length > 0) {
                    let event = eventPlayers[0].getEventID();
                    this.updateEvent(event);
                    if (event.getTrackID()) {
                        // load track
                        parseDao.fetchObject(event.getTrackID())
                            .then (track => {
                                this.updateEventTrack(track)
                            })
                            .catch(error => {
                                error.trace = "EVDA.event.loadTr";
                                notificationActions.parseError(error);
                            });
                    }
                    if (event.isTournament() && event.getTournamentGroupID() && event.getTournamentRoundID()) {
                        this.loadEventTUGroupLive(event);
                    }
                    eventDao.queryEventComment(event, null)
                        .then(eventComments => {
                            this.updateEventComments(eventComments)
                        })
                        .catch(error => {
                            error.trace = "EVDA.event.loadEVC";
                            notificationActions.parseError(error);
                        });
                } else {
                    // load only event
                    eventDao.queryEvent(eventID)
                        .then (event => {
                            if (event != null) {
                                this.updateEvent(event);
                            }

                        })
                        .catch(error => {
                            error.trace = "EVDA.event.loadEV";
                            notificationActions.parseError(error);
                        });
                }
                this.updateEventPlayerList(eventPlayers);
            })
            .catch(error => {
                error.trace = "EVDA.event.load";
                notificationActions.parseError(error);
            });
        return {}
    }

    loadEventTUGroupLive(event) {
        liveDao.loadTUGroupLive(event.getTournamentGroupID(), event.getTournamentRoundID())
            .then(tuGroupLiveEntries => {
                this.updateEventGroupLives(tuGroupLiveEntries)
            })
            .catch(error => {
                error.trace = "EVDA.tuGroupLives.load";
                notificationActions.parseError(error);
            });
        return {}
    }

    saveEvent(event) {
        parseDao.saveObject(event)
            .then(sEvent => {
                this.updateEvent(sEvent)
            })
            .catch(error => {
                error.trace = "EVDA.ev.save";
                notificationActions.parseError(error);
            });
        return {}
    }

    loadBAProfile(user, callback) {
        eventDao.queryBowArrowProfiles(user)
            .then(result => {
                callback(result)
            })
            .catch(error => {
                error.trace = "EVDA.ld.baProfil";
                notificationActions.parseError(error);
            });
        return {}
    }

    saveEventPlayer(eventPlayer) {
        this.reset();
        parseDao.saveObject(eventPlayer)
            .then(sEventPlayer => {
                this.queryEvent(eventPlayer.getEventID().id);
            })
            .catch(error => {
                error.trace = "EVDA.upd.baprof";
                notificationActions.parseError(error);
            });
        return {}
    }

    changeEventPlayerStatus(eventPlayer, status) {
        this.reset();
        eventDao.updateEventPlayerStatus(eventPlayer, status)
            .then(result => {
                this.queryEvent(eventPlayer.getEventID().id);
            })
        .catch(error => {
                error.trace = "EVDA.upd.status";
                notificationActions.parseError(error);
        });
        return {}
    }

    reduceTrack(eventTrack) {
        eventDao.reduceTrack(eventTrack.id, 1)
            .then(result => {
                notificationActions.info(result)
            })
            .catch(error => {
                error.trace = "EVDA.rTr";
                notificationActions.parseError(error);
            });
        return {}
    }

    generateGPX(event, eventTrack, user) {
        let filename = "Track_export.gpx";
        let xmlData = [];
        xmlData.push('<?xml version="1.0" encoding="UTF-8" standalone="no" ?>');
        xmlData.push('<gpx version="1.1" creator="3D SKill Board">');
        // meta
        xmlData.push('<metadata>');
        xmlData.push('<name>' + filename + '</name>');
        xmlData.push('<author>');
        xmlData.push('<name>' + user.get("username") + '</name>');
        xmlData.push('</author>');
        xmlData.push('</metadata>');
        // waypoints
        // TODO add targets as waypoint ??
        // track
        xmlData.push('<trk>');
        xmlData.push('<name>' +  event.getName() + '</name>');
        xmlData.push('<time>' + Utility.getGPXDate(event.getInsstmp()) + '</time>');
        let trackArr = eventTrack.getTrack();
        if (trackArr && trackArr.length > 0) {
            trackArr.map(track => {
                let jsTrack = JSON.parse(track);
                let targetNumber = Object.keys(jsTrack)[0];
                let coordArray = jsTrack[targetNumber];
                if (coordArray != null && coordArray.length > 0) {
                    // target segment
                    xmlData.push('<trkseg>');
                    xmlData.push('<name>Ziel ' + targetNumber + '</name>');
                    coordArray.map(coordinate => {
                        if (coordinate) {
                            xmlData.push('<trkpt lat="' + coordinate.la + '" lon="' + coordinate.lo + '">');
                            xmlData.push('<ele>' + coordinate.h + '</ele>');
                            xmlData.push('<time>' +  Utility.getGPXDate(coordinate.ts) + '</time>');
                            if (coordinate.sp) {
                                // add speed
                                xmlData.push('<speed>' + coordinate.sp + '</speed>');
                            }
                            if (coordinate.ac) {
                                // add accurancy
                                xmlData.push('<hdop>' + coordinate.ac + '</hdop>');
                            }
                            xmlData.push('</trkpt>');
                        }
                    });
                    xmlData.push('</trkseg>');
                }
            });

        }
        xmlData.push('</trk>');
        xmlData.push('</gpx>');
        const output = xmlData.join('\n');
        FileDownloadUtility.download(output, filename );
        return {};
    }


    updateEventPlayerList(pEventPlayers) {
        return pEventPlayers;
    }

    updateEvent(pEvent) {
        return pEvent;
    }

    updateEventTrack(pTrack) {
        return pTrack
    }

    updateEventGroupLives(tuGroupLives) {
        return tuGroupLives;
    }

    updateEventComments(eventComments) {
        return eventComments
    }

    reset() {
        return {};
    }

}

module.exports = alt.createActions(EventDetailActions);
