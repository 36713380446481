const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Alert, Button,ButtonGroup, Panel, Table} = require("react-bootstrap");
const {Tournament, TournamentRound, TournamentPrice} = require("parse/_Domain");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DecisionButton = require("components/form/DecisionButton"),
    TooltipButton  = require("components/form/TooltipButton"),
    CheckboxInput= require("components/form/CheckboxInput"),
    messages = require("i18n/messages"),
    TournamentCreateModal = require("components/tournamentmodal/TournamentCreateModal"),
    TournamentRegStatus = require("components/tournament/TournamentRegStatus"),
    AdditionalEditorModal = require("components/modals/AdditionalEditorModal"),
    Utility = require("util/Utility");

const SolutionHelpLink = require("components/links/SolutionHelpLink");

const TournamentMMAddBusinessAccount = require("components/tournamentmanage/TournamentMMAddBusinessAccount"),
    BusinessManagementInfo = require("components/tournamentmanage/BusinessManagementInfo"),
    TournamentCupManager = require ("components/tournamentmanage/TournamentCupManager"),
    TournamentConfigAgePriceWarning = require("components/tournamentmanage/TournamentConfigAgePriceWarning"),
    TournamentChangeLogTable= require("components/tournamentmanage/TournamentChangeLogTable"),
    TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader");

const notificationActions = require("components/notification/NotificationActions"),
    tournamentActions = require("actions/TournamentManagerActions");

const TournamentManagerPage  = ({tournamentId}) => {
    return <TournamentMMLoader tournamentId={tournamentId}>
        <TournamentMangeDetails />
    </TournamentMMLoader>
};
TournamentManagerPage.propTypes = {
    tournamentId: PropTypes.string.isRequired
};
const TournamentMangeDetails = ({user, tournament, childTournaments, tChangeLogs, tournamentRoundMap}) => {
        document.title = "Manage " + tournament.getName();
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <h1>{tournament.getName() +", " + tournament.getTournamentDate()}</h1>
                        <SolutionHelpLink articleID="42000051431" />
                    </Col>
                </Row>
                <Row>
                    <TournamentMainStatus  user={user}
                                           tournament={tournament}
                                           tournamentRoundMap={tournamentRoundMap}
                                           childTournaments={childTournaments}/>
                </Row>
                {
                    tChangeLogs != null ? <TournamentChangeLogTable tChangeLogs={tChangeLogs}/> : null
                }
                <Row>
                {
                    tournament.hasManagementID() ?
                        <BusinessManagementInfo management={tournament.getManagementID()} tournament={tournament} user={user}/>
                        :
                        <TournamentMMAddBusinessAccount user={user}
                                                        tournament={tournament}/>
                }
                </Row>
            </div>)
};
TournamentMangeDetails.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentRoundMap:PropTypes.object,
    childTournaments:PropTypes.array
};
class TournamentMainStatus extends React.Component {
    constructor(props) {
        super(props);
        this.setOnlinePaymentActive = this.setOnlinePaymentActive.bind(this);
        this.setOnlinePaymentInActive = this.setOnlinePaymentInActive.bind(this);
        this.setTournamentPrepared = this.setTournamentPrepared.bind(this);
        this.setTournamentOpen = this.setTournamentOpen.bind(this);
        this.setTournamentClosed = this.setTournamentClosed.bind(this);
        this.setTournamentDeleted = this.setTournamentDeleted.bind(this);
        this.allRoundsClosed = this.allRoundsClosed.bind(this);

        this.state = {}
    }
    setOnlinePaymentActive(e) {
        e != null ? e.preventDefault() : null;
        tournamentActions.updateTournamentPayStatus(this.props.tournament, this.props.user, TournamentPrice.prototype.payStatus.active);
    }
    setOnlinePaymentInActive(e) {
        e != null ? e.preventDefault() : null;
        tournamentActions.updateTournamentPayStatus(this.props.tournament, this.props.user, TournamentPrice.prototype.payStatus.inactive);
    }
    setTournamentPrepared() {
        tournamentActions.prepareTournament(this.props.tournament, this.props.user);
    }
    setTournamentOpen() {
        tournamentActions.startTournament(this.props.tournament, this.props.user, this.props.tournamentRoundMap.qualify);
    }
    setTournamentClosed() {
        // check all rounds closed
        if (this.props.tournament.isPublished() ||
            (this.allRoundsClosed(this.props.tournamentRoundMap.qualify)
                && this.allRoundsClosed(this.props.tournamentRoundMap.finale)
                && this.allRoundsClosed(this.props.tournamentRoundMap.group))) {
            tournamentActions.finishTournament(this.props.tournament, this.props.user);
        } else {
            notificationActions.warning("tournament.manager.notAllRoundsClosed");
        }
    }
    setTournamentDeleted() {
        tournamentActions.updateTournamentStatus(this.props.tournament, this.props.user, Tournament.prototype.status.deleted);
    }
    allRoundsClosed(tRounds) {
        for (let i = 0; i < tRounds.length; i++) {
            if (TournamentRound.prototype.status.closed != tRounds[i].getStatus()) {
                return false;
            }
        }
        return true;
    }
    render() {
        const {user, tournament, tournamentRoundMap, childTournaments} = this.props;
        const hasTournamentFeature = tournament.hasTournamentFeature();
        const hasPaymentFeature = tournament.hasPaymentFeature();
        let statusInfo = null;
        let payStatusInfo = null;
        let nextAction = null; // which action shall be  done next
        let alertStyle = "default";
        let showRegistrationStatusButtons=false;
        let showDeleteButton = false;
        let showPaymentStatus=false;
        let showTournamentStatusButtons=false;
        let showSimpleRoundButtons = false;
        let showFinalRoundButtons = false;
        let showGroupRoundButtons = false;
        let roundCounter = 0;
        let roundFinalCounter = 0;
        if (tournament.isRegistrationPublished()) {
            // regStatus = A, tournament not configured or registration never opened
            statusInfo = "tournament.status.published";
            nextAction = <Button bsStyle="info"
                                 href={linkUtil.getLink("tournament/" + tournament.id + "/mmconfig")}>{messages.get("tournament.manager.startConfig")}</Button>;
            alertStyle = "info";
            showDeleteButton = true;
        } else if (tournament.isCanceled()) {
            statusInfo = "tournament.status.canceled";
            nextAction = null;
            alertStyle = "danger";
            showDeleteButton = true;
        } else if (tournament.isTypeCupMaster() && tournament.hasInitialRegistration()) {
            showTournamentStatusButtons=true;
            if (tournament.isRunning()) {
                nextAction=<I18n code="tournament.manager.wait4SubTournaments"/>;
                alertStyle="success";
                statusInfo = "tournament.status.running";
            } else if (tournament.isClosed()) {
                // status = C or D
                alertStyle="primary";
                statusInfo = "tournament.status.closed";
            } else {
                // status = A
                nextAction=<I18n code="tournament.manager.wait4SubTournaments"/>;
                alertStyle="info";
                statusInfo = "tournament.status.published";
            }
        } else {
            showTournamentStatusButtons = true;
            showPaymentStatus = true;
            if (tournament.isRunning()) {
                statusInfo = "tournament.status.running";
                nextAction = <Button bsStyle="info"  href={linkUtil.getLink("tournament/" + tournament.id + "/mmtuser")}>{messages.get("tournament.button.menu.mmtu")}</Button>;
                alertStyle="success";
                showSimpleRoundButtons = tournamentRoundMap.qualify.length > 0;
                showFinalRoundButtons = tournamentRoundMap.finale.length > 0;
                showGroupRoundButtons = tournamentRoundMap.group.length > 0;
                showRegistrationStatusButtons = true;
                showDeleteButton = false;
            } else if (tournament.isClosed()) {
                // status = C or D
                statusInfo = "tournament.status.closed";
                alertStyle="primary";
                if (tournament.getResultImageID() == null) {
                    nextAction = <p>{messages.get("tournament.manager.populateResultImage")}</p>;
                }
            } else {
                // status = A
                statusInfo = "tournament.status.published";
                showRegistrationStatusButtons = true;
                if (tournament.hasManagementID()) {
                    if ((tournamentRoundMap.qualify.length > 0) || (tournamentRoundMap.group.length > 0)) {
                        nextAction = <TournamentStartButton alertStyle={"info"} tournament={tournament} handleClick={this.setTournamentOpen}/>
                    } else {
                        // no rounds need to configure them before start
                        nextAction = <Button bsStyle="info"  href={linkUtil.getLink("tournament/"+ tournament.id + "/mmround")}>{messages.get("tournament.button.menu.rounds")}</Button>;
                        showTournamentStatusButtons = false;
                    }
                } else {
                    // no account - start not allowed
                    nextAction = <Button bsStyle="info"  href={linkUtil.getLink("tournament/" + tournament.id + "/mmtuser")}>{messages.get("tournament.button.menu.mmtu")}</Button>;
                }
                alertStyle="info";

            }
            if (tournament.isOnlinePaymentEnabled()) {
                payStatusInfo = "tournament.paystatus.active"
            } else {
                payStatusInfo = "tournament.paystatus.inactive"
            }
        }
        return (
            <Panel bsStyle="default">
                <Panel.Heading>
                    <TournamentNextStep nextAction={nextAction}/>
                </Panel.Heading>
                <Panel.Body>
                    <Row>
                        <Col lg={4}>
                            <Alert bsStyle={alertStyle}>
                                <h3><TournamentRegStatus tournament={tournament} /></h3>
                                {
                                    showRegistrationStatusButtons ? <TournamentRegistrationButtons tournament={tournament} user={user} alertStyle={alertStyle} /> : null
                                }
                                <TournamentConfigAgePriceWarning tournament={tournament}/>
                                {
                                    showDeleteButton ?
                                        <DecisionButton
                                            user={user}
                                            buttonText={messages.get("tournament.manager.delete")}
                                            buttonIcon={"trash"}
                                            titleCode={"tournament.manager.delete"}
                                            messageCode={"club.member.modal.delete.ask"}
                                            okButtonCode={"modal.button.delete"}
                                            cancelButtonCode={"modal.button.cancel"}
                                            handleOK={this.setTournamentDeleted}/> : null

                                }
                                {
                                    hasTournamentFeature ? null :
                                        <p><br/><br/>{messages.get("tournament.manager.free")}</p>
                                }
                            </Alert>
                        </Col>
                        <Col lg={4}>
                            {
                                showPaymentStatus ?
                                    <Alert bsStyle={alertStyle}>
                                        <p><strong>{messages.get(payStatusInfo)}</strong></p>
                                        {
                                            hasPaymentFeature ?
                                                <ButtonGroup>
                                                    <Button bsStyle={alertStyle} disabled={tournament.isOnlinePaymentEnabled()} onClick={this.setOnlinePaymentActive}>{messages.get("modal.button.activate")}</Button>
                                                    <Button bsStyle={alertStyle} disabled={!tournament.isOnlinePaymentEnabled()}  onClick={this.setOnlinePaymentInActive}>{messages.get("modal.button.deactivate")}</Button>
                                                </ButtonGroup>
                                                :
                                                <p><br/>{messages.get("tournament.manager.chargeable.payment")}<br/>
                                                    <strong><a href={linkUtil.getLink("solutions")}> {messages.get("management.request.form.info.solutions")}</a></strong>
                                                </p>
                                        }
                                    </Alert> : null
                            }
                        </Col>
                        <Col lg={4}>
                            {
                                showTournamentStatusButtons ?
                                    <Alert bsStyle={alertStyle}>
                                        <p>{messages.get("tournament.manager.status") + ": "}<strong>{messages.get(statusInfo)}</strong></p>
                                        <ButtonGroup>
                                            <TournamentPrepareButton alertStyle={alertStyle} tournament={tournament} handleClick={this.setTournamentPrepared}/>
                                            <TournamentStartButton alertStyle={alertStyle} tournament={tournament} handleClick={this.setTournamentOpen}/>
                                            {
                                                tournament.isClosed() ? null :
                                                    <TournamentFinishButton alertStyle={alertStyle} tournament={tournament} handleClick={this.setTournamentClosed}/>
                                            }

                                        </ButtonGroup>
                                        {
                                            hasTournamentFeature ? null :
                                                <p><br/><br/>{messages.get("tournament.manager.chargeable") + " " + messages.get("tournament.manager.chargeable.tournament")}<br/>
                                                    <strong><a href={linkUtil.getLink("solutions")}> {messages.get("management.request.form.info.solutions")}</a></strong>
                                                </p>
                                        }
                                    </Alert>
                                    : null
                            }
                        </Col>
                    </Row>
                    {
                        childTournaments.length > 0 ?
                            <TournamentCupManager user={user}
                                                  tournament={tournament}
                                                  childTournaments={childTournaments}/>
                            : null
                    }
                    <hr/>
                    <Row>
                        <Col lg={6}>
                            {
                                showSimpleRoundButtons ?
                                    <Alert>
                                        <h4>{messages.get("tournament.manager.round.qualify.title")}</h4>
                                        {
                                            tournamentRoundMap.qualify.map(function(tournamentRound){
                                                roundCounter++;
                                                return <TournamentRoundButtons key={tournamentRound.id}
                                                                               hideRoundPoints={true}
                                                                               tournament={tournament}
                                                                               tournamentRound={tournamentRound}
                                                                               lastRound={roundCounter === tournamentRoundMap.qualify.length} />
                                            })
                                        }
                                    </Alert>
                                    : null
                            }
                        </Col>
                        <Col lg={6}>
                            {
                                showFinalRoundButtons ?
                                    <Alert bsStyle={alertStyle}>
                                        <h4>{messages.get("tournament.manager.round.final.title")}</h4>
                                        {
                                            tournamentRoundMap.finale.map(function(tournamentRound){
                                                roundFinalCounter++;
                                                return <TournamentRoundButtons key={tournamentRound.id}
                                                                               hideRoundPoints={false}
                                                                               tournament={tournament}
                                                                               tournamentRound={tournamentRound}
                                                                               lastRound={roundFinalCounter === tournamentRoundMap.finale.length} />
                                            })
                                        }
                                    </Alert>
                                    : null
                            }
                        </Col>
                        <Col lg={6}>
                            {
                                showGroupRoundButtons ?
                                    <Alert bsStyle={alertStyle}>
                                        <h4>{messages.get("tournament.manager.round.groups.title")}</h4>
                                        {
                                            tournamentRoundMap.group.map(function(tournamentRound){
                                                return <TournamentRoundButtons key={tournamentRound.id}
                                                                               hideRoundPoints={false}
                                                                               tournament={tournament}
                                                                               tournamentRound={tournamentRound}
                                                                               lastRound={true} />
                                            })
                                        }
                                    </Alert>
                                    : null
                            }
                        </Col>
                    </Row>
                </Panel.Body>
                <Panel.Footer>
                    <TournamentEditModals tournament={tournament} user={user}/>
                </Panel.Footer>
            </Panel>)
    }
}
TournamentMainStatus.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentRoundMap:PropTypes.object.isRequired,
    childTournaments:PropTypes.array.isRequired
};
const TournamentRegistrationButtons = ({alertStyle, tournament, user}) => {
    function setRegistrationOpen(e) {
        e != null ? e.preventDefault() : null;
        tournamentActions.updateTournamentRegStatus(tournament, user, Tournament.prototype.registrationStatus.open);
    }
    function setRegistrationFull(e) {
        e != null ? e.preventDefault() : null;
        tournamentActions.updateTournamentRegStatus(tournament, user, Tournament.prototype.registrationStatus.full);
    }
    function setRegistrationClosed(e) {
        e != null ? e.preventDefault() : null;
        tournamentActions.updateTournamentRegStatus(tournament, user, Tournament.prototype.registrationStatus.closed);
    }
    return <ButtonGroup>
        <Button bsStyle={alertStyle} disabled={tournament.isRegistrationOpened()} onClick={setRegistrationOpen}>{messages.get("tournament.manager.openRegistration")}</Button>
        <Button bsStyle={alertStyle} disabled={tournament.isRegistrationFull()}  onClick={setRegistrationFull}>{messages.get("tournament.manager.fullRegistration")}</Button>
        <Button bsStyle={alertStyle} disabled={tournament.isRegistrationClosed()} onClick={setRegistrationClosed}>{messages.get("tournament.manager.closeRegistration")}</Button>
    </ButtonGroup>
};
TournamentRegistrationButtons.propTypes = {
    tournament: PropTypes.object.isRequired,
    alertStyle:PropTypes.string.isRequired
};
const TournamentPrepareButton = ({alertStyle, tournament, handleClick}) => {
    return (
        <TooltipButton bsStyle={alertStyle} tooltip={messages.get("tournament.status.published.tooltip")}
                       onClick={handleClick} disabled={tournament.isPublished()}>
            <I18n code={"tournament.manager.prepareTournament"}/>
        </TooltipButton>
    )
};
TournamentPrepareButton.propTypes = {
    tournament: PropTypes.object.isRequired,
    alertStyle:PropTypes.string.isRequired
};
const TournamentStartButton = ({tournament, alertStyle, handleClick}) => {
    return (
        <TooltipButton bsStyle={alertStyle} tooltip={messages.get("tournament.status.running.tooltip")}
                       onClick={handleClick} disabled={!tournament.hasManagementID() || tournament.isRunning()}>
            <I18n code={"tournament.manager.startTournament"}/>
        </TooltipButton>
    )
};
TournamentStartButton.propTypes = {
    tournament:PropTypes.object.isRequired,
    alertStyle:PropTypes.string.isRequired,
    handleClick:PropTypes.func.isRequired
};
const TournamentFinishButton  = ({tournament, alertStyle, handleClick}) => {
    return (
        <TooltipButton bsStyle={alertStyle} tooltip={messages.get("tournament.status.closed.tooltip")}
                       onClick={handleClick} disabled={tournament.isClosed()}>
            <I18n code={"tournament.manager.closeTournament"}/>
        </TooltipButton>
    )
};
TournamentFinishButton.propTypes = {
    tournament:PropTypes.object.isRequired,
    alertStyle:PropTypes.string.isRequired,
    handleClick:PropTypes.func.isRequired
};
const TournamentRoundButtons = ({hideRoundPoints, tournament, tournamentRound, lastRound}) => {
    function setPrepareRound() {
        tournamentActions.updateTournamentRoundStatus(tournamentRound, TournamentRound.prototype.status.present);
    }
    function setStartRound() {
        tournamentActions.updateTournamentRoundStatus(tournamentRound, TournamentRound.prototype.status.running);
    }
    function setFinishRound() {
        if (tournamentRound.isHidePoints()) {
            tournamentRound.setHidePoints(false);
        }
        if (tournamentRound.isTypeOf(TournamentRound.prototype.typeGroups.group_finale)) {
            tournamentActions.closeTournamentFinalRound(tournament, tournamentRound, lastRound);
        } else if (tournamentRound.isTypeOf(TournamentRound.prototype.typeGroups.group_groups)) {
            tournamentActions.closeTournamentGroupRound(tournament, tournamentRound);
        } else {
            tournamentActions.closeTournamentSimpleRound(tournament, tournamentRound, lastRound);
        }
    }
    function setHideRoundPoints(hide) {
        tournamentRound.setHidePoints(hide);
        tournamentActions.saveTournamentRound(tournamentRound)
    }
    let alertStyle = "success";
    return (
        <React.Fragment>
            <p>{tournamentRound.getName()
            + "(" + tournamentRound.getTargetAmounts() + " "+ messages.get("parcours.details.targets")+ "): "}
                <strong>{messages.get(tournamentRound.getStatusTranslKey())}</strong></p>
            <ButtonGroup>
                <Button bsStyle={alertStyle} disabled={tournamentRound.isPresent()} onClick={setPrepareRound}>{messages.get("tournament.manager.prepareTournament")}</Button>
                <Button bsStyle={alertStyle} disabled={tournamentRound.isRunning()}  onClick={setStartRound}>{messages.get("tournament.manager.startRound")}</Button>
                <Button bsStyle={alertStyle} disabled={tournamentRound.isClosed()} onClick={setFinishRound}>{messages.get("tournament.manager.closeTournament")}</Button>
            </ButtonGroup><br/>
            {
                hideRoundPoints ?
                    <CheckboxInput default={tournamentRound.isHidePoints()}
                                   onAfterChange={value => setHideRoundPoints(value)}
                                   faIcon={"eye-slash"}
                                   message={messages.get("tournament.manager.hide.points")} />
                    : null
            }
            <br/><br/>
        </React.Fragment>)
};
TournamentRoundButtons.propTypes ={
    hideRoundPoints:PropTypes.bool.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentRound:PropTypes.object.isRequired,
    lastRound:PropTypes.bool.isRequired
};
const TournamentNextStep = ({nextAction}) => {
    return (
        <Row>
            <Col lg={12}>
                {
                    nextAction ?
                        <Alert bsStyle="info">
                            {messages.get("tournament.manager.nextStep")}: {nextAction}
                        </Alert>  : null
                }
            </Col>
        </Row>);
};
TournamentNextStep.propTypes = {
    nextAction:PropTypes.object
};
class TournamentEditModals extends React.Component {
    constructor(props) {
        super(props);
        this.refTournamentCreateModal = React.createRef();

        this.openMainModal = this.openMainModal.bind(this);
        this.setTournamentCancel = this.setTournamentCancel.bind(this);
        this.loadTournamentChangeLogs = this.loadTournamentChangeLogs.bind(this);
        this.state = {}
    }
    openMainModal() {
        this.refTournamentCreateModal.current.edit(this.props.tournament);
    }
    loadTournamentChangeLogs() {
        tournamentActions.queryTournamentChangeLogs(this.props.tournament);
    }
    setTournamentCancel(value) {
        tournamentActions.cancelTournament(this.props.tournament, this.props.user, value);
    }
    render() {
        const {user, tournament} = this.props;
        return (
            <Row>
                <Col xs={6} sm={3}>
                    <Button bsStyle="success" onClick={this.openMainModal}>
                        <FontAwesome icon="pencil-alt"/><I18n code="tournament.manager.editMaster"/>
                    </Button>
                    <TournamentCreateModal ref={this.refTournamentCreateModal} user={user}/>
                </Col>
                <Col xs={6} sm={3}>
                    <AdditionalEditorModal objectType={"Tournament"} object={tournament} titleCode={"tournament.manager.editors"}/>
                </Col>
                <Col xs={6} sm={3}>
                    <Button bsStyle="success" onClick={this.loadTournamentChangeLogs}>
                        <FontAwesome icon="history"/><I18n code="parcours.details.button.seeChangeLog"/>
                    </Button>
                </Col>
                <Col xs={6} sm={3}>
                    <CheckboxInput default={tournament.isCanceled()}
                                   onAfterChange={value => this.setTournamentCancel(value)}
                                   message={messages.get("tournament.manager.canceled")} />

                </Col>

            </Row>

        );
    }
}
TournamentEditModals.propTypes = {
    user: PropTypes.object.isRequired,
    tournament: PropTypes.object.isRequired
};
module.exports = TournamentManagerPage;
