const {differenceInDays, differenceInYears} = require('date-fns');
const monthLabels= ['Jänner', 'Feber', 'März', 'April',
    'Mai', 'Juni', 'Juli', 'August', 'September',
    'Oktober', 'November', 'Dezember']
const Utility = {
    isSet: function (val) {
        return (typeof val !== 'undefined') && val !== null;

    },
    isEmpty: function (val) {
        return !this.isSet(val)
            || (typeof val === 'string' && val.trim().length <= 0)
            || (typeof val === 'object' && Object.keys(val).length <= 0)
            || (typeof val === 'object' && val.length <= 0);
    },
    contains: function (col, val) {
        return col.indexOf(val) != -1;
    },
    containsArray: function (array, val) {
        if (array) {
            const length = array.length;
            for (let i = 0; i < length; i++) {
                if (array[i] == val) {
                    return true;
                }
            }
        }
        return false;
    },
    hasWriteLock(user, nullUserResponse) {
        if (user != null && typeof user.hasWriteLock === "function") {
            return user.hasWriteLock()
        }
        return nullUserResponse;
    },
    chunkArray: function(array, size) {
        const chunked_arr = [];
        for (let i = 0; i < array.length; i++) {
            const last = chunked_arr[chunked_arr.length - 1];
            if (!last || last.length === size) {
                chunked_arr.push([array[i]]);
            } else {
                last.push(array[i]);
            }
        }
        return chunked_arr;
    },
    buildModArray: function(length, mod) {
        let array = [];
        let arrayCount = length / mod;
        if (length % mod !== 0) {
            arrayCount++
        }
        for (let i = 0; i < arrayCount; i++) {
            array.push(i)
        }
        return array;
    },
    int2Array: function (maxValue) {
        const array = [];
        for (let i = 1; i <= maxValue; i++) {
            array.push(i);
        }
        return array
    },
    stringCSV2NumberArray: function (text) {
        let result = []
        if (text != null) {
            const txtArray = text.split(",")
            txtArray.forEach(txt => {
                let txtInt = parseInt(txt)
                if (!isNaN(txtInt)) {
                    result.push(txtInt)
                }
            })
        }
        return result
    },
    buildNoEmail:function (name, surname) {
        let email = name.trim().toLocaleLowerCase() + "." + surname.trim().toLocaleLowerCase();
        email = email.toLocaleLowerCase();
        email = email.replace(/ /g , ".");
        email = email.replace(/ö/g , "oe");
        email = email.replace(/ü/g , "ue");
        email = email.replace(/ä/g , "ae");
        return email + "@noemail.com";
    },
    isValidEmail: function(email) {
        return email != null && email.length > 0 && email.indexOf("noemail") === -1 && email.indexOf("nomail") === -1
    },
    cleanInvalidChars: function(name) {
        let fileName = name;
        fileName = fileName.toLocaleLowerCase();
        fileName = fileName.replace(/ /g , ".");
        fileName = fileName.replace(/ö/g , "oe");
        fileName = fileName.replace(/ü/g , "ue");
        fileName = fileName.replace(/ä/g , "ae");
        return fileName;
    },
    getBase64ImageFromURL(url) {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.setAttribute("crossOrigin", "anonymous");
            img.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL);
            };
            img.onerror = error => {
                reject(error);
            };
            img.src = url;
        });
    },
    isMobileDevice: function () {
        if (Keys.isProd) {
            return this.isAndroid() || this.isIOS();
        }
        return true;
    },
    isAndroid: function () {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.match(/Android/i)) {
            return true;
        }
        return false;
    },
    isIOS: function () {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)
            || userAgent.match(/iPod/i) || userAgent.match(/Macintosh/i)) {
            return true;
        }
        return false;
    },
    parseErrorInfo:function (error) {
        let code = 0;
        if (error.code) {
            code = error.code;
        }
        let trace = "";
        if (error.trace != null) {
            trace = " (" + error.trace + ")";
        }
        return error.message + " - Code: " + code + "," + trace;
    },
    prettyJSON: function (obj) {
        return JSON.stringify(obj, null, 2);
    },
    randomId: function () {
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    },
    getRandomInt: function(max) {
        return Math.floor(Math.random() * Math.floor(max));
    },
    reduceDecimals: function(value, decimalFloor) {
        return Math.floor(value * decimalFloor) / decimalFloor
    },
    getReadableDateRange(startDate, endDate) {
        let result = "";
        result = startDate.getDate() + "." + (startDate.getMonth() + 1) ;
        if (endDate && differenceInDays(startDate, endDate) > 1) {
            result += " - " + endDate.getDate() + "." + (endDate.getMonth() + 1) + "." + endDate.getFullYear();
        } else {
            result += "." + startDate.getFullYear();
        }
        return result;
    },
    getReadableDate: function (date) {
        if (date) {
            let day = date.getDate();
            if (day < 10) {
                day = "0" + day;
            }
            let month = (date.getMonth() + 1);
            if (month < 10) {
                month = "0" + month;
            }
            return day + "." + month + "." + date.getFullYear();
        }
        return "";
    },
    getDateSettlementExport: function (date) {
        let day = date.getDate();
        if (day < 10) {
            day = "0" + day;
        }
        let month = (date.getMonth() + 1);
        if (month < 10) {
            month = "0" + month;
        }
        return date.getFullYear() + "-" + month + "-" + day;
    },
    getReadableTime: function (date) {
        if (date) {
            let hours = date.getHours();
            if (hours < 10) {
                hours = "0" + hours;
            }
            let min = date.getMinutes();
            if (min < 10) {
                min = "0" + min;
            }
            return hours + ":" + min
        }
        return "";
    },
    getReadableTimeSeconds: function (date) {
        if (date) {
            let hours = date.getHours();
            if (hours < 10) {
                hours = "0" + hours;
            }
            let min = date.getMinutes();
            if (min < 10) {
                min = "0" + min;
            }
            let sec = date.getSeconds();
            if (sec < 10) {
                sec = "0" + sec;
            }
            return hours + ":" + min + ":" + sec
        }
        return "";
    },
    getGPXDate(timestamp) {
        // return yyyy-mm-ddThh:mm:ssZ
        let date = new Date(timestamp);
        let day = date.getDate();
        if (day < 10) {
            day = "0" + day;
        }
        let month = (date.getMonth() + 1);
        if (month < 10) {
            month = "0" + month;
        }
        let hours = date.getHours();
        if (hours < 10) {
            hours = "0" + hours;
        }
        let min = date.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        let sec = date.getSeconds();
        if (sec < 10) {
            sec = "0" + sec;
        }
        return date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + min + ":" + sec
    },
    convertMinutesToHours: function(minutes) {
        if (minutes != null && minutes > 0) {
            let hours = Math.floor(minutes / 60);
            if (hours < 10) {
                hours = "0" + hours;
            }
            let min = minutes % 60;
            if (min < 10) {
                min = "0" + min;
            }
            return hours +":"+min
        }
        return "00:00"
    },
    validateDate: function (dateValue, hours) {
        const dateReg = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
        if (dateValue.match(dateReg)) {
            const dateParts = dateValue.split(".");
            const startDate = new Date(dateParts[2], (dateParts[1] - 1), dateParts[0], hours, 0, 0, 0);
            return startDate;
        }
        return null;
    },
    calcDifferenceInYears: function (tournamentStartDate, birthdate) {
        return differenceInYears(tournamentStartDate, birthdate)
    },
    buildDate: function (dateValue) {
        const endDateParts = dateValue.split(".");
        return new Date(endDateParts[2], (endDateParts[1] - 1), endDateParts[0], 0, 0, 0, 0);
    },
    buildDateTime: function(dateValue, timeValue) {
        const endDateParts = dateValue.split(".");
        const endTimeParts = timeValue.split(":");
        return new Date(endDateParts[2], (endDateParts[1] - 1), endDateParts[0], endTimeParts[0], endTimeParts[1], 0, 0);
    },
    getWeek: function(date) {
        // Thursday in current week decides the year.
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
    },
    getMonthName: function (month) {
        // start by zero
        if (month >= 0 && month < 12) {
            return monthLabels[month]
        }
        return ""
    },
    buildKillCountDict(resKillCounts) {
        const nKillCounts = {};
        let rkillCounts = resKillCounts.split(",");
        for (var k = 0; k < rkillCounts.length; k++) {
            if (k != 0) {
                const killCnt = rkillCounts[k].split(":");
                if (killCnt.length = 2) {
                    let amount = nKillCounts[killCnt[0]];
                    if (amount) {
                        amount = parseInt(amount) + parseInt(killCnt[1]);
                    } else {
                        amount = parseInt(killCnt[1]);
                    }
                    nKillCounts[killCnt[0]] = amount;
                }
            }
        }
        return nKillCounts;
    },
    buildKillCounts2String: function(killCountsStr) {
        let resultString = "";
        let arrowSumUp = false;
        let z1 = 0;
        let z2 = 0;
        let z3 = 0;
        if (killCountsStr) {
            const killCounts = killCountsStr.split(",");
            killCounts.forEach(function (killCountString) {
                const killCnt = killCountString.split(':');
                if (killCnt.length > 1) {
                    // found count
                    const value = parseInt(killCnt[1]);
                    if (killCnt[0] % 1000 != 0) {
                        // hit a zone
                        if (arrowSumUp) {
                            var zone = killCnt[0].slice(-1);
                            if (1 == zone) {
                                z1 += parseInt(killCnt[1]);
                            } else if (2 == zone) {
                                z2 += parseInt(killCnt[1]);
                            } else if (3 == zone) {
                                z3 += parseInt(killCnt[1]);
                            }
                        } else {
                            if (1001 == killCnt[0]) {
                                z1 += parseInt(killCnt[1]);
                            } else if (1002 == killCnt[0]) {
                                z2 += parseInt(killCnt[1]);
                            } else if (1003 == killCnt[0]) {
                                z3 += parseInt(killCnt[1]);
                            }
                        }
                    }
                } else {
                    // first always f or t
                    if ("t" === killCnt[0]) {
                        arrowSumUp = true;
                    }
                }
            });
            resultString = z1 + "/" + z2 + "/" + z3;
        }
        return resultString;
    },
    highestZoneFromKillCount(killCountsStr) {
        let arrowSumUp = false;
        let z1 = 0;
        if (killCountsStr) {
            const killCounts = killCountsStr.split(",");
            killCounts.forEach(function (killCountString) {
                const killCnt = killCountString.split(':');
                if (killCnt.length > 1) {
                    // found count
                    const value = parseInt(killCnt[1]);
                    if (killCnt[0] % 1000 != 0) {
                        // hit a zone
                        if (arrowSumUp) {
                            var zone = killCnt[0].slice(-1);
                            if (1 == zone) {
                                z1 += parseInt(killCnt[1]);
                            }
                        } else {
                            if (1001 == killCnt[0]) {
                                z1 += parseInt(killCnt[1]);
                            }
                        }
                    }
                } else {
                    // first always f or t
                    if ("t" === killCnt[0]) {
                        arrowSumUp = true;
                    }
                }
            });
        }
        return z1;
    },
    getTUserStatusMessageCode(tUser) {
        let code = "";
        const status = tUser.status;
        switch (tUser.getStatus()) {
            case status.registered:
            {
                code = "tournamentUser.status.registered";
                break;
            }
            case status.present:
            {
                code = "tournamentUser.status.A";
                break;
            }
            case status.started:
            {
                code = "tournamentUser.status.S";
                break;
            }
            case status.scoreSubmitted:
            {
                code = "tournamentUser.status.S";
                break;
            }
            case status.proofSingleResult:
            {
                code = "tournamentUser.status.proof";
                break;
            }
            case status.waiting:
            {
                code = "tournamentUser.status.waiting";
                break;
            }
            case status.finished:
            {
                code = "tournament.status.finished";
                break;
            }
            case status.finishedRound:
            {
                code = "tournament.status.finished";
                break;
            }
            case status.disqualified:
            {
                code = "tournamentUser.status.disqualified";
                break;
            }
            case status.deleted:
            {
                code = "tournamentUser.status.D";
                break;
            }
        }
        return code;
    }
};

module.exports = Utility;
