const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const messages = require("i18n/messages"),
    I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput"),
    CheckboxInput = require("components/form/CheckboxInput");

const linkUtil = require("linkUtil");
const managementActions = require("actions/ManagementActions");

class BusinessPartnerForm extends React.Component {
    constructor(props) {
        super(props);
        this.refCompany = React.createRef();
        this.refMail = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refStreet = React.createRef();
        this.refZip = React.createRef();
        this.refPlace = React.createRef();
        this.refPhone = React.createRef();
        this.refFeatTM = React.createRef();
        this.refFeatPA = React.createRef();
        this.refFeatPP = React.createRef();
        this.refFeatUM = React.createRef();
        
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            error: null
        }
    }
    valid() {
        var valid = true;
        var required = [
            this.refStreet.current.getValue(),
            this.refZip.current.getValue(),
            this.refPlace.current.getValue(),
            this.refName.current.getValue(),
            this.refSurname.current.getValue(),
            this.refPhone.current.getValue(),
            this.refMail.current.getValue()
        ];
        for (var i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let address = {
            company: this.refCompany.current.getValue().trim(),
            name: this.refName.current.getValue().trim(),
            surname: this.refSurname.current.getValue(),
            phone: this.refPhone.current.getValue().trim(),
            mail: this.refMail.current.getValue().trim(),
            street: this.refStreet.current.getValue().trim(),
            zip: this.refZip.current.getValue().toString().trim(),
            place: this.refPlace.current.getValue().trim()
        };
        let features = [];
        if (this.refFeatTM.current.getValue()) {
            features.push("TM");
        }
        if (this.refFeatPA.current.getValue()) {
            features.push("PA");
        }
        if (this.refFeatPP.current.getValue()) {
            features.push("PP");
        }
        if (this.refFeatUM.current.getValue()) {
            features.push("UM");
        }
        managementActions.register(address, features, this.props.callback);
    }
    render() {
        let validateNow = this.state.error != null;
        return (
            <form action="#" onSubmit={this.handleSubmit}>
                <Row>
                    <Col sm={12}>
                        <h4><I18n code="management.request.form.header.Adress"/></h4>
                        <ValidInput ref={this.refCompany}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.union")}
                                    default=""/>
                        <ValidInput ref={this.refStreet}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.street")}
                                    default=""/>
                    </Col>
                    <Col sm={4}>
                        <ValidInput ref={this.refZip}
                                    valid={{maxLength: 6, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.zip")}
                                    default=""/>
                    </Col>
                    <Col sm={8}>
                        <ValidInput ref={this.refPlace}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.place")}
                                    default=""/>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col sm={12}>
                        <h4><I18n code="management.request.form.header.Contact"/></h4>
                    </Col>
                    <Col sm={6}>
                        <ValidInput ref={this.refName}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    placeholder={messages.get("tournament.register.name")}
                                    default=""/>
                    </Col>
                    <Col sm={6}>
                        <ValidInput ref={this.refSurname}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    placeholder={messages.get("tournament.register.surname")}
                                    default=""/>
                    </Col>
                    <Col sm={12}>
                        <ValidInput ref={this.refPhone}
                                    valid={{maxLength: 50, check:['isRequired','isPhone']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.phone")}
                                    addonBefore={<FontAwesome icon="phone"/>}
                                    default=""/>
                        <ValidInput ref={this.refMail}
                                    valid={{maxLength: 50, check:['isRequired', 'isMail']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.mail")}
                                    addonBefore={<FontAwesome icon="envelope"/>}
                                    default=""/>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col sm={12}>
                        <h4><I18n code="management.request.form.header.features"/></h4>
                        <strong><a href={linkUtil.getLink("solutions")}> {messages.get("management.request.form.info.solutions")}</a></strong>
                        <CheckboxInput ref={this.refFeatTM} message={messages.get("management.feature.TM")} />
                        <CheckboxInput ref={this.refFeatPA} message={messages.get("management.feature.PA")} />
                        <CheckboxInput ref={this.refFeatPP} message={messages.get("management.feature.PP")} />
                        <CheckboxInput ref={this.refFeatUM} message={messages.get("management.feature.UM")} />
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    {this.state.error != null ?
                        <Col sm={12}>
                            <Alert bsStyle="danger">
                                <div style={{display:"inline-block"}}>
                                    <FontAwesome icon="exclamation-circle"/>
                                </div>
                                <div style={{display:"inline-block"}}>
                                    {this.state.error}
                                </div>
                            </Alert>
                        </Col>
                        :
                        ""
                    }
                    <Col sm={12}>
                        <Button block bsSize="large" type="submit" bsStyle="primary">{messages.get("management.request.form.submit")}</Button>
                    </Col>
                </Row>
            </form>)
    }
}
BusinessPartnerForm.propTypes = {
    callback: PropTypes.func.isRequired
};
module.exports = BusinessPartnerForm;
