const alt = require("AltInit"),
    Parse = require("parse");
const linkUtil = require("linkUtil");
const parseDao = require("actdao/ParseBaseDao");
const releaseDao = require("actdao/ReleasesDao");
const userDao = require("actdao/UserDao");
const notificationActions = require("components/notification/NotificationActions");

const {User, UserToken} = require("parse/_Domain");

class UserActions {
    register(user) {
        user.signUp()
            .then(sUser => {
                location.reload();
            })
            .catch(error => {
                console.log(error.message);
                this.showLoginError("parse.error." + error.code);
            });
        return {};
    };

    verifyVersion(bundleVersion) {
        releaseDao.queryLastWebReleases()
            .then(release => {
               if (!release.isLatestVersion(bundleVersion)) {
                   // invalid version
                   notificationActions.notifyNewVersion();
               }
            })
            .catch(error => {
                console.log("QueryRelease", + error);
            });
        return {};
    }

    loginUsernameOrEmail(usernameOrEmail, password) {
        if (usernameOrEmail.indexOf("@") > -1) {
            // mabye email entered
            userDao.queryEmailLogin(usernameOrEmail)
                .then(username => {
                    if (username && username.length > 0) {
                        this.login(username, password);
                    } else {
                        this.login(usernameOrEmail, password);
                    }
                })
                .catch(error => {
                    console.log("error: " + error.message);
                });
        } else {
            this.login(usernameOrEmail, password);
        }
        return {};
    };

    login(username, password) {
        Parse.User.logIn(username, password)
            .then(user => {
                Parse.User.enableRevocableSession()
                    .catch(error => {
                        if (error && error.code == 100) {
                            console.log("CouldNotConnectToParse");
                            notificationActions.notifyParseOffline()
                        } else if(error && error.code == 209) {
                            console.log("ParseInvalidSession");
                            notificationActions.notifyInvalidSession();
                        }
                    })
                    .finally(_=> {
                        if (window.location.href.indexOf("userToken") > 0) {
                            // forward to start page as eg password was changed before
                            window.location.href = linkUtil.getLink("");
                        } else {
                            location.reload();
                        }
                    })
            })
            .catch(error => {
                if (error != null && error.code != null && error.code == 209) {
                    Parse.User.logOut();
                    window.location.reload()
                } else {
                    this.showLoginError("parse.error." + error.code);
                }
            });
        return {};
    };

    updateUserPassword(user, pwd, callback) {
        // update password
        const params = {userID: user.id, pass: pwd};
        userDao.editUserCCP(params)
            .then(result => {
                if (callback) {
                    callback(true);
                }
            })
            .catch(error => {
                error.trace="USRA.eUserPassword";
                notificationActions.parseError(error);
                if (callback) {
                    callback(false);
                }
            });
        return {};
    }

    setVerifiedEmailFlag(user, callback) {
        const params = {userID: user.id, emailVerified: true};
        userDao.editUserCCP(params)
            .then(result => {
                callback(result)
            })
            .catch(error => {
                error.trace="USRA.vUserMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveObject(object) {
        parseDao.saveObject(object);
        return {}
    }



    resetPasswordMail(email) {
        userDao.generateUserToken(email, UserToken.prototype.action.password)
            .then(result => {
                notificationActions.success("modal.password.reset.mail.success");
            })
            .catch(error => {
                if (error != null && "No user for this mail" === error.message) {
                    notificationActions.warning("parse.error.NoAccountForMail")
                } else {
                    error.trace="ADUA.rPwdMail for: " + email;
                    notificationActions.parseError(error);
                }

            });
        return {};
    }

    loadUserToken(userTokenID, callback) {
        userDao.loadUserToken(userTokenID)
            .then(userToken => {
                callback(userToken)
            })
            .catch(error => {
                error.trace="ADUA.lUsTo";
                notificationActions.parseError(error);
            });
        return {};
    }

    resendAuthenticationMail(user, userEmail) {
        userDao.resendAuthenticationMail(user.id, userEmail)
            .then(result => {
                notificationActions.success("contact.form.sendSuccess");
            })
            .catch(error => {
                error.trace="ADUA.rAuthMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    logout() {
        Parse.User.logOut()
            .then(result => {
                location.reload();
            })
            .catch(error => {
                notificationActions.parseError(error);
                location.reload();
            });
    };

    showLoginModal() {
        return {};
    };

    showLoginCreateModal() {
        return {};
    };

    hideLoginModal() {
        return {};
    };

    showLoginError(messageCode) {
        return messageCode;
    };

    removeLoginError() {
        return {}
    }
}

module.exports = alt.createActions(UserActions);
