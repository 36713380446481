const alt = require("AltInit"),
    TournamentUserResultDic = require("util/TournamentUserResultDic"),
    TournamentUserTeamResultDic = require("util/TournamentUserTeamResultDic");

const { TournamentRound} = require("parse/_Domain");

const parseDao = require("actdao/ParseBaseDao");
const tournamentResultDao = require("actdao/TournamentResultDao");
const notificationActions = require("components/notification/NotificationActions");

class TournamentResultActions {

    showResultDetails(tournament) {
        this.loadTournamentRounds(tournament);
    };

    loadTournamentUserResult(tournament, tournamentConfigAge, tournamentConfigBow, params) {
        const isLippek = tournament.getBowUnionID() ? tournament.getBowUnionID().isTypeLippek() : false;
        const isCupCombineAge = params.tConfigCombinedAge != null && tournament.getTournamentCupOptionID() ? tournament.getTournamentCupOptionID().hasCombineAge() : false;
        const userResultDic = new TournamentUserResultDic();
        if (tournament.getBowUnionID() != null) {
            userResultDic.setSortByMissFirst(tournament.getBowUnionID().isSortByMissFirst())
        }
        if (!isLippek) {
            // only needed if not Lippek
            if (isCupCombineAge) {
                userResultDic.setTournamentCombineGroups(tournament.getTournamentCupOptionID().getCombineTConfigAgeIDs());
                userResultDic.setTournamentSecondGroup([params.tConfigCombinedAge])
            } else if (params.tConfigCombinedAge) {
                // combine all configAgeIds
                userResultDic.setTournamentCombineGroups(tournament.getTournamentConfigAge());
                userResultDic.setTournamentSecondGroup([params.tConfigCombinedAge])
            } else {
                userResultDic.setTournamentSecondGroup(tournament.getTournamentConfigAge());
            }
        }
        tournamentResultDao.loadTournamentUserResult(tournament, tournamentConfigAge, tournamentConfigBow, params)
            .then(tUsers => {
                tUsers.forEach(function (tUser) {
                    if (isLippek) {
                        userResultDic.addSortTournamentUserLippek(tUser);
                    } else {
                        userResultDic.addSortTournamentUser(tUser, params.needFinalAdd);
                    }

                });
                this.updateTournamentUserResult(userResultDic);
            })
            .catch(error => {
                error.trace = "TREA.loadTURes";
                notificationActions.parseError(error);
            });
        return {};
    };

    loadTournamentRoundResult(tournamentRound, callback) {
        tournamentResultDao.loadTournamentRoundResult(tournamentRound)
            .then(tUserRoundResults => {
                callback(tUserRoundResults)
            })
            .catch(error => {
                error.trace = "TREA.loadTURoundRes";
                notificationActions.parseError(error);
            });
        return {};
    }

    removeTargetsFromResult(targetNrArr, selRound, tUserRoundResult, callback) {
        const hit_zone_matrix = [ 100000, 10000, 1000, 100, 10, 1, 1, 1, 1, 1, 1 ]
        // find event for the tournamentUser
        tournamentResultDao.loadTournamentEventPlayer(selRound, tUserRoundResult.getTournamentUserID())
            .then(resultPlayers => {
                if (resultPlayers.length > 0) {
                    console.log("Found " + tUserRoundResult.getTournamentUserID().getFullName() + " Event: " + resultPlayers[0].getPlayerName());
                    // recalc Result but remove targetNrs
                    let sumPoints = 0;
                    // TODO killCounts
                    let killValue = 0;
                    let killCounts = {};
                    const evRes = resultPlayers[0].getEventResults();
                    evRes.map(evResult => {
                        const evrTargetNr = evResult.getTargetIndex();
                        if (targetNrArr.indexOf(evrTargetNr) == -1) {
                            sumPoints += evResult.getPoints();
                            if (evResult.getKillNr() > 0 && evResult.getKillNr() < hit_zone_matrix.length) {
                                killValue += hit_zone_matrix[evResult.getKillNr() - 1]
                            }

                        }
                    });
                    tUserRoundResult.setCorrectionPoints(sumPoints, killValue);
                    tUserRoundResult.save(null).then(tUpdTUserRoundResult => {
                        callback(tUpdTUserRoundResult)
                    });
                    console.log("Points " +   resultPlayers[0].getSumPoints() + " -> New sum Points " + sumPoints)
                } else {
                    console.log("NOT Found " + tUserRoundResult.getTournamentUserID().getFullName() )
                }
        });
        return {}
    }

    switchWithCorrPoints(tRoundUserResults, callback) {
        tRoundUserResults.map(tRoundUserResult => {
            let corrPoints = tRoundUserResult.getCorrectionPoints();
            let corrKillValues = tRoundUserResult.getCorrectionKillValue();
            if (corrPoints != null) {
                let sumPoints = tRoundUserResult.getRoundPoints();
                let killValue = tRoundUserResult.getKillValue();
                tRoundUserResult.setRoundPoints(corrPoints);
                tRoundUserResult.setKillValue(corrKillValues);
                tRoundUserResult.setCorrectionPoints(sumPoints, killValue);
            }
        });
        parseDao.saveAll(tRoundUserResults)
            .then(result => {
                callback()
            })
            .catch(error => {
                error.trace = "TREA.switchWithCorrPoints";
                notificationActions.parseError(error);
            });
        return {}
    }


    loadTournamentUserTeamResult(tournament) {
        const userResultDic = new TournamentUserTeamResultDic();
        tournamentResultDao.loadTournamenUserTeam(tournament)
            .then(tUserTeams => {
                tUserTeams.forEach(function (tUserTeam) {
                    userResultDic.addSortTournamentUserTeam(tUserTeam);
                });
                this.updateTournamentUserResult(userResultDic);
            })
            .catch(error => {
                error.trace = "TREA.loadTURes";
                notificationActions.parseError(error);
            });
        return {};
    };

    loadTournamentRounds(tournament) {
        // init displayState
        const displayState = tournament.isTypePairRegistration() ? "G" : "S";
        this.updateDisplayState(displayState, true);
        // load rounds
        tournamentResultDao.loadTournamentRounds(tournament)
            .then(tRounds => {
                const params = {needFinalAdd: false, optionString: null, tConfigCombinedAge: null};
                const tournamentRoundMap = {
                    qualify:[], finale:[], group:[], extra:[]
                }
                tRounds.map(tRound => {
                    if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_simple)) {
                        tournamentRoundMap.qualify.push(tRound);
                    } else if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_finale)) {
                        if (tRound.isFinalAdd()) {
                            params.needFinalAdd = true;
                        }
                        tournamentRoundMap.finale.push(tRound);
                    } else if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_groups)){
                        tournamentRoundMap.group.push(tRound);
                    } else if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_extra)) {
                        tournamentRoundMap.extra.push(tRound);
                    }
                });
                this.updateTournamentRoundMap(tournamentRoundMap);
                this.loadTournamentResult(tournament, displayState, params);
            })
            .catch(error => {
                error.trace = "TREA.loadTRounds";
                notificationActions.parseError(error);
            });
        return {};
    };

    loadTournamentResult(tournament, displayState, params) {
        this.updateParams(params);
        if (displayState === "G") {
            this.loadTournamentUserTeamResult(tournament);
        } else {
            this.loadTournamentUserResult(tournament, null, null, params);
        }
        return {};
    }

    deleteContender(contenderTUser, tournament) {
        console.log("del cont act");
        tournamentResultDao.deleteContender(contenderTUser, tournament)
            .then(tournamentUser => {
                if (tournamentUser.length <= 0) {
                    // no registration with given email
                    this.refreshCompetitorTUser();
                } else {
                    // horray registration found
                    console.log("Found " + tournamentUser[0].getName());
                    this.removeContCompTuser(contenderTUser, tournamentUser[0],tournament, false);
                }
            })
            .catch(error => {
                error.trace = "TREA.delContAct";
                notificationActions.parseError(error);
            });
        return {};
    };
    deleteCompetitor(competitorTUser, tournament) {
        console.log("del comp act");
        tournamentResultDao.deleteCompetitor(competitorTUser, tournament)
            .then(tournamentUser => {
                if (tournamentUser.length <= 0) {
                    // no registration with given email
                    this.refreshCompetitorTUser();
                } else {
                    // horray registration found
                    console.log("Found " + tournamentUser[0].getName());
                    this.removeContCompTuser(tournamentUser[0], competitorTUser, tournament, true);
                }
            })
            .catch(error => {
                error.trace = "TREA.delCompAct";
                notificationActions.parseError(error);
            });
        return {};
    };

    removeContCompTuser(contenderTUser, competitorTUser, tournament, mail2Cont) {
        const that = this;
        contenderTUser.removeContender();
        competitorTUser.removeCompetitor();
        const tournamentUsers = [];
        tournamentUsers.push(competitorTUser);
        tournamentUsers.push(contenderTUser);
        parseDao.saveAll(tournamentUsers)
            .then(stournamentUser => {
                console.log("juhu - updated");
            })
            .catch(error => {
                error.trace = "TREA.removeContComp";
                console.log("error: " + error.message);
            });
    };

    refreshCompetitorTUser() {
       return {};
    };

    updateTournamentUserResult(tournamentUserDic) {
        return tournamentUserDic;
    };

    updateTournamentRoundMap(tournamentRoundMap) {
        return tournamentRoundMap;
    };

    resetTournamentResults() {
        return {};
    };

    updateParams(params) {
        return params
    }

    updateDisplayState(displayState, loading) {
        return {displayState: displayState, loading: loading};
    }
}

module.exports = alt.createActions(TournamentResultActions);
