const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Alert, Button, ButtonGroup, Dropdown, Well, MenuItem, PageHeader, Table} = require("react-bootstrap");
const {ParcoursRating} = require("parse/_Domain");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ImageGalleryTeaser = require("components/widgets/ImageGalleryTeaser"),
    ParcoursAllRatingsModal = require("components/modals/ParcoursAllRatingsModal"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    StarRating = require("components/widgets/StarRating"),
    StarRater = require("components/widgets/StarRater"),
    ImageBanner = require("components/widgets/ImageBanner"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    ParcoursFavorit = require("components/parcours/ParcoursFavorit"),
    SubmitButton = require("components/form/SubmitButton"),
    ValidInput = require("components/form/ValidInput");

const Utility = require("util/Utility");

const ParcoursRankingButton = require("components/links/ParcoursRankingButton"),
    ParcoursTargetButton = require("components/links/ParcoursTargetButton"),
    ParcoursVisitButton = require("components/links/ParcoursVisitButton"),
    ParcoursPaymentButton = require("components/links/ParcoursPaymentButton");

const DecisionModal = require("components/modals/DecisionModal"),
    TournamentCreateModal = require("components/tournamentmodal/TournamentCreateModal");
const  ParcoursLoader = require("components/parcours/ParcoursLoader"),
    ParcoursContact = require("components/parcours/ParcoursContact"),
    ParcoursCategory = require("components/parcours/ParcoursCategory"),
    ParcoursOpenDays = require("components/parcours/ParcoursOpenDays"),
    ParcoursAddModal = require("components/parcours/ParcoursAddModal");
const messages = require("i18n/messages");

const userActions = require("actions/UserActions"),
    analyticsActions = require("actions/AnalyticsActions"),
    parcoursActions = require("actions/ParcoursActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const ParcoursDetailPage = ({onlineID}) => {
    return <ParcoursLoader onlineID={onlineID} rating={true} editCheck={"F"} loginMandatory={false}>
        <ParcoursDetails />
    </ParcoursLoader>
};
ParcoursDetailPage.propTypes = {
    onlineID: PropTypes.string.isRequired
};
class ParcoursDetails extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        document.title = this.props.parcours.buildNames();
    }
    componentWillUnmount() {
        parcoursActions.resetParcours();
    }
    render() {
        const {parcours, user, ownRating, parcoursArticles} = this.props;
        let parCat = null;
        if (parcours.getCategory() && parcours.getCategory().length > 0) {
            parCat = parcours.getCategory();
        }
        let parOwnCat = null;
        if (parcours.getParcoursOwnerID().getCategory() != null && parcours.getParcoursOwnerID().getCategory().length > 0) {
            parOwnCat = parcours.getParcoursOwnerID().getCategory();
        }
        let constraints = null;
        if (parcours.getConstraints() && parcours.getConstraints().length > 0) {
            constraints = parcours.getConstraints();
        }
        let associations = null;
        if (parcours.getParcoursOwnerID().getAssociations() && parcours.getParcoursOwnerID().getAssociations().length > 0) {
            associations = parcours.getParcoursOwnerID().getAssociations();
        }
        let targetAmount = "";
        if (parcours.getTargetAmounts() != null) {
            targetAmount = parcours.getTargetAmounts() + " " + messages.get("parcours.details.targets");
        }
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            <Breadcrumb code={parcours.buildNames()} active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <ImageBanner imageObject={parcours.getParcoursOwnerID().getBannerImageID()}/>
                </Row>
                <Row>
                    <Col lg={12}>
                        <PageHeader>{parcours.buildNames()}
                            <br/><ParcoursFavorit parcours={parcours}/>
                            <ActionMenu parcours={parcours} user={user} />
                        </PageHeader>
                    </Col>
                </Row>
                <AboFunctionsButtons parcours={parcours} />
                {
                    parcours.getSortIndex() > 950 && parcours.getSortIndex() < 1010 ?
                        <Row><Col lg={12}><br/>
                            <Alert bsStyle="info">
                                <I18n code="parcours.details.add.moreInfo" />&nbsp;
                                <Button bsStyle="primary" onClick={this.handleEditClick} href={linkUtil.getLink("parcours/" + parcours.getOnlineID() + "/edit")}>
                                    <FontAwesome icon="pencil-alt"/><I18n code="parcours.details.button.editParcours"/>
                                </Button>
                            </Alert>
                        </Col></Row> : null
                }
                <br/><br/>
                <Row>
                    <Col sm={4}>
                        <h3>{targetAmount}</h3>
                        <LineBreaker items={parcours.getDescription()}/>
                        <LineBreaker items={parcours.getParcoursOwnerID().getDescription()}/>
                        { parCat == null ? "" :
                            <ParcoursCategory header={<I18n code="parcours.details.area.parcours"/>} list={parCat}
                                              faIcon="asterisk"/>

                        }
                        { constraints == null ? "" :
                            <ParcoursCategory header={<I18n code="parcours.details.area.constraints"/>}
                                              list={constraints} faIcon="exclamation-circle"/>
                        }
                    </Col>
                    { parOwnCat == null && associations == null ? "" :
                        <Col sm={4}>
                            { parOwnCat == null ? "" :
                                <ParcoursCategory header={<I18n code="parcours.details.area.infra"/>} list={parOwnCat}
                                                  faIcon="asterisk"/>}
                            {associations == null ? "" :
                                <ParcoursCategory header={<I18n code="parcours.details.area.association"/>}
                                                  list={associations} faIcon="book"/>}
                            <p><strong><I18n code="parcours.details.last.change"/></strong><br/>{Utility.getReadableDate(parcours.getLastUpdatedAt())}</p>
                        </Col>
                    }
                    <Col sm={4}>
                        <ParcoursRatingElement parcours={parcours} ownRating={ownRating} user={user}/>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    {
                        parcours.hasPaymentEnabled() ? <ParcoursPrices parcoursArticles={parcoursArticles}/> :
                            <Col sm={4}>
                                <h3><FontAwesome icon="euro-sign"/><I18n code="parcours.details.area.prices"/></h3>
                                <LineBreaker items={parcours.getDayprice()}/>
                                <LineBreaker items={parcours.getSpecialprice()}/>
                                <LineBreaker items={parcours.getParcoursOwnerID().getYearprice()}/>
                            </Col>
                    }
                    <Col sm={4}>
                        <h3><FontAwesome icon="clock"/><I18n code="parcours.details.area.openTimes"/></h3>
                        <LineBreaker items={parcours.getParcoursOwnerID().getOpentimes()}/>
                        <LineBreaker items={parcours.getParcoursOwnerID().getSupporttimes()}/>
                        <ParcoursOpenDays items={parcours.getParcoursOwnerID().getOpenDays()}/>
                    </Col>
                    <Col sm={4}>
                        <ParcoursContact parcoursOwner={parcours.getParcoursOwnerID()}/>
                    </Col>
                </Row>
                <PointInterestBanner />
                <ErrorBoundary>
                    <ImageGalleryTeaser parcours={parcours} user={user}/>
                </ErrorBoundary>
            </div>)
    }
}
ParcoursDetails.propTypes = {
    parcours:PropTypes.object.isRequired,
    ownRating:PropTypes.object,
    user:PropTypes.object
};
const ParcoursPrices = ({parcoursArticles}) => {
    return <Col sm={4}>
        <h3><FontAwesome icon="euro-sign"/><I18n code="parcours.details.area.prices"/></h3>
        {
            parcoursArticles != null && parcoursArticles.length > 0 ?
                <Table striped responsive>
                    <thead>
                    <tr>
                        <th className="col-xs-9 col-md-9"><I18n code="tournament.table.column.article"/></th>
                        <th className="col-xs-3 col-md-3"><I18n code="parcours.articles.form.price"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        parcoursArticles.map(article => {
                            return <tr>
                                <td>{article.getName()}</td>
                                <td>{article.getPrice().toFixed(2) + ' ' + article.getCurrency()}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
                :
                <Alert bsStyle="warning"><I18n code="checkout.info.parcours.cart.empty"/></Alert>
        }
    </Col>
}
const LineBreaker = ({items}) => {
    let counter = 0;
    return (
        <p>
            {
                items == null ? "" :
                    items.split("\n").map(item => {
                        counter++;
                        return (
                            <span key={counter}>{item}<br/></span>
                        )
                    })
            }
        </p>)
};
LineBreaker.propTypes = {
    items:PropTypes.string
};
const AboFunctionsButtons = ({parcours}) => {
    function buildLinkUrl() {
        let send2AppUrl = "http://www.3dturnier.com/download.html";
        if (Utility.isAndroid()) {
            send2AppUrl = "http://www.3dturnier.com/parcoursimp" +
                "?name=" + encodeURIComponent(parcours.buildNames()) +
                "&loc=" + encodeURIComponent(parcours.getZipPlace()) +
                "&id=" + encodeURIComponent(parcours.getOnlineID());
        } else if (Utility.isIOS()) {
            send2AppUrl = "com.3dturnier://parcours" +
                "?name=" + encodeURIComponent(parcours.buildNames()) +
                "&loc=" + encodeURIComponent(parcours.getZipPlace()) +
                "&id=" + encodeURIComponent(parcours.getOnlineID());
        }
        return send2AppUrl
    }
    return <Well>
        {Utility.isMobileDevice() ?
            <Row><Col xs={12} lgHidden>
                <Button bsStyle="primary" href={buildLinkUrl()}>
                    <FontAwesome icon="mobile-alt"/><I18n code="parcours.button.sendToApp"/>
                </Button>
                <hr/>
            </Col></Row>
            : null
        }

        <Row>
        <Col md={2} sm={4} xs={4}>
            {
                parcours.hasVisitEnabled() ? <ParcoursVisitButton block parcours={parcours}/> :
                    parcours.hasPaymentEnabled() ? <ParcoursPaymentButton block parcours={parcours}/> : null
            }
        </Col>
        <Col md={2} sm={4} xs={4}>
            <ParcoursRankingButton block parcours={parcours}/>
        </Col>
        <Col md={2} sm={4} xs={4}>
            {
                parcours.hasTargetEnabled(true) ? <ParcoursTargetButton block parcours={parcours}/> :null
            }

        </Col>
    </Row></Well>
};
class ActionMenu extends React.Component {
    constructor(props) {
        super(props);
        this.refParcoursAddModal = React.createRef();
        this.refDeleteParcours = React.createRef();
        this.refTournamentCreateModal = React.createRef();
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleAddParcours = this.handleAddParcours.bind(this);
        this.showDeleteButton = this.showDeleteButton.bind(this);
        this.askParcoursDelete = this.askParcoursDelete.bind(this);
        this.openTournamentCreateModal = this.openTournamentCreateModal.bind(this);
        this.handleParcoursDelete = this.handleParcoursDelete.bind(this);
    }
    handleEditClick() {
        analyticsActions.trackParcoursClick(this.props.parcours.getOnlineID(), true);
    }
    handleAddParcours() {
        if (this.props.user !== null) {
            this.refParcoursAddModal.current.open();
        } else {
            userActions.showLoginModal();
        }
    }
    askParcoursDelete() {
        this.refDeleteParcours.current.open();
    }
    openTournamentCreateModal() {
        if (this.props.user !== null) {
            this.refTournamentCreateModal.current.open();
        } else {
            userActions.showLoginModal();
        }
    }

    showDeleteButton() {
        if (this.props.user != null && this.props.parcours.getEditor() != null) {
            return this.props.user.id == this.props.parcours.getEditor().id;
        }
        return false;
    }
    handleParcoursDelete() {
        parcoursActions.deleteParcours(this.props.parcours);
    }
    render() {
        const {parcours, user} = this.props;

        const menuItems = [];
        menuItems.push(<MenuItem key="1edit" eventKey="1" onClick={this.handleEditClick}
                                 href={linkUtil.getLink("parcours/" + parcours.getOnlineID() + "/edit")}>
            <FontAwesome icon="pencil-alt"/><I18n code="parcours.details.button.editParcours"/></MenuItem>);
        menuItems.push(<MenuItem key="2add" eventKey="2" onSelect={this.handleAddParcours}>
            <FontAwesome icon="plus"/><I18n code="parcours.details.button.addParcours"/></MenuItem>);
        menuItems.push(<MenuItem key="2addT" eventKey="21" onSelect={this.openTournamentCreateModal}>
            <FontAwesome icon="plus"/><I18n code="tournament.button.create"/></MenuItem>);
        if (!parcours.hasParcoursManager()) {
            menuItems.push(<MenuItem key="3manage" eventKey="3"
                                     href={linkUtil.getLink("parcours/" + parcours.getParcoursOwnerID().id + "/manage")}>
                <FontAwesome icon="inbox"/><I18n code="parcours.details.button.takeManagement"/></MenuItem>);
        }

        if (this.showDeleteButton()) {
            menuItems.push(<MenuItem key="4del" eventKey="4" onSelect={this.askParcoursDelete}>
                <FontAwesome icon="trash"/><I18n code="parcours.details.button.deleteParcours"/></MenuItem>);
        }

        return (<React.Fragment>
            <ButtonGroup  className="pull-right">
                <Dropdown id="actionDropDown" disabled={Utility.hasWriteLock(user, false)}>
                    <Dropdown.Toggle bsStyle="primary">
                        <FontAwesome icon="pencil-alt"/>
                        <I18n code="parcours.details.button.actions"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                        {menuItems}
                    </Dropdown.Menu>
                    <TournamentCreateModal ref={this.refTournamentCreateModal} parcours={parcours} user={user} />
                </Dropdown>
            </ButtonGroup>
            <ParcoursAddModal ref={this.refParcoursAddModal}
                              parcoursOwner={parcours.getParcoursOwnerID()}/>
            <DecisionModal ref={this.refDeleteParcours}
                           titleCode={"parcours.details.button.deleteParcours"}
                           messageCode={"parcours.details.ask.deleteParcours"}
                           okButtonCode={"modal.button.delete"}
                           cancelButtonCode={"modal.button.cancel"}
                           handleOK={this.handleParcoursDelete}/>
        </React.Fragment>);
    }
}
class ParcoursRatingElement extends React.Component {
    constructor(props) {
        super(props);
        this.refParcoursAllRatingsModal = React.createRef();
        this.onClickAllRatings = this.onClickAllRatings.bind(this);
        this.state = {}
    }
    onClickAllRatings() {
        this.refParcoursAllRatingsModal.current.open();
    }
    render() {
        const {parcours, user, ownRating} = this.props;
        let ratingAv = null;
        if (parcours.getRatingAv() != null) {
            ratingAv = parcours.getRatingAv().toFixed(1);
        }
        return (
            <div>
                <h3><FontAwesome icon="star"/><I18n code="parcours.details.area.rating"/></h3>
                {
                    ratingAv != null ?
                        <div><h3>{ratingAv} <StarRating rating={parseInt(ratingAv)}/></h3>
                            <FontAwesome icon="user"/>{parcours.getRatingCo()}&nbsp;&nbsp;&nbsp;
                            <Button onClick={this.onClickAllRatings}>
                                <I18n code="parcours.details.area.allratings"/>&nbsp;<FontAwesome icon="caret-down"/>
                            </Button>
                            <ParcoursAllRatingsModal ref={this.refParcoursAllRatingsModal}/>
                            <br/><br/>
                        </div> : null
                }
                <OwnParcoursRating user={user} parcours={parcours} ownRating={ownRating}/>
            </div>)
    }
}
ParcoursRatingElement.propTypes = {
    parcours:PropTypes.object.isRequired,
    ownRating:PropTypes.object,
    user:PropTypes.object
};
class OwnParcoursRating extends React.Component {
    constructor(props) {
        super(props);
        this.refRatingComment = React.createRef();

        this.handleStarsSelected = this.handleStarsSelected.bind(this);
        this.saveRating = this.saveRating.bind(this);

        this.state = {
            starsSelected: 0
        }
    }
    handleStarsSelected(amount) {
      this.setState({
          starsSelected: amount
      })
    }
    saveRating() {
        let tmpRating = this.props.ownRating;
        if (tmpRating == null) {
            tmpRating = new ParcoursRating();
            tmpRating.setEditor(this.props.user);
            tmpRating.setStatus("A");
            tmpRating.setParcoursID(this.props.parcours);
            tmpRating.setOldRating(0);
        } else {
            tmpRating.setOldRating(tmpRating.getRating());
        }
        tmpRating.setRating(this.state.starsSelected);
        tmpRating.setComment(this.refRatingComment.current.getValue());
        this.handleStarsSelected(0);
        parcoursActions.saveUserRating(tmpRating);
    }
    render() {
        const {user, ownRating} = this.props;
        if (Utility.hasWriteLock(user, true)) {
            return null;
        } else {
            let info = messages.get("parcours.details.area.rateEntry");
            let starRater;
            let comment = null;
            if (ownRating != null) {
                info = ownRating.getRatingDate();
                comment = ownRating.getComment();
                starRater = <StarRater key={ownRating.id} actRating={ownRating.getRating()} handleStarsSelected={this.handleStarsSelected}/>;
            } else {
                starRater = <StarRater key="emptyRater" actRating={0} handleStarsSelected={this.handleStarsSelected}/>;
            }
            return (
                <Well>
                    <h4>{user.getUsername()}:&nbsp;{info}</h4>
                    {starRater}
                    {
                        this.state.starsSelected == 0 ? null :
                            <form>
                                <br/>
                                <ValidInput ref={this.refRatingComment}
                                            componentClass="textarea"
                                            placeholder={messages.get("parcours.details.area.rating.comment")}
                                            default={comment}
                                            valid={{maxLength: 500}}/>
                                <SubmitButton onClick={this.saveRating} disabled={false}
                                              text={<I18n code="modal.button.save"/>}
                                              bsStyle="success"/>

                            </form>
                    }
                </Well>)
        }
    }
}
OwnParcoursRating.propTypes = {
    parcours:PropTypes.object.isRequired,
    ownRating:PropTypes.object,
    user:PropTypes.object
};
module.exports = ParcoursDetailPage;
