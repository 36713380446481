const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Image} = require("react-bootstrap");
const messages = require("i18n/messages");

const Title = require("components/widgets/Title"),
    LinkButton = require("components/links/LinkButton"),
    Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n"),
    DateInput = require("components/form/DateInput"),
    TextCenter = require("components/widgets/TextCenter"),
    TournamentConfigAgeCalculator = require("components/tournamentregister/TournamentConfigAgeCalculator"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    FontAwesome = require("components/widgets/FontAwesome");

const licenseStore = require("stores/LicenseStore"),
    licenseActions = require("actions/LicenseActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");
const {Tournament} = require("parse/_Domain");

class TournamentRulePage extends React.Component {
    constructor(props) {
        super(props);
        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.updateTournament = this.updateTournament.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        let tournament = new Tournament();
        tournament.setDate(new Date())
        tournament.setTournamentConfigAge([])
        this.state = {
            licenseStore: licenseStore.getState(),
            tournament: tournament,
            bowUnionCode: this.props.bowUnionCode != null ? this.props.bowUnionCode.toUpperCase() : null
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.tournamentRules");
        licenseStore.listen(this.handleLicenseChange);
        if (this.state.bowUnionCode) {
            licenseActions.loadBowUnionsAndTypes.defer(this.state.bowUnionCode);
        }
    }
    componentWillUnmount() {
        licenseStore.unlisten(this.handleLicenseChange);
    }
    handleLicenseChange(state) {
        this.setState({licenseStore: state});
        if (state.bowUnions != null && state.ageTypes != null) {
            this.updateTournament(state.bowUnions[0], state.ageTypes)
        }
    }
    updateTournament(bowUnion, ageTypes) {
        let tournament = this.state.tournament;
        tournament.setBowUnionID(bowUnion)
        tournament.setTournamentConfigAge(ageTypes)
        this.setState({tournament: tournament})
    }
    handleStartDateChange(value) {
        // TODO how to recalc age class after changing tournament date
        let tournament = this.state.tournament;
        const startDateParts = new Date(value);
        tournament.setDate(new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), 4, 0, 0, 0));
        this.setState({tournament: tournament})
    }
    render() {
        const {bowUnionCode, tournament, licenseStore} = this.state
        const {loading, bowTypes, ageTypes} = licenseStore
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            {
                                bowUnionCode != null ? <React.Fragment>
                                        <Breadcrumb link="/tournament/rules" code="header.nav.tournamentRules"/>
                                        <Breadcrumb link="/tournament/rules" code={bowUnionCode} active/>
                                    </React.Fragment> :
                                    <Breadcrumb link="/tournament/rules" code="header.nav.tournamentRules" active/>
                            }
                        </Breadcrumbs>
                    </Col>
                </Row>
                {
                    bowUnionCode != null ? <Title code="header.nav.tournamentRules">&nbsp;{bowUnionCode}</Title>
                        : <React.Fragment>
                            <Title code="header.nav.tournamentRules"></Title>
                            <h3>Wähle einen Verband aus:</h3>
                            <LinkButton bsSize="large" label="WA" href={linkUtil.getLink("tournament/rules/WA")}/>
                            <LinkButton bsSize="large" label="IFAA" href={linkUtil.getLink("tournament/rules/IFAA")}/>
                        </React.Fragment>
                }
                {
                    bowUnionCode === "WA" ? <RulesWA/> : null
                }
                {
                    bowUnionCode === "IFAA" ? <RulesIFAA/> : null
                }
                <hr/>
                <PointInterestBanner />
                {
                    bowUnionCode != null ? <React.Fragment>
                        <br/>
                        {
                            loading ? <Loading/> : <Row>
                                <BowAgeTypes title="tournament.class.bow" array={bowTypes} icon="bullseye"/>
                                <BowAgeTypes title="tournament.class.age" array={ageTypes} icon="book"/>
                                <Col xs={12}>
                                    <br/><br/>
                                    <TournamentConfigAgeCalculator tournament={tournament} selectCallback={null}>
                                        <Col lg={3}>
                                            <DateInput default={tournament.getDate()}
                                                       label={messages.get("tournament.create.date")}
                                                       onAfterChange={this.handleStartDateChange}/>
                                        </Col>
                                    </TournamentConfigAgeCalculator>
                                </Col>
                            </Row>
                        }
                    </React.Fragment> : null
                }


            </Grid>)
    }
}
const BowAgeTypes =({title, icon, array}) => {
    if (array != null) {
        return <Col md={6}>
            <h2><FontAwesome icon={icon}/><I18n code={title}/></h2>
            <ul>
                {
                    array.map(entry => {
                        return <li key={entry.id}>{entry.getSelectString()}</li>
                    })
                }
            </ul>
        </Col>
    }
    return null
}
const RulesWA = () => {
    return <Row>
        <Col md={6}>
            <h2><FontAwesome icon="check"/>Schießen</h2>
            <ul>
                <li>Schützen schießen paarweise</li>
                <li>Einen Meter seitlich oder hinter dem Pflock</li>
                <li>1,5 Minuten Zeitlimit</li>
            </ul>
            <h2><FontAwesome icon="check"/>Rückpraller und Durchschuß</h2>
            <ul>
                <li>Gruppe entscheidet ob es ein Rückpraller/ Durchschuß ist</li>
                <li>Gruppen entscheidet auch über den Wert</li>
            </ul>
            <h2><FontAwesome icon="check"/>Zählweise</h2>
            <ul>
                <li>Anliegend - Linie zählt zum höheren Wertungsbereich</li>
                <li>Gruppenentscheid bei strittigem Pfeil</li>
                <li>Abgelenkter Pfeil wie er steckt</li>
                <li>Boden-auf gilt</li>
                <li>Huf und Horn ist Miss</li>
            </ul>
        </Col>
        <Col md={6}>
            <Image src="/img/wiki/arrow_line.jpg" responsive rounded />
            <TextCenter><strong>Anliegend - Bei WA ein Treffer</strong></TextCenter>
        </Col>
    </Row>
}
const RulesIFAA = () => {
    return <Row>
        <Col md={6}>
            <h2><FontAwesome icon="check"/>Schießen</h2>
            <ul>
                <li>Max 15 cm Abstand vom Pflock</li>
                <li>Nicht vor dem Pflock</li>
                <li>Kein Zeitlimit</li>
            </ul>
            <h2><FontAwesome icon="check"/>Rückpraller und Durchschuß</h2>
            <ul>
                <li>Gekennzeichneter Pfeil kann nachgeschossen werden</li>
            </ul>
            <h2><FontAwesome icon="check"/>Zählweise</h2>
            <ul>
                <li>Durch-trennt - Linie zählt zum niedrigeren Wertungsbereich</li>
                <li>Target-Käptn entscheidet</li>
                <li>Abgelenkter Pfeil wie er steckt</li>
                <li>Boden-auf gilt nicht!</li>
                <li>Hufe zählen zum Wertungsbereich</li>
            </ul>
        </Col>
        <Col md={6}>
            <Image src="/img/wiki/arrow_line.jpg" responsive rounded />
            <TextCenter><strong>Nur anliegend - Bei IFAA kein Treffer</strong></TextCenter>
        </Col>
    </Row>
}
module.exports = TournamentRulePage;




