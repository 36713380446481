const React = require("react"),
    PropTypes = require('prop-types');
const {APIProvider, Map, useMap} = require('@vis.gl/react-google-maps');

const BaseMap = ({mapOptions, elements, children, onCenterChanged, geoSearchString, geoCallback}) => {
    return <APIProvider apiKey={"AIzaSyB_TWuP1qLuj9sQ7lLai1E8BCj-MUvcFho"}>
        <MapVis children={children} elements={elements} geoCallback={geoCallback} geoSearchString={geoSearchString}
                mapOptions={mapOptions} onCenterChanged={onCenterChanged}/>
    </APIProvider>
}

const MapVis = ({mapOptions, elements, children, onCenterChanged, geoSearchString, geoCallback}) => {
    const map = useMap();
    const centerChanged = React.useCallback(function callback(event) {
        // console.log("centerChanged", event, event.detail.center)
        if (onCenterChanged) {
            onCenterChanged(event.detail.center, event.detail.zoom)
        }
    }, []);
    React.useEffect(() => {
        if (map && geoSearchString && geoSearchString.length > 0) {
            geoCode()
        }
        if (map && elements) {
            // console.log("UseEffect", map, elements)
            let bounds = new window.google.maps.LatLngBounds();
            let maxBounds = Math.min(30, elements.length)
            for(let i = 0; i < maxBounds; i++) {
                bounds.extend(new window.google.maps.LatLng({lat:  elements[i].coordinate._latitude, lng: elements[i].coordinate._longitude}));
            }
            map.fitBounds(bounds)
        }
    }, [map])
    function geoCode() {
        // console.log("GeoSearch", geoSearchString, map)
        const geocoder =  new window.google.maps.Geocoder();
        geocoder.geocode( { 'address': geoSearchString}, function(results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                let rLat = results[0].geometry.location.lat();
                let rLong = results[0].geometry.location.lng()
                // console.log("geocoder-Result", results[0], rLat, rLong)
                geoCallback(rLat, rLong);
                let bounds = new window.google.maps.LatLngBounds();
                bounds.extend(new window.google.maps.LatLng({lat:  rLat, lng: rLong}));
                map.fitBounds(bounds)
                map.setZoom(mapOptions.zoom)
            } else {
                geoCallback(48.249863, 14.635051);
            }
        });
    }
    return (
            <Map mapId={mapOptions.mapID}
                 onCenterChanged={centerChanged}
                 onZoomChanged={centerChanged}
                 style={mapOptions.containerStyle}
                 mapTypeId={mapOptions.mapTypeId}
                 defaultCenter={mapOptions.center}
                 defaultZoom={mapOptions.zoom}>
                {children}
            </Map>
    );
}
BaseMap.propTypes = {
    children:PropTypes.any,
    mapOptions: PropTypes.object,
    onCenterChanged: PropTypes.func,
    geoSearchString: PropTypes.string,
    geoCallback: PropTypes.func
};
module.exports = React.memo(BaseMap);