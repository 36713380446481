const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    PremiumBanner = require("components/widgets/PremiumBanner"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TrainingScatterFilter  = require("components/training/TrainingScatterFilter");

const {BaseContext} = require("context/BaseContext");

const trainingActions = require("actions/TrainingActions"),
    trainingStore = require("stores/TrainingStore"),
    notificationActions = require("components/notification/NotificationActions");

class TabEventScatter extends React.Component {
    constructor(props) {
        super(props);
        this.handleTrainingChange = this.handleTrainingChange.bind(this);
        this.state = {
            trainingStore: trainingStore.getState(),
        }
    }
    componentDidMount() {
        trainingStore.listen(this.handleTrainingChange);
        notificationActions.reset();
        trainingActions.queryTrainingCountTypes();
    }
    componentWillUnmount() {
        trainingStore.unlisten(this.handleTrainingChange);
    }
    handleTrainingChange(state) {
        this.setState({trainingStore: state});
    }
    render() {
        const { user, userInfoState:{ userFeatures, userLoaded} } = this.context;
        const {eventTrainingList, eventTrainingLoading, trainingCountTypes} = this.state.trainingStore
        if (!userLoaded) {
            return (<Row><Col xs={12}><Loading/></Col></Row>)
        } else {
            if (userFeatures != null && userFeatures.isStatisticFeatureActive()) {
                return <TrainingScatterFilter user={user} year={this.props.year} allowEventTargets={false}
                                              trainingCountTypes={trainingCountTypes}
                                              eventTrainingList={eventTrainingList} eventTrainingLoading={eventTrainingLoading}/>
            } else {
                return <Alert bsStyle="warning">
                    <FontAwesome icon="exclamation-circle"/>
                    <I18n code="skillboard.premium.noAbo"/><br/><br/>
                    <PremiumBanner />
                </Alert>
            }
        }
    }
}
TabEventScatter.contextType = BaseContext;
TabEventScatter.propTypes = {
    year: PropTypes.number.isRequired
};
module.exports = TabEventScatter;
