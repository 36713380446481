const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, PageHeader} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    JSONFileImporter = require("components/form/JSONFileImporter"),
    messages = require("i18n/messages"),
    ClubLoader = require("components/club/ClubLoader");

const clubUserActions = require("actions/ClubUserActions");


const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubSettingsPage extends React.Component {
    render() {
        // Club details will be loaded as soon as club and clubUsers will be loaded
        return <ClubLoader clubID={this.props.clubID} clubUserRole={null} loadClubUsers={true} loginMandatory={true}>
            <ClubDetails />
        </ClubLoader>
    }
}
ClubSettingsPage.propTypes = {
    clubID: PropTypes.string.isRequired
};
const ClubDetails = ({club, clubUsers}) => {
    document.title = club.getName();
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                            <Breadcrumb code="modal.button.edit" active/>
                        </Breadcrumbs>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <PageHeader>{club.getName()}</PageHeader>
                </Col>
            </Row>
            <ClubUserImport club={club}/>
            <ClubLicenseImport club={club} clubUsers={clubUsers}/>

        </Grid>)
};
ClubDetails.propTypes = {
    club:PropTypes.object.isRequired
};
const ClubUserImport = ({club}) => {
    const receivedClubUserArray = (objectArray) => {
        clubUserActions.handleImportClubUser(club, objectArray);
    };
    return (<Row>
        <Col xs={12} lg={6}>
            <JSONFileImporter startImportArray={receivedClubUserArray}
                              optJsonKeys={["number", "mail", "mail2", "tel", "mobil", "entrydate", "birthday"]}
                              mandJsonKeys={["name","surname", "street", "zip", "place"]}
                              title={messages.get("club.member.header")}/>
        </Col>
        <Col xs={12} lg={6}>
            <h3>For each entry one row in brace with following fields, full dataset not covered in []</h3>
            <p><a href="https://shancarter.github.io/mr-data-converter/">Convert xls to json file</a></p>
            <p><strong>Mandatory fields</strong></p>
            <p>"surname": "Peterseil",</p>
            <p>"name": "Daniel",</p>
            <p>"street": "Holzweg 3",</p>
            <p>"zip": "3489",</p>
            <p>"place": "Mordor",</p>
            <p><strong>nullable fields</strong></p>
            <p>"number": "1",</p>
            <p>"tel": "664 825 66 99"</p>
            <p>"mobil": "0664 852 356 98"</p>
            <p>"mail": "office@3dturnier.com"</p>
            <p>"mail2": "office@3dturnier.com"</p>
            <p>"birthday": "10.02.2001", </p>
            <p>"entrydate": "10.02.2001", </p>
            <p>"iban": "05778"</p>
            <p>"bic": "77878"</p>
        </Col>
    </Row>)
};
ClubUserImport.propTypes = {
    club:PropTypes.object.isRequired
};
const ClubLicenseImport = ({club, clubUsers}) => {
    const receivedClubUserLicenseArray = (objectArray) => {
        clubUserActions.handleImportClubUserLicense(club, clubUsers, objectArray);
    };
    return (<Row>
        <Col xs={12} lg={6}>
            <JSONFileImporter startImportArray={receivedClubUserLicenseArray}
                              optJsonKeys={["mail", "birthday", "articleId", "bowUnionId", "licClub", "licNumber", "ageCode", "bowCode", ]}
                              mandJsonKeys={["name","surname"]}
                              title="New member with club license import"/>
        </Col>
        <Col xs={12} lg={6}>
            <h3>For each entry one row in brace with following fields, full dataset not covered in []</h3>
            <p><a href="https://shancarter.github.io/mr-data-converter/">Convert xls to json file</a></p>
            <p><strong>Mandatory fields</strong></p>
            <p>"surname": "Peterseil",</p>
            <p>"name": "Daniel",</p>
            <p><strong>nullable fields</strong></p>
            <p>"mail": "office@3dturnier.com"</p>
            <p>"birthday": "10.02.2001", </p>
            <p>"bowUnionId": "ok8ATbPjUq", </p>
            <p>"licNumber": "1",</p>
            <p>"licClub": "BSV",</p>
            <p>"ageCode": "Erw",</p>
            <p>"bowCode": "BB",</p>
            <p>"articleId": "zHj8MxkZaB",</p>
        </Col>
    </Row>)
};
ClubLicenseImport.propTypes = {
    club:PropTypes.object.isRequired
};
module.exports = ClubSettingsPage;
