const alt = require("AltInit"),
    userActions = require("actions/UserActions");
const {User} = require("parse/_Domain");

const UserFeatures = require("beans/UserFeatures");
const ParseListUtility = require("util/ParseListUtility");

class UserStore {
    constructor() {
        this.displayName = "UserStore";
        this.user = null;
        this.isLoginModalVisible = false;
        this.loginKind = "login";
        this.messageCode = null;
        this.userDetail = null;
        this.userAbos = null;
        this.userDetailLoading = true;
        this.userAboLoading = true;
        this.userFavorite = null;
        this.isAdmin = null;
        this.userFeatures = new UserFeatures();
        this.bindListeners({
            updateUser: userActions.updateUser,
            showLoginModal: userActions.showLoginModal,
            showLoginCreateModal: userActions.showLoginCreateModal,
            hideLoginModal: userActions.hideLoginModal,
            showLoginError: userActions.showLoginError,
            removeLoginError: userActions.removeLoginError,
            updateUserFavorite: userActions.updateUserFavorite,
            updateUserDetail: userActions.updateUserDetail,
            updateUserAbos: userActions.updateUserAbos,
            updateUserAbo: userActions.updateUserAbo,
            onGetIsAdmin: userActions.getIsAdmin,
            onGetIsAdminSuccess: userActions.getIsAdminSuccess
        });
    }

    updateUserDetail(userDetail) {
        this.userDetailLoading = false;
        this.userDetail = userDetail;
    }

    updateUserAbos(pUserAbos) {
        this.userAboLoading = false;
        this.userAbos = pUserAbos;
        this.setUserFeatures(pUserAbos);
    }

    updateUserAbo(userAbo) {
        ParseListUtility.updateOrPush(this.userAbos, userAbo);
        this.setUserFeatures(this.userAbos);
    }

    setUserFeatures(pUserAbos) {
        let nFeat = new UserFeatures();
        nFeat.setUserFeatures(pUserAbos);
        this.userFeatures = nFeat;
    }

    showLoginModal() {
        this.loginKind = "login";
        this.isLoginModalVisible = true;
    }

    showLoginCreateModal() {
        this.loginKind = "register";
        this.isLoginModalVisible = true;
    };

    hideLoginModal() {
        this.messageCode = null;
        this.isLoginModalVisible = false;
    }

    updateUser(user) {
        this.isLoginModalVisible = false;
        this.messageCode = null;
        this.user = user;
    }

    updateUserFavorite(pUserFavorite){
        this.userFavorite = pUserFavorite;
    }

    showLoginError(messageCode) {
        this.messageCode = messageCode;
    }

    removeLoginError() {
        this.messageCode = null;
    }

    onGetIsAdmin() {
        this.isAdmin = null;
    }
    onGetIsAdminSuccess(isAdmin) {
        this.isAdmin = isAdmin;
    }
}
module.exports = alt.createStore(UserStore);
