const React = require("react"),
    PropTypes = require('prop-types');
const {Label} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    SubmitButton = require("components/form/SubmitButton"),
    messages = require("i18n/messages");

const {BaseContext} = require("context/BaseContext");

const clubActions = require("actions/ClubActions"),
    clubInvoiceActions = require("actions/ClubInvoiceActions");

function ClubUserPaymentInfo({club, clubUser, currentYear}) {
    const { user } = React.useContext(BaseContext);
    function payArticle() {
        // update payed year in clubUser
        clubUser.setArticlePayedYear(currentYear)
        clubActions.saveClubUser(clubUser);
        // add clubInvoice
        clubInvoiceActions.setClubUserPaid(user, club, clubUser, currentYear);

    }
    if (clubUser.getArticlePayAmountNumber() > 0 || clubUser.getArticlePayedYear() != null) {
        let currentYearPaid = clubUser.getArticlePayedYear() != null && currentYear === clubUser.getArticlePayedYear();
        return <React.Fragment>
            <Label bsStyle={currentYearPaid ? "success" : "warning"}>
                <FontAwesome icon="calendar-alt">{clubUser.getArticlePayedYear()} -> </FontAwesome>
                <FontAwesome icon="euro">{clubUser.getArticlePayAmountNumber()}</FontAwesome>
            </Label><br/>
            {
                !currentYearPaid && clubUser.getArticlePayAmountNumber() > 0 ?<SubmitButton onClick={payArticle} confirm={true}
                                                icon={<FontAwesome icon="euro"/>}
                                                text={clubUser.getArticlePayAmountNumber() + " " + messages.get("tournament.attendee.paid") }
                                                bsStyle="primary"/> : null

            }
        </React.Fragment>
    }
    return null;
}
ClubUserPaymentInfo.propTypes = {
    club: PropTypes.object.isRequired,
    currentYear: PropTypes.number.isRequired
};
module.exports = ClubUserPaymentInfo;
