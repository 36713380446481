const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    RowNoUserCheck = require("components/widgets/RowNoUserCheck"),
    TrainingUser = require("components/training/TrainingUser");

const {BaseContext} = require("context/BaseContext");

const calenderStore = require("stores/CalendarStore"),
    trainingActions = require("actions/TrainingActions"),
    trainingStore = require("stores/TrainingStore"),
    notificationActions = require("components/notification/NotificationActions");

class TrainingRemotePage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTrainingChange = this.handleTrainingChange.bind(this);
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.state = {
            trainingStore: trainingStore.getState(),
            calenderStore: calenderStore.getState(),
        }
    }
    componentDidMount() {
        const { user } = this.context;
        document.title = messages.get("header.nav.training");
        trainingStore.listen(this.handleTrainingChange);
        calenderStore.listen(this.handleCalendarChange);
        notificationActions.reset();
        trainingActions.queryTrainingCountTypes();
        trainingActions.queryRemoteTraining(user, this.props.memberID);
    }
    componentWillUnmount() {
        trainingStore.unlisten(this.handleTrainingChange);
        calenderStore.unlisten(this.handleCalendarChange);
    }
    handleTrainingChange(state) {
        this.setState({trainingStore: state});
    }
    handleCalendarChange(state) {
        this.setState({calenderStore: state});
    }
    render() {
        const { userInfoState:{ userFeatures, userLoaded} } = this.context;
        return (
            <Grid>
                <RowNoUserCheck>
                    <React.Fragment>
                        {
                            this.state.trainingStore.trainingTeams == null || !userLoaded ?
                                <Loading /> : <React.Fragment>
                                    {
                                        this.state.trainingStore.remoteMember == null  ?
                                            <Alert bsStyle="warning">
                                                <FontAwesome icon="exclamation-circle"/>
                                                <I18n code="profile.training.team.noMember"/>
                                            </Alert>
                                            :
                                            <TrainingUser
                                                user={this.state.trainingStore.remoteMember.getUserID()}
                                                userFeatures={userFeatures}
                                                userDetail={null}
                                                remoteMember={this.state.trainingStore.remoteMember}
                                                trainingTypes={this.state.trainingStore.trainingTypes}
                                                trainingCountTypes={this.state.trainingStore.trainingCountTypes}
                                                eventTrainingList={this.state.trainingStore.eventTrainingList}
                                                calendarBoundaries={this.state.calenderStore.calendarBoundaries}
                                                selectedCalEvent={this.state.calenderStore.selectedCalEvent}
                                            />
                                    }
                                </React.Fragment>
                        }
                    </React.Fragment>

                </RowNoUserCheck>
            </Grid>

        )
    }
}
TrainingRemotePage.contextType = BaseContext;
module.exports = TrainingRemotePage;
