const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Panel, ButtonGroup, Button,Table} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    I18n = require("components/widgets/I18n"),
    SubmitButton = require("components/form/SubmitButton"),
    DateInput = require("components/form/DateInput"),
    CheckboxArray = require("components/form/CheckboxArray"),
    ParcoursSelection = require("components/form/ParcoursSelection"),
    CheckboxInput = require("components/form/CheckboxInput"),
    ValidInput = require("components/form/ValidInput"),
    messages = require("i18n/messages");

const SolutionHelpLink = require("components/links/SolutionHelpLink");

const TournamentUnionSelection = require("components/tournamentmanage/TournamentUnionSelection"),
    TournamentMMConfigAdvanced = require("components/tournamentmanage/TournamentMMConfigAdvanced"),
    TournamentConfigAgePriceWarning = require("components/tournamentmanage/TournamentConfigAgePriceWarning"),
    TournamentMMConfigSlots = require("components/tournamentmanage/TournamentMMConfigSlots"),
    TournamentConfigExtraModal = require("components/tournamentmodal/TournamentConfigExtraModal"),
    TournamentConfigModal  = require("components/tournamentmodal/TournamentConfigModal"),
    TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader");
const PLUtil = require("util/ParseListUtility");
const {Tournament, TournamentPrice, TournamentCupOption} = require("parse/_Domain");

const tournamentActions = require("actions/TournamentManagerActions"),
    notificationActions = require("components/notification/NotificationActions");

const TournamentMMConfigPage  = ({tournamentId}) => {
    return <TournamentMMLoader tournamentId={tournamentId}>
        <TournamentConfigHierarchy />
    </TournamentMMLoader>
};
TournamentMMConfigPage.propTypes = {
    tournamentId: PropTypes.string.isRequired
};
class TournamentConfigHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.startRegistration = this.startRegistration.bind(this);
        this.acceptCupMaster = this.acceptCupMaster.bind(this);
        this.disableAcceptCupMaster = this.disableAcceptCupMaster.bind(this);
        this.disableStartRegistration = this.disableStartRegistration.bind(this);
        this.state = {
        }
    }
    startRegistration() {
        // start Registration action
        tournamentActions.updateTournamentRegStatus(this.props.tournament, this.props.user, Tournament.prototype.registrationStatus.open);
    }
    acceptCupMaster() {
        // accept Master config
        tournamentActions.updateTournamentRegStatus(this.props.tournament, this.props.user, Tournament.prototype.registrationStatus.activatedCupMaster);
    }
    disableAcceptCupMaster() {
        // check if Master config is Ready
        return this.props.tournament.getTournamentConfigAge() == null || this.props.tournament.getTournamentConfigBow() == null;
    }
    disableStartRegistration() {
        return this.props.tournament.getTournamentConfigAge() == null
            || this.props.tournament.getTournamentConfigBow() == null
            || (this.props.tournament.getTournamentPriceID() == null
                || this.props.tournament.getTournamentPriceID().isInValid())
            || this.props.tournament.getParcoursID() == null
            || !(this.props.tournament.getRegMaxUser() > 0);
    }
    render() {
        const {tournament, user, tournamentSlots} = this.props;
        const showTOptions = tournament.isTypeSingle() || tournament.isTypeCupChild();
        const isTypeCupMaster = tournament.isTypeCupMaster();
        const showTConfig = isTypeCupMaster || tournament.isTypeSingle();
        return (<React.Fragment>
            <Row>
                <Col lg={12}>
                    <h1>{tournament.getName() +", " + tournament.getTournamentDate()}</h1>
                    <h3>{messages.get("tournament.manager.config.title")}</h3>
                    <SolutionHelpLink articleID="42000051443" />
                </Col>
            </Row>
            {
                !tournament.hasInitialRegistration() ?
                    <Row>
                        <Col sm={4} smOffset={4}>
                            {
                                isTypeCupMaster ?
                                    <SubmitButton block bsSize="large"
                                                  onClick={this.acceptCupMaster} disabled={this.disableAcceptCupMaster()}
                                                  icon={<FontAwesome icon="check"/>}
                                                  text={messages.get("bowUnion.type.CM") + " " + messages.get("modal.button.activate")}
                                                  bsStyle="primary"/>
                                    :
                                    <SubmitButton block bsSize="large"
                                                  onClick={this.startRegistration} disabled={this.disableStartRegistration()}
                                                  icon={<FontAwesome icon="check"/>}
                                                  text={messages.get("tournament.manager.config.startReg")}
                                                  bsStyle="primary"/>
                            }
                        </Col>
                    </Row>
                    :
                    <Alert bsStyle="info"><I18n code="tournament.manager.config.doNotEdit"/></Alert>
            }
            <hr/>
            {
                tournament.getBowUnionID() ? null :
                    <Row>
                        <TournamentUnionSelection tournament={tournament}/>
                    </Row>
            }
            {
                showTOptions ?
                    <Row>
                        <TournamentConfigMain tournament={tournament}/>
                        <TournamentConfigPrice tournament={tournament}/>
                        <TournamentConfigParcours tournament={tournament}/>
                    </Row> : null
            }
            {
                isTypeCupMaster ?
                    <Row>
                        <TournamentConfigCupOptions tournament={tournament}/>
                    </Row> : null
            }
            <Row>
                <TournamentClassConfig tournament={tournament} allowEdit={showTConfig} isBowEntry={true}
                                       header={<FontAwesome icon="bullseye"><I18n code="tournament.class.bow"/></FontAwesome>}/>

                <TournamentClassConfig tournament={tournament} allowEdit={showTConfig} isBowEntry={false}
                                       header={<FontAwesome icon="book"><I18n code="tournament.class.age"/></FontAwesome>}/>

            </Row>
            <hr/>
            {
                showTOptions ? <React.Fragment>
                    <Row>
                        <TournamentMMConfigAdvanced tournament={tournament} />
                    </Row>
                    <Row>
                        <TournamentMMConfigSlots tournament={tournament} tournamentSlots={tournamentSlots}/>
                    </Row>
                    </React.Fragment>: null
            }
        </React.Fragment>)}
}
TournamentConfigHierarchy.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentSlots: PropTypes.array.isRequired
};
class TournamentConfigMain extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refRegMaxUser = React.createRef();
        this.refDesc = React.createRef();
        this.refRegStartDate = React.createRef();

        this.setChanged = this.setChanged.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            hasChanged: false
        }
    }
    setChanged() {
        this.setState({hasChanged: true});
    }
    valid() {
        let valid = true;
        let required = [
            this.refRegMaxUser.current.getValue(),
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            this.refSubmitButton.current.resetLoading();
            return;
        }
        // save changes
        let tournament = this.props.tournament;
        tournament.setDescription(this.refDesc.current.getValue());
        tournament.setRegMaxUser(parseInt(this.refRegMaxUser.current.getValue()));

        let regStartDate = this.refRegStartDate.current.getValue();
        if (regStartDate != null) {
            const regStartDateParts = new Date(regStartDate);
            const regStart =  new Date(regStartDateParts.getFullYear(), regStartDateParts.getMonth(), regStartDateParts.getDate(), 23, 59, 0, 0);
            tournament.setRegStartDate(regStart);
        } else {
            tournament.unset(Tournament.prototype.col.regStartDate);
        }
        tournamentActions.saveTournamentCB(tournament, ()=> {
            this.refSubmitButton.current.resetLoading();
            this.setState({error: null, hasChanged: false});
        });
    }
    render() {
        const {tournament} = this.props;
        const validateNow = this.state.error && this.state.error.length > 0;
        return (<Col sm={4}>
            <Panel bsStyle={tournament.getRegMaxUser() > 0 ? "default" : "warning"}>
                <Panel.Heading>
                    <FontAwesome icon="exclamation-triangle"><I18n code="tournament.info.title"/></FontAwesome>
                </Panel.Heading>
                <Panel.Body>
                    {this.state.error ?
                        <Alert bsStyle="warning">{this.state.error}</Alert>
                        : ""}
                    <Row>
                        <Col xs={6}>
                            <ValidInput ref={this.refRegMaxUser} type="number"
                                        valid={{maxLength: 3, check:['isRequired', 'isNumber', 'posNumber']}}
                                        validateNow={validateNow}
                                        default={tournament.getRegMaxUser()}
                                        onAfterChange={this.setChanged}
                                        label={messages.get("tournament.manager.config.maxAttendees")}/>
                        </Col>
                        <Col xs={12}>
                            <DateInput ref={this.refRegStartDate} default={tournament.getRegStartDate()}
                                       onAfterChange={this.setChanged}
                                       label={messages.get("tournament.info.regStart")}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ValidInput ref={this.refDesc}
                                        label={messages.get("tournament.manager.config.desc.label")}
                                        placeholder={messages.get("tournament.manager.config.desc.placeholder")}
                                        componentClass="textarea"
                                        onAfterChange={this.setChanged}
                                        default={tournament.getDescription()}
                                        valid={{maxLength: 1500}}/>
                        </Col>
                    </Row>
                </Panel.Body>
                <Panel.Footer>
                    <Row><Col xs={12} className="text-center">
                        <SubmitButton ref={this.refSubmitButton} block
                                      onClick={this.handleSubmit} disabled={!this.state.hasChanged}
                                      icon={<FontAwesome icon="save"/>}
                                      text={messages.get("modal.button.save")}
                                      bsStyle="primary"/>
                    </Col></Row>
                </Panel.Footer>
            </Panel>
        </Col>)
    }
}
TournamentConfigMain.propTypes = {
    tournament:PropTypes.object.isRequired
};
class TournamentConfigPrice extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refPayCondition = React.createRef();
        this.refPayMail = React.createRef();
        this.refTPrice = React.createRef();
        this.refTSurprice = React.createRef();
        this.refCurrency = React.createRef();
        this.refPay4Start = React.createRef();
        this.refRegMailCC = React.createRef();

        this.setChanged = this.setChanged.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setPriceHandlingChanged = this.setPriceHandlingChanged.bind(this);

        let tPrice = this.props.tournament.getTournamentPriceID();
        this.state = {
            hasChanged: false,
            complexPriceHandling: tPrice != null && (tPrice.getPrice() > 0 || tPrice.getSurprice() > 0)
        }
    }
    setChanged() {
        this.setState({hasChanged: true});
    }
    setPriceHandlingChanged(value) {
        this.setState({complexPriceHandling: value});
    }
    valid() {
        let valid = true;
        let required = [
            this.refPayCondition.current.getValue(),
            this.refPayMail.current.getValue(),
        ];
        if (this.state.complexPriceHandling) {
            required.push(this.refTPrice.current.getValue());
            required.push(this.refCurrency.current.getValue());
        }
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            this.refSubmitButton.current.resetLoading();
            return;
        }
        // save changes
        let tPrice = this.props.tournament.getTournamentPriceID();
        if (tPrice == null) {
            tPrice = new TournamentPrice();
            tPrice.setStatus("A");
        }
        tPrice.setPaymentCondition(this.refPayCondition.current.getValue());
        tPrice.setEmail(this.refPayMail.current.getValue());
        if (this.state.complexPriceHandling) {
            tPrice.setPrice(parseFloat(this.refTPrice.current.getValue()));
            tPrice.setSurprice(parseFloat(this.refTSurprice.current.getValue()));
            tPrice.setCurrency(this.refCurrency.current.getValue());
        } else {
            tPrice.setPrice(0);
            tPrice.setSurprice(0);
            tPrice.setCurrency("EUR");
        }
        tPrice.setPay4Start(this.refPay4Start.current.getValue() == true);
        tPrice.setRegistrationMailCC(this.refRegMailCC.current.getValue() == true);
        tournamentActions.saveTournamentSubObject(this.props.tournament, tPrice, Tournament.prototype.col.tournamentPriceID ,()=> {
            this.refSubmitButton.current.resetLoading();
            this.setState({error: null, hasChanged: false});
        });
    }
    render() {
        const {tournament} = this.props;
        const { hasChanged, complexPriceHandling} = this.state;
        const validateNow = this.state.error && this.state.error.length > 0;
        const tPrice = tournament.getTournamentPriceID();
        return (<Col sm={4}>
            <Panel bsStyle={tPrice && !tPrice.isInValid() ? "default" : "warning"}>
                <Panel.Heading>
                    <FontAwesome icon="euro-sign"><I18n code="tournament.register.label.payCondition"/></FontAwesome>
                </Panel.Heading>
                <Panel.Body>
                    {this.state.error ?
                        <Alert bsStyle="warning">{this.state.error}</Alert>
                        : ""}
                    <Row>
                        <Col xs={12}>
                            <ValidInput ref={this.refPayCondition}
                                        label={messages.get("tournament.register.label.payCondition")}
                                        placeholder={messages.get("tournament.manager.tConfig.payment.desccondition")}
                                        componentClass="textarea"
                                        validateNow={validateNow}
                                        onAfterChange={this.setChanged}
                                        default={tPrice ? tPrice.getPaymentCondition() : ""}
                                        valid={{maxLength: 1500, check: ['isRequired']}}/>
                        </Col>
                        <Col xs={12}>
                            <ValidInput ref={this.refPayMail}
                                        label={messages.get("tournament.manager.tConfig.payment.paymail")}
                                        valid={{maxLength: 50, check: ['isRequired', 'isMail']}}
                                        validateNow={validateNow}
                                        onAfterChange={this.setChanged}
                                        placeholder={messages.get("address.mail")}
                                        addonBefore={<FontAwesome icon="envelope"/>}
                                        default={tPrice ? tPrice.getEmail() : null}/>
                        </Col>
                        <Col xs={12}>
                            <CheckboxInput ref={this.refRegMailCC}
                                           default={tPrice ? tPrice.getRegistrationMailCC() : true}
                                           onAfterChange={this.setChanged}
                                           message={messages.get("tournament.manager.tConfig.payment.regMailCC")} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <CheckboxInput ref={this.refPay4Start}
                                           default={tPrice ? tPrice.getPay4Start() : null}
                                           onAfterChange={this.setChanged}
                                           message={messages.get("tournament.manager.tConfig.payment.pay4start")} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <CheckboxInput default={complexPriceHandling}
                                           onAfterChange={this.setPriceHandlingChanged}
                                           message={messages.get("tournament.manager.tConfig.complexPriceHandling")} />
                        </Col>
                        {
                            complexPriceHandling ? <React.Fragment>
                                <Col sm={6}>
                                    <ValidInput ref={this.refTPrice} type="number"
                                                valid={{maxLength: 5, check:['isNumber', 'point4comma', 'posNumber']}}
                                                validateNow={validateNow}
                                                default={tPrice ? tPrice.getPrice() : "0"}
                                                onAfterChange={this.setChanged}
                                                label={messages.get("tournament.manager.tConfig.payment.price")}/>
                                </Col>
                                <Col sm={6}>
                                    <ValidInput ref={this.refTSurprice} type="number"
                                                valid={{maxLength: 5, check:['isNumber', 'point4comma', 'posNumber']}}
                                                validateNow={validateNow}
                                                default={tPrice ? tPrice.getSurprice() : "0"}
                                                onAfterChange={this.setChanged}
                                                label={messages.get("tournament.manager.tConfig.payment.surprice")}/>
                                </Col>
                                <Col sm={6}>
                                    <ValidInput ref={this.refCurrency} type="string"
                                                valid={{maxLength: 4, check:['isRequired']}}
                                                validateNow={validateNow}
                                                default={tPrice ? tPrice.getCurrency() : "EUR"}
                                                onAfterChange={this.setChanged}
                                                label={messages.get("parcours.articles.form.currency")}/>
                                </Col>
                                <Col sm={12}>
                                    <p>{messages.get("tournament.manager.tConfig.complexPriceHandling.desc")}</p>
                                </Col>
                            </React.Fragment> : null
                        }
                    </Row>

                </Panel.Body>
                <Panel.Footer>
                    <Row><Col xs={12} className="text-center">
                        <SubmitButton ref={this.refSubmitButton} block
                                      onClick={this.handleSubmit} disabled={!hasChanged}
                                      icon={<FontAwesome icon="save"/>}
                                      text={messages.get("modal.button.save")}
                                      bsStyle="primary"/>
                    </Col></Row>
                </Panel.Footer>
            </Panel>
        </Col>)
    }
}
TournamentConfigPrice.propTypes = {
    tournament:PropTypes.object.isRequired
};
class TournamentConfigParcours extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refManPoints = React.createRef();
        this.refNoTUserGrp = React.createRef();
        this.refChooseTUserGrp = React.createRef();

        this.setChanged = this.setChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);

        this.state = {
            selParcours: this.props.tournament.getParcoursID() ? this.props.tournament.getParcoursID() : null ,
        }
    }
    setChanged()  {
        this.setState({hasChanged: true});
    }
    handleSubmit() {
        let tournament = this.props.tournament;
        if (this.state.selParcours) {
            tournament.setParcoursID(this.state.selParcours);
        } else {
            notificationActions.warning("Bitte Parcours auswählen");
            this.refSubmitButton.current.resetLoading();
            return;
        }
        // save changes for checkboxes
        tournament.setAllowManualPoints(this.refManPoints.current.getValue() == true);

        if (this.refChooseTUserGrp.current.getValue() == true) {
            tournament.setChooseTUserGroup(true);
            tournament.setNoTUserGroups(false);
        } else {
            tournament.setChooseTUserGroup(false);
            tournament.setNoTUserGroups(this.refNoTUserGrp.current.getValue() == true);
        }

        tournamentActions.saveTournamentCB(tournament, () => {
            this.refSubmitButton.current.resetLoading();
            this.setState({hasChanged: false});
        });

    }
    handleParcoursChange(parcours) {
        if (parcours) {
            console.log("SEL PAGE " + parcours.getName());
            this.setState({selParcours: parcours, hasChanged: true});
        }
    }
    render() {
        const {tournament} = this.props;
        return (<Col sm={4}>
            <Panel bsStyle={tournament.getParcoursID() ? "default" : "warning"}>
                <Panel.Heading>
                    <FontAwesome icon="map-marker-alt"><I18n code="tournament.manager.config.parcours"/></FontAwesome>
                </Panel.Heading>
                <Panel.Body>
                    <Row>
                        <Col xs={12}>
                            <ParcoursSelection parcours={this.state.selParcours}
                                               desc={<I18n code="tournament.manager.tConfig.parcoursSelection"/>}
                                               onAfterChange={this.handleParcoursChange}
                                               mainGroupPlace={tournament.getMainGroupPlaceID()}
                                               regionGroupPlace={tournament.getGroupPlaceID()}/>
                        </Col>
                        <Col xs={12}>
                            <CheckboxInput ref={this.refManPoints}
                                           default={tournament.getAllowManualPoints()}
                                           onAfterChange={this.setChanged}
                                           message={messages.get("tournament.manager.tConfig.parcours.manPoints")} />
                            <CheckboxInput ref={this.refNoTUserGrp} disabled={tournament.getChooseTUserGroup()}
                                           default={tournament.isNoTUserGroups()}
                                           onAfterChange={this.setChanged}
                                           message={messages.get("tournament.manager.tConfig.parcours.manTUGroups")} />

                            <CheckboxInput ref={this.refChooseTUserGrp}
                                           default={tournament.getChooseTUserGroup()}
                                           onAfterChange={this.setChanged}
                                           message={messages.get("tournament.manager.tConfig.parcours.chooseTUGroups")} />
                        </Col>
                    </Row>
                </Panel.Body>
                <Panel.Footer>
                    <Row><Col xs={12} className="text-center">
                        <SubmitButton ref={this.refSubmitButton} block
                                      onClick={this.handleSubmit} disabled={!this.state.hasChanged || this.state.selParcours == null}
                                      icon={<FontAwesome icon="save"/>}
                                      text={messages.get("modal.button.save")}
                                      bsStyle="primary"/>
                    </Col></Row>
                </Panel.Footer>
            </Panel>
        </Col>)
    }
}
TournamentConfigParcours.propTypes = {
    tournament:PropTypes.object.isRequired
};
class TournamentConfigCupOptions extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refMinCupResults = React.createRef();
        this.refBaseTargets = React.createRef();
        this.refCupPoints = React.createRef();
        this.refTournamentConfigModal = React.createRef();

        this.setCombineAge = this.setCombineAge.bind(this);
        this.setAttendFinal = this.setAttendFinal.bind(this);
        this.handleAgeTypeChange = this.handleAgeTypeChange.bind(this);
        this.setChanged = this.setChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNewClicked = this.handleNewClicked.bind(this);
        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.addTConfigCombine = this.addTConfigCombine.bind(this);
        const cupOption = this.props.tournament.getTournamentCupOptionID();
        let ageTypes = this.generateTypeDict(this.props.tournament.getTournamentConfigAge(), cupOption.getCombineTConfigAgeIDs());
        this.state = {
            hasChanged: false,
            attendFinal: cupOption.hasAttendFinal(),
            combineAge: cupOption.hasCombineAge(),
            combineConfigAge: cupOption.getTConfigCombinedAgeID(),
            selAgeTypes: ageTypes,
        }
    }
    setCombineAge(bCombine) {
        this.setState({
            combineAge: bCombine,
            hasChanged: true});
    }
    setAttendFinal(attend) {
        this.setState({
            attendFinal: attend,
            hasChanged: true});
    }
    handleAgeTypeChange(checked, elementKey) {
        let dic = this.state.selAgeTypes;
        dic[elementKey].selected = checked;
        this.setState({selAgeTypes: dic, hasChanged: true});
    }
    handleNewClicked() {
        this.refTournamentConfigModal.current.open();
    }
    addTConfigCombine(tConfig) {
        this.setState({hasChanged: true, combineConfigAge: tConfig});
    }
    handleEditClicked() {
        this.refTournamentConfigModal.current.edit(this.state.combineConfigAge);
    }
    generateTypeDict(typeArray, selTypeArray) {
        const typeDict = {};
        if (typeArray != null) {
            typeArray.map(type => {
                typeDict[type.id] = {
                    selected: selTypeArray != null ? PLUtil.contains(selTypeArray, type) : false,
                    name: type.getSelectString()
                }
            });
        }
        return typeDict;
    }
    setChanged() {
        this.setState({hasChanged: true});
    }
    handleSubmit() {
        let tCupOption = this.props.tournament.getTournamentCupOptionID();
        if (this.refMinCupResults.current.getValue()) {
            tCupOption.setMinCupResults(parseInt(this.refMinCupResults.current.getValue()));
        }
        if (this.refCupPoints.current.getValue()) {
            let cupPointsArr = this.refCupPoints.current.getValue().split(",");
            if (cupPointsArr.length > 1) {
                tCupOption.setCupPoints(cupPointsArr);
            } else {
                tCupOption.unset(TournamentCupOption.prototype.col.cupPoints)
            }

        }
        if (this.refBaseTargets.current.getValue()) {
            let value = parseInt(this.refBaseTargets.current.getValue())
            if (value > 0) {
                tCupOption.setBaseTargets(value);
            } else {
                tCupOption.unset(TournamentCupOption.prototype.col.baseTargets)
            }
        }
        tCupOption.setAttendFinal(this.state.attendFinal);
        if (this.state.combineAge && this.state.selAgeTypes) {
            tCupOption.setCombineTConfigAgeIDs(this.props.tournament.getTournamentConfigAge(), this.state.selAgeTypes)
        } else {
            tCupOption.unset(TournamentCupOption.prototype.col.combineTConfigAgeIDs)
        }
        if (this.state.combineConfigAge) {
            tCupOption.setTConfigCombinedAgeID(this.state.combineConfigAge);
        } else {
            tCupOption.unset(TournamentCupOption.prototype.col.tConfigCombinedAgeID)
        }
        tournamentActions.saveTournamentSubObject(this.props.tournament, tCupOption, Tournament.prototype.col.tournamentCupOptionID, () => {
            this.refSubmitButton.current.resetLoading();
            this.setState({hasChanged: false});
        });
    }
    render() {
        const {combineAge, combineConfigAge} = this.state;
        const {tournament} = this.props;
        const cupOption = tournament.getTournamentCupOptionID();
        return (<Col sm={12}>
            <Panel>
                <Panel.Heading>
                    <FontAwesome icon="book"><I18n code="tournament.manager.config.cupOptions"/></FontAwesome>
                </Panel.Heading>
                <Panel.Body>
                    <Row>
                        <Col sm={4}>
                            <ValidInput ref={this.refMinCupResults} type="number"
                                        valid={{maxLength: 3, check:['isRequired', 'isNumber', 'posNumber']}}
                                        default={cupOption.getMinCupResults()}
                                        onAfterChange={this.setChanged}
                                        label={messages.get("tournament.manager.config.minCupResults")}/>
                        </Col>
                        <Col sm={4}>
                            <ValidInput ref={this.refBaseTargets} type="number"
                                        valid={{maxLength: 3, check:['isRequired', 'isNumber', 'posNumber']}}
                                        disabled={cupOption.getCupPoints() != null}
                                        default={cupOption.getBaseTargets()}
                                        onAfterChange={this.setChanged}
                                        label={messages.get("tournament.manager.config.baseTargets")}/>
                        </Col>
                        <Col xs={12}>
                            <ValidInput ref={this.refCupPoints}
                                        label={messages.get("tournament.manager.config.cupPoints")}
                                        componentClass="textarea"
                                        disabled={cupOption.getBaseTargets() != null}
                                        onAfterChange={this.setChanged}
                                        default={cupOption.getCupPointsString()}
                                        valid={{maxLength: 1500, check:['isRequired']}}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col xs={12}>
                            {
                                cupOption.getCupGroups() != null ? <CupGroupSetting cupOption={cupOption}/> : null
                            }
                        </Col>
                        <Col xs={12}>
                            <CheckboxInput default={cupOption.hasAttendFinal()}
                                           onAfterChange={this.setAttendFinal}
                                           message={messages.get("tournament.manager.config.attendFinale")} />
                            <CheckboxInput default={cupOption.hasCombineAge()}
                                           onAfterChange={this.setCombineAge}
                                           message={messages.get("tournament.manager.config.combineAge")} />
                        </Col>
                        {
                            combineAge ? <React.Fragment>
                                <Col xs={6}>
                                    <CheckboxArray cbEntryDic={this.state.selAgeTypes} onAfterChange={this.handleAgeTypeChange}/>
                                </Col>
                                <Col xs={6}>
                                    <Button disabled={combineConfigAge != null} onClick={this.handleNewClicked}>
                                        <FontAwesome icon="plus"/><I18n code="tournament.manager.config.combineAge.tConfig"/>
                                    </Button>
                                    <TournamentConfigModal ref={this.refTournamentConfigModal} isBowEntry={false} showPrice={false} tournament={tournament} configCallback={this.addTConfigCombine}/>
                                    <Table responsive striped hover>
                                        <thead>
                                        <tr>
                                            <th><I18n code="tournament.table.column.name"/></th>
                                            <th><I18n code="parcours.details.button.actions"/></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            combineConfigAge ? <tr>
                                                    <td>{combineConfigAge.getCodeName()}</td>
                                                    <td><Button onClick={this.handleEditClicked}><FontAwesome icon="pencil-alt"/></Button></td>
                                                </tr>: null
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            </React.Fragment> : null

                        }

                    </Row>
                </Panel.Body>
                <Panel.Footer>
                    <Row><Col sm={4} smOffset={8} className="text-center">
                        <SubmitButton ref={this.refSubmitButton} block
                                      onClick={this.handleSubmit} disabled={!this.state.hasChanged}
                                      icon={<FontAwesome icon="save"/>}
                                      text={messages.get("modal.button.save")}
                                      bsStyle="primary"/>
                    </Col></Row>
                </Panel.Footer>
            </Panel>
        </Col>)
    }
}
TournamentConfigCupOptions.propTypes = {
    tournament:PropTypes.object.isRequired
};
class TournamentClassConfig extends React.Component {
    constructor(props) {
        super(props);
        this.refTournamentConfigModal = React.createRef();

        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.handleNewClicked = this.handleNewClicked.bind(this);

        this.state = {}
    }
    handleEditClicked(editConfig) {
        this.refTournamentConfigModal.current.edit(editConfig);
    }
    handleNewClicked() {
        this.refTournamentConfigModal.current.open();
    }
    render() {
        const {header, tournament, allowEdit, isBowEntry} = this.props;
        const tConfigList = isBowEntry ? tournament.getTournamentConfigBow() : tournament.getTournamentConfigAge();
        return (<Col sm={6}>
            <Panel bsStyle={tConfigList ? "default" : "warning"}>
                <Panel.Heading>{header}</Panel.Heading>
                <Panel.Body>
                    <Button disabled={!allowEdit} onClick={this.handleNewClicked}>
                        <FontAwesome icon="plus"/><I18n code="modal.button.newEntry"/>
                    </Button>
                    <TournamentConfigModal ref={this.refTournamentConfigModal} isBowEntry={isBowEntry} showPrice={!isBowEntry} tournament={tournament}/>
                    <Table responsive striped hover>
                        <thead>
                        <tr>
                            <th><I18n code="tournament.table.column.name"/></th>
                            <th>{isBowEntry ? null : <I18n code="parcours.articles.form.price"/>}</th>
                            <th><I18n code="parcours.details.button.actions"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            tConfigList ?
                                tConfigList.map(tConfig => {
                                    if (!tConfig.isDeleted()) {
                                        return <ClassConfigEntry key={tConfig.id}
                                                                 tournament={tournament}
                                                                 tConfig={tConfig}
                                                                 editEntry={this.handleEditClicked}
                                                                 isBowEntry={isBowEntry}
                                                                 allowEdit={allowEdit}/>
                                    }
                                }) : null
                        }
                        </tbody>
                    </Table>
                    {
                        !isBowEntry ? <TournamentConfigAgePriceWarning tournament={tournament}/> : null
                    }

                </Panel.Body>
            </Panel>
        </Col>)
    }
}
TournamentClassConfig.propTypes = {
    header: PropTypes.any.isRequired,
    tournament:PropTypes.object.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    isBowEntry: PropTypes.bool.isRequired
};
const ClassConfigEntry = ( {tournament, allowEdit, isBowEntry, tConfig, editEntry}) => {
    const handleEditClicked = () => {
        editEntry(tConfig);
    };
    const handleDeleteClicked = (doDelete) => {
        if (doDelete) {
            tConfig.setStatus("D");
            tournamentActions.saveTournamenConfig(tournament, isBowEntry, tConfig, null)
        }
    };
    let countType = null;
    if (isBowEntry && tConfig.getCountTypeID()) {
        countType = messages.get(tConfig.getCountTypeID().getName());
    }
    const tOption = tournament.getTournamentOptionID();
    return (<tr>
        <td>{tConfig.getCodeName()}<br/>{countType}</td>
        <td>{tConfig.getPrice() ? tConfig.getPrice() : "" }{tConfig.getTOptionCupSurchargeString(tournament)}<br/>
            {tConfig.getTOptionSurchargeString(tournament, tOption)}</td>
        <td>
            <ButtonGroup >
                <Button disabled={!allowEdit} onClick={handleEditClicked}>
                    <FontAwesome icon="pencil-alt"/></Button>

                <DeleteDecisionModal disabled={!allowEdit} messageCode="list.entry.delete"
                                     entryName={tConfig.getCodeName()} handleDelete={handleDeleteClicked}/>
                {
                    tOption != null && !isBowEntry ? <TournamentConfigExtraModal tournament={tournament} tConfig={tConfig} tOption={tOption}/> : null
                }
                {
                    tournament.isTypeCupChild() && !isBowEntry ? <TournamentConfigExtraModal tournament={tournament} tConfig={tConfig} tOption={null}/> : null
                }

            </ButtonGroup>
        </td>
    </tr>)
};
ClassConfigEntry.propTypes = {
    tConfig:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    isBowEntry: PropTypes.bool.isRequired,
    editEntry: PropTypes.func.isRequired
};
const CupGroupSetting = ({cupOption}) => {
    return <p>
        <strong>Cup Groups: </strong>{cupOption.getCupGroups()}<br/>
        <small>Set groups in the database; each child tournament need to be in a group, only best result within a group for the archer will be taken and shown in the master tournament for this group</small></p>
}
module.exports = TournamentMMConfigPage;
