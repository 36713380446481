/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    ModalBasic = require ("components/modals/ModalBasic"),
    messages = require("i18n/messages");

const ClubUser = require("parse/ClubUser");
const clubActions = require("actions/ClubActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

class ClubArticlePayModal extends React.Component {
    constructor(props) {
        super(props);
        this.editClubUser = this.editClubUser.bind(this);
        this.refModal = React.createRef();
    }
    editClubUser(clubUser) {
        this.refModal.current.openObject(clubUser, null)
    }
    render() {
        return (
            <ModalBasic ref={this.refModal}
                title={null}
                customButton={<React.Fragment></React.Fragment>}>
                <ClubArticlePayForm club={this.props.club} />
            </ModalBasic>
        )
    }
};
ClubArticlePayModal.propTypes = {
    club:PropTypes.object.isRequired
};

class ClubArticlePayForm extends React.Component {
    constructor(props) {
        super(props);
        this.refCuArticle = React.createRef();
        this.refPayAmount = React.createRef();
        this.refPayedYear = React.createRef();
        this.valid = this.valid.bind(this);
        this.handleArticleChange = this.handleArticleChange.bind(this);
        this.getDefault = this.getDefault.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
    }
    valid() {
        const { setErrorFct, object } = this.context;
        if (object === null) {
            setErrorFct({message: messages.get("modal.error.noObject")});
            return false;
        }
        let valid = true;
        let required = [
            // this.refNumber.current.getValue().trim()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {object, closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);
        let clubUser = object;

        if (this.refCuArticle.current && this.refCuArticle.current.getValue()) {
            clubUser.setArticleID(this.refCuArticle.current.getValue());
        } else {
            clubUser.unset(ClubUser.prototype.col.articleID);
        }
        console.log("amount", this.refPayAmount.current.getValue())
        if (this.refPayAmount.current.getValue().length > 0) {
            console.log("Set to refPayAmount", this.refPayAmount.current.getValue().trim())
            clubUser.setArticlePayAmount(parseFloat(this.refPayAmount.current.getValue().trim()));
        } else if (this.refPayAmount.current.getValue() > 0) {
            clubUser.setArticlePayAmount(this.refPayAmount.current.getValue());
        } else{
            clubUser.unset(ClubUser.prototype.col.articlePayAmount)
        }
        console.log("year", this.refPayedYear.current.getValue())
        if (this.refPayedYear.current.getValue().length > 0) {
            console.log("Set to refPayedYear", this.refPayedYear.current.getValue().trim() )
            clubUser.setArticlePayedYear(parseInt(this.refPayedYear.current.getValue().trim()));
        } else if (this.refPayedYear.current.getValue() > 0) {
            clubUser.setArticlePayedYear(this.refPayedYear.current.getValue());
        } else {
            clubUser.unset(ClubUser.prototype.col.articlePayedYear)
        }
        clubActions.saveClubUser(clubUser);
        closeFct();
    }
    handleArticleChange(article) {
        // article saved during saveClubUser
        if (this.refPayAmount.current != null) {
            console.log("UPdate to price")
            this.refPayAmount.current.setValue(article.getPrice())
        }
    }
    getDefault(column) {
        const {object} = this.context;
        if (object != null) {
            return object.get(column);
        }
        return "";
    }
    render() {
        const {error, submitDisabled} = this.context;
        const {club} = this.props;
        const validateNow = error && (error.message != null || error.length > 0);

        let articles = [];
        if (club != null && club.getArticles() != null) {
            articles = club.getArticles().filter(article => {
                return article.isGroupMember();
            });
        }

        return (<React.Fragment>
            <Row>
                {
                    articles.length > 0 ?
                        <Col xs={12}>
                            <h4><I18n code={"club.member.type.member"}/></h4>
                            <DropDownParseObject ref={this.refCuArticle}
                                                 default={this.getDefault(ClubUser.prototype.col.articleID)}
                                                 placeholder={<option key={"nullkey"}
                                                                      value={"null"}>{messages.get("club.group.modal.chooseArticle")}</option>}
                                                 onAfterChange={this.handleArticleChange}
                                                 objectList={articles}/>
                        </Col>
                        : null
                }
                <Col sm={6} xs={12}>
                    <ValidInput ref={this.refPayAmount}
                                valid={{maxLength: 10, check:['isNumber', 'point4comma']}}
                                label={messages.get("club.member.article.payAmount")}
                                default={this.getDefault(ClubUser.prototype.col.articlePayAmount)}
                                addonBefore={<FontAwesome icon="euro"/>}
                                validateNow={validateNow}/>

                </Col>
                <Col sm={6} xs={12}>
                    <ValidInput ref={this.refPayedYear}
                                valid={{maxLength: 10, check:['isNumber']}}
                                label={messages.get("club.member.article.payedYear")}
                                default={this.getDefault(ClubUser.prototype.col.articlePayedYear)}
                                addonBefore={<FontAwesome icon="calendar-alt"/>}
                                validateNow={validateNow}/>

                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                    <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                        <I18n code={"modal.button.save"}/>
                    </Button>
                </Col>
            </Row>

        </React.Fragment>)
    }
}
ClubArticlePayForm.propTypes = {
    club:PropTypes.object.isRequired
};
ClubArticlePayForm.contextType = ModalBasicContext;
module.exports = ClubArticlePayModal;