const alt = require("AltInit"),
    linkUtil = require("linkUtil");
const parseDao = require("actdao/ParseBaseDao");
const tournamentDao = require("actdao/TournamentDao");
const tournamentManDao = require("actdao/TournamentManagerDao");
const notificationActions = require("components/notification/NotificationActions");
const tournamentUserActions = require("actions/TournamentUserActions");

class TournamentActions {

    // sets loading to true
    showProgress() {
        return {};
    };

    initTeaser(callback) {
        tournamentDao.initTeaser()
            .then(tournaments => {
                const today = new Date();
                let result = tournaments.filter(tournament => {
                    // remove which are from past
                    if (tournament.getDate() < today) {
                        if (tournament.isCanceled()) {
                            // and canceled
                            return false;
                        }
                        if (tournament.isRegistrationClosed() && tournament.isPublished()) {
                            // but registration still closed and not started
                            return false;
                        }
                    }
                    return true;
                });
                callback(result)
            })
            .catch(error => {
                callback([]);
                error.trace = "TOUA.initTeaser";
                notificationActions.parseError(error);
            });
        return {};
    };

    showDetails(id) {
        tournamentDao
            .loadTournamentDetails(id)
            .then(pTournamentDetail => {
                if (pTournamentDetail.isTypePairRegistration()) {
                    tournamentUserActions.loadTournamentUserTeams(pTournamentDetail);
                } else {
                    // standard
                    tournamentUserActions.loadTournamentUsers(pTournamentDetail);
                }
                if (pTournamentDetail.isTypeCupMaster()) {
                    // load child tournaments
                    this.loadTournamentCupChilds(pTournamentDetail);
                } else {
                    this.updateCupChildTournaments([]);
                }
                this.updateTournamentDetail(pTournamentDetail);
            })
            .catch(error => {
                error.trace = "TOUA.showDetails";
                notificationActions.parseError(error);
            });
        return {};
    }
    showCupDetails(buCode, year, licNumber) {
        const startDate = new Date(year, 0, 1, 0, 0 ,0, 0);
        // find latest master tournament
        tournamentDao.loadTournamentMasterCup(buCode, startDate)
            .then(cupMaster => {
                if (cupMaster != null) {
                    this.updateTournamentDetail(cupMaster);
                    // load child tournaments
                    tournamentManDao.loadTournamentCupChilds(cupMaster)
                        .then(tournaments => {
                            this.updateCupChildTournaments(tournaments);
                            let tournamentIds = [];
                            for (let i = 0; i < tournaments.length; i++) {
                                tournamentIds.push(tournaments[i].id);
                            }
                            if (licNumber != null) {
                                tournamentUserActions.loadCupTournamentUsers(buCode, licNumber, tournamentIds);
                            }
                        })
                        .catch(error => {
                            error.trace = "TOUA.loadCupChilds";
                            notificationActions.parseError(error);
                        });
                } else if (year > 2017) {
                    // try to load the year before - but not forever
                    this.showCupDetails(buCode, year -1, licNumber);
                }
            })
            .catch(error => {
                error.trace = "TOUA.showDetails";
                notificationActions.parseError(error);
            });
        return {};
    }
    showCheckoutDetails(id, callbackParcoursOnlineId) {
        tournamentDao
            .loadTournamentDetails(id)
            .then(pTournamentDetail => {
                if (callbackParcoursOnlineId != null && pTournamentDetail.getParcoursID() != null) {
                    callbackParcoursOnlineId(pTournamentDetail.getParcoursID().getOnlineID())
                }
                if (pTournamentDetail.isOnlinePaymentEnabled()) {
                    tournamentUserActions.loadTournamentUsersUnpaid(pTournamentDetail);
                }
                this.updateTournamentDetail(pTournamentDetail);
            })
            .catch(error => {
                error.trace = "TOUA.checkoutDetails";
                notificationActions.parseError(error);
            });
        return {};
    }
    searchTournaments(searchParams) {
        tournamentDao
            .searchTournaments(searchParams)
            .then(tournaments => {
                this.updateTournamentList(tournaments);
            })
            .catch(error => {
                error.trace = "TOUA.search";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchCupTournaments(searchParams) {
        const startDate = new Date((new Date().getFullYear()), 0, 1, 0, 0 ,0, 0);
        tournamentDao.loadBowUnion(searchParams.buCode)
            .then(bowUnion => {
                if (bowUnion != null) {
                    this.updateBowUnion(bowUnion)
                    tournamentDao.loadTournamentBuCodeParcoursList(searchParams, bowUnion, startDate)
                        .then(tournaments => {
                            this.updateTournamentList(tournaments);
                        })
                }
            })
            .catch(error => {
                error.trace = "TOUA.search";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadTournamentCupChilds(cupMaster) {
        tournamentManDao.loadTournamentCupChilds(cupMaster)
            .then(tTournaments => {
                this.updateCupChildTournaments(tTournaments);
            })
            .catch(error => {
                error.trace = "TOUA.loadCupChilds";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchTournamentList(params) {
        return params
    }


    updateTournamentDetail(pTournamentDetail) {
        return pTournamentDetail;
    }

    updateBowUnion(bowUnion) {
        return bowUnion
    }

    updateTournamentList(pTournamentList) {
        return pTournamentList;
    }

    updateDateFilter(dateFilter) {
        return dateFilter;
    };

    createTournament(tournament) {
        parseDao.saveObject(tournament)
            .then(sTournament => {
                window.location.href = linkUtil.getLink("tournament/" + sTournament.id);
            })
            .catch(error => {
                error.trace = "TOUA.createT";
                notificationActions.parseError(error);
            });
        return {};
    };

    setMainEditor(tournament, user) {
        console.log("user " + user.get("username") + " gets mainEditor for " + tournament.get("name"));
        tournamentDao.setMainEditor(tournament, user)
            .then(result => {
                console.log("User set as mainEditor");
                this.showDetails(tournament.id);
            })
            .catch(error => {
                error.trace = "TOUA.mainEditor";
                notificationActions.parseError(error);
            });
        return {};
    };

    resetTournamentDetails() {
        return {};
    }

    updateListRenderAmount(amount) {
        return amount;
    }

    updateCupChildTournaments(childTournaments) {
        return childTournaments;
    };
}

module.exports = alt.createActions(TournamentActions);
