const React = require("react"),
    PropTypes = require('prop-types');
const {MenuItem, DropdownButton, Label, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n");

class SwitchButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.onClickMenuItem = this.onClickMenuItem.bind(this);
        this.resetSelected = this.resetSelected.bind(this);
        this.state = {
            selectedIndex: this.props.preSelectIndex >= 0 ? this.props.preSelectIndex : null
        }
    }

    resetSelected() {
        this.setState({
            selectedIndex: null,
        });
        this.props.onClickButton(null)
    }
    onClickMenuItem(entry, index) {
        this.setState({
            selectedIndex: index,
        });
        this.props.onClickButton(entry.status);
    }
    render() {
        const {selectedIndex} = this.state;
        const {dropDownEntries} = this.props;
        let selectedEntry = selectedIndex != null ? dropDownEntries[selectedIndex] : null;

        return <React.Fragment>
                <DropdownButton title={selectedEntry == null ? "Filter" : <Label bsStyle={selectedEntry.bsStyle}><I18n code={selectedEntry.code}/></Label>} id="bg-vertical-dropdown-1">
                {
                    dropDownEntries.map((entry, index) => {
                        return  <MenuItem key={index + 1} onClick={() => this.onClickMenuItem(entry, index)} eventKey={index + 1}><Label bsStyle={entry.bsStyle}><I18n code={entry.code}/></Label></MenuItem>
                    })
                }
            </DropdownButton>
            {
                selectedEntry != null ? <Button onClick={this.resetSelected}>X</Button> : null
            }
        </React.Fragment>
    }
}
SwitchButtonGroup.propTypes = {
    dropDownEntries: PropTypes.array.isRequired,
    onClickButton: PropTypes.func.isRequired,
    preSelectIndex: PropTypes.number
};
module.exports = SwitchButtonGroup;
