const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel, Alert} = require("react-bootstrap");
var I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    PointInterestBanner = require("components/widgets/PointInterestBanner");

var messages = require("i18n/messages");

const {BaseContext} = require("context/BaseContext");

var eventStore = require("stores/EventStore"),
    eventActions = require("actions/EventActions"),
    notificationActions = require("components/notification/NotificationActions");

class TabEventStatistic extends React.Component {
    constructor(props) {
        super(props);
        this.handleEventChange = this.handleEventChange.bind(this);

        this.state = {
            eventStore: eventStore.getState(),
        }
    }
    componentDidMount() {
        const { user } = this.context;
        eventStore.listen(this.handleEventChange);
        notificationActions.reset();
        eventActions.reset();
        eventActions.queryYearEventList(user, this.props.year);
    }
    componentWillUnmount() {
        eventStore.unlisten(this.handleEventChange);
    }
    handleEventChange(state) {
        this.setState({eventStore: state});
    }
    render() {
        var body = null;
        if (this.state.eventStore.loading === true || this.state.eventStore.countTypes == null) {
            body = <Loading/>;
        } else if (this.state.eventStore.loading === false && this.state.eventStore.countTypes.length <= 0){
            body = <Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert>;
        } else if (this.state.eventStore.loading === false && this.state.eventStore.countTypes.length > 0) {
            body = <CountTypePanels countTypes={this.state.eventStore.countTypes}
                                            epYearStat={this.state.eventStore.epYearStat} />;
        } else {
            console.log("WTF - never should be here");
        }
        return (<React.Fragment>
                <Row>
                    <Col xs={12}>
                        {body}
                    </Col>
                </Row>
                <PointInterestBanner />
            </React.Fragment>
        )
    }
}
TabEventStatistic.contextType = BaseContext;
TabEventStatistic.propTypes = {
    year: PropTypes.number.isRequired
};
const CountTypePanels  = ({countTypes, epYearStat}) => {
    let sumEvent = 0;
    let sumPoints = 0;
    let sumArrows = 0;
    let sumHit = 0;
    let sumMiss = 0;
    let sumZ1 = 0;
    let sumZ2 = 0;
    let sumZ3 = 0;
    return (
        <React.Fragment>
            {countTypes.map(countType => {
                var eventPlayerObject = epYearStat.getEventPlayerEntries(countType);
                var killAnalyseObject = epYearStat.analyseKills(countType);
                var percent = (eventPlayerObject.sumPoints * 100) / eventPlayerObject.maxPoints;
                var dps = eventPlayerObject.sumPoints / (killAnalyseObject.hit + killAnalyseObject.miss);
                sumEvent += eventPlayerObject.events;
                sumPoints += eventPlayerObject.sumPoints;
                sumArrows += killAnalyseObject.arrows;
                sumHit += killAnalyseObject.hit;
                sumMiss += killAnalyseObject.miss;
                sumZ1 += killAnalyseObject.z1;
                sumZ2 += killAnalyseObject.z2;
                sumZ3 += killAnalyseObject.z3;

                return <EventPlayerObject key={countType}
                    header={messages.get("countType." + countType)}
                    sumPoints={eventPlayerObject.sumPoints + " (" + percent.toFixed(0) + "%)"}
                    dps={dps.toFixed(2)}
                    events={eventPlayerObject.events}
                    arrows={killAnalyseObject.arrows}
                    hit={killAnalyseObject.hit}
                    miss={killAnalyseObject.miss}
                    kills={killAnalyseObject.z1 + "/" + killAnalyseObject.z2 + "/" + killAnalyseObject.z3}
                />
            })}
            <EventPlayerObject
                header={messages.get("tournament.table.column.sum")}
                sumPoints={sumPoints}
                events={sumEvent}
                arrows={sumArrows}
                hit={sumHit}
                miss={sumMiss}
                kills={sumZ1 + "/" + sumZ2 + "/" + sumZ3}
            />
        </React.Fragment>
    )
};
const EventPlayerObject = ({header, events, arrows, sumPoints, hit, miss, kills, dps}) => {
    return (
        <Panel>
            <Panel.Heading>
                <Row>
                    <Col sm={4} xs={12}><h4>{header}</h4></Col>
                    <Col sm={4} xs={7}><h4>{messages.get("tournament.table.column.points") + ": " + sumPoints}</h4></Col>
                    {
                        dps == null ? "" : <Col sm={4} xs={5}><h4>{"DPS: " + dps}</h4></Col>
                    }
                </Row>
            </Panel.Heading>
            <Panel.Body>
                <Col sm={6} xs={6}>
                    <dl>
                        <dt className="size-140"><I18n code="statistics.column.event.amount"/>:&nbsp;</dt>
                        <dd>{events}</dd>
                        <dt className="size-140"><I18n code="statistics.column.arrows"/>:&nbsp;</dt>
                        <dd>{arrows}</dd>
                        <dt className="size-140"><I18n code="parcours.details.targets"/>:&nbsp;</dt>
                        <dd>{(hit + miss)}</dd>
                    </dl>
                </Col>
                <Col sm={6} xs={6}>
                    <dl>
                        <dt className="size-140"><I18n code="statistics.column.hit"/>:&nbsp;</dt>
                        <dd>{hit}</dd>
                        <dt className="size-140"><I18n code="statistics.column.miss"/>:&nbsp;</dt>
                        <dd>{miss}</dd>
                        <dt className="size-140"><I18n code="Kills"/>:&nbsp;</dt>
                        <dd>{kills}</dd>
                    </dl>
                </Col>
            </Panel.Body>
        </Panel>)
};

module.exports = TabEventStatistic;
