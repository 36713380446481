const Parse = require("parse");
const parseDao = require("actdao/ParseBaseDao");
const userDao = require("actdao/UserDao");
const notificationActions = require("components/notification/NotificationActions");

const {User, UserToken} = require("parse/_Domain");
const UserFeatures = require("beans/UserFeatures");

class UserInfoActions {

    constructor() {
    }
    static refreshUser(user, callback) {
        userDao.refreshUser(user)
            .then(fUser => {
                callback(fUser);
            })
            .catch(error => {
                error.trace = "USRA.fetchUser";
                notificationActions.parseError(error);
            });
    }


    static async fetchUserInfos(user) {
        let fUserAbos = null;
        let fUserDetail = null;
        let fUserFavorite = null;
        if (user && user.get(User.prototype.col.userAbos)) {
            try {
                fUserAbos = await userDao.fetchUserAbos(user)
            } catch (error) {
                error.trace = "USRA.fetchUserAbo";
                notificationActions.parseError(error);
            }
        }
        if (user && user.get(User.prototype.col.userDetailID)) {
            try {
                fUserDetail = await userDao.fetchUserDetail(user)
            } catch (error) {
                error.trace = "USRA.fetchUserDetail";
                notificationActions.parseError(error);
            }

        }
        if (user) {
            try {
                fUserFavorite = await userDao.fetchUserFavorite(user)
            } catch (error) {
                error.trace = "USRA.fetchUserFavorite";
                notificationActions.parseError(error);
            }
        }
        let nFeat = new UserFeatures();
        nFeat.setUserFeatures(fUserAbos);
        return {
            userDetail: fUserDetail,
            userAbos: fUserAbos,
            userFeatures: nFeat,
            userFavorite: fUserFavorite,
            userLoaded: true
        }
    }


    static saveUser(user, params, callback) {
        userDao.editUserCCP(params)
            .then(result => {
                notificationActions.success("modal.login.save.success");
                callback({reloadUser:true})
            })
            .catch(error => {
                error.trace = "USRA.saveUser";
                notificationActions.parseError(error);
            });
    };

    static createEmptyUserDetail(user, userDetail, callback) {
        if (user) {
            if (userDetail != null) {
                userDao.createEmptyUserAddress(user, userDetail)
                    .then(usrDetail => {
                        callback({usrDetail: usrDetail})
                    })
                    .catch(error => {
                        error.trace = "USRIA.createEmptyUserAddress";
                        notificationActions.parseError(error);
                    });
            } else {
                // create userDetail and address
                userDao.createEmptyUserDetail(user)
                    .then(usrDetail => {
                        user.set("userDetailID", usrDetail);
                        parseDao.saveObject(user)
                            .then(sUser => {
                                callback({usrDetail: usrDetail, user: sUser})
                            })
                            .catch(error => {
                                error.trace = "USRIA.createEmptyUserDetail.saveObject";
                                notificationActions.parseError(error);
                            });
                    })
                    .catch(error => {
                        error.trace = "USRIA.createEmptyUserDetail";
                        notificationActions.parseError(error);
                    });
            }
        }
    }

    static saveUserImageFile(userDetail, parseFile, callback) {
        userDao.saveUserImageFile(userDetail, parseFile)
            .then(sUserDetail => {
                callback(sUserDetail)
            })
            .catch(error => {
                error.trace="USRA.saveImg";
                notificationActions.parseError(error);
                callback(false)
            })
    }

    static renameUserDetail(usrDetail, name, surname, callback) {
        usrDetail.setFullName(name, surname);
        parseDao.saveObject(usrDetail)
            .then(sUsrDetail => {
                callback({usrDetail: sUsrDetail})
            })
            .catch(error => {
                error.trace = "USRIA.renameUserDetail.saveObject";
                notificationActions.parseError(error);
            });
    }

    static updateParcoursFavorite(onlineID, add2Fav, user, callback) {
        Parse.Cloud.run('userFavoriteParcours', {parcoursOnlineID: onlineID, add2Fav: add2Fav})
            .then(result => {
                // reload element
                if (callback) {
                    userDao.fetchUserFavorite(user)
                        .then(userFavorite => {
                            callback(userFavorite)
                        })

                }
            })
            .catch(error => {
                error.trace = "USRA.usrFavoritParcours";
                notificationActions.parseError(error);
            });
    };

    static updateUserAboPause(userAbo, status, callback) {
        userDao.updateUserAboPause(userAbo, status)
            .then(sUserAbo => {
                if (callback) {
                    callback(sUserAbo)
                }
            })
            .catch(error => {
                error.trace = "USRA.updAbo";
                notificationActions.parseError(error);
            });
    }

    static checkIsAdmin(callback) {
        parseDao.isUserAdmin()
            .then(response => {
                callback(true)
            })
            .catch(error =>{
                // user is no admin
                callback(false)
            });
    }

    static hasRole(user, roleID, callback) {
        parseDao.hasRole(user, roleID)
            .then(hasRole => {
                callback(hasRole);
            })
            .catch(error => {
                error.trace = "USRA.hasRole";
                notificationActions.parseError(error);
                callback(false);
            });
    }

    static addRole(user, roleID, callback) {
        parseDao.addRole(user, roleID)
            .then(hasRole => {
                callback(hasRole);
            })
            .catch(error => {
                error.trace = "USRA.addRole";
                notificationActions.parseError(error);
                callback(false);
            });
    }
    static removeRole(user, roleID, callback) {
        parseDao.removeRole(user, roleID)
            .then(hasRole => {
                callback(!hasRole);
            })
            .catch(error => {
                error.trace = "USRA.removeRole";
                notificationActions.parseError(error);
                callback(false);
            });
    }

    static setTournamentRole(user) {
        userDao.setTournamentRole(user)
            .then(result => {
                console.log("Granted tournament role to " + user.get("username"));
            })
            .catch(error => {
                console.log("Error grant tournament role to " + user.get("username") + "_error: " + error.message);
            });
    };

    static saveUserObject(userObject, callback) {
        parseDao.saveObject(userObject)
            .then(sUserObject => {
                callback(sUserObject);
            })
            .catch(error => {
                error.trace = "TRAA.saveUserDetail";
                notificationActions.parseError(error);
            });
    }
}

module.exports = UserInfoActions;
