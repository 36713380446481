const Parse = require("ParseInit");

const ParcoursOwner = Parse.Object.extend("parcoursowner", {
    col: {
        ACL: "ACL",
        bannerImageID: "bannerImageID",
        articles: "articles",
        category: "category",
        association: "association",
        contactMail: "contactMail",
        contactName: "contactName",
        contactPhone: "contactPhone",
        coordinates: "coordinates",
        createdAt: "createdAt",
        description: "description",
        editor: "editor",
        groupPlaceID: "groupPlaceID",
        mainGroupPlaceID: "mainGroupPlaceID",
        manageStatus: "manageStatus",
        managementID: "managementID",
        hideFront: "hideFront",
        clubID: "clubID",
        name: "name",
        objectId: "objectId",
        opentimes: "opentimes",
        openDays: "openDays",
        place: "place",
        status: "status",
        street: "street",
        supporttimes: "supporttimes",
        sortIndex: "sortIndex",
        ruleImageID: "ruleImageID",
        updatedAt: "updatedAt",
        weblink: "weblink",
        extLink: "extLink",
        yearprice: "yearprice",
        zip: "zip"
    },
    mgmtStatus: {
        active: "A",
        inactive: "I"
    },
    getZipPlace() {
        let tPlace = "";
        if (this.getZip()) {
          tPlace = this.getZip() + " ";
        }
        if (this.getPlace()) {
          tPlace += this.getPlace();
        }
        return tPlace;
    },
    hasManagementID() {
        return this.get(this.col.manageStatus) == this.mgmtStatus.active  || this.get(this.col.manageStatus) == this.mgmtStatus.inactive;
    },
    hasActiveManagementID() {
        return this.get(this.col.manageStatus) == this.mgmtStatus.active;
    },
    hasParcoursFeature() {
        const management = this.getManagementID();
        if (management) {
          return management.hasParcoursFeature();
        }
        return false;
    },
    hasPaymentFeature() {
        const management = this.getManagementID();
        if (management) {
          return management.hasPaymentFeature();
        }
        return false;
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getArticles() {
        return this.get(this.col.articles)
    },
    setArticles(articles) {
        return this.set(this.col.articles, articles)
    },
    getBannerImageID() {
        return this.get(this.col.bannerImageID)
    },
    setBannerImageID(bannerImageID) {
        return this.set(this.col.bannerImageID, bannerImageID)
    },
    getBannerImageUrl() {
        const bannerImageID = this.getBannerImageID();
        if (bannerImageID) {
          return bannerImageID.get("image").url();
        }
        return "";
    },
    getCategory() {
        return this.get(this.col.category)
    },
    setCategory(category) {
        return this.set(this.col.category, category)
    },
    getAssociations() {
        return this.get(this.col.association)
    },
    getContactMail() {
        return this.get(this.col.contactMail)
    },
    setContactMail(contactMail) {
        return this.set(this.col.contactMail, contactMail)
    },
    getContactName() {
        return this.get(this.col.contactName)
    },
    setContactName(contactName) {
        return this.set(this.col.contactName, contactName)
    },
    getContactPhone() {
       return this.get(this.col.contactPhone)
    },
    setContactPhone(contactPhone) {
        return this.set(this.col.contactPhone, contactPhone)
    },
    getCoordinates() {
        return this.get(this.col.coordinates)
    },
    getReadableCoordinates() {
        const coords = this.getCoordinates();
        if (coords) {
            return coords._latitude + "," + coords._longitude;
        } else {
            return null;
        }
    },
    setCoordinates(coordinates) {
       return this.set(this.col.coordinates, coordinates)
    },
    getCreatedAt() {
       return this.get(this.col.createdAt)
    },
    getDescription() {
       return this.get(this.col.description)
    },
    setDescription(description) {
       return this.set(this.col.description, description)
    },
    getEditor() {
       return this.get(this.col.editor)
    },
    setEditor(editor) {
       return this.set(this.col.editor, editor)
    },
    getExternalLink() {
        return this.get(this.col.extLink)
    },
    setExternalLink(extLink) {
        return this.set(this.col.extLink, extLink)
    },
    isHideOnFrontPage() {
        return this.get(this.col.hideFront)
    },
    setHideOnFrontPage(hide) {
        this.set(this.col.hideFront, hide)
    },
    getGroupPlaceID() {
        return this.get(this.col.groupPlaceID)
    },
    setGroupPlaceID(groupPlaceID) {
        return this.set(this.col.groupPlaceID, groupPlaceID)
    },
    getMainGroupPlaceID() {
        return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID(mainGroupPlaceID) {
        return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getManagementID() {
        return this.get(this.col.managementID);
    },
    setManagementID(management) {
        return this.set(this.col.managementID, management);
    },
    getManageStatus() {
        return this.get(this.col.manageStatus)
    },
    setManageStatus(status) {
        return this.set(this.col.manageStatus, status)
    },
    getClubID() {
        return this.get(this.col.clubID)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
       return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
       return this.set(this.col.objectId, objectId)
    },
    getOpentimes() {
        return this.get(this.col.opentimes)
    },
    setOpentimes(opentimes) {
       return this.set(this.col.opentimes, opentimes)
    },
    getOpenDays() {
       return this.get(this.col.openDays)
    },
    getPlace() {
        return this.get(this.col.place)
    },
    setPlace(place) {
       return this.set(this.col.place, place)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
       return this.set(this.col.status, status)
    },
    getStreet() {
       return this.get(this.col.street)
    },
    setStreet(street) {
        return this.set(this.col.street, street)
    },
    getSupporttimes() {
        return this.get(this.col.supporttimes)
    },
    setSupporttimes(supporttimes) {
        return this.set(this.col.supporttimes, supporttimes)
    },
    getSortIndex() {
        return this.get(this.col.sortIndex)
    },
    setSortIndex(sortIndex) {
        return this.set(this.col.sortIndex, sortIndex)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getRulesImage() {
        return this.get(this.col.ruleImageID)
    },
    setRulesImage(ruleImage) {
        return this.set(this.col.ruleImageID, ruleImage)
    },
    getWeblink() {
        return this.get(this.col.weblink)
    },
    setWeblink(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    getYearprice() {
        return this.get(this.col.yearprice)
    },
    setYearprice(yearprice) {
        return this.set(this.col.yearprice, yearprice)
    },
    getZip() {
        return this.get(this.col.zip)
    },
    setZip(zip) {
        return this.set(this.col.zip, zip)
    }
});

module.exports = ParcoursOwner;
