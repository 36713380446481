const React = require("react"),
    PropTypes = require('prop-types');
const {ListGroupItem, ListGroup, Button} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n");

const notificationStore = require("components/notification/NotificationStore"),
    notificationActions = require("components/notification/NotificationActions"),
    msgActions = require("actions/MessageActions"),
    userActions = require("actions/UserActions");

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.onStoreUpdate = this.onStoreUpdate.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.reportError = this.reportError.bind(this);
        this.resendAuthenticationMail = this.resendAuthenticationMail.bind(this);
        this.state = {
            notificationStore: notificationStore.getState(),
            allowNotify: true
        }
    }
    componentDidMount() {
        notificationStore.listen(this.onStoreUpdate);
    }
    componentWillUnmount() {
        notificationStore.unlisten(this.onStoreUpdate);
    }
    onStoreUpdate(state) {
        window.scrollTo(0,0);
        this.setState({notificationStore: state})
    }
    reloadPage() {
        window.location.reload(true);
    }
    logoutUser() {
        userActions.logout();
    }
    resendAuthenticationMail() {
        const {userAuhenticate} = this.state.notificationStore;
        userActions.resendAuthenticationMail(userAuhenticate, userAuhenticate.getEmail());
    }
    reportError(code, trace) {
        msgActions.slackNotify("ERROR: " + code + ":" + trace + " URL: " + window.location.href, null)
        this.setState({allowNotify: false})
    }
    render() {
        const {code,style, trace, newVersion, invalidSession, parseOffline, userAuhenticate, lockMessage} = this.state.notificationStore;
        const {allowNotify} = this.state;
        if (code.length <= 0 && newVersion == false && invalidSession == false && parseOffline == false && userAuhenticate == null && lockMessage == null) {
            return null;
        } else {
            return (<ListGroup>
                {
                    newVersion ? <ListGroupItem key={"nV"} bsStyle="warning">
                        <Button bsStyle="primary" onClick={this.reloadPage}><FontAwesome icon="sync"/></Button>&nbsp;<I18n code="releasenotes.newVersion"/>
                    </ListGroupItem> : null
                }
                {
                    parseOffline ? <ListGroupItem key={"pO"} bsStyle="warning">
                        <Button bsStyle="primary" onClick={this.reloadPage}><FontAwesome icon="sync"/></Button>&nbsp;<I18n code="parse.error.offline"/>
                    </ListGroupItem> : null
                }
                {
                    invalidSession ? <ListGroupItem key={"iS"} bsStyle="danger">
                        <I18n code="Dialog_Body_Error_invalid_session"/>&nbsp;<Button bsStyle="primary" onClick={this.logoutUser}><FontAwesome icon="sign-out-alt"/><I18n code="header.nav.logout"/></Button>
                    </ListGroupItem> : null
                }
                {
                    userAuhenticate != null ?
                        <ListGroupItem key={"ueV"} bsStyle="warning">
                            <I18n code="header.nav.mailNotVerified"/><br/>
                            <Button bsStyle="primary" onClick={this.resendAuthenticationMail}> <FontAwesome icon="envelope"/>{"Authentication mail to " + userAuhenticate.getEmail() }</Button>
                        </ListGroupItem> : null

                }
                {
                    lockMessage != null ?
                        <ListGroupItem key={"ueV"} bsStyle="danger">
                            <I18n code="header.nav.accountLocked"/><br/>{lockMessage}
                        </ListGroupItem> : null
                }
                {
                    code.map((code, index) =>{
                        return <ListGroupItem key={"10" + index} bsStyle={style[index]}>
                            <I18n code={code}/><br/>
                            {
                                trace[index] && allowNotify > 0 ? <Button bsStyle="primary" onClick={() => this.reportError(code, trace[index])}> <FontAwesome icon="envelope"/>{"Send to Support" }</Button> : null
                            }
                        </ListGroupItem>

                    })
                }
                <Button className="pull-right" bsStyle="link" onClick={notificationActions.reset}>X</Button>
            </ListGroup>);
        }
    }
}
module.exports = Notification;
