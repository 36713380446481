const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col,Grid, Panel, Alert, ListGroup, Table, Label, ButtonGroup, Button, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    MapSmall = require("components/widgets/MapSmall"),
    SortTableText = require("components/form/SortTableText"),
    messages = require("i18n/messages"),
    ExtLink = require("components/links/ExtLink"),
    EntryRemovable = require("components/form/EntryRemovable"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ShareButton = require("components/widgets/ShareButton");

const {BaseContext} = require("context/BaseContext");

const clubEventStore = require("stores/ClubEventStore"),
    clubEventActions = require("actions/ClubEventActions"),
    notificationActions = require("components/notification/NotificationActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubEventPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleClubChange = this.handleClubChange.bind(this);
        this.state = {
            clubEventStore: clubEventStore.getState(),
        }
    }
    componentDidMount() {
        const { user } = this.context;
        clubEventStore.listen(this.handleClubChange);
        notificationActions.reset();
        clubEventActions.showEventDetails(this.props.eventID, user, this.props.secret);
    }
    componentWillUnmount() {
        clubEventStore.unlisten(this.handleClubChange);
    }
    handleClubChange(state) {
        this.setState({clubEventStore: state});
    }
    render() {
        const { user } = this.context;
        if (this.state.clubEventStore.loading === true || this.state.clubEventStore.clubDetail == null) {
        return (
            <Grid>
                <Row>
                    <Col lg={12}><Loading/></Col>
                </Row>
            </Grid>)
        }
        return (<ClubEventVisibilityCheck
            user={user}
            club={this.state.clubEventStore.clubDetail}
            clubEvent={this.state.clubEventStore.clubEvent}
            clubUsers={this.state.clubEventStore.clubUsers}
            sortParams={this.state.clubEventStore.sortParams}
            ownClubUser={this.state.clubEventStore.ownClubUser}
            hasLinkSecret={this.state.clubEventStore.clubEvent.hasLinkSecret(this.props.secret)}
        />)
    }
}
ClubEventPage.contextType = BaseContext;
ClubEventPage.propTypes = {
    eventID: PropTypes.string.isRequired,
    secret: PropTypes.string
};
const ClubEventVisibilityCheck = ({club, user, ownClubUser, clubEvent, clubUsers, sortParams, hasLinkSecret}) => {
    let alert = null;
    if (clubEvent != null && clubEvent.isDeleted()) {
        alert = <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
    } else {
        if (!hasLinkSecret) {
            // link secret overwrites user visibility
            if ((user == null || ownClubUser == null || ownClubUser.isExMember()) && !clubEvent.isPublic()) {
                // only show if public event
                alert = <Alert bsStyle="warning">{clubEvent.getName() + " - " + messages.get("club.event.foreign.notVisible")}</Alert>
            } else if (user != null && ownClubUser != null && !clubEvent.checkVisibilityGroup(ownClubUser)) {
                alert =  <Alert bsStyle="warning">{clubEvent.getName() + " - " + messages.get("club.event.intern.notVisible")}</Alert>
            }
        }
    }
    if (alert != null) {
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                        </Breadcrumbs>
                    </Col>
                    <Col xs={12}>
                        {alert}
                    </Col>
                </Row>
            </Grid>)
    } else {
        return (<ClubEventDetails
            club={club}
            clubEvent={clubEvent}
            clubUsers={clubUsers}
            ownClubUser={ownClubUser}
            sortParams={sortParams}
            hasLinkSecret={hasLinkSecret}
        />)
    }
};
ClubEventVisibilityCheck.propTypes = {
    club:PropTypes.object.isRequired,
    clubEvent:PropTypes.object.isRequired,
    sortParams: PropTypes.object,
    user:PropTypes.object,
    clubUsers: PropTypes.array,
    ownClubUser:PropTypes.object,
    hasLinkSecret: PropTypes.bool.isRequired
};

const ClubEventDetails = ({club, ownClubUser, clubEvent, clubUsers, sortParams, hasLinkSecret}) => {
    document.title = club.getName();
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                            <Breadcrumb code={clubEvent.getName()} active/>
                        </Breadcrumbs>
                </Col>
            </Row>
            <Col lg={12}>
                <PageHeader>{clubEvent.getName()}
                    &nbsp;{clubEvent.isDraft()? <Label>{messages.get("club.event.create.draft")}</Label> : null}
                    &nbsp;{clubEvent.isPublic()? <Label>{messages.get("event.vis.public")}</Label> : null}
                </PageHeader>
            </Col>
            <Col sm={6}>
                <h4><FontAwesome icon="calendar-alt"/>{clubEvent.getEventStartDate() + " - "  + clubEvent.getEventEndDate()}</h4>
                { clubEvent.getWeblink() != null ?
                    <label><FontAwesome icon="external-link-alt"/><ExtLink href={clubEvent.getWeblink()}>{clubEvent.getWeblink()}</ExtLink></label>
                    : null

                }
                <br/>
                { clubEvent.getDescription() != null ?
                    <label><FontAwesome icon="exclamation-triangle"/>{clubEvent.getDescription()}</label>
                    : null

                }
                <br/>
                {
                    clubEvent.getDocument() != null ?
                        <a href={clubEvent.getDocument().url()} target="_blank"><FontAwesome icon="file-alt"/>{clubEvent.getDocumentName()}</a>
                        : null
                }
                <br/><br/>
                <ShareButton url={linkUtil.getFullLink("club/event/" + clubEvent.id)}/>
            </Col>
            <Col sm={6}>
                { clubEvent.getPlace() != null ?
                    <h4><FontAwesome icon="map-marker-alt"/>{clubEvent.getPlace()}</h4>
                    : null

                }
                <br/>
                { clubEvent.getCoordinates() != null ?
                    <MapSmall mapCoordinates={clubEvent.getReadableCoordinates()}/> : null
                }
            </Col>
            <hr/>
            <ErrorBoundary identifier={"ClubEventId:" + clubEvent.id}>
            {
                clubEvent.getOptions() != null && (ownClubUser != null || hasLinkSecret === true)  ?
                    <ClubEventOptions clubEvent={clubEvent}
                                      clubUsers={clubUsers}
                                      ownClubUser={ownClubUser}
                                      sortParams={sortParams}
                                      hasLinkSecret={hasLinkSecret}/> : null
            }
            </ErrorBoundary>
        </Grid>)
};
ClubEventDetails.propTypes = {
    club:PropTypes.object.isRequired,
    clubEvent:PropTypes.object.isRequired,
    clubUsers: PropTypes.array,
    ownClubUser:PropTypes.object,
    sortParams: PropTypes.object,
    hasLinkSecret: PropTypes.bool.isRequired
};
const ClubEventOptions = ({ownClubUser, clubEvent, clubUsers, sortParams, hasLinkSecret}) => {
    function sortBy(sortKey, direction) {
        let sortParams = {
            sortKey: sortKey,
            sortDir: direction
        };
        clubEventActions.sortClubUsers(clubUsers, sortParams);
    }
    let allowEditAnswers = ownClubUser ? ownClubUser.hasMessaging() : false;
    let allowSetAnswers = allowEditAnswers || hasLinkSecret;
    let colSM = 4;
    if (clubEvent.getOptions().length > 2) {
        colSM = 3;
    }
    return (<React.Fragment>
        <Row>
            <Col  xs={12}>
                <h3>{messages.get("club.event.column.answer")}</h3>
            </Col>
            {
                clubEvent.getOptions().map(evOption => {
                    return <Col xs={6} sm={colSM} key={"Col" + evOption}>
                        <ClubEventOptionPanel evOption={evOption}
                                              clubEvent={clubEvent}
                                              ownClubUser={ownClubUser}
                                              allowEditAnswers={allowEditAnswers}/>
                    </Col>
                })
            }
        </Row>
        {
            clubUsers != null ? <Row>
                <Col  xs={12}>
                    <h3>{messages.get("club.event.column.missing.answer")}</h3>
                </Col>
                <Col xs={12} sm={6}>
                    <Table responsive striped hover>
                        <thead>
                        <tr>
                            <th>
                                <SortTableText sortKey="fullName" sortParams={sortParams} handleSort={sortBy}><I18n code="tournament.table.column.name"/></SortTableText>
                            </th>
                            <th><I18n code="club.event.column.answer"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            clubUsers.map(clubUser => {
                                if (!clubEvent.hasOptionResult(clubUser) && clubEvent.checkVisibilityGroup(clubUser)) {
                                    // has not answered yet but should have
                                    return (<ClubUserAnswerEntry key={"CUAE_" + clubUser.id}
                                                                 clubUser={clubUser}
                                                                 ownClubUser={ownClubUser}
                                                                 clubEvent={clubEvent}
                                                                 allowEditAnswers={allowSetAnswers}

                                    />)
                                }
                            })
                        }
                        </tbody>
                    </Table>
                </Col>

            </Row> : null
        }
    </React.Fragment>)
};
ClubEventOptions.propTypes = {
    clubEvent:PropTypes.object.isRequired,
    clubUsers: PropTypes.array,
    ownClubUser:PropTypes.object,
    sortParams: PropTypes.object,
    hasLinkSecret: PropTypes.bool.isRequired
};
const ClubEventOptionPanel = ({ownClubUser, allowEditAnswers, clubEvent, evOption}) => {
    let objectList = clubEvent.getOptionResultList(evOption);
    return (
        <Panel>
            <Panel.Heading>
                <h3>{evOption}: <Label>{objectList.length}</Label></h3>
            </Panel.Heading>
            <Panel.Body>
                <ListGroup fill>
                    {
                        objectList.map(optionRes => {
                            return <ClubEventOptionItem key={"CEOI" + optionRes["cuID"]}
                                                        optionRes={optionRes}
                                                        evOption={evOption}
                                                        ownClubUser={ownClubUser}
                                                        clubEvent={clubEvent}
                                                        allowEditAnswers={allowEditAnswers}
                            />

                        })
                    }
                </ListGroup>
            </Panel.Body>
        </Panel>)
};
ClubEventOptionPanel.propTypes = {
    clubEvent:PropTypes.object.isRequired,
    allowEditAnswers: PropTypes.bool.isRequired,
    evOption: PropTypes.string.isRequired,
    ownClubUser:PropTypes.object
};
const ClubEventOptionItem = ({clubEvent, ownClubUser, optionRes, allowEditAnswers, evOption}) => {
    const handleOptionClicked = () => {
        clubEventActions.removeClubEventOption(clubEvent, optionRes["cuID"], evOption);
    };
    let eAllowEditAnswers = allowEditAnswers;
    if (!eAllowEditAnswers && ownClubUser != null) {
        // check if his is own entry
        eAllowEditAnswers = optionRes["cuID"] === ownClubUser.id;
    }
    return <EntryRemovable  key={optionRes["name"]}
                            group={optionRes["name"]}
                            onRemove={handleOptionClicked}
                            denyRemove={!eAllowEditAnswers}/>
};
ClubEventOptionItem.propTypes = {
    clubEvent:PropTypes.object.isRequired,
    allowEditAnswers: PropTypes.bool.isRequired,
    evOption: PropTypes.string.isRequired,
    optionRes: PropTypes.string.isRequired,
    ownClubUser:PropTypes.object
};
const ClubUserAnswerEntry = ({ownClubUser, clubEvent, clubUser, allowEditAnswers}) => {
    const handleOptionClicked = (option) => {
        clubEventActions.handleClubEventOption(clubEvent, clubUser, option);
    };
    let eAllowEditAnswers = allowEditAnswers;
    if (!eAllowEditAnswers && ownClubUser != null) {
        // check if his is own entry
        eAllowEditAnswers = clubUser.id === ownClubUser.id;
    }
    return (<tr>
        <td>
            {clubUser.getFullSurName()}
        </td>
        {
            eAllowEditAnswers === true ?
                <td>
                    <ButtonGroup>
                        {
                            clubEvent.getOptions().map(option => {
                                return (<Button bsStyle="primary" key={"opt_"+option}
                                                onClick={() => {handleOptionClicked(option);}}>
                                            {option}
                                        </Button>)
                            })
                        }
                    </ButtonGroup>
                </td> : <td></td>
        }
    </tr>)
};
ClubUserAnswerEntry.propTypes = {
    clubEvent:PropTypes.object.isRequired,
    allowEditAnswers: PropTypes.bool.isRequired,
    clubUser: PropTypes.object.isRequired,
    ownClubUser:PropTypes.object
};
module.exports = ClubEventPage;
