const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Image} = require("react-bootstrap");
const messages = require("i18n/messages");

const Title = require("components/widgets/Title"),
    LinkButton = require("components/links/LinkButton"),
    Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n"),
    DateInput = require("components/form/DateInput"),
    TextCenter = require("components/widgets/TextCenter"),
    TournamentConfigAgeCalculator = require("components/tournamentregister/TournamentConfigAgeCalculator"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    FontAwesome = require("components/widgets/FontAwesome");

const licenseStore = require("stores/LicenseStore"),
    licenseActions = require("actions/LicenseActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");
const {Tournament} = require("parse/_Domain");


const rulesWA = {
    shoot: ["Schützen schießen paarweise", "Einen Meter seitlich oder hinter dem Pflock", "90 Sekunden Zeitlimit"],
    back: ["Gruppe entscheidet ob es ein Rückpraller/ Durchschuß ist", "Gruppen entscheidet auch über den Wert"],
    count: ["Anliegend - Linie zählt zum höheren Wertungsbereich","Gruppenentscheid bei strittigem Pfeil",
        "Abgelenkter Pfeil wie er steckt", "Boden-auf gilt", "Huf und Horn ist Miss"],
    touch: "Anliegend - Bei WA ein Treffer"
}
const rulesIFAA = {
    shoot: ["Max 15 cm Abstand vom Pflock", "Nicht vor dem Pflock", "Kein Zeitlimit"],
    back: ["Gekennzeichneter Pfeil kann nachgeschossen werden"],
    count: ["Durch-trennt - Linie zählt zum niedrigeren Wertungsbereich", "Target-Käptn entscheidet", "Abgelenkter Pfeil wie er steckt",
        "Boden-auf gilt nicht!", "Hufe zählen zum Wertungsbereich"],
    touch: "Nur anliegend - Bei IFAA kein Treffer"
}

const rulesAAA = {
    shoot: ["Schützen schießen einzeln", "Pflock berühren", "90 Sekunden Zeitlimit"],
    back: ["Gruppe entscheidet ob es ein Rückpraller/ Durchschuß ist", "Gruppen entscheidet auch über den Wert"],
    count: ["Boden-auf gilt nicht!", "Huf und Horn ist Miss", "Abgelenkter Pfeil wie er steckt"],
    touch: "Anliegend - Bei AAA ein Treffer"
}

const allRules = {WA: rulesWA, IFAA: rulesIFAA, AAA: rulesAAA}

class TournamentRulePage extends React.Component {
    constructor(props) {
        super(props);
        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.updateTournament = this.updateTournament.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        let tournament = new Tournament();
        tournament.setDate(new Date())
        tournament.setTournamentConfigAge([])
        this.state = {
            licenseStore: licenseStore.getState(),
            tournament: tournament,
            bowUnionCode: this.props.bowUnionCode != null ? this.props.bowUnionCode.toUpperCase() : null
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.tournamentRules");
        licenseStore.listen(this.handleLicenseChange);
        if (this.state.bowUnionCode) {
            licenseActions.loadBowUnionsAndTypes.defer(this.state.bowUnionCode);
        }
    }
    componentWillUnmount() {
        licenseStore.unlisten(this.handleLicenseChange);
    }
    handleLicenseChange(state) {
        this.setState({licenseStore: state});
        if (state.bowUnions != null && state.ageTypes != null) {
            this.updateTournament(state.bowUnions[0], state.ageTypes)
        }
    }
    updateTournament(bowUnion, ageTypes) {
        let tournament = this.state.tournament;
        tournament.setBowUnionID(bowUnion)
        tournament.setTournamentConfigAge(ageTypes)
        this.setState({tournament: tournament})
    }
    handleStartDateChange(value) {
        // TODO how to recalc age class after changing tournament date
        let tournament = this.state.tournament;
        const startDateParts = new Date(value);
        tournament.setDate(new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), 4, 0, 0, 0));
        this.setState({tournament: tournament})
    }
    render() {
        const {bowUnionCode, tournament, licenseStore} = this.state
        const {loading, bowTypes, ageTypes} = licenseStore
        return (
            <Grid>
                <Row>
                    <Col xs={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            {
                                bowUnionCode != null ? <React.Fragment>
                                        <Breadcrumb link="/tournament/rules" code="header.nav.tournamentRules"/>
                                        <Breadcrumb link="/tournament/rules" code={bowUnionCode} active/>
                                    </React.Fragment> :
                                    <Breadcrumb link="/tournament/rules" code="header.nav.tournamentRules" active/>
                            }
                        </Breadcrumbs>
                    </Col>
                </Row>
                {
                    bowUnionCode != null ? <Title code="header.nav.tournamentRules">&nbsp;{bowUnionCode}</Title>
                        : <React.Fragment>
                            <Title code="header.nav.tournamentRules"></Title>
                            <h3>Wähle einen Verband aus:</h3>
                            <LinkButton bsSize="large" label="WA" href={linkUtil.getLink("tournament/rules/WA")}/>
                            <LinkButton bsSize="large" label="IFAA" href={linkUtil.getLink("tournament/rules/IFAA")}/>
                            <LinkButton bsSize="large" label="AAA" href={linkUtil.getLink("tournament/rules/AAA")}/>
                        </React.Fragment>
                }
                {
                    bowUnionCode != null ? <Rules ruleDict={allRules[bowUnionCode]}/> : null
                }

                <hr/>
                <PointInterestBanner />
                {
                    bowUnionCode != null ? <React.Fragment>
                        <br/>
                        {
                            loading ? <Loading/> : <Row>
                                <BowAgeTypes title="tournament.class.bow" array={bowTypes} icon="bullseye"/>
                                <BowAgeTypes title="tournament.class.age" array={ageTypes} icon="book"/>
                                <Col xs={12}>
                                    <br/><br/>
                                    <TournamentConfigAgeCalculator tournament={tournament} selectCallback={null}>
                                        <Col lg={3}>
                                            <DateInput default={tournament.getDate()}
                                                       label={messages.get("tournament.create.date")}
                                                       onAfterChange={this.handleStartDateChange}/>
                                        </Col>
                                    </TournamentConfigAgeCalculator>
                                </Col>
                            </Row>
                        }
                    </React.Fragment> : null
                }


            </Grid>)
    }
}
const BowAgeTypes =({title, icon, array}) => {
    if (array != null) {
        return <Col md={6}>
            <h2><FontAwesome icon={icon}/><I18n code={title}/></h2>
            <ul>
                {
                    array.map(entry => {
                        return <li key={entry.id}>{entry.getSelectString()}</li>
                    })
                }
            </ul>
        </Col>
    }
    return null
}
const Rules = ({ruleDict}) => {
    return <Row>
        <Col md={6}>
            <RulesSection ruleDict={ruleDict} ruleSection="shoot"/>
            <RulesSection ruleDict={ruleDict} ruleSection="back"/>
            <RulesSection ruleDict={ruleDict} ruleSection="count"/>
        </Col>
        <Col md={6}>
            <Image src="/img/wiki/arrow_line.jpg" responsive rounded />
            <TextCenter><strong>{ruleDict.touch}</strong></TextCenter>
        </Col>
    </Row>
}
const RulesSection = ({ruleDict, ruleSection}) => {
    return <React.Fragment>
    <h2><FontAwesome icon="check"/><I18n code={"tournament.rules." + ruleSection}/></h2>
    <ul>
        {
            ruleDict[ruleSection].map((entry,i)=> {
                return <li key={ruleSection + "." + i}>{entry}</li>
            })
        }
    </ul></React.Fragment>
}
module.exports = TournamentRulePage;




