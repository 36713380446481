const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Grid, Row, Col, Alert, Panel, Button, Table, Well} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    messages = require("i18n/messages"),
    ValidInput = require("components/form/ValidInput"),
    LoginButton = require("components/widgets/LoginButton"),
    HRMargin = require("components/widgets/HRMargin"),
    SubmitButton = require("components/form/SubmitButton");

const Utility = require("util/Utility");

const {ParcoursVisit} = require("parse/_Domain");

const ParcoursVisitForm= require("components/parcours/ParcoursVisitForm"),
    ParcoursPaymentButton = require("components/links/ParcoursPaymentButton");

const  ParcoursLoader = require("components/parcours/ParcoursLoader");

const notificationActions = require("components/notification/NotificationActions"),
    parcoursActions = require("actions/ParcoursActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const ParcoursVisitPage = ({onlineID, visitID}) => {
    return <ParcoursLoader onlineID={onlineID} rating={false} editCheck={"F"} loginMandatory={false} fetchUserDetail={true}>
        <ParcoursDetails visitID={visitID}/>
    </ParcoursLoader>
};
ParcoursVisitPage.propTypes = {
    onlineID: PropTypes.string.isRequired,
    visitID: PropTypes.string
};
class ParcoursDetails extends React.Component {
    constructor(props) {
        super(props);
        this.refNote = React.createRef();
        this.refEndTime = React.createRef();

        this.updateParcoursVisit = this.updateParcoursVisit.bind(this);
        this.loadLiveParcoursVisit = this.loadLiveParcoursVisit.bind(this);
        this.updateLiveParcoursVisit = this.updateLiveParcoursVisit.bind(this);
        this.closeParcoursVisit = this.closeParcoursVisit.bind(this);
        this.closeVisitDetail = this.closeVisitDetail.bind(this);
        this.state = {
            parcoursVisitLoading: this.props.visitID != null,
            showVisitForm: this.props.visitID == null && this.props.parcours.hasVisitEnabled(),
            parcoursVisit: null,
            liveParcoursVisits: null
        }
    }
    componentDidMount() {
        document.title = this.props.parcours.buildNames();
        if (this.props.visitID) {
            // load parcoursVisit
            parcoursActions.loadParcoursVisit.defer(this.props.visitID, parcoursVisit => {
                this.updateParcoursVisit(parcoursVisit)
            })
        }
    }
    loadLiveParcoursVisit() {
        if (this.props.user) {
            parcoursActions.loadLiveParcoursVisit.defer(this.props.parcours, parcoursVisits => {
                this.updateLiveParcoursVisit(parcoursVisits)
            })
        }
    }
    componentWillUnmount() {
        parcoursActions.resetParcours();
    }
    updateParcoursVisit(parcoursVisit) {
        this.setState({
            showVisitForm: false,
            parcoursVisitLoading: false,
            parcoursVisit: parcoursVisit
        })
    }
    updateLiveParcoursVisit(parcoursVisits) {
        this.setState({
            liveParcoursVisits: parcoursVisits
        })
    }
    closeVisitDetail() {
        this.setState({
            showVisitForm: true,
            parcoursVisitLoading: false,
            parcoursVisit: null
        }, () => this.state.liveParcoursVisits != null ? this.loadLiveParcoursVisit() : null)
    }
    closeParcoursVisit(changeObject) {
        if (this.state.parcoursVisit != null && changeObject != null) {
            parcoursActions.updateParcoursVisitStatus.defer(this.state.parcoursVisit, changeObject, parcoursVisit => {
                this.updateParcoursVisit(parcoursVisit)
            })
        }
    }
    render() {
        const {parcours, user, userDetail, userDetailLoading, visitID} = this.props;
        const {parcoursVisit, parcoursVisitLoading, showVisitForm, liveParcoursVisits} = this.state;
        // configurable
        const parcoursOwner = parcours.getParcoursOwnerID();
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link={"/parcours/" + parcoursOwner.id + "/home"}  code={parcoursOwner.getName()}/>
                            <Breadcrumb code={parcours.getName()} active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}  style={{"textAlign": "center"}}>
                        <h2><FontAwesome icon="book-open"/><I18n code="parcours.visit.header"/></h2>
                        <p>{parcours.buildNames()}</p>
                    </Col>
                </Row>
                {
                    userDetailLoading || parcoursVisitLoading ? <Row><Col lg={12}><Loading/></Col></Row>:null
                }
                {
                    !this.props.parcours.hasVisitEnabled() ? <Alert bsStyle="warning"><strong>{messages.get("parcours.visit.disabled")}</strong></Alert> : null
                }
                {
                    showVisitForm  && !userDetailLoading ? <React.Fragment>
                        {
                            user === null ? <VisitNoUserInfo /> : null
                        }
                        <Row>
                            <ParcoursVisitForm user={user} userDetail={userDetail} address={userDetail ? userDetail.getAddressID() : null}
                                               parcours={parcours} parcoursOwner={parcours.getParcoursOwnerID()} callbackParcoursVisit={this.updateParcoursVisit}/>
                        </Row>
                        <HRMargin needRow={true}/>
                        {
                            liveParcoursVisits != null ?
                                <LiveParcoursVisits liveParcoursVisits={liveParcoursVisits} />
                                :
                                <Row><Col xs={12}>
                                    <SubmitButton bsStyle="default" block
                                                  onClick={this.loadLiveParcoursVisit}
                                                  icon={<FontAwesome icon="caret-down"/>}
                                                  text={messages.get("parcours.visit.live")}/>
                                </Col></Row>
                        }
                    </React.Fragment>: null
                }
                {
                    parcoursVisit != null ? <Row>
                        <Col md={10} mdOffset={1}>
                        <PacoursVisitDetail parcours={parcours} parcoursVisit={parcoursVisit}
                                            endTimeComponent={<VisitEndTime parcoursVisit={parcoursVisit} closeVisit={this.closeParcoursVisit}/>}
                                            />
                        {
                            visitID == null ? <Button onClick={this.closeVisitDetail}>{messages.get("modal.button.newEntry")}</Button> : null
                        }

                    </Col></Row> : null

                }
            </Grid>)
    }
}
ParcoursDetails.propTypes = {
    parcours:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    userDetail:PropTypes.object,
    userDetailLoading: PropTypes.bool
};
class VisitEndTime extends React.Component {
    constructor(props) {
        super(props);
        this.refNote = React.createRef();
        this.refEndTime = React.createRef();

        this.closeParcoursVisit = this.closeParcoursVisit.bind(this);
    }
    closeParcoursVisit() {
        if (this.refEndTime.current && this.refEndTime.current.getValue() && this.refEndTime.current.getValue().split(":").length === 2) {
            const changeObject = {};
            let visitDate = new Date();
            changeObject[ParcoursVisit.prototype.col.status] = ParcoursVisit.prototype.status.finished;

            const endTimeParts = this.refEndTime.current.getValue().split(":");
            let visitEndTime = new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), parseInt(endTimeParts[0]), parseInt(endTimeParts[1]),0,0);
            changeObject[ParcoursVisit.prototype.col.endTime] = visitEndTime;

            if (this.refNote.current.getValue() && this.refNote.current.getValue().length > 0) {
                changeObject[ParcoursVisit.prototype.col.note] = this.refNote.current.getValue().trim();
            }
            this.props.closeVisit(changeObject)
        }
    }
    render() {
        const {parcoursVisit} = this.props;
        return <Row>
            <Col sm={12}>
                <h3><I18n code="parcours.visit.not.closed"/></h3>
            </Col>
            <Col sm={6}>
                <ValidInput ref={this.refEndTime} default={Utility.getReadableTime(new Date())} type="text"
                            label={messages.get("tournament.create.endtime")}
                            addonBefore={<FontAwesome icon="clock"/>}
                            valid={{check:['isTime', 'isRequired']}}/>
            </Col>
            <Col sm={12}>
                <ValidInput ref={this.refNote} default={parcoursVisit.getNote()}
                            componentClass="textarea"
                            valid={{maxLength: 1500}}
                            placeholder={messages.get("tournament.table.column.note")}/>
            </Col>
            <Col sm={12}>
                <SubmitButton bsStyle="primary" bsSize="large" block
                              onClick={this.closeParcoursVisit}
                              icon={<FontAwesome icon="clock"/>}
                              text={messages.get("parcours.visit.button.close")}/>
            </Col>
        </Row>
    }
}
VisitEndTime.propTypes = {
    parcoursVisit: PropTypes.object.isRequired,
    closeVisit: PropTypes.func.isRequired
};
const VisitNoUserInfo = ({}) =>{
    return (<Row>
        <Col xs={12}>
            <Panel>
                <Panel.Body>
                    <h4>{messages.get("tournament.register.description.noaccount")}</h4>
                    <p>{messages.get("parcours.visit.description.getAccount")}
                        <ul>
                            <li>{messages.get("parcours.visit.description.reUse")}</li>
                            <li>{messages.get("tournament.register.description.useApp")}</li>
                        </ul>
                    </p>
                    <LoginButton/>&nbsp;&nbsp;<LoginButton create={true}/>
                </Panel.Body>
            </Panel>
        </Col>
    </Row>)
};
const PacoursVisitDetail = ({parcours, parcoursVisit, endTimeComponent}) => {
    const addArcherNames = parcoursVisit.getAddVisitorNames();
    return <React.Fragment>
        <Panel style={{lineHeight: "2.5em", fontSize: "130%"}}>
            <Panel.Heading>
                <h2>Parcoursbuch Eintrag vom {parcoursVisit.getVisitDate()}</h2>
            </Panel.Heading>
            <Panel.Body>
                <Table style={{margin: "0"}}>
                    <tbody>
                        <tr>
                            <th><FontAwesome icon="user"/><I18n code="address.contactName"/></th>
                            <td>{parcoursVisit.getFullName()}</td>
                        </tr>
                        <tr>
                            <th><FontAwesome icon="envelope"/><I18n code="address.mail"/></th>
                            <td><a href={"mailto:" + parcoursVisit.getMail()} >{parcoursVisit.getMail()}</a></td>
                        </tr>
                        {
                            parcoursVisit.getAddress() ?
                                <React.Fragment>
                                    <tr>
                                        <th><I18n code="address.street"/></th>
                                        <td>{parcoursVisit.getStreet()}</td>
                                    </tr>
                                    <tr>
                                        <th><I18n code="address.place"/></th>
                                        <td>{parcoursVisit.getZipPlace()}</td>
                                    </tr>
                                    <tr>
                                        <th><I18n code="address.phone"/></th>
                                        <td><a href={"tel:" + parcoursVisit.getPhone()} >{parcoursVisit.getPhone()}</a></td>
                                    </tr>
                                </React.Fragment> : null
                        }
                        <tr>
                            <th><FontAwesome icon="hashtag"/><I18n code="parcours.visit.amount"/></th>
                            <td>{parcoursVisit.getVisitorAmount()}</td>
                        </tr>
                        {
                            addArcherNames ? <tr>
                                <th><FontAwesome icon="users"/><I18n code="tournament.table.column.group"/></th>
                                <td>{ Object.keys(addArcherNames).map(id => {
                                    return <p>{addArcherNames[id]}</p>
                                })}</td>
                            </tr> : null
                        }
                        <tr>
                            <th><FontAwesome icon="play"/><I18n code="tournament.create.time"/></th>
                            <td>{parcoursVisit.getVisitStart()}</td>
                        </tr>
                        <tr>
                            <th><FontAwesome icon="stop"/><I18n code="tournament.create.endtime"/></th>
                            <td>{parcoursVisit.getVisitEnd()}</td>
                        </tr>
                    </tbody>
                </Table>
                {
                    parcoursVisit.getNote() ? <Well>{parcoursVisit.getNote()}</Well> : null
                }
                <hr/>
                {
                    parcoursVisit.getVisitEnd() == null ?
                        <React.Fragment>
                            {endTimeComponent}
                            <hr/>
                            <Alert bsStyle="info">
                                <h4>{messages.get("parcours.visit.pay.online")}</h4>
                                <ParcoursPaymentButton bsSize="large" parcours={parcours}/>
                            </Alert>
                        </React.Fragment>: null
                }
            </Panel.Body>
        </Panel>
    </React.Fragment>
};
PacoursVisitDetail.propTypes = {
    parcours: PropTypes.object.isRequired,
    parcoursVisit: PropTypes.object.isRequired,
    endTimeComponent: PropTypes.object.isRequired
};

const LiveParcoursVisits = ({liveParcoursVisits}) =>{

    return <Row>
        <Col lg={12}  style={{"textAlign": "center"}}>
            <h2><FontAwesome icon="book-open"/><I18n code="parcours.visit.live"/></h2>
        </Col>
        <Col xs={12}>
            <Table responsive striped hover>
                <thead>
                <tr>
                    <th><I18n code="address.contactName"/></th>
                    <th><I18n code="tournament.table.column.time"/></th>
                    <th><I18n code="tournament.table.column.amount"/></th>
                    <th><I18n code="tournament.table.column.group"/></th>
                </tr>
                </thead>
                <tbody>
                {liveParcoursVisits.map(parcoursVisit => {
                    return (<LiveVisitRow key={parcoursVisit.id} parcoursVisit={parcoursVisit}/>);
                })}
                </tbody>
            </Table>
        </Col>
    </Row>
};
LiveParcoursVisits.propTypes = {
    liveParcoursVisits: PropTypes.array.isRequired,
};
const LiveVisitRow = ({parcoursVisit}) => {
       const addArcherNames = parcoursVisit.getAddVisitorNames();
    return (
        <tr id={parcoursVisit.id}>
            <td>{parcoursVisit.getFullName()}</td>
            <td><FontAwesome icon="play"/>{parcoursVisit.getVisitStart()}</td>
            <td>{parcoursVisit.getVisitorAmount()}</td>
            <td>{addArcherNames ? <React.Fragment>
                    { Object.keys(addArcherNames).map(id => {
                        return <p>{addArcherNames[id]}</p>
                    })}
                </React.Fragment> : null}</td>
        </tr>)
};
LiveVisitRow.propTypes = {
    parcoursVisit:PropTypes.object.isRequired
};
module.exports = ParcoursVisitPage;
