const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button,Table} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    ParcoursSelection = require("components/form/ParcoursSelection"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    messages = require("i18n/messages");
const SolutionHelpLink = require("components/links/SolutionHelpLink");

const TournamentRoundModal = require("components/tournamentmodal/TournamentRoundModal"),
    TournamentConfigManipulatorModal = require("components/tournamentmodal/TournamentConfigManipulatorModal"),
    TournamentRound = require("parse/TournamentRound");

const TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader");

const tournamentActions = require("actions/TournamentManagerActions");

const TournamentMMRoundPage  = ({tournamentId}) => {
    return <TournamentMMLoader tournamentId={tournamentId}>
        <TournamentRoundHierarchy />
    </TournamentMMLoader>
};
TournamentMMRoundPage.propTypes = {
    tournamentId: PropTypes.string.isRequired
};
class TournamentRoundHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const {tournament, tournamentRoundMap} = this.props;
        const isLippek = tournament.getBowUnionID() ? tournament.getBowUnionID().isTypeLippek() : false;
        let maxQualifyRound = 3;
        let maxFinalRound = 2;
        let maxGroupRound = 1;
        let maxExtraRound = 1;
        if (tournament.isTypePairRegistration()) {
            maxQualifyRound = 0;
            maxFinalRound = 0;
        }
        return (<React.Fragment>
            <Row>
                <Col lg={12}>
                    <h1>{tournament.getName() + ", " + tournament.getTournamentDate()}</h1>
                    <SolutionHelpLink articleID="42000051444" />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <h3>{messages.get("tournament.manager.round.qualify.title")}</h3>
                    <p>{messages.get("tournament.manager.round.qualify.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.S")}: </strong>{messages.get("tournament.round.type.S.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.B")}: </strong>{messages.get("tournament.round.type.B.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.L")}: </strong>{messages.get("tournament.round.type.L.desc")}</p>
                    <br/>
                </Col>
                {
                    maxQualifyRound > 0 ?
                        <TournamentRoundDetails
                            roundTypes={isLippek === true ? TournamentRound.prototype.typeGroups.group_simple_lippek : TournamentRound.prototype.typeGroups.group_simple_best}
                            showMemberAmount={false}
                            maxRound={maxQualifyRound}
                            tournament={tournament}
                            tournamentRounds={tournamentRoundMap.qualify}/>
                        :
                        <Col lg={12}>
                            <Alert bsStyle="info">{messages.get("tournament.manager.round.noSingleRounds")}</Alert>
                        </Col>
                }
            </Row>
            <hr/>
            <Row>
                <Col lg={12}>
                    <h3>{messages.get("tournament.manager.round.final.title")}</h3>
                    <p>{messages.get("tournament.manager.round.final.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.F0")}: </strong>{messages.get("tournament.round.type.F0.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.F1")}: </strong>{messages.get("tournament.round.type.F1.desc")}</p>

                    <br/>
                </Col>
                {
                    maxFinalRound > 0 && isLippek === false ?
                        <TournamentRoundDetails
                            roundTypes={TournamentRound.prototype.typeGroups.group_finale}
                            showMemberAmount={false}
                            maxRound={maxFinalRound}
                            tournament={tournament}
                            tournamentRounds={tournamentRoundMap.finale}/>
                        :
                        <Col lg={12}>
                            <Alert bsStyle="info">{messages.get("tournament.manager.round.noSingleRounds")}</Alert>
                        </Col>
                }
            </Row>
            <hr/>
            <Row>
                <Col lg={12}>
                    <h3>{messages.get("tournament.manager.round.groups.title")}</h3>
                    <p>{messages.get("tournament.manager.round.groups.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.GR")}: </strong>{messages.get("tournament.round.type.GR.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.GL")}: </strong>{messages.get("tournament.round.type.GL.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.GT")}: </strong>{messages.get("tournament.round.type.GT.desc")}</p>
                    <br/>
                </Col>
                {
                    maxGroupRound > 0 ?
                        <TournamentRoundDetails
                            roundTypes={TournamentRound.prototype.typeGroups.group_groups}
                            showMemberAmount={true}
                            maxRound={1}
                            tournament={tournament}
                            tournamentRounds={tournamentRoundMap.group}/>
                        :
                        <Col lg={12}>
                            <Alert bsStyle="info">{messages.get("Noch nicht verfügbar")}</Alert>
                        </Col>
                }

            </Row>
            <hr/>
            <Row>
                <Col lg={12}>
                    <h3>{messages.get("tournament.manager.round.extra.title")}</h3>
                    <p>{messages.get("tournament.manager.round.extra.desc")}</p>
                    <p><strong>{messages.get("tournament.round.type.EI")}: </strong>{messages.get("tournament.round.type.EI.desc")}</p>
                </Col>
                {
                    maxExtraRound > 0 ?
                        <TournamentRoundDetails
                            roundTypes={TournamentRound.prototype.typeGroups.group_extra}
                            showMemberAmount={false}
                            maxRound={1}
                            tournament={tournament}
                            tournamentRounds={tournamentRoundMap.extra}/>
                        :
                        <Col lg={12}>
                            <Alert bsStyle="info">{messages.get("Noch nicht verfügbar")}</Alert>
                        </Col>
                }
            </Row>
        </React.Fragment>)}
}
TournamentRoundHierarchy.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentRoundMap:PropTypes.object.isRequired,
};
class TournamentRoundDetails extends React.Component {
    constructor(props) {
        super(props);
        this.refTRoundCreateModal = React.createRef();
        
        this.openTRoundModal = this.openTRoundModal.bind(this);
        this.state = {}
    }
    openTRoundModal() {
        this.refTRoundCreateModal.current.open();
    }
    render() {
        const {tournament, tournamentRounds, roundTypes, showMemberAmount, maxRound} = this.props;
        let index = 0;
        return (
                <Col lg={12}>
                    <Table>
                        <thead>
                        <tr>
                            <th className="col-md-2"><I18n code="tournament.table.column.name"/></th>
                            <th className="col-md-2"><I18n code="parcours.details.area.targetamount"/></th>
                            <th className="col-md-1"><I18n code="tournament.table.column.type"/></th>
                            <th className="col-md-2"><I18n code="tournament.table.column.status"/></th>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                        </tr>
                        </thead>
                        <tbody id="trounds-table">
                        {
                            tournamentRounds.map(tournamentRound => {
                                if (TournamentRound.prototype.status.deleted != tournamentRound.getStatus()) {
                                    index++;
                                    return <TournamentRoundPanel key={tournamentRound.id}
                                                                 tournamentRound={tournamentRound}
                                                                 tournament={tournament}
                                                                 roundTypes={roundTypes}
                                                                 showMemberAmount={showMemberAmount}
                                                                 disableType={index == 2 && roundTypes === TournamentRound.prototype.typeGroups.group_finale}/>
                                }
                            })
                        }
                        </tbody>
                    </Table>
                    <Button disabled={index >= maxRound} bsStyle="success" onClick={this.openTRoundModal}>
                        <FontAwesome icon="plus"/><I18n code="tournament.manager.round.button.add"/>
                    </Button>
                    <TournamentRoundModal ref={this.refTRoundCreateModal} tournament={tournament} index={index} roundTypes={roundTypes} showMemberAmount={showMemberAmount}/>
                </Col>)
    }
}
TournamentRoundDetails.propTypes = {
    roundTypes:PropTypes.array.isRequired,
    showMemberAmount: PropTypes.bool.isRequired,
    maxRound:PropTypes.number.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentRounds:PropTypes.array.isRequired,
};
class TournamentRoundPanel extends React.Component {
    constructor(props) {
        super(props);
        this.refTRoundEditModal = React.createRef();
        this.refTRoundManipulatorModal = React.createRef();
        
        this.editTRoundModal = this.editTRoundModal.bind(this);
        this.openTRoundManipulatorModal = this.openTRoundManipulatorModal.bind(this);
        this.deleteRound = this.deleteRound.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this)
        this.state = {

        }
    }
    editTRoundModal() {
        this.refTRoundEditModal.current.open();
    }
    openTRoundManipulatorModal() {
        this.refTRoundManipulatorModal.current.open();
    }
    deleteRound(doDelete) {
        if (doDelete) {
            this.props.tournamentRound.setStatus(TournamentRound.prototype.status.deleted);
            tournamentActions.saveTournamentRound(this.props.tournamentRound);
        }
    }
    handleParcoursChange(parcours) {
        if (parcours) {
            this.props.tournamentRound.setParcoursID(parcours)
            tournamentActions.saveTournamentRound(this.props.tournamentRound);
        }
    }
    render() {
        const {tournament, tournamentRound, roundTypes, showMemberAmount} = this.props;
        const selParcours = tournamentRound.getParcoursID() || tournament.getParcoursID()
        return (
            <tr>
                <td><strong>{tournamentRound.getName()}</strong></td>
                <td><strong>{tournamentRound.getTargetAmounts() + " " + messages.get("parcours.details.targets")}</strong></td>
                {
                    this.props.disableType ?
                        <td></td>
                        :
                        <td>{messages.get("tournament.round.type." + tournamentRound.getType())}</td>

                }
                <td>{messages.get(tournamentRound.getStatusTranslKey())}</td>
                <td><ParcoursSelection parcours={selParcours} desc={<I18n code="tournament.manager.round.parcoursSelection"/>}
                                       onAfterChange={this.handleParcoursChange}
                                       mainGroupPlace={tournament.getMainGroupPlaceID()}
                                       regionGroupPlace={tournament.getGroupPlaceID()}/></td>
                <td>
                    <Button onClick={this.editTRoundModal}><FontAwesome icon="pencil-alt"/></Button>
                    {tournamentRound.isLippek() ?
                        <Button onClick={this.openTRoundManipulatorModal}
                                bsStyle={tournamentRound.getBowConfigManipulator() == null ? "danger" : "default"}>
                            <FontAwesome icon="asterisk"/>
                        </Button> : null}
                        <DeleteDecisionModal messageCode="tournament.manager.round.button.delete.desc"
                                             warningCode={tournamentRound.isRunning() ? "tournament.manager.round.button.delete.warning" : null}
                                             entryName={tournamentRound.getName()} handleDelete={this.deleteRound}   />
                </td>
                <TournamentRoundModal ref={this.refTRoundEditModal} tournamentRound={tournamentRound} roundTypes={roundTypes} showMemberAmount={showMemberAmount}/>
                <TournamentConfigManipulatorModal ref={this.refTRoundManipulatorModal} tournamentRound={tournamentRound}
                                                  tConfigs={tournament.getTournamentConfigBow()}/>
            </tr>
        )
    }
}
TournamentRoundPanel.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentRound: PropTypes.object.isRequired,
    roundTypes: PropTypes.array.isRequired,
    showMemberAmount: PropTypes.bool.isRequired,
    disableType: PropTypes.bool
};
module.exports = TournamentMMRoundPage;
