const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert} = require("react-bootstrap");

const messages = require("i18n/messages"),
    Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const BusinessPartnerForm = require("components/business/BusinessPartnerForm"),
    ManagementDetail = require("components/business/ManagementDetail"),
    LoginButton = require("components/widgets/LoginButton");

const {BaseContext} = require("context/BaseContext");

const managementStore = require("stores/ManagementStore");
const managementActions = require("actions/ManagementActions");

class BusinessRegisterPage extends React.Component {

    constructor(props) {
        super(props);
        this.handleFormCallback = this.handleFormCallback.bind(this);
        this.handleManagementChange = this.handleManagementChange.bind(this);
        this.state = {
            managementStore: managementStore.getState()
        };
    }
    componentDidMount() {
        const { user } = this.context;
        managementStore.listen(this.handleManagementChange);
        if (this.props.clubID != null && user != null) {
            managementActions.initBusinessRegisterPage(user, this.props.clubID);
        }
    }
    componentWillUnmount() {
        managementStore.unlisten(this.handleManagementChange);
    }
    handleManagementChange(state) {
        this.setState({managementStore: state});
    }
    handleFormCallback(management) {
        if (this.state.club != null) {
            managementActions.requestClubManagement(management, this.state.club)
        } else {
            managementActions.initBusinessRegisterPageSuccess({
                management: management
            });
        }
    }
    render() {
        const { user } = this.context;
        const {club, showSpinner, management} = this.state.managementStore;
        if (showSpinner) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </Grid>)
        } else if (user == null) {
            return (<Grid><Row>
                <Col xs={12}>
                    <Alert bsStyle="warning"><I18n code="management.request.form.info.login"/> <LoginButton/></Alert>
                </Col>
            </Row></Grid>)
        } else {
            return (
                <Grid>
                    {
                        club ?
                            <Row>
                                <Col lg={12}>
                                    <Breadcrumbs>
                                        <Breadcrumb link="/" code="header.nav.home"/>
                                        <Breadcrumb link="/club" code="header.nav.clubs"/>
                                        <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                                        <Breadcrumb code="management.request.business.header" active/>
                                    </Breadcrumbs>
                                </Col>
                            </Row>
                        : null
                    }
                    <Row>
                        <Col xs={12}>
                            <h1>{messages.get("management.request.business.header")}</h1>
                            {
                                management ? null : <p>{messages.get("management.request.form.header.fillForm")}</p>
                            }
                        </Col>
                        <Col xs={12}>
                            {management ?
                                <ManagementDetail management={management}/>
                                :
                                <BusinessPartnerForm callback={this.handleFormCallback}/>
                            }
                            <br/>
                            <p>{messages.get("management.request.form.info.contact")}</p>
                        </Col>
                    </Row>
                </Grid>)
        }
    }
}
BusinessRegisterPage.contextType = BaseContext;
BusinessRegisterPage.propTypes = {
    clubID: PropTypes.string
};
module.exports = BusinessRegisterPage;
