const React = require("react");
const {Row, Col, Alert } = require("react-bootstrap");
const {BaseContext} = require("context/BaseContext");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

const RowNoUserCheck = ({children}) => {
    const { user } = React.useContext(BaseContext);
    if (user != null) {
        return children
    }
    return (<Row>
        <Col xs={12}>
            <Alert bsStyle="danger">
                <FontAwesome icon="exclamation-circle"/>
                <I18n code="tournament.register.description.noaccount"/>
            </Alert>
        </Col>
    </Row>)
};
module.exports = RowNoUserCheck;
