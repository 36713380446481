const React = require("react"),
    PropTypes = require('prop-types');
const { Button} = require("react-bootstrap");
const messages = require("i18n/messages"),
    FileSelectInput = require("components/form/FileSelectInput");

class JSONFileImporter extends React.Component {
    // https://shancarter.github.io/mr-data-converter/
    constructor(props) {
        super(props);
        this.updateReceivedArray = this.updateReceivedArray.bind(this);
        this.updateErrorArray = this.updateErrorArray.bind(this);
        this.onClickImport = this.onClickImport.bind(this);
        this.state = {
            receivedDicArray: null,
            impErrors: []
        }
    }
    updateReceivedArray(objectArray)  {
        const {mandJsonKeys} = this.props;
        if (mandJsonKeys && mandJsonKeys.length > 0) {
            let length = mandJsonKeys.length;
            let validatedLines = [];
            // validate array
            objectArray.map((objectLine, index) => {
                // validate objectLine
                let isValidated = true;
                for (let i = 0; i < length; i++) {
                    if (objectLine[mandJsonKeys[i]] == null) {
                        isValidated = false;
                        this.updateErrorArray("JObject "+ index  + ": Mandatory key missing: " + mandJsonKeys[i]);
                        break;
                    }
                }
                if (isValidated) {
                    validatedLines.push(objectLine)
                }
            });
            this.setState({
                receivedDicArray: validatedLines
            });
        } else {
            // no line validation
            this.setState({
                receivedDicArray: objectArray
            });
        }

    }
    updateErrorArray(error)  {
        let eda = this.state.impErrors;
        eda.push(error);
        this.setState({
            impErrors: eda
        });
    }
    onClickImport() {
        // TODO show progress?
        this.props.startImportArray(this.state.receivedDicArray);
    }
    render() {
        const {receivedDicArray, impErrors} = this.state;
        const {title, addElement} = this.props;
        return (<React.Fragment>
            <h2>{title}</h2>
            <p><strong>{messages.get("importer.warning")}</strong></p>
            <FileSelectInput allowedFileTypes={["J"]}
                             label={"1) "  + messages.get("importer.selectJson")}
                             receivedLineErrors={this.updateErrorArray }
                             receivedDictArray={this.updateReceivedArray} />
            {addElement}
            {
                receivedDicArray != null && receivedDicArray.length > 0 ? <Button bsStyle="primary" onClick={this.onClickImport}>{"Import " + receivedDicArray.length + " lines"}</Button> : null
            }
            <ul>
                {
                    impErrors.map(error => {
                        return <li>{error.toString()}</li>
                    })
                }
            </ul>
        </React.Fragment>)
    }
}
JSONFileImporter.propTypes = {
    title: PropTypes.string.isRequired,
    addElement: PropTypes.any,
    mandJsonKeys:PropTypes.array,
    optJsonKeys:PropTypes.array,
    startImportArray: PropTypes.func.isRequired
};

module.exports = JSONFileImporter;
