const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert} = require("react-bootstrap");
var I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    UserTransactionTable = require("components/parcoursmanage/UserTransactionTable");

const {BaseContext} = require("context/BaseContext");

var eventStore = require("stores/EventStore"),
    eventActions = require("actions/EventActions"),
    notificationActions = require("components/notification/NotificationActions");

class TabTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.handleEventChange = this.handleEventChange.bind(this);

        this.state = {
            eventStore: eventStore.getState()
        }
    }
    componentDidMount() {
        const { user } = this.context;
        eventStore.listen(this.handleEventChange);
        notificationActions.reset();
        eventActions.reset();
        eventActions.queryTransactions(user, this.props.year);
    }
    componentWillUnmount() {
        eventStore.unlisten(this.handleEventChange);
    }
    handleEventChange(state) {
        this.setState({eventStore: state});
    }
    render() {
        var body = null;
        if (this.state.eventStore.loading === true || this.state.eventStore.transactions == null) {
            body = <Loading/>;
        } else if (this.state.eventStore.loading === false && this.state.eventStore.transactions.length <= 0){
            body = <Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert>;
        } else if (this.state.eventStore.loading === false) {
            body = <Transactions transactions={this.state.eventStore.transactions}/>;
        } else {
            console.log("WTF - never should be here");
        }
        return (
            <Row>
                <Col xs={12}>
                    {body}
                </Col>
            </Row>
        )
    }
}
TabTransaction.contextType = BaseContext;
TabTransaction.propTypes = {
    year: PropTypes.number.isRequired
};
const Transactions = ({transactions}) => {
    return(<Row>
        <Col xs={12}>
            <h3><FontAwesome icon="euro-sign"/>&nbsp;{messages.get("profile.transactions.header")}</h3>
            <UserTransactionTable transactions={transactions}/>
        </Col>
    </Row>)
};
Transactions.propTypes = {
    transactions: PropTypes.array.isRequired
};
module.exports = TabTransaction;
