const React = require("react"),
    PropTypes = require('prop-types');
const {Grid } = require("react-bootstrap");
const {RoutedTabNav, RoutedTabs, RoutedTab} = require("components/widgets/RoutedTabNav");

const RowNoUserCheck = require("components/widgets/RowNoUserCheck"),
    YearPager= require("components/widgets/YearPager"),
    messages = require("i18n/messages");

class ProfileTabPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTabSelect = this.handleTabSelect.bind(this);
        // start with year from url this year
        let year = this.props.year;
        if (year == null) {
            let today = new Date();
            year = today.getFullYear();
        }
        // find selected tab from url
        let tabLink = "/profile";
        if (this.props.path != null) {
            if (this.props.path.indexOf("profile/tournaments") !== -1) {
                tabLink = "/profile/tournaments/";
            } else if (this.props.path.indexOf("profile/events") !== -1) {
                tabLink = "/profile/events/";
            } else if (this.props.path.indexOf("profile/scatter") !== -1) {
                tabLink = "/profile/scatter/";
            } else if (this.props.path.indexOf("profile/statistics") !== -1) {
                tabLink = "/profile/statistics/";
            } else if (this.props.path.indexOf("profile/invoices") !== -1) {
                tabLink = "/profile/invoices/";
            }
        }
        this.state = {
            year: year,
            tabLink: tabLink
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.profile");
    }
    handleTabSelect(tabLink) {
        this.setState({
            tabLink: tabLink
        });
    }
    render() {
        const {tabLink, year} = this.state;
        return (
            <Grid>
                {
                    tabLink === "/profile" ? <div><br/><br/><br/></div> :
                        <YearPager year={year} href={tabLink} headerText=""/>
                }
                <RowNoUserCheck>
                    <RoutedTabNav>
                        <RoutedTabs tabs pathname={this.props.path}>
                            <RoutedTab linkPath="/profile" exact
                                       linkCode="club.detail.actual"
                                       icon="newspaper"
                                       onClick={_ => this.handleTabSelect("/profile")}/>
                            <RoutedTab linkPath={"/profile/tournaments/" + year}
                                       linkCode="header.nav.tournaments"
                                       icon="trophy"
                                       onClick={_ => this.handleTabSelect("/profile/tournaments/")}/>
                            <RoutedTab linkPath={"/profile/events/" + year}
                                       linkCode="header.nav.events"
                                       icon="list"
                                       onClick={_ => this.handleTabSelect("/profile/events/")}/>
                            <RoutedTab linkPath={"/profile/scatter/" + year}
                                       linkCode="header.nav.scatter"
                                       icon="list"
                                       onClick={_ => this.handleTabSelect("/profile/scatter/")}/>
                            <RoutedTab linkPath={"/profile/statistics/" + year}
                                       linkCode="statistics.header"
                                       icon="star"
                                       onClick={_ => this.handleTabSelect("/profile/statistics/")}/>
                            <RoutedTab linkPath={"/profile/invoices/" + year}
                                       linkCode="header.nav.invoices"
                                       icon="euro-sign"
                                       onClick={_ => this.handleTabSelect("/profile/invoices/")}/>
                        </RoutedTabs>
                        <br/>
                        {this.props.children}
                    </RoutedTabNav>
                </RowNoUserCheck>
            </Grid>
        )
    }
}
module.exports = ProfileTabPage;
