/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    CheckboxInput = require("components/form/CheckboxInput"),
    RadioGroupInput = require("components/form/RadioGroupInput"),
    messages = require("i18n/messages");

const ClubUser = require("parse/ClubUser");
const clubActions = require("actions/ClubActions");

class ClubGroupRoleModal extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.clickedOK = this.clickedOK.bind(this);
        this.valid = this.valid.bind(this);
        this.clickChangeGroup = this.clickChangeGroup.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.handleArticleChange = this.handleArticleChange.bind(this);
        this.clickChangeFunction = this.clickChangeFunction.bind(this);
        this.clickChangeUserRole = this.clickChangeUserRole.bind(this);
        this.isOwner = this.isOwner.bind(this);

        this.fcts = null;
        this.grp = null;
        this.usrRole = null;
        this.state = {
            showModal: false,
            submitDisabled: false,
            club: null,
            clubUser: null,
            error: null
        }
    }
    open(clubUser) {
        this.setState({
            showModal: true,
            club: this.props.club,
            clubUser: clubUser
        });
    }
    close() {
        if (this.grp) {
            this.grp = null;
        }
        if (this.fcts) {
            this.fcts = null;
        }
        if (this.usrRole) {
            this.usrRole = null;
        }
        this.setState({submitDisabled: false, showModal: false, error: null});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    clickedOK() {
        this.close();
    }
    valid() {
        let valid = true;
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});

        if (this.grp != null) {
            // something has changed
            if(this.grp.length > 0) {
                this.state.clubUser.setGroups(this.grp);
            } else {
                this.state.clubUser.unset(ClubUser.prototype.col.groups);
            }
        }
        if (this.fcts != null ) {
            // something has changed
            if (this.fcts.length > 0) {
                this.state.clubUser.setFunctions(this.fcts);
            } else {
                this.state.clubUser.unset(ClubUser.prototype.col.functions);
            }

        }
        if (this.usrRole != null) {
            this.state.clubUser.setRole(this.usrRole);
            // update userFavorite for clubUser.user
            clubActions.updateClubUserFavorite(this.state.club, this.state.clubUser.isActiveMember(), this.state.clubUser.getUserID());

        }
        clubActions.saveClubUser(this.state.clubUser);
        this.close();
    }
    clickChangeGroup(group, value) {
        if (this.grp == null) {
            this.grp = this.state.clubUser.getGroups();
            if (this.grp == null) {
                this.grp = [];
            }
        }
        console.log("Changed " + group + " to " + value);
        if (value == true) {
            this.grp.push(group);
        } else {
            this.grp = this.grp.filter(function (u) {
                return u != group;
            });
        }
        console.log(this.grp);
    }
    handleArticleChange(article) {
        // article saved during saveClubUser
    }
    clickChangeFunction(fct, value) {
        if (this.fcts == null) {
            this.fcts = this.state.clubUser.getFunctions();
            if (this.fcts == null) {
                this.fcts = [];
            }
        }
        console.log("Changed " + fct + " to " + value);
        if (value == true) {
            this.fcts.push(fct);
            if (fct == "cl_role_user") {
                clubActions.setClubUserRole(this.state.clubUser.getUserID());
            }
        } else {
            this.fcts = this.fcts.filter(function (u) {
                return u != fct;
            });
        }
        console.log(this.fcts);
    }
    clickChangeUserRole(userRole) {
        this.usrRole = userRole;
    }
    isOwner() {
        if (this.props.ownClubUser != null) {
            return this.props.ownClubUser.hasClubOwner();
        }
        return false;
    }
    render() {
        let groups = [];
        if (this.state.club != null && this.state.club.getGroups() != null) {
            groups = this.state.club.getGroups();
        }
        let name = "";
        if (this.state.clubUser != null) {
            name = this.state.clubUser.getFullName();
        }
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n code={name}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    {
                                        this.isOwner() ?
                                            <Col xs={12}>
                                                <h4><I18n code={"club.group.modal.role"}/></h4>
                                                <RadioGroupInput default={this.state.clubUser.getRole()}
                                                                 list={[ClubUser.prototype.role.owner, ClubUser.prototype.role.member, ClubUser.prototype.role.exMember]}
                                                                 onAfterChange={this.clickChangeUserRole}/>
                                            </Col> : null
                                    }
                                    {
                                        this.isOwner() && this.state.clubUser.getUserID() != null ?
                                            <Col xs={12}>
                                                <h4><I18n code={"club.group.modal.functions"}/></h4>
                                                <CheckboxInput default={this.state.clubUser.hasFunction(ClubUser.prototype.role.userHandling)}
                                                               onAfterChange={value => {
                                                                   this.clickChangeFunction(ClubUser.prototype.role.userHandling, value);
                                                               }}
                                                               faIcon={this.state.clubUser.getFaIcon(ClubUser.prototype.role.userHandling)}
                                                               message={messages.get(ClubUser.prototype.role.userHandling)} />

                                                <CheckboxInput default={this.state.clubUser.hasFunction(ClubUser.prototype.role.messaging)}
                                                               onAfterChange={value => {
                                                                   this.clickChangeFunction(ClubUser.prototype.role.messaging, value);
                                                               }}
                                                               faIcon={this.state.clubUser.getFaIcon(ClubUser.prototype.role.messaging)}
                                                               message={messages.get(ClubUser.prototype.role.messaging)} />

                                                <CheckboxInput default={this.state.clubUser.hasFunction(ClubUser.prototype.role.invoice)}
                                                               onAfterChange={value => {
                                                                   this.clickChangeFunction(ClubUser.prototype.role.invoice, value);
                                                               }}
                                                               faIcon={this.state.clubUser.getFaIcon(ClubUser.prototype.role.invoice)}
                                                               message={messages.get(ClubUser.prototype.role.invoice)} />
                                            </Col> : null
                                    }
                                    <Col xs={12}>
                                        <h4><I18n code={"club.group.modal.existing"}/></h4>
                                            {
                                                groups.map(group => {
                                                        return <GroupEntry key={"EDT" + group}
                                                                           group={group}
                                                                           defaultValue={this.state.clubUser.hasGroup(group)}
                                                                           onChange={this.clickChangeGroup}/>
                                                    })
                                        }
                                    </Col>
                                    {this.state.error == null ? null : <Alert bsStyle="danger">{this.state.error}</Alert>}
                                    <br/><br/>
                                    <Col xs={12}>
                                        <Button onClick={this.clickedSave} disabled={this.state.submitDisabled} block bsStyle="success"><I18n code={"modal.button.save"}/></Button>
                                    </Col>
                                </Row>
                            </form>: null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}
ClubGroupRoleModal.propTypes = {
    club:PropTypes.object.isRequired,
    ownClubUser:PropTypes.object,
};
const GroupEntry = ({defaultValue, group, onChange}) => {
    function onAfterChange(value) {
        onChange(group, value)
    }
    return (
        <CheckboxInput default={defaultValue} onAfterChange={onAfterChange} message={group} />);
};
GroupEntry.propTypes = {
    defaultValue:PropTypes.bool.isRequired,
    onChange:PropTypes.func.isRequired,
    group:PropTypes.string.isRequired
};
module.exports = ClubGroupRoleModal;