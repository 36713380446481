const React = require("react"),
    PropTypes = require('prop-types');
const {Panel, Table, Label, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    LicenseSelectModal = require("components/modals/LicenseSelectModal"),
    messages = require("i18n/messages");
const {BaseContext} = require("context/BaseContext");
const licenseActions = require("actions/LicenseActions");

class ProfileLicensePanel extends React.Component {
    constructor(props) {
        super(props);
        this.refLicSelectModal = React.createRef();
        this.handleAddLicense = this.handleAddLicense.bind(this);
        this.handleEditLicense = this.handleEditLicense.bind(this);
        this.handleDeleteLicense = this.handleDeleteLicense.bind(this);
        this.state = {}
    }
    handleAddLicense() {
        this.refLicSelectModal.current.openAdd();
    }
    handleEditLicense(editUsrLic) {
        this.refLicSelectModal.current.openEdit(editUsrLic);
    }
    handleDeleteLicense(delUsrLic) {
        const { updateUserInfoState } = this.context;
        licenseActions.deleteUserBowLicense(delUsrLic, this.props.clubUser, this.props.userDetail, callbackUserInfoObject => {
            updateUserInfoState(callbackUserInfoObject)
        });
    }
    render() {
        const {userDetail, clubUser, clubUserDetail} = this.props;
        let licenseListIDs = null;
        let licFooter = <Button key="edt" onClick={this.handleAddLicense}><FontAwesome icon="plus"/>{messages.get("modal.license.add")}</Button>;
        if (clubUserDetail != null) {
            licenseListIDs = clubUserDetail.getLicenseIDs();
            // handling with clubUser
        } else if (userDetail != null) {
            licenseListIDs = userDetail.getLicenseIDs();
        } else {
            licFooter = <Alert bsStyle="warning">{messages.get("modal.license.noUserDetail")}</Alert>
        }

        return (
            <Panel>
                <Panel.Heading><I18n code="modal.license"/></Panel.Heading>
                <Panel.Body>
                    <LicenseSelectModal ref={this.refLicSelectModal} clubUser={clubUser} userDetail={userDetail}/>
                    {
                        licenseListIDs == null ?
                            <Alert bsStyle="warning">{messages.get("modal.license.empty")}</Alert>
                            :
                            <LicenseTable licenseListIDs={licenseListIDs}
                                          defaultUnion={userDetail ? userDetail.getUnion() : null}
                                          handleEditLicense={this.handleEditLicense}
                                          handleDeleteLicense={this.handleDeleteLicense}/>
                    }
                </Panel.Body>
                <Panel.Footer>{licFooter}</Panel.Footer>
            </Panel>);
    }
}
ProfileLicensePanel.contextType = BaseContext;
ProfileLicensePanel.propTypes = {
    userDetail: PropTypes.object,
    clubUser: PropTypes.object,
    clubUserDetail: PropTypes.object
};
const LicenseTable = ({licenseListIDs, defaultUnion, handleEditLicense, handleDeleteLicense}) => {
    return (<Table responsive striped hover>
        <thead>
        <tr>
            <th><I18n code="tournament.class.union"/></th>
            <th><I18n code="tournament.class.bow"/></th>
            <th><I18n code="tournament.class.age"/></th>
            <th><I18n code="modal.license"/></th>
            <th><I18n code="tournament.table.column.union"/></th>
            <th><I18n code="modal.license.validUntil"/></th>
            <th><I18n code="parcours.details.button.actions"/></th>
        </tr>
        </thead>
        <tbody>
        {
            licenseListIDs.map(licenseID => {
                return <LicenseTableEntry key={licenseID.id}
                                          license={licenseID} defaultUnion={defaultUnion}
                                          handleDeleteLicense={handleDeleteLicense}
                                          handleEditLicense={handleEditLicense}/>
            })
        }
        </tbody>
    </Table>)
};
LicenseTable.propTypes = {
    licenseListIDs: PropTypes.array,
    defaultUnion: PropTypes.string,
    handleEditLicense: PropTypes.func.isRequired,
    handleDeleteLicense: PropTypes.func.isRequired
};
const LicenseTableEntry = ({license, defaultUnion, handleEditLicense, handleDeleteLicense}) => {
    function validEndDate() {
        let validEndDate = "---";
        if (license.getValidEndDate() != null) {
            if (new Date() > license.getValidEndDate()) {
                // license not valid any more
                validEndDate = <Label bsStyle="warning">{license.getReadableValidEndDate()}</Label>
            } else {
                // license is valid
                validEndDate = license.getReadableValidEndDate();
            }

        }
        return validEndDate;
    }
    function handleEditPressed() {
        if (handleEditLicense != null) {
            handleEditLicense(license);
        }
    }
    function handleDeletePressed() {
        if (handleDeleteLicense != null) {
            handleDeleteLicense(license);
        }
    }
    return (<tr>
        <td>{license.getBowUnionCode()}</td>
        <td>{license.getBowTypeName()}</td>
        <td>{license.getAgeTypeName()}</td>
        <td>{license.getLicenseNumber()}</td>
        <td>{license.getUnion() ? license.getUnion() : defaultUnion}</td>
        <td>{validEndDate()}</td>
        <td>
            <Button onClick={handleEditPressed}><FontAwesome icon="pencil-alt"/></Button>
            <DeleteDecisionModal messageCode="list.entry.delete"  titleCode="list.entry.hdr.delete"
                                 entryName={messages.get("tournament.class.union") + ": " + license.getBowUnionCode()} handleDelete={handleDeletePressed}/>
        </td>
    </tr>)
};
LicenseTableEntry.propTypes = {
    license: PropTypes.object.isRequired,
    defaultUnion: PropTypes.string,
    handleEditLicense: PropTypes.func.isRequired,
    handleDeleteLicense: PropTypes.func.isRequired
};
module.exports = ProfileLicensePanel;
