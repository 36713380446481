const Alt = require("AltInit");
const PLUtil = require("util/ParseListUtility");
const tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions");
const tournamentUserActions = require("actions/TournamentUserActions");

class TournamentRegisterModalStore {
    constructor() {
        this.displayName = "TournamentRegisterModalStore";
        this.bindListeners({
            updateStep: tournamentRegisterModalActions.updateStep,
            updateLoading: tournamentRegisterModalActions.updateLoading,
            updateUserDetailLoading: tournamentRegisterModalActions.updateUserDetailLoading,
            openDialogForAccount: tournamentRegisterModalActions.openDialogForAccount,
            openDialogReadOnly: tournamentRegisterModalActions.openDialogReadOnly,
            openDialogForeignRegister: tournamentRegisterModalActions.openDialogForeignRegister,
            startStepSelfRegistration: tournamentRegisterModalActions.startStepSelfRegistration,
            startStepForeignRegistration: tournamentRegisterModalActions.startStepForeignRegistration,
            updateRegisteredTournamentUsers: tournamentRegisterModalActions.updateRegisteredTournamentUsers,
            addTournamentUser: tournamentRegisterModalActions.addTournamentUser,
            udpateTournamentUser: tournamentRegisterModalActions.udpateTournamentUser,
            removeTournamentUser: tournamentRegisterModalActions.removeTournamentUser,
            filterTournamentUsers: [tournamentUserActions.updateTournamentUsers, tournamentRegisterModalActions.filterOwnRegistrations],
            updateUserDetail: tournamentRegisterModalActions.updateUserDetail,
            updateCupTournamenUser: tournamentRegisterModalActions.updateCupTournamenUser,
            updateUseCupChildRegistration: tournamentRegisterModalActions.updateUseCupChildRegistration,
            initTournament: tournamentRegisterModalActions.initTournament,
            initUser: tournamentRegisterModalActions.initUser,
            updateTournamentSlots: tournamentRegisterModalActions.updateTournamentSlots,
            updateSelectedRegSlot: tournamentRegisterModalActions.updateSelectedRegSlot,
            updateUserLicenseNumber: tournamentRegisterModalActions.updateUserLicenseNumber,
            resetUserLicenseNumber: tournamentRegisterModalActions.resetUserLicenseNumber,
            updateSelectedConfigAge: tournamentRegisterModalActions.updateSelectedConfigAge,
            updateSelectedConfigBow: tournamentRegisterModalActions.updateSelectedConfigBow,
            updateSelectedOption: tournamentRegisterModalActions.updateSelectedOption,
            updateSelectedConfigBow2nd: tournamentRegisterModalActions.updateSelectedConfigBow2nd,
            closeDialog: [
                tournamentRegisterModalActions.closeDialog,
                tournamentUserActions.addTournamentUser
            ]
        });
        this.step = 1;
        this.user = null;
        this.tournament = null;
        this.bowUnion = null;
        this.show = false;
        this.selfRegistration = true;
        this.loading = true;
        this.loadingUserDetail = true;
        this.cupTournamenUser = null;
        this.cupLicenseRegister = false;
        this.registeredTournamentUsers = null;
        this.tournamentUsers = []; // registered for this tournament
        this.tournamentRegSlots = [];
        this.selectedTRegSlotId = null;
        this.userDetail = null;
        this.selectedAgeTypeID= null;
        this.selectedBowTypeID= null;
        this.selectedAgeType= null;
        this.selectedBowType= null;
        this.selectedBowType2nd= null;
        this.selectedUserLicense= null;
        this.selectedOption = null;
        this.price = 0;
        this.userLicenseUnion=null;
        this.userLicenseNumber= null;
        this.tOption=null;
    }

    updateStep(step) {
        this.step = step;
    }

    updateLoading(loading) {
        this.loading = loading;
    }

    updateUserDetailLoading(loading) {
        this.loadingUserDetail = loading;
    }

    updateUserDetail(pUserDetail) {
        let nUserLoading = false;
        this.userDetail = pUserDetail;
        if (this.tournament != null) {
            let pSelectedAgeType = this.tournament.getTournamentConfigAge()[0];
            let pSelectedBowType = this.tournament.getTournamentConfigBow()[0];
            let selectedBowType2nd = this.tournament.getTournamentConfigBow()[0];
            let pUserLicenseNumber = null;
            let pUserLicenseUnion = pUserDetail ? pUserDetail.getUnion() : null;
            let pLicense = null;
            if (pUserDetail != null && this.bowUnion != null) {
                let license =  pUserDetail.getUserBowUnionLicense(this.bowUnion);
                if (license != null) {
                    console.log("Found " + license.getBowUnionCode());
                    pLicense = license;
                    pUserLicenseNumber = license.getLicenseNumber();
                    if (license.getUnion() != null && license.getUnion().length > 0) {
                        pUserLicenseUnion = license.getUnion();
                    }
                    if (license.getBowTypeID() != null) {
                        var configBowTypes = this.tournament.getTournamentConfigBow();
                        if (configBowTypes != null) {
                            for (let cb = 0; cb < configBowTypes.length; cb++) {
                                if (configBowTypes[cb].getTypeID() === license.getBowTypeID().id) {
                                    pSelectedBowType = configBowTypes[cb];
                                    break;
                                }
                            }
                        }
                    }
                    if (license.getAgeTypeID() != null) {
                        var configAgeTypes = this.tournament.getTournamentConfigAge();
                        if (configAgeTypes != null) {
                            for (let ca = 0; ca < configAgeTypes.length; ca++) {
                                if (configAgeTypes[ca].getTypeID() === license.getAgeTypeID().id) {
                                    pSelectedAgeType = configAgeTypes[ca];
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            this.selectedAgeType= pSelectedAgeType;
            this.selectedAgeTypeID= pSelectedAgeType.id;
            this.selectedBowType= pSelectedBowType;
            this.selectedBowTypeID= pSelectedBowType.id;
            this.selectedBowType2nd= selectedBowType2nd;
            this.selectedUserLicense = pLicense;
            this.userLicenseUnion = pUserLicenseUnion;
            this.userLicenseNumber= pUserLicenseNumber;
            this.calculatePrice();
        }
        this.loadingUserDetail = nUserLoading;
    };
    calculatePrice() {
        let curr = "E";
        let sum = 0;
        const tPrice = this.tournament.getTournamentPriceID();
        if (tPrice) {
            curr = tPrice.getCurrency();
            if (parseInt(tPrice.getPrice())) {
                sum += parseInt(tPrice.getPrice());
            }
        }
        if (this.selectedAgeType) {
            if (this.selectedAgeType.getPrice()) {
                sum += this.selectedAgeType.getPrice();
            }
            let tOptionObject = this.selectedAgeType.getTOptionSurcharge();
            if (tOptionObject != null) {
                if (this.selectedOption && tOptionObject[this.tournament.id + "_" + this.selectedOption]) {
                    sum += parseInt(tOptionObject[this.tournament.id + "_" + this.selectedOption])
                } else if (this.selectedOption && tOptionObject[this.selectedOption]) {
                    sum += parseInt(tOptionObject[this.selectedOption]) // for options without tournamentId
                }
                if (this.tournament.isTypeCupChild() && tOptionObject[this.tournament.id] != null) {
                    sum += parseInt(tOptionObject[this.tournament.id])
                }
            }

        }
        if (this.selectedBowType && this.selectedBowType.getPrice()) {
            sum += this.selectedBowType.getPrice();
        }
        this.price = sum;
    }
    resetUserLicenseNumber() {
        this.userLicenseNumber = null;
        this.loadingUserDetail = true;
    }
    updateUserLicenseNumber(licenseNumber) {
        this.userLicenseNumber= licenseNumber;
        this.loadingUserDetail = false;
    }
    updateSelectedConfigAge(configAge) {
        this.selectedAgeType= configAge;
        this.selectedAgeTypeID= configAge.id;
        this.calculatePrice();
    }
    updateSelectedOption(option) {
        this.selectedOption = option;
        this.calculatePrice();
    }
    updateSelectedConfigBow(configBow) {
        this.selectedBowType= configBow;
        this.selectedBowTypeID= configBow.id;
        this.calculatePrice();
    }
    updateSelectedConfigBow2nd(configBow) {
        this.selectedBowType2nd= configBow;
    }
    filterTournamentUsers(allTournamentUsers) {
        if (this.user) {
            let ownTournamentUser = allTournamentUsers.filter(tournamentUser => {
                    return tournamentUser.isSelfRegisteredUsers(this.user);
                });
            this.updateOwnTournamentUsers(ownTournamentUser);
        }
    }
    updateOwnTournamentUsers(tournamentUsers) {
        this.loading = false;
        if (tournamentUsers.length > 0) {
            // go to overview step
            this.step = 2;
        } else {
            this.step = 1;
            this.selfRegistration = true;
        }
        this.tournamentUsers = tournamentUsers;
    }

    udpateTournamentUser(tournamentUser) {
        PLUtil.updateOrPush(this.tournamentUsers, tournamentUser)
        this.updateOwnTournamentUsers(this.tournamentUsers)
    }

    addTournamentUser(tournamentUser) {
        this.updateOwnTournamentUsers(this.tournamentUsers.concat(tournamentUser));
    }
    removeTournamentUser(tournamentUser) {
        this.updateOwnTournamentUsers(
            this.tournamentUsers.filter(function (u) {
                return u.id != tournamentUser.id;
            })
        );
    }
    updateTournamentSlots(tSlots) {
        this.tournamentRegSlots = tSlots;
    };
    updateSelectedRegSlot(regSlotId) {
        this.selectedTRegSlotId = regSlotId;
    }
    initUser(user) {
        this.user = user;
    }
    initTournament(pTournament) {
        this.loading = true;
        this.tournament = pTournament;
        this.cupLicenseRegister = pTournament.isTypeCupChild();
        this.bowUnion = pTournament.getBowUnionID();
        if (pTournament.getTournamentOptionID() != null && pTournament.getTournamentOptionID().hasOptions()) {
            this.tOption = pTournament.getTournamentOptionID();
            // this.selectedOption = this.tOption.getOptions()[0];
            this.selectedOption = null;
        } else {
            this.tOption = null;
            this.selectedOption = null;
        }
    }

    openDialogForAccount() {
        this.show = true;
        this.selfRegistration = true;
    }

    openDialogReadOnly() {
        this.show = true;
        this.loading = false;
        this.selfRegistration = true;
    }

    openDialogForeignRegister() {
        this.show = true;
        this.loading = false;
        this.selfRegistration = false;
    }

    startStepSelfRegistration() {
        this.step = 1;
        // this.loading = true;
        this.selfRegistration = true;
        if (this.tournamentUsers.length > 0) {
            // go to overview step
            this.step = 2;
        }
    }

    startStepForeignRegistration() {
        this.step = 1;
        this.selfRegistration = false;
    }

    updateRegisteredTournamentUsers(tournamentUsers) {
        this.registeredTournamentUsers = tournamentUsers;
    }

    updateUseCupChildRegistration(use) {
        this.cupLicenseRegister = use
    }

    updateCupTournamenUser(pCupTournamenUser) {
        this.cupTournamenUser = pCupTournamenUser;
        if (pCupTournamenUser) {
            // only advise if config - TypeId is the same
            let fittingTConfigBow = this.tournament.findFittingTournamentConfigBow(pCupTournamenUser);
            if (fittingTConfigBow != null) {
                this.selectedBowType= fittingTConfigBow;
                this.selectedBowTypeID= fittingTConfigBow.id;
            }
            let fittingTConfigAge = this.tournament.findFittingTournamentConfigAge(pCupTournamenUser);
            if (fittingTConfigAge != null) {
                this.selectedAgeType= fittingTConfigAge;
                this.selectedAgeTypeID= fittingTConfigAge.id;
            }
            this.userLicenseNumber = pCupTournamenUser.getLicenseNumber();
            this.userLicenseUnion = pCupTournamenUser.getUnion();
        } else {
            this.selectedAgeType= this.tournament.getTournamentConfigAge()[0];
            this.selectedAgeTypeID= null;
            this.selectedBowType= this.tournament.getTournamentConfigBow()[0];
            this.selectedBowTypeID= null;
            this.selectedUserLicense = null;
            this.userLicenseNumber = null;
            this.userLicenseUnion = null;
            if (this.bowUnion != null && this.bowUnion.isAutoLicenseNumber()) {
                this.loadingUserDetail = true; // loading need to be set by bow union number
                tournamentRegisterModalActions.getNextBowUnionNumber.defer(this.bowUnion.getCode())
            }
        }
        this.calculatePrice();
    }

    closeDialog() {
        console.log("close Dialog");
        this.show = false;
    }
}

module.exports = Alt.createStore(TournamentRegisterModalStore);
