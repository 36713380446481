const React = require("react"),
    PropTypes = require('prop-types');
const linkUtil = require("linkUtil");
const {Row, Col, ButtonGroup, DropdownButton, ProgressBar, MenuItem} = require("react-bootstrap");

const {TournamentUser} = require("parse/_Domain");

const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    SplitButtonGroup = require("components/form/SplitButtonGroup"),
    TooltipButton  = require("components/form/TooltipButton"),
    MailAdvancedModal = require("components/modals/MailAdvancedModal"),
    CheckboxInput = require("components/form/CheckboxInput"),
    LinkButton = require("components/links/LinkButton"),
    messages = require("i18n/messages");
const SearchTable = require("components/form/SearchTable"),
    SortTableText = require("components/form/SortTableText");

const TournamentUserManagerStatus = require("components/tournamentmanage/TournamentUserManagerStatus"),
    TournamentUserGroupManagerStatus = require("components/tournamentmanage/TournamentUserGroupManagerStatus"),
    TournamentUserFilterModal = require("components/tournamentmodal/TournamentUserFilterModal"),
    TournamentScoreModal = require("components/tournamentmodal/TournamentScoreModal"),
    TournamentMMTUserModal = require("components/tournamentmodal/TournamentMMTUserModal");

const tournamentUserManagerActions = require("actions/TournamentUserManagerActions"),
    changeLogActions = require("actions/ChangeLogActions");

const tableAction = require("actions/TableActions"),
    tableStore = require("stores/TableStore");

const _tuStatusList = ["R", "A", "S", "P", "F", "W", "X" , "D"];

class TournamentUserManagerTable extends React.Component {
    constructor(props) {
        super(props);
        const columns = {};
        //Spalten ein/ausblenden: Punkte - Referenz - bezahlt - Option - Lizenz
        columns["sumPoints"] = false;
        columns["dps"] = false;
        columns["ref"] = false;
        columns["paid"] = true;
        columns["option"] = this.props.tournament.getTournamentOptionID() != null;
        columns["licenseNumber"] = this.props.tournament.isLicenseMandatory();
        this.changedVisibility = this.changedVisibility.bind(this);
        this.buildHeaderLeft = this.buildHeaderLeft.bind(this);
        this.buildCustFilter = this.buildCustFilter.bind(this);
        this.buildRow = this.buildRow.bind(this);
        this.handleTRowClicked = this.handleTRowClicked.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.state = {
            visColumns: columns,
            tableStore: tableStore.state
        }
    }
    componentDidMount() {
        tableStore.listen(this.handleTableChange);
    }
    componentWillUnmount() {
        tableStore.unlisten(this.handleTableChange);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.mmtuMode !== this.props.mmtuMode) {
            // switch COL paid and points depending on mmtUMode R and A
            // console.log("MODE changed to: " + this.props.mmtuMode);
            let vis = this.state.visColumns;
            if (this.props.mmtuMode === "A") {
                vis.sumPoints = true;
                vis.dps = this.props.tournament.getBowUnionID() != null ? this.props.tournament.getBowUnionID().isShowDPS() : false;
                vis.paid = false;
            } else if (this.props.mmtuMode === "R"){
                vis.sumPoints = false;
                vis.dps = false;
                vis.paid = true;
            }
            this.setState({visColumns: vis});
        }
    }
    handleTableChange(state) {
        this.setState({tableStore: state});
    }
    changedVisibility(colCode, value) {
        let vLng = this.state.visColumns;
        vLng[colCode] = value;
        this.setState({visColumns: vLng});
    }
    handleTRowClicked(event, entry, selArray) {
        // TODO ???
    }
    buildRow(tournamentUser) {
        return <TUserManagerRow key={tournamentUser.id}
                                tournamentUser={tournamentUser}
                                visColumns={this.state.visColumns}/>
    }
    buildHeaderLeft(searchParams) {
        return <React.Fragment>
            <h4><strong><I18n code="tournament.attendees.panel.title"/></strong></h4>
            <CheckboxInput default={!this.props.tournamentUserStore.hideDeleted} onAfterChange={value => {
                tournamentUserManagerActions.updateHideDeleted(!value);
                tournamentUserManagerActions.loadTournamentUsers.defer(this.props.tournament, !value);
                tournamentUserManagerActions.loadTournamentUserGroups(this.props.tournament, !value);
                tournamentUserManagerActions.loadTournamentUserTeams(this.props.tournament, !value);
            }}
                           message={messages.get("tournament.mmtuser.showdeleted")}/>
        </React.Fragment>;
    }
    buildCustFilter(searchParams) {
        return <React.Fragment>
            <TournamentUserFilterModal tournament={this.props.tournament} tournamentRegSlots={this.props.tournamentRegSlots}
                                       searchParams={searchParams} searchParamCallback={this.updateSearchParams}
                                       showStatus={true}/>
            <LinkButton label="tournament.result.panel.print.full" bsStyle="default" symbol={<FontAwesome icon="print"/>}
                        href={linkUtil.getLink("tournament/"+ this.props.tournament.id)}/>
        </React.Fragment>
    }
    updateSearchParams(searchParams) {
        tableAction.searchObject(searchParams);
    }
    render() {
        const {visColumns, tableStore} = this.state;
        const {tournament, tournamentUserStore, user, singleStartedTRound, tournamentRoundsAll, tournamentRegSlots, mmtuMode, needTeamButton} = this.props;
        const groupingMode = mmtuMode == "G";
        return <Col xs={12} md={groupingMode ? 8 : 12}>
                <SearchTable searchFieldTypeAhead={false}
                             tHead={<TableHeader tournament={tournament} visColumns={visColumns} sortParams={tableStore.sortParams}/>}
                             topTableArea={<TopTableArea tournament={tournament} tournamentUserStore={tournamentUserStore} user={user}
                                                         singleStartedTRound={singleStartedTRound}
                                                         tournamentRegSlots={tournamentRegSlots}
                                                         tournamentRoundsAll={tournamentRoundsAll}
                                                         selectedObjectList={tableStore.selectedObjectList}
                                                         filteredObjectList={tableStore.objectList}
                                                         allObjectList={tableStore.allObjectList }
                                                         groupingMode={groupingMode} needTeamButton={needTeamButton}/>}
                             tRow={(listEntry) => this.buildRow(listEntry)}
                             tRowClicked={this.handleTRowClicked}
                             custHeaderLeftFunction={this.buildHeaderLeft}
                             custFilterFunction={this.buildCustFilter}/>
            </Col>
    }
};
TournamentUserManagerTable.propTypes = {
    tournamentUserStore: PropTypes.object.isRequired,
    tournament: PropTypes.object.isRequired,
    tournamentRoundsAll: PropTypes.array,
    singleStartedTRound :PropTypes.object,
    tournamentRegSlots: PropTypes.array,
    user: PropTypes.object.isRequired,
    mmtuMode: PropTypes.string.isRequired,
    needTeamButton: PropTypes.bool.isRequired
};
const TableHeader = ({tournament, sortParams, visColumns}) => {
    function handleTableSort(sortKey, direction) {
        tableAction.updateSortParams(sortKey, direction)
    }
    return <thead>
    <tr>
        <th>
            <SortTableText sortKey="name" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.register.name"/></SortTableText>
            <SortTableText sortKey="surname" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.register.surname"/></SortTableText><br/>
            <SortTableText sortKey="union" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.union"/></SortTableText>
        </th>
        <th></th>
        <th>
            <SortTableText sortKey="status" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.status"/></SortTableText><br/>
            <SortTableText sortKey="updatedAt" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.updatedAt"/></SortTableText>
        </th>
        <th><I18n code="tournament.class.bow"/><br/><I18n code="tournament.class.age"/></th>
        <th><SortTableText sortKey="group" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.group"/></SortTableText></th>
        {
            Object.keys(visColumns).map(colCode => {
                if (visColumns[colCode]) {
                    if (colCode == "option" && tournament.getTournamentOptionID() != null) {
                        return <th key={"col_" + colCode}>{tournament.getTournamentOptionID().getOptionLabel()}</th>
                    }
                    return <th key={"col_" + colCode}><I18n code={"tournament.table.column." + colCode}/></th>
                }
            })
        }
    </tr>
    </thead>
}
const TopTableArea = ({user, tournament, tournamentRegSlots, tournamentUserStore, singleStartedTRound, tournamentRoundsAll, selectedObjectList, allObjectList, filteredObjectList, groupingMode, needTeamButton }) => {
    const selTUserLength = selectedObjectList.length;
    const changeStatus = (status) => {
        for (let i = 0; i < selTUserLength; i++) {
            let tUser = selectedObjectList[i];
            let oldStatus = tUser.getStatus();
            tUser.setStatus(status);
            if (TournamentUser.prototype.status.deleted === status) {
                // remove group on delete entry
                tUser.unset(TournamentUser.prototype.col.tournamentGroupID);
                // remove slot on delete entry
                if (tUser.getTournamentRegSlotID() != null) {
                    const slotId = tUser.getTournamentRegSlotID().id;
                    tUser.unset(TournamentUser.prototype.col.tRegSlotID);
                    tournamentUserManagerActions.recountTournamentRegSlot(slotId)
                }
                // log delete
                changeLogActions.addTournamentUserDeleteCL(tournament, tUser, user, oldStatus);
            }
            tournamentUserManagerActions.saveTournamentUser(tUser, null);
        }
        tableAction.resetSelectedObjectList()
    };
    const changePayment = (status) => {
        for (let i = 0; i < selTUserLength; i++) {
            let tUser = selectedObjectList[i];
            if ("paid" === status) {
                tUser.setPayment(true);
            } else if ("regpaid" === status) {
                tUser.setPayment(true);
                tUser.setStatus(TournamentUser.prototype.status.present);
            } else if ("sppaid" === status) {
                tUser.setPayment(true);
                let newSum = tUser.getPayAmount() + tournament.getTournamentPriceID().getSurprice();
                tUser.setPayAmount(newSum);
            } else if ("spregpaid" === status) {
                tUser.setPayment(true);
                tUser.setStatus(TournamentUser.prototype.status.present);
                let newSum = tUser.getPayAmount() + tournament.getTournamentPriceID().getSurprice();
                tUser.setPayAmount(newSum);
            } else if ("freepaid" === status) {
                tUser.setPayment(true);
                tUser.setPayAmount(0);
            } else {
                tUser.setPayment(false);
            }

            tournamentUserManagerActions.saveTournamentUser(tUser, null);
        }
        tableAction.resetSelectedObjectList()
    };
    const reloadTUsers = () => {
        tournamentUserManagerActions.loadTournamentUsers.defer(tournament, tournamentUserStore.hideDeleted)
        tableAction.resetSelectedObjectList()
    };
    const exportTUser = () => {
        tournamentUserManagerActions.exportTUser(filteredObjectList);
    };
    const createGroup = () => {
        tournamentUserManagerActions.createTournamentGroup(tournament, selectedObjectList, singleStartedTRound, tournamentUserStore.tournamentUserGroups);
        tableAction.resetSelectedObjectList()
    };
    const createTeam = () => {
        tournamentUserManagerActions.createTournamentTeam(tournament, selectedObjectList, singleStartedTRound, tournamentUserStore.tournamentUserTeams);
        tableAction.resetSelectedObjectList()
    };
    const removeFromGroup = () => {
        tournamentUserManagerActions.removeFromTournamentGroup(tournament, selectedObjectList);
        tableAction.resetSelectedObjectList()
    }
    // TODO reset selected array on start/click search
    return (<React.Fragment>
            <Row style={{position:"sticky", top: "55px", padding:"10px", background: "aliceblue"}}>
            <Col xs={3} sm={4} md={groupingMode ? 3 : 2}>
                <p>{messages.get("tournament.mmtuser.action.single")}</p>
                <ButtonGroup>
                    <TournamentMMTUserModal tournament={tournament} tournamentRegSlots={tournamentRegSlots}
                                            selectedObjectList={selectedObjectList} selTUserLength={selTUserLength}/>
                    <TournamentScoreModal tournament={tournament} selectedObjectList={selectedObjectList} selTUserLength={selTUserLength}
                        tournamentRoundsAll={tournamentRoundsAll} singleStartedTRound={singleStartedTRound}/>
                </ButtonGroup>
            </Col>
            <Col xs={6} sm={4} md={groupingMode ? 5 : 4}>
                <p>{messages.get("tournament.mmtuser.action.several") + " (" + selTUserLength + ") ausgewählt"}</p>
                <ButtonGroup>
                    <TournamentUserPayButtons changeStatus={changePayment} tPrice={tournament.getTournamentPriceID()}/>
                    <TournamentUserStatusButtons changeStatus={changeStatus} defaultStatus={"R"}/>
                    <TournamentUserStatusButtons changeStatus={changeStatus} defaultStatus={"A"}/>

                    <TournamentGroupButtons disabled={tournamentUserStore.tournamentUserGroups == null || tournament.isTypeCupMaster()}
                                            createGroup={createGroup} removeFromGroup={removeFromGroup}/>
                    {
                        needTeamButton ?  <TooltipButton onClick={createTeam} disabled={tournamentUserStore.tournamentUserTeams == null}
                                                         icon="user-friends" tooltip={messages.get("tournament.mmtuser.tooltip.team.new")} /> : null
                    }

                </ButtonGroup>
            </Col>
            <Col xs={3} sm={2} md={groupingMode ? 3 : 2}>
                <p>{messages.get("tournament.mmtuser.action.all")}</p>
                <ButtonGroup>
                    <TooltipButton onClick={reloadTUsers} icon="sync" tooltip={messages.get("tournament.mmtuser.tooltip.sync")} />
                    <TooltipButton onClick={exportTUser} icon="download" tooltip={messages.get("tournament.mmtuser.tooltip.download")} />
                    <MailAdvancedModal senderMail={tournament.getTournamentPriceID() ? tournament.getTournamentPriceID().getEmail() : user.getEmail()}
                                       tournamentUsers={filteredObjectList} tournament={tournament} tooltip={messages.get("tournament.mmtuser.tooltip.mail")}/>
                </ButtonGroup>
            </Col>
        </Row>
        <br/><TournamentUserStatusProgressBar allTournamentUsers={allObjectList}/>
    </React.Fragment>)
};
TopTableArea.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUserStore: PropTypes.object.isRequired,
    singleStartedTRound :PropTypes.object,
    tournamentRoundsAll: PropTypes.array,
    tournamentRegSlots: PropTypes.array,
    selectedObjectList: PropTypes.array.isRequired,
    groupingMode: PropTypes.bool.isRequired,
    filteredObjectList: PropTypes.array.isRequired,
    allObjectList: PropTypes.array.isRequired,
    needTeamButton: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
};
const TournamentUserStatusButtons = ({changeStatus, defaultStatus}) => {
    const createEntries = () => {
        return _tuStatusList.map(status => {
            return {status: status,
                icon: messages.get("tournament.mmtuser.icon." + status),
                tooltip:messages.get("tournament.mmtuser.tooltip." + status)
            }
        });
    };
    return <SplitButtonGroup onClickButton={changeStatus}
                            default={{status: defaultStatus,
                                icon: messages.get("tournament.mmtuser.icon." + defaultStatus),
                                tooltip:messages.get("tournament.mmtuser.tooltip."  + defaultStatus)}}
                            dropDownEntries={createEntries()}
    />
};
TournamentUserStatusButtons.propTypes = {
    changeStatus: PropTypes.func.isRequired,
    defaultStatus: PropTypes.string.isRequired
};
const TournamentUserPayButtons = ({changeStatus, tPrice}) => {
    const createEntries = () =>  {
        if (tPrice && tPrice.getSurprice() > 0) {
            return [
                {status: "paid", icon: "euro-sign", tooltip:messages.get("tournament.mmtuser.tooltip.paid")},
                {status: "regpaid", icon: "check-double", tooltip:messages.get("tournament.mmtuser.tooltip.regpaid")},
                {status: "sppaid", icon: "money-bill-wave", tooltip:messages.get("tournament.mmtuser.tooltip.sppaid") + " (+" + tPrice.getSurprice() + ")"},
                {status: "spregpaid", icon: "money-bill-wave", tooltip:messages.get("tournament.mmtuser.tooltip.spregpaid") + " (+" + tPrice.getSurprice() + ")"},
                {status: "freepaid", icon: ["fab","creative-commons-zero"], tooltip:messages.get("tournament.mmtuser.tooltip.freepaid")},
                {status: "unpaid", icon: ["fab","creative-commons-nc-eu"], tooltip:messages.get("tournament.mmtuser.tooltip.unpaid")}
            ];
        } else {
            return [
                {status: "paid", icon: "euro-sign", tooltip:messages.get("tournament.mmtuser.tooltip.paid")},
                {status: "regpaid", icon: "check-double", tooltip:messages.get("tournament.mmtuser.tooltip.regpaid")},
                {status: "freepaid", icon: ["fab","creative-commons-zero"], tooltip:messages.get("tournament.mmtuser.tooltip.freepaid")},
                {status: "unpaid", icon: ["fab","creative-commons-nc-eu"], tooltip:messages.get("tournament.mmtuser.tooltip.unpaid")}
            ];
        }
    };
    return <SplitButtonGroup onClickButton={changeStatus}
                            default={{status: "paid",
                                icon: "euro-sign",
                                tooltip:messages.get("tournament.mmtuser.tooltip.paid")}}
                            dropDownEntries={createEntries()}
    />
};
const TournamentGroupButtons = ({disabled, createGroup, removeFromGroup}) => {
    return <ButtonGroup>
        <TooltipButton onClick={createGroup} disabled={disabled}
                       icon="users" tooltip={messages.get("tournament.mmtuser.tooltip.group.new")} />

        <DropdownButton as={ButtonGroup} id="bg-nested-dropdown">
            <MenuItem key={1} eventKey={1} onClick={removeFromGroup} disabled={disabled}>
                <FontAwesome icon="ban"/>{messages.get("tournament.mmtuser.tooltip.group.remUser")}</MenuItem>
        </DropdownButton>
    </ButtonGroup>
}
TournamentUserPayButtons.propTypes = {
    changeStatus: PropTypes.func.isRequired,
    tPrice: PropTypes.object
};
const TournamentUserStatusProgressBar = ({allTournamentUsers}) => {
    const allStat = allTournamentUsers.length;
    if (allStat <= 0) {
        return null;
    }
    let statusCounter = {R:0, A:0, S:0, X:0, L:0, C:0, F:0, P:0, D:0, W:0};
    allTournamentUsers.map(tUser => {
        statusCounter[tUser.getStatus()]++;
    });
    let statR = 100 * statusCounter.R / allStat;
    let statA = 100 * statusCounter.A / allStat;
    let statS = 100 * statusCounter.S / allStat;
    let statP = 100 * statusCounter.P / allStat;
    let statW = 100 * statusCounter.W / allStat;
    let statC = 100 * (statusCounter.C + statusCounter.X + statusCounter.F + statusCounter.L) / allStat;
    return <ProgressBar>
        <ProgressBar bsStyle="warning" now={statR} key="ProgBarR" label={messages.get("tournamentUser.status.registered")} />
        <ProgressBar bsStyle="success" now={statA} key="ProgBarA" label={messages.get("tournamentUser.status.A")} />
        <ProgressBar bsStyle="info" now={statS} key="ProgBarS" label={messages.get("tournamentUser.status.S")} />
        <ProgressBar bsStyle="primary" striped now={statP} key="ProgBarP" label={messages.get("tournamentUser.status.proof")} />
        <ProgressBar now={statC} key="ProgBarF" label={messages.get("tournament.status.finished")} />
        <ProgressBar bsStyle="warning" striped now={statW} key="ProgBarW" label={messages.get("tournamentUser.status.waiting")} />
    </ProgressBar>
};
TournamentUserStatusProgressBar.propTypes = {
    allTournamentUsers: PropTypes.array.isRequired
};
const TUserManagerRow = ({tournamentUser, visColumns}) => {
    return (
        <React.Fragment>
                <td>{tournamentUser.getFullName()}&nbsp;
                    {tournamentUser.getNote() ? <FontAwesome icon="info-circle" title={tournamentUser.getNote()}/> : null }
                    <br/>&nbsp;{tournamentUser.getUnion()}</td>
                <td><FontAwesome icon={tournamentUser.getSexIcon()} size="2x"/></td>
                <td><TournamentUserManagerStatus tournamentUser={tournamentUser} addText={true}/></td>
                <td>{tournamentUser.getTournamentConfigBowCodeName()}<br/>{tournamentUser.getTournamentConfigAgeCodeName()}</td>
                <td><TournamentUserGroupManagerStatus tournamentUserGroup={tournamentUser.getTournamentGroupID()}
                                                      addText={tournamentUser.getGroupColText() + tournamentUser.getGroupKeyForUser()} /></td>
                {
                    Object.keys(visColumns).map(colCode => {
                        if (visColumns[colCode]) {
                            let body;
                            if (colCode === "paid") {
                                body = <span><FontAwesome icon={tournamentUser.getPaidIcon()} color={tournamentUser.isPaid() ? "" : "red"}>{tournamentUser.getPayAmount()}</FontAwesome>&nbsp;&nbsp;&nbsp;
                                    <FontAwesome icon="calendar-alt" color={tournamentUser.isPaid() ? "" : "red"}>{tournamentUser.getReadablePayDate()}</FontAwesome>
                                    <br/>{tournamentUser.getPayRefID()}</span>
                            } else if (colCode === "dps") {
                                let maxDPS = parseInt(tournamentUser.getTournamentConfigAge().getCode()) + 0.4;
                                let userDPS = tournamentUser.getTUserDPS();
                                if (userDPS > maxDPS) {
                                    body = <FontAwesome icon="exclamation-triangle"
                                                        color={"red"}><strong>{userDPS}</strong></FontAwesome>
                                } else {
                                    body = userDPS;
                                }
                            } else if (colCode === "option") {
                                body = <span>{tournamentUser.getOption()}<br/>{tournamentUser.getTournamentRegSlotName()}</span>
                            } else if (colCode === "sumPoints") {
                                if (tournamentUser.getTournamentRoundUserIDs() != null && tournamentUser.getTournamentRoundUserIDs().length > 1) {
                                    // show more results
                                    body = <span>{tournamentUser.getSumPoints()}<br/>
                                        {
                                            tournamentUser.getTournamentRoundUserIDs().map(tRoundUserID => {
                                                return tRoundUserID.getRoundPoints() + ","
                                            })
                                        }
                                    </span>
                                } else {
                                    // single result
                                    body = tournamentUser.get(colCode);
                                }
                            } else {
                                body = tournamentUser.get(colCode);
                            }
                            return <td key={colCode + "_" + tournamentUser.id}>
                                    {body}
                                </td>
                        }
                    })
                }
        </React.Fragment>
    )
};
TUserManagerRow.propTypes = {
    tournamentUser: PropTypes.object.isRequired,
    visColumns: PropTypes.object.isRequired
};
module.exports = TournamentUserManagerTable;
