const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col,Grid, Table, FormControl, Alert, ButtonGroup, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ClubLoader= require("components/club/ClubLoader"),
    ClubAccountingStat= require("components/clubaccounting/ClubAccountingStat"),
    ClubInvoiceModal= require("components/clubmodal/ClubInvoiceModal"),
    YearPager= require("components/widgets/YearPager"),
    DecisionModal= require("components/modals/DecisionModal"),
    ClubInvoiceButton= require("components/links/ClubInvoiceButton"),
    TypeListDefinitionModal = require("components/modals/TypeListDefinitionModal"),
    TooltipButton  = require("components/form/TooltipButton"),
    messages = require("i18n/messages");

const clubInvoiceStore = require("stores/ClubInvoiceStore"),
    clubInvoiceActions = require("actions/ClubInvoiceActions"),
    clubActions = require("actions/ClubActions");
const ClubUser = require("parse/ClubUser");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubAccountingPage extends React.Component {
    render() {
        // Club details will be loaded as soon as club and clubUsers will be loaded
        return <ClubLoader clubID={this.props.clubID} clubUserRole={ClubUser.prototype.role.invoice} loadClubUsers={false} loginMandatory={true}>
            <ClubAccounting />
        </ClubLoader>
    }
}
ClubAccountingPage.propTypes = {
    clubID: PropTypes.string.isRequired
};
const timeoutLock = {
    num: 0,
    getNext() {
        this.num++;
        return this.num;
    }
};
class ClubAccounting extends React.Component {
    constructor(props) {
        super(props);
        this.handleClubChange = this.handleClubChange.bind(this);
        this.handleChangedDate = this.handleChangedDate.bind(this);

        this.state = {
            clubInvoiceStore: clubInvoiceStore.getState()
        }
    }
    componentDidMount() {
        clubInvoiceStore.listen(this.handleClubChange);
        clubInvoiceActions.loadClubInvoices.defer(this.props.club, this.state.clubInvoiceStore.year);
    }
    componentWillUnmount() {
        clubInvoiceStore.unlisten(this.handleClubChange);
    }
    handleClubChange(state) {
        this.setState({clubInvoiceStore: state});
    }
    handleChangedDate(year) {
        clubInvoiceActions.reloadInvoices();
        clubInvoiceActions.loadClubInvoices(this.props.club, year);
    }
    render() {
        const {user, club, isSKillBoardClub} = this.props;
        return (<Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/club" code="header.nav.clubs"/>
                        <Breadcrumb link={"/club/" + club.id}
                                    code={club.getName()}/>
                        <Breadcrumb code="cl_role_invoice" active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <YearPager year={this.state.clubInvoiceStore.year} handleChangedDate={this.handleChangedDate}
                               headerText={messages.get("club.invoice.header")}/>
                </Col>
            </Row>
            <ClubInvoiceTable user={user} isSKillBoardClub={isSKillBoardClub}
                              year={this.state.clubInvoiceStore.year}
                              club={club}
                              loading={this.state.clubInvoiceStore.loading}
                              invoices={this.state.clubInvoiceStore.clubInvoices}/>
            <ClubAccountingStat loading={this.state.clubInvoiceStore.loading}
                                invoiceStat={this.state.clubInvoiceStore.invoiceStat}/>
        </Grid>);
    }
}
ClubAccounting.propTypes = {
    club: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};
class ClubInvoiceTable extends React.Component {
    constructor(props) {
        super(props);
        this.refClubInvoiceModal = React.createRef();
        this.refDelClubInvoiceModal = React.createRef();
        this.refPaidClubInvoiceModal = React.createRef();
        this.refInvTypeDefModal = React.createRef();

        this.handleModal = this.handleModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleMarkAsPaid = this.handleMarkAsPaid.bind(this);
        this.exportClubInvoices = this.exportClubInvoices.bind(this);
        this.openInvoiceCreateModal = this.openInvoiceCreateModal.bind(this);
        this.addTypeFunction = this.addTypeFunction.bind(this);
        this.removeTypeFunction = this.removeTypeFunction.bind(this);
        this.openTypeDefinitions = this.openTypeDefinitions.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);

        this.invoice = null;
        this.state = {
            searchText: "",
            searchType: null
        }
    }
    handleModal(modalType, invoice) {
        if (modalType == "edt") {
            this.refClubInvoiceModal.current.openInvoice(invoice);
        } else if (modalType == "del") {
            this.invoice = invoice;
            this.refDelClubInvoiceModal.current.openNamedHeader(invoice.getInvoiceDate() + ": " + invoice.getName());
        } else if (modalType == "paid") {
            this.invoice = invoice;
            this.refPaidClubInvoiceModal.current.openNamedHeader(invoice.getInvoiceDate() + ": " + invoice.getName());
        }
    }
    handleDelete() {
        if (this.invoice != null) {
            clubInvoiceActions.deleteInvoice(this.invoice);
            this.invoice = null;
        }
    }
    handleMarkAsPaid() {
        if (this.invoice != null) {
            clubInvoiceActions.markInvoicePaid(this.invoice);
            this.invoice = null;
        }
    }
    exportClubInvoices() {
        clubInvoiceActions.exportAllInvoices(this.props.invoices);
    }
    openInvoiceCreateModal() {
        this.refClubInvoiceModal.current.openCreate();
    }
    addTypeFunction(type) {
        clubActions.updateClubInvoiceType(this.props.club, type, null);
    }
    removeTypeFunction(type) {
        clubActions.updateClubInvoiceType(this.props.club, null, type);
    }
    openTypeDefinitions() {
        this.refInvTypeDefModal.current.open();
    }
    handleTextChange(e) {
        var that = this;
        let search = e.target.value;
        let num = timeoutLock.getNext();
        setTimeout(function () {
            if (num == timeoutLock.num) {
                that.setState({searchText: search});
                clubInvoiceActions.searchClubInvoices({searchText: search, searchType: that.state.searchType});
            }
        }, 300);
    }
    handleTypeChange(e) {
        let type = e.target.value;
        if (type === "All") {
            type = null;
        }
        this.setState({searchType: type});
        clubInvoiceActions.searchClubInvoices({searchText: this.state.searchText, searchType: type});
    }
    render() {
        const {user, club, year, invoices, loading, isSKillBoardClub } = this.props;
        document.title = club.getName() + " - " + messages.get("cl_role_invoice");
        let alert = null;
        let hasClubManagement = club.hasClubManagement();
        if (club.hasManagementID()) {
            if (!hasClubManagement) {
                alert = <Col xs={12}><Alert bsStyle="warning">{messages.get("club.business.noClubFeature.desc")}</Alert></Col>;
            }
        } else {
            alert = <Col xs={12}><Alert bsStyle="warning">{messages.get("club.business.link2BusinessAccount.desc")}</Alert></Col>;
        }
        let invTypes = club.getInvoiceTypes();
        let nextNewNumber = invoices ? invoices.length + 1 : 1;
        let sumAmountIn = 0;
        let sumAmountOut = 0;
        return (<Row>
            { alert }
            <Col xs={12} sm={6}>
                &nbsp;<Button disabled={!hasClubManagement} bsStyle="primary" onClick={this.openInvoiceCreateModal}><FontAwesome icon="plus"/>{messages.get("club.invoice.button.create")}</Button>
                <ClubInvoiceModal ref={this.refClubInvoiceModal} club={club} user={user} nextNewNumber={nextNewNumber}/>
                <DecisionModal ref={this.refDelClubInvoiceModal}
                               titleCode={"club.invoice.modal.delete.header"}
                               messageCode={"club.invoice.modal.delete.ask"}
                               okButtonCode={"modal.button.delete"}
                               cancelButtonCode={"modal.button.cancel"}
                               handleOK={this.handleDelete}/>
                <DecisionModal ref={this.refPaidClubInvoiceModal}
                               titleCode={"club.invoice.modal.paid.header"}
                               messageCode={"club.invoice.modal.paid.ask"}
                               okButtonCode={"modal.button.ok"}
                               cancelButtonCode={"modal.button.cancel"}
                               handleOK={this.handleMarkAsPaid}/>
            </Col>
            <Col xs={12} sm={6}>
                <ClubInvoiceButton club={club} disabled={!hasClubManagement || !isSKillBoardClub} />
                <Button disabled={!hasClubManagement} bsStyle="primary" onClick={this.openTypeDefinitions}><FontAwesome icon="users"/><I18n code="club.invoice.type.modal.title"/></Button>&nbsp;
                <Button disabled={!hasClubManagement} bsStyle="primary" onClick={this.exportClubInvoices}><FontAwesome icon="download"/><I18n code="club.invoice.button.exportInv"/></Button>
                <TypeListDefinitionModal ref={this.refInvTypeDefModal}
                                         header={messages.get("club.invoice.type.modal.title")}
                                         inputText={messages.get("club.invoice.type.modal.input")}
                                         list={invTypes}
                                         addTypeFunction={this.addTypeFunction}
                                         removeTypeFunction={this.removeTypeFunction}/>
            </Col>
            <Col xs={12}>
                <br/><br/>
                <Table responsive striped hover>
                    <thead>
                    <tr>
                        <th className="col-xs-1"><I18n code="tournament.table.column.date"/></th>
                        <th className="col-xs-1"><I18n code="tournament.table.column.number"/></th>
                        <th className="col-xs-4"><I18n code="tournament.table.column.text"/></th>
                        <th className="col-xs-2"><I18n code="tournament.table.column.type"/></th>
                        <th className="col-xs-1"><I18n code="club.invoice.amount.in.true"/></th>
                        <th className="col-xs-1"><I18n code="club.invoice.amount.in.false"/></th>
                        <th className="col-xs-2"><I18n code="parcours.details.button.actions"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td  colSpan="2">
                            <FormControl type="text"
                                         placeholder={messages.get("club.invoice.button.search")}
                                         onChange={this.handleTextChange}/></td>
                        <td>
                            { invTypes != null ?
                                <FormControl componentClass="select" placeholder="select" onChange={this.handleTypeChange}>
                                    <option value="All">Alle</option>
                                    {invTypes.map(type => {
                                        return <option key={type} value={type}>{type}</option>
                                    })}

                                </FormControl> : null
                            }
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {
                        loading || invoices == null ?
                            <tr><td colSpan="7"><Loading /></td></tr>
                            : invoices.map(invoice => {
                                if (invoice.getInOrOut() === true) {
                                    sumAmountIn += invoice.getAmount();
                                } else {
                                    sumAmountOut += invoice.getAmount();
                                }
                                return (<ClubInvoiceTableEntry key={invoice.id}
                                                               invoice={invoice}
                                                               handleModal={this.handleModal}/>)
                            })
                    }
                    <tr>
                        <td></td>
                        <td></td>
                        <td><b>{messages.get("tournament.table.column.sum")}</b></td>
                        <td></td>
                        <td className="text-right"><b>{(Math.round(sumAmountIn * 100) / 100).toFixed(2)}</b></td>
                        <td className="text-right"><b>{(Math.round(sumAmountOut * 100) / 100).toFixed(2)}</b></td>
                        <td></td>
                    </tr>
                    {
                        sumAmountIn > sumAmountOut ?

                            <tr>
                                <td></td>
                                <td></td>
                                <td><b>{messages.get("tournament.table.column.profit")}</b></td>
                                <td></td>
                                <td className="text-right"><b>{(Math.round((sumAmountIn - sumAmountOut) * 100) / 100).toFixed(2)}</b></td>
                                <td></td>
                                <td></td>
                            </tr>
                            :
                            <tr>
                                <td></td>
                                <td></td>
                                <td><b>{messages.get("tournament.table.column.loss")}</b></td>
                                <td></td>
                                <td></td>
                                <td className="text-right"><b>{(Math.round((sumAmountIn - sumAmountOut) * 100) / 100).toFixed(2)}</b></td>
                                <td></td>
                            </tr>
                    }
                    </tbody>
                </Table>
            </Col>
        </Row>);
    }
}
ClubInvoiceTable.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    year:PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    invoices: PropTypes.array.isRequired,
    isSKillBoardClub: PropTypes.bool.isRequired
};
const ClubInvoiceTableEntry = ({ invoice, handleModal }) => {
    function handleEdit() {
        handleModal("edt", invoice);
    }
    function handleDelete() {
        handleModal("del", invoice);
    }
    function handlePaid() {
        handleModal("paid", invoice);
    }
    let amountIN = null;
    let amountOUT = null;
    if (invoice.getInOrOut() === true) {
        amountIN = invoice.getAmount().toFixed(2);
    } else {
        amountOUT = invoice.getAmount().toFixed(2);
    }
    return (<tr>
        <td>{invoice.getInvoiceDate()}</td>
        <td>{invoice.getInvoiceNumber()}</td>
        <td>{invoice.getDocument() != null ? <a href={invoice.getDocument().url()} target="_blank"><FontAwesome icon="file-alt"/>{invoice.getName()}</a> : invoice.getName()}<br/>
            {invoice.getPrice() > invoice.getAmount() ? <strong>{"Offener Betrag: " + invoice.getOpenAmount()}<br/></strong> : null}
            {invoice.getDescription() != null ? invoice.getDescription() : null}
        </td>
        <td>{invoice.getType()}</td>
        <td className="text-right">{amountIN}</td>
        <td className="text-right">{amountOUT}</td>
        <td><ButtonGroup>
            <Button key="edt" onClick={handleEdit}><FontAwesome icon="pencil-alt"/></Button>
            <TooltipButton key="del"  onClick={handleDelete} icon="trash" tooltip={messages.get("club.invoice.modal.delete.header")}
                           disabled={invoice.getParcoursArticles() != null && invoice.getInvoiceNumber() != null}/>
            {
                invoice.isOpenInvoice() ? <TooltipButton key="pay" onClick={handlePaid} icon="money-bill-wave" tooltip={messages.get("club.invoice.modal.paid.header")}/> : null
            }
        </ButtonGroup></td>
    </tr>)
};
ClubInvoiceTableEntry.propTypes = {
    invoice:PropTypes.object.isRequired,
    handleModal:PropTypes.func.isRequired
};
module.exports = ClubAccountingPage;
