const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Button,ButtonGroup, Panel} = require("react-bootstrap");
const messages = require("i18n/messages");

const Title = require("components/widgets/Title"),
    GroupPlaces = require("components/widgets/GroupPlaces"),
    Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    DateGroupInputTypeAhead  = require("components/form/DateGroupInputTypeAhead"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ExtLink = require("components/links/ExtLink"),
    TournamentMapFrame = require("components/maps/TournamentMapFrame"),
    ListLayout = require("components/widgets/ListLayout"),
    Utility = require("util/Utility");

const TournamentStatus = require("components/tournament/TournamentStatus"),
    TournamentPrivate = require("components/tournament/TournamentPrivate"),
    TournamentCreateModal = require("components/tournamentmodal/TournamentCreateModal"),
    TournamentLink = require("components/tournament/TournamentLink");

const {BaseContext} = require("context/BaseContext");

const groupPlaceActions = require("actions/GroupPlaceActions"),
    tournamentStore = require("stores/TournamentStore"),
    tournamentActions = require("actions/TournamentActions"),
    layoutActions = require("actions/LayoutActions"),
    layoutStore = require("stores/LayoutStore"),
    notificationActions = require("components/notification/NotificationActions"),
    UserActions = require("actions/UserActions");

const parseDao = require("actdao/ParseBaseDao");

const analyticsActions = require("actions/AnalyticsActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class TournamentPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleSearchTournaments = this.handleSearchTournaments.bind(this);
        this.state = {
            tournamentStore: tournamentStore.getState(),
            layoutStore: layoutStore.getState()
        }
    }

    componentDidMount() {
        document.title = messages.get("header.nav.tournaments");
        tournamentStore.listen(this.handleTournamentChange);
        layoutStore.listen(this.handleLayoutChange);
        notificationActions.reset();
        groupPlaceActions.reset();
        tournamentActions.showProgress();
        parseDao.getParseConfig()
            .then(config => {
                this.handleSearchTournaments(config)
            })
            .catch(error => {
                this.handleSearchTournaments(null)
            });

    }
    handleSearchTournaments(config) {
        const searchParams = this.state.tournamentStore.searchParams;
        searchParams.maxDuration = config != null ? config.get("TournamentSearchMaxDuration") : 0
        if (this.props.searchKey === "cup"  && this.props.searchText != null) {
            searchParams.buCode = this.props.searchText.toUpperCase()
            searchParams.allowMap = true
            searchParams.needCupMaster = true
            searchParams.maxDuration = 0
            layoutActions.displayTypeChanged("MAP");
            tournamentActions.searchCupTournaments(searchParams)
        } else if (this.props.searchKey === "filter" && this.props.searchText != null) {
            layoutActions.displayTypeChanged("LIST");
            tournamentActions.updateDateFilter(this.props.searchText);
            tournamentActions.searchTournaments(searchParams);
        } else {
            layoutActions.displayTypeChanged("LIST");
            tournamentActions.searchTournaments(searchParams);
        }
    }
    componentWillUnmount() {
        tournamentStore.unlisten(this.handleTournamentChange);
        layoutStore.unlisten(this.handleLayoutChange);
    }
    handleTournamentChange(state) {
        this.setState({tournamentStore: state});
    }
    handleLayoutChange(state) {
        this.setState({layoutStore:state});
    }
    render() {
        const {tournamentStore} = this.state;
        const {tournaments, listRenderAmount, bowUnion} = tournamentStore
        const {displayType} = this.state.layoutStore;
        return (
            <Grid>
                <Row>
                    <Col xs={12} xsHidden>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/tournament" code="header.nav.tournaments" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Title code="tournament.title">
                    {
                        bowUnion != null ? <span> - {bowUnion.getName()}</span> : null
                    }
                </Title>

                <ListLayout.Wrapper>
                    <ListLayout.Filter>
                        <TournamentFilter displayType={displayType}
                                          tournamentStore={tournamentStore}/>
                        <PointInterestBanner />
                    </ListLayout.Filter>
                    {
                        displayType === "MAP" ?
                            <ListLayout.List>
                                <TournamentMapFrame tournaments={tournaments} ownPosition={null}/>
                            </ListLayout.List> : null
                    }
                    {
                        displayType === "LIST" ?
                            <ListLayout.List>
                                <TournamentList list={tournaments} listRenderAmount={listRenderAmount}/>
                            </ListLayout.List> : null
                    }

                    <ListLayout.List>

                    </ListLayout.List>
                </ListLayout.Wrapper>
            </Grid>)
    }
};
const TournamentList = ({list, listRenderAmount}) =>  {
    const addRenderAmount = 50;
    const handleMore = () => {
        tournamentActions.updateListRenderAmount(listRenderAmount + addRenderAmount)
    };
    if (list == null) {
        return (<Loading/>)
    } else if (list.length == 0) {
        return (<Alert bsStyle="warning"><I18n code="tournament.empty.result"/></Alert>)
    }
    let index = 0;
    let month = 0;
    return (
        <React.Fragment>
            {list.map(tournament => {
                if (index < listRenderAmount) {
                    index++;
                    const date = tournament.getDate()
                    if (month != (date.getMonth() + 1)) {
                        month = date.getMonth() + 1;
                        return (
                            <React.Fragment key={"div" + tournament.id}>
                                <h3>{messages.get("montPager.month." + month) + " " + date.getFullYear()}</h3>
                                <TournamentItem key={tournament.id} tournament={tournament}/>
                            </React.Fragment>);
                    } else {
                        return (<TournamentItem key={tournament.id} tournament={tournament}/>);
                    }
                }
            })}
            {
                list.length > listRenderAmount ?
                    <Button onClick={handleMore}>{addRenderAmount}&nbsp;<I18n code="tournament.button.show.more"/></Button> : null
            }
        </React.Fragment>
    )
};

class TournamentFilter extends React.Component {
    constructor(props) {
        super(props);
        this.refTournamentCreateModal = React.createRef();
        this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
        this.handleGroupPlaceChange = this.handleGroupPlaceChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.openTournamentCreateModal = this.openTournamentCreateModal.bind(this);
        this.handleSearchTextFoundElement = this.handleSearchTextFoundElement.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.state = {}
    }
    handleGroupPlaceChange(mainGroupPlaceId, regionGroupPlaceId) {
        tournamentActions.showProgress();
        this.props.tournamentStore.searchParams.mainGroupPlaceID = mainGroupPlaceId;
        this.props.tournamentStore.searchParams.regionGroupPlaceID = regionGroupPlaceId;
        tournamentActions.searchTournaments(this.props.tournamentStore.searchParams);
    }
    handleFilterChange(displayType) {
        if (this.props.displayType != displayType) {
            layoutActions.displayTypeChanged(displayType);
        }
    }
    handleDateFilterChange(dateFilter) {
        tournamentActions.showProgress();
        tournamentActions.updateDateFilter(dateFilter);
        tournamentActions.searchTournaments(this.props.tournamentStore.searchParams);
    }
    openTournamentCreateModal() {
        const { user } = this.context;
        if (user !== null) {
            if (!Utility.hasWriteLock(user, false)) {
                this.refTournamentCreateModal.current.open();
            }
        } else {
            UserActions.showLoginModal();
        }
    }
    handleSearchTextFoundElement(element) {
        // forward to selected tournament
        window.location = linkUtil.getLink("tournament/" + element.id);
    }
    handleSearchTextChange(searchText) {
        analyticsActions.trackTournamentSearch(searchText);
        const params = this.props.tournamentStore.searchParams;
        params.searchText = searchText;
        tournamentActions.searchTournamentList(params);
    }
    render() {
        const {searchParams, allTournaments, bowUnion} = this.props.tournamentStore;
        const { user } = this.context;
        const {displayType} = this.props
        const that = this;
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12}>
                        <h4><strong><I18n code="tournament.button.search"/></strong></h4>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={12}>
                        <DateGroupInputTypeAhead
                            defaultValue={searchParams.searchText}
                            objectList={allTournaments}
                            handleSelectedObject={this.handleSearchTextFoundElement}
                            handleNoObjectFound={this.handleSearchTextChange}
                            placeholder={messages.get("parcours.button.search.placeholder")}/>
                    </Col>
                    <Col xsHidden  sm={6} md={6} lgHidden>
                        <Button block bsStyle="primary" onClick={this.openTournamentCreateModal}>
                            <FontAwesome icon="plus"/><I18n code="tournament.button.create"/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7} sm={6} md={6} lg={12}>
                        <h4>Filter</h4>
                        <ButtonGroup>
                            <Button onClick={function(){that.handleDateFilterChange("current")}}
                                    className={searchParams.dateFilter === "current" ? "active" : ""}>
                                <FontAwesome icon="calendar-alt"/><I18n code="tournament.filter.current"/>
                            </Button>
                            <Button onClick={function(){that.handleDateFilterChange("past")}}
                                    className={searchParams.dateFilter === "past" ? "active" : ""}>
                                <FontAwesome icon="trophy"/><I18n code="tournament.filter.results"/>
                            </Button>
                        </ButtonGroup>
                    </Col>
                    {
                        searchParams.allowMap ? <Col xsHidden sm={3} md={3} lg={12}>
                            <h4><I18n code="filter.header.display"/></h4>
                            <ButtonGroup>
                                <Button onClick={() => this.handleFilterChange("LIST")}
                                        className={displayType === "LIST" ? "active" : ""}>
                                    <FontAwesome icon="list"/><I18n code="filter.button.list"/>
                                </Button>
                                <Button onClick={() => this.handleFilterChange("MAP")}
                                        className={displayType === "MAP" ? "active" : ""}>
                                    <FontAwesome icon="globe"/><I18n code="filter.button.map"/>
                                </Button>
                            </ButtonGroup>
                        </Col> : null
                    }

                    <Col xs={5} sm={6} md={6} lg={12}>
                        <h4><I18n code="tournament.filter.region"/></h4>
                        <GroupPlaces handleChange={this.handleGroupPlaceChange}/>
                        <br/>
                    </Col>
                    {
                        bowUnion != null ? null : <Col xs={12}  smHidden mdHidden lg={12}>
                            <Button block bsSize="large" bsStyle="primary" onClick={this.openTournamentCreateModal}>
                                <FontAwesome icon="plus"/><I18n code="tournament.button.create"/>
                            </Button>
                            <TournamentCreateModal ref={this.refTournamentCreateModal} user={user}/>
                        </Col>
                    }
                </Row>
                <br/>
            </React.Fragment>)
    }
}
TournamentFilter.contextType = BaseContext;
const TournamentItem = ({tournament}) => {
    const highlight = (tournament) => {
        if (tournament.isCanceled()) {
            return <StrikeOutText strikeOut={true}>{tournament.getName()}</StrikeOutText>
        } else if (tournament.isManaged() && !tournament.isRegistrationPublished()) {
            return <b>{tournament.getName()}</b>
        } else  {
            return tournament.getName()
        }
    };
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <h4>
                            <TournamentLink tournament={tournament}
                                            label={highlight(tournament)}/>
                        </h4>
                    </Col>
                    <Col xs={12} md={3}>
                        <h4>
                            {tournament.getTournamentDate()}
                        </h4>
                    </Col>
                    <Col xs={12}>
                        <ExtLink href={tournament.getUnionLink()}>{tournament.getUnion()}</ExtLink>
                    </Col>
                    <Col xs={12}>
                        <TournamentPrivate tournament={tournament} />
                        {tournament.getMainEditor() != null ? (<span>{<TournamentStatus tournament={tournament}/>} | </span>) : ""}
                        {tournament.getZip()} {tournament.getPlace()}
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
TournamentItem.propTypes = {
    tournament: PropTypes.object.isRequired
};

module.exports = TournamentPage;




