module.exports = {
    "description" : "This privacy policy governs your use of the software application 3D SKill Board (“Application”) for android mobile devices and 3dturnier.com website that was created by 3dturnier.com. The Application is Basic description of the app (features, functionality and content)",
    "header1": "User Provided Information",
    "body1" : "The Application obtains the information you provide when you download and register the Application. Registration with us is optional. However, please keep in mind that you may not be able to use some of the features offered by the Application unless you register with us. \n" +
    "When you register with us and use the Application, you generally provide (a) your name, email address; (b) information you provide us when you contact us for help; (c) information you enter into our system when using the Application, such as parcours information and ratings. \n" +
    "We may also use the information you provided us to contact your from time to time to provide you with important information, required notices and marketing promotions.",
    "header2": "Automatically Collected Information",
    "body2" : "In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, and information about the way you use the Application. \n" +
    "This Application does not collect precise information about the location of your mobile device.",
    "header3": "Comments, course information and images",
    "body3" : "If an user enters or changes a course as well as adds a comment or picture to it, we will store his name and e-mail address linked to the change. This is for our own tsafety if the users enters illegal content. " +
    " In such a case we could be prosecuted for that and therefore we need the identity of the editor. <br><br>The course information as well as comments and images are public on our webpage and can be seen by a third party at any time.",
    "header4": "Publish a tournament",
    "body4" : "If an user publishs a tournament, the invitation or result for it, we will store his name and e-mail address. This is for our own tsafety if the users enters illegal content. " +
    "In such a case we could be prosecuted for that and therefore we need the identity of the editor. <br><br>The entered tournament details, the invitation as well as the result are public on our webpage and can be seen by a third party at any time.",
    "header5":"Tournament registrations and -results",
    "body5":"For a tournament on our webpage, 3dturnier is the processor an the hosting club is responsible for the personal data of the tournament attendees. " +
    "If an users registers for a tournament he has to enter usually his name, sex, e-mail and club membership. These information are needed to host a tournament and can be used for further tournament registrations on the 3dturnier webpage. " +
    "<br><br>Children under the age of 14 need the consent of their parents. " +
    "The entered registration data except the e-mail will be published on the registration list as well as on the tournament result at the 3dturnier webpage. The hosting club may forward the result to others clubs or organisations. " +
    "<br><br>Publishing sport results is acceptable by the legitimate interest of the responsible organisations and therefore excepted from the proposal of deletion or revokement.",
    "header6":"Uploading events and showing them in the ranking",
    "body6":"If an user enters an event (eg a round on a course) in the app - the event data are only on his device. As soon as he uploads the event to our central database, the event data (the name and e-mail of the archer, start time, the course as well as the result for each archer and target) will be stored. Each participant will find the event in his profile and may delete it there. "+
    "<br><br>We are creating a ranking from all uploaded events and this ranking is visible to the public on our 3dturnier.com webpage if the course host has enabled our course package. This ranking will show the entered name of the arches and date of the event as well as the scored result. ",
    "header7":"Pay a course round or a tournament fee",
    "body7":"If an user pays a course round or a tournament fee over the app or the 3dturnier webpage we need the name, e-mail and the address for creating and sending an invoice. These data will be stored as requested by legal requirements. "+
    "<br><br>The name and living place of the user as well as the payment sum and payment method will be handed over to the course host for checking the payment. " +
    "<br><br>If the user pays by credit card, Sofortüberweisung or PayPal the entered payment data will not be entered or stored on our system but at the payment host Unzer Austria GmbH or Stripe Inc.",
    "header8":"Managing a club",
    "body8":"For managing a club on our webpage, 3dturnier is the processor. It is the responsibility of the club which personal data will be entered for the members and also when the data will be deleted. It is possible to enter name, address, birthday, sex, e-mail, image and telephone number.",
    "header9":"Deleting data",
    "body9":"If an user wants to delete data (events, tournament registrations, club members, club events,...) in our central database, the rows will be marked as deleted first and can be restored if the users wants. If the deleted row has not changed within the last 30 days - a background routine will erase the row finally.",
    "header10": "Do third parties see and/or have access to information obtained by the Application?",
    "body10" : "Only aggregated, anonymized data is periodically transmitted to external services to help us improve the Application and our service. We will share your information with third parties only in the ways that are described in this privacy statement.\n" +
    "We may disclose User Provided and Automatically Collected Information:" +
    "<br> -) as required by law, such as to comply with a subpoena, or similar legal process;" +
    "<br> -) when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;" +
    "<br> -) with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement." +
    "<br> -) if 3DTurnier is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information.",
    "header11": "What are my opt-out rights?",
    "body11" : "You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.",
    "header12": "Data Retention Policy, Managing Your Information",
    "body12" : "We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided via the Application, please contact us at office@3dturnier.com and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.",
    "header13": "Security",
    "body13" : "We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve our Application. Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.",
    "header14": "Changes",
    "body14" : "This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and informing you via email or text message. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.",
    "header15": "Your Consent",
    "body15" : "By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. \"Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in the United States. If you reside outside the United States your information will be transferred, processed and stored there under United States privacy standards.",
    "header16": "Contact us",
    "body16" : "If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at office@3dturnier.com.",
};