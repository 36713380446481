const { library } = require ('@fortawesome/fontawesome-svg-core');
const {faAddressBook} = require ('@fortawesome/free-solid-svg-icons/faAddressBook');
const {faAsterisk} = require ('@fortawesome/free-solid-svg-icons/faAsterisk');
const {faArrowLeft} = require ('@fortawesome/free-solid-svg-icons/faArrowLeft');
const {faArrowRight} = require ('@fortawesome/free-solid-svg-icons/faArrowRight');
const {faBan} = require ('@fortawesome/free-solid-svg-icons/faBan');
const {faBars} = require ('@fortawesome/free-solid-svg-icons/faBars');
const {faBell} = require ('@fortawesome/free-solid-svg-icons/faBell');
const {faBirthdayCake} = require ('@fortawesome/free-solid-svg-icons/faBirthdayCake');
const {faBomb} = require ('@fortawesome/free-solid-svg-icons/faBomb');
const {faBold} = require ('@fortawesome/free-solid-svg-icons/faBold');
const {faBook} = require ('@fortawesome/free-solid-svg-icons/faBook');
const {faBookOpen} = require ('@fortawesome/free-solid-svg-icons/faBookOpen');
const {faBriefcase} = require ('@fortawesome/free-solid-svg-icons/faBriefcase');
const {faBug} = require ('@fortawesome/free-solid-svg-icons/faBug');
const {faBullhorn} = require ('@fortawesome/free-solid-svg-icons/faBullhorn');
const {faBullseye} = require ('@fortawesome/free-solid-svg-icons/faBullseye');
const {faCalculator} = require ('@fortawesome/free-solid-svg-icons/faCalculator');
const {faCalendarAlt} = require ('@fortawesome/free-solid-svg-icons/faCalendarAlt');
const {faCalendarPlus} = require ('@fortawesome/free-solid-svg-icons/faCalendarPlus');
const {faCaretDown} = require ('@fortawesome/free-solid-svg-icons/faCaretDown');
const {faCaretLeft} = require ('@fortawesome/free-solid-svg-icons/faCaretLeft');
const {faCaretUp} = require ('@fortawesome/free-solid-svg-icons/faCaretUp');
const {faChartLine} = require ('@fortawesome/free-solid-svg-icons/faChartLine');
const {faCheck} = require ('@fortawesome/free-solid-svg-icons/faCheck');
const {faCheckDouble} = require ('@fortawesome/free-solid-svg-icons/faCheckDouble');
const {faChessRook} = require ('@fortawesome/free-solid-svg-icons/faChessRook');
const {faCheckCircle} = require ('@fortawesome/free-solid-svg-icons/faCheckCircle');
const {faCheckSquare} = require ('@fortawesome/free-solid-svg-icons/faCheckSquare');
const {faClock} = require ('@fortawesome/free-solid-svg-icons/faClock');
const {faClipboardCheck} = require ('@fortawesome/free-solid-svg-icons/faClipboardCheck');
const {faClipboardQuestion} = require ('@fortawesome/free-solid-svg-icons/faClipboardQuestion');
const {faCog} = require ('@fortawesome/free-solid-svg-icons/faCog');
const {faCogs} = require ('@fortawesome/free-solid-svg-icons/faCogs');
const {faComment} = require ('@fortawesome/free-solid-svg-icons/faComment');
const {faCreditCard} = require ('@fortawesome/free-solid-svg-icons/faCreditCard');
const {faCubes} = require ('@fortawesome/free-solid-svg-icons/faCubes');
const {faDesktop} = require ('@fortawesome/free-solid-svg-icons/faDesktop');
const {faDownload} = require ('@fortawesome/free-solid-svg-icons/faDownload');
const {faEdit} = require ('@fortawesome/free-solid-svg-icons/faEdit');
const {faEnvelope} = require ('@fortawesome/free-solid-svg-icons/faEnvelope');
const {faEuroSign} = require ('@fortawesome/free-solid-svg-icons/faEuroSign');
const {faExternalLinkAlt} = require ('@fortawesome/free-solid-svg-icons/faExternalLinkAlt');
const {faExchangeAlt} = require ('@fortawesome/free-solid-svg-icons/faExchangeAlt');
const {faExclamation} = require ('@fortawesome/free-solid-svg-icons/faExclamation');
const {faExclamationCircle} = require ('@fortawesome/free-solid-svg-icons/faExclamationCircle');
const {faExclamationTriangle} = require ('@fortawesome/free-solid-svg-icons/faExclamationTriangle');
const {faEye} = require ('@fortawesome/free-solid-svg-icons/faEye');
const {faEyeSlash} = require ('@fortawesome/free-solid-svg-icons/faEyeSlash');
const {faFileAlt} = require ('@fortawesome/free-solid-svg-icons/faFileAlt');
const {faFilter} = require ('@fortawesome/free-solid-svg-icons/faFilter');
const {faFlag} = require ('@fortawesome/free-solid-svg-icons/faFlag');
const {faGift} = require ('@fortawesome/free-solid-svg-icons/faGift');
const {faGlobe} = require ('@fortawesome/free-solid-svg-icons/faGlobe');
const {faGraduationCap} = require ('@fortawesome/free-solid-svg-icons/faGraduationCap');
const {faHandSpock} = require ('@fortawesome/free-solid-svg-icons/faHandSpock');
const {faHome} = require ('@fortawesome/free-solid-svg-icons/faHome');
const {faHourglassHalf} = require ('@fortawesome/free-solid-svg-icons/faHourglassHalf');
const {faHashtag} = require ('@fortawesome/free-solid-svg-icons/faHashtag');
const {faHistory} = require ('@fortawesome/free-solid-svg-icons/faHistory');
const {faIdCard} = require ('@fortawesome/free-solid-svg-icons/faIdCard');
const {faImage} = require ('@fortawesome/free-solid-svg-icons/faImage');
const {faInbox} = require ('@fortawesome/free-solid-svg-icons/faInbox');
const {faInfoCircle} = require ('@fortawesome/free-solid-svg-icons/faInfoCircle');
const {faLanguage} = require ('@fortawesome/free-solid-svg-icons/faLanguage');
const {faLevelDownAlt} = require ('@fortawesome/free-solid-svg-icons/faLevelDownAlt');
const {faLink} = require ('@fortawesome/free-solid-svg-icons/faLink');
const {faList} = require ('@fortawesome/free-solid-svg-icons/faList');
const {faListOl} = require ('@fortawesome/free-solid-svg-icons/faListOl');
const {faMale} = require ('@fortawesome/free-solid-svg-icons/faMale');
const {faMars} = require ('@fortawesome/free-solid-svg-icons/faMars');
const {faMap} = require ('@fortawesome/free-solid-svg-icons/faMap');
const {faMapMarkerAlt} = require ('@fortawesome/free-solid-svg-icons/faMapMarkerAlt');
const {faMapPin} = require ('@fortawesome/free-solid-svg-icons/faMapPin');
const {faMedal} = require ('@fortawesome/free-solid-svg-icons/faMedal');
const {faMarsAndVenus} = require ('@fortawesome/free-solid-svg-icons/faMarsAndVenus');
const {faMinus} = require ('@fortawesome/free-solid-svg-icons/faMinus');
const {faMobileAlt} = require ('@fortawesome/free-solid-svg-icons/faMobileAlt');
const {faMoneyBillWave} = require ('@fortawesome/free-solid-svg-icons/faMoneyBillWave');
const {faNewspaper} = require ('@fortawesome/free-solid-svg-icons/faNewspaper');
const {faPause} = require ('@fortawesome/free-solid-svg-icons/faPause');
const {faPencilAlt} = require ('@fortawesome/free-solid-svg-icons/faPencilAlt');
const {faPercent} = require ('@fortawesome/free-solid-svg-icons/faPercent');
const {faPlay} = require ('@fortawesome/free-solid-svg-icons/faPlay');
const {faPhone} = require ('@fortawesome/free-solid-svg-icons/faPhone');
const {faPoll} = require ('@fortawesome/free-solid-svg-icons/faPoll');
const {faPlayCircle} = require ('@fortawesome/free-solid-svg-icons/faPlayCircle');
const {faPlus} = require ('@fortawesome/free-solid-svg-icons/faPlus');
const {faPlusCircle} = require ('@fortawesome/free-solid-svg-icons/faPlusCircle');
const {faPrint} = require ('@fortawesome/free-solid-svg-icons/faPrint');
const {faQrcode} = require ('@fortawesome/free-solid-svg-icons/faQrcode');
const {faQuestionCircle} = require ('@fortawesome/free-solid-svg-icons/faQuestionCircle');
const {faRegistered} = require ('@fortawesome/free-solid-svg-icons/faRegistered');
const {faRetweet} = require ('@fortawesome/free-solid-svg-icons/faRetweet');
const {faSave} = require ('@fortawesome/free-solid-svg-icons/faSave');
const {faSearch} = require ('@fortawesome/free-solid-svg-icons/faSearch');
const {faShareAlt} = require ('@fortawesome/free-solid-svg-icons/faShareAlt');
const {faShoppingCart} = require ('@fortawesome/free-solid-svg-icons/faShoppingCart');
const {faSignInAlt} = require ('@fortawesome/free-solid-svg-icons/faSignInAlt');
const {faSignOutAlt} = require ('@fortawesome/free-solid-svg-icons/faSignOutAlt');
const {faSort} = require ('@fortawesome/free-solid-svg-icons/faSort');
const {faSortDown} = require ('@fortawesome/free-solid-svg-icons/faSortDown');
const {faSortUp} = require ('@fortawesome/free-solid-svg-icons/faSortUp');
const {faSpinner} = require ('@fortawesome/free-solid-svg-icons/faSpinner');
const {faStar} = require ('@fortawesome/free-solid-svg-icons/faStar');
const {faStarHalf} = require ('@fortawesome/free-solid-svg-icons/faStarHalf');
const {faStop} = require ('@fortawesome/free-solid-svg-icons/faStop');
const {faSync} = require ('@fortawesome/free-solid-svg-icons/faSync');
const {faTasks} = require ('@fortawesome/free-solid-svg-icons/faTasks');
const {faTextHeight} = require ('@fortawesome/free-solid-svg-icons/faTextHeight');
const {faThumbsUp} = require ('@fortawesome/free-solid-svg-icons/faThumbsUp');
const {faTimes} = require ('@fortawesome/free-solid-svg-icons/faTimes');
const {faTimesCircle} = require ('@fortawesome/free-solid-svg-icons/faTimesCircle');
const {faTransgender} = require ('@fortawesome/free-solid-svg-icons/faTransgender');
const {faTrash} = require ('@fortawesome/free-solid-svg-icons/faTrash');
const {faTrophy} = require ('@fortawesome/free-solid-svg-icons/faTrophy');
const {faUndo} = require ('@fortawesome/free-solid-svg-icons/faUndo');
const {faUniversity} = require ('@fortawesome/free-solid-svg-icons/faUniversity');
const {faUnlock} = require ('@fortawesome/free-solid-svg-icons/faUnlock');
const {faUser} = require ('@fortawesome/free-solid-svg-icons/faUser');
const {faUserPlus} = require ('@fortawesome/free-solid-svg-icons/faUserPlus');
const {faUsers} = require ('@fortawesome/free-solid-svg-icons/faUsers');
const {faUsersCog} = require ('@fortawesome/free-solid-svg-icons/faUsersCog');
const {faUserFriends} = require ('@fortawesome/free-solid-svg-icons/faUserFriends');
const {faUserClock} = require ('@fortawesome/free-solid-svg-icons/faUserClock');
const {faUserCircle} = require ('@fortawesome/free-solid-svg-icons/faUserCircle');
const {faUserCheck} = require ('@fortawesome/free-solid-svg-icons/faUserCheck');
const {faVenus} = require ('@fortawesome/free-solid-svg-icons/faVenus');
const {faWalking} = require ('@fortawesome/free-solid-svg-icons/faWalking');
// free-brands-svg-icons
const { faAndroid } = require ('@fortawesome/free-brands-svg-icons/faAndroid');
const { faApple } = require ('@fortawesome/free-brands-svg-icons/faApple');
const { faFacebook } = require ('@fortawesome/free-brands-svg-icons/faFacebook');
const { faHtml5 } = require ('@fortawesome/free-brands-svg-icons/faHtml5');
const { faLinkedin } = require ('@fortawesome/free-brands-svg-icons/faLinkedin');
const { faPaypal } = require ('@fortawesome/free-brands-svg-icons/faPaypal');
const { faStripeS } = require ('@fortawesome/free-brands-svg-icons/faStripeS');
const { faTelegram } = require ('@fortawesome/free-brands-svg-icons/faTelegram');
const { faCreativeCommonsNcEu } = require ('@fortawesome/free-brands-svg-icons/faCreativeCommonsNcEu');
const { faCreativeCommonsZero } = require ('@fortawesome/free-brands-svg-icons/faCreativeCommonsZero');

library.add( faAddressBook, faAndroid, faApple, faAsterisk, faArrowLeft, faArrowRight, faBan, faBars, faBell, faBirthdayCake, faBold,faBomb, faBook, faBookOpen, faBriefcase, faBug, faBullhorn, faBullseye, faCalculator, faCalendarAlt, faCalendarPlus, faCaretDown, faCaretLeft, faCaretUp, faChartLine, faCheck, faCheckDouble, faCheckCircle, faCheckSquare, faChessRook, faClock, faClipboardCheck, faClipboardQuestion, faCog, faCogs, faComment,faCreditCard, faCreativeCommonsNcEu,faCreativeCommonsZero, faCubes, faDesktop, faDownload, faEdit, faEnvelope, faEuroSign, faExternalLinkAlt, faExchangeAlt, faExclamation, faExclamationCircle, faExclamationTriangle, faFacebook, faEye, faEyeSlash, faFileAlt, faFlag, faFilter, faGlobe, faGift, faGraduationCap, faHandSpock, faHome, faHtml5, faHourglassHalf, faHashtag, faHistory, faIdCard, faImage, faInbox, faInfoCircle, faLanguage, faLevelDownAlt, faLink, faLinkedin, faList, faListOl, faMap, faMale, faMars, faMapMarkerAlt, faMapPin, faMedal, faMarsAndVenus, faMinus, faMobileAlt, faMoneyBillWave, faNewspaper, faPaypal, faPause, faPencilAlt, faPercent, faPlay, faPoll, faPhone, faPlayCircle, faPlus, faPlusCircle, faPrint, faQrcode, faQuestionCircle, faRegistered, faRetweet, faSave, faSearch, faShareAlt, faShoppingCart, faSignInAlt, faSignOutAlt, faSort, faSortDown,faSortUp, faSpinner, faStar, faStarHalf, faStop, faStripeS, faSync, faTelegram,faTasks, faTextHeight, faThumbsUp, faTimes, faTimesCircle, faTransgender, faTrash, faTrophy, faUndo, faUniversity, faUnlock, faUser, faUserPlus, faUsers, faUsersCog, faUserFriends, faUserCircle, faUserClock, faUserCheck, faVenus, faWalking);