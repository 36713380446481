const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const { ListGroup, ListGroupItem, Label, Table, ButtonGroup, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    IconTextEllipse = require("components/widgets/IconTextEllipse")
    StrikeOutText = require("components/widgets/StrikeOutText"),
    FontAwesome = require("components/widgets/FontAwesome");

const {EventTraining} = require("parse/_Domain");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const memoize = require("memoize-one").default;

const {isToday, getDay, addDays, getISOWeek, areIntervalsOverlapping} = require('date-fns');

const filterEvents = memoize((calEvents, calEventAmount, calendarBoundaries) => {
        let eventWeekIl = {};
        // month - used to ensure correct redraw of table
        if (calEvents) {
            //console.log("Filter Events " + calEvents.length);
            for (let i = 0; i < calEvents.length; i++) {
                // check if eventStart or  eventEnd is in this month
                let calEvent = calEvents[i];
                // console.log("Overlapping", calEvent.getCalStartDate(), calendarBoundaries)
                if (areIntervalsOverlapping({start: calEvent.getCalStartDate(), end: calEvent.getCalEndDate()},
                    {start: calendarBoundaries.firstDay, end:calendarBoundaries.lastDay})) {

                    // event is in the week range - set the event to start week!
                    let observeEventDate = calEvent.getCalStartDate();
                    // loop until last day is reached
                    while (observeEventDate <= calEvent.getCalEndDate()) {
                        let startWeekNumberEV = getISOWeek(observeEventDate);
                        if (startWeekNumberEV in calendarBoundaries.weekDict) {
                            // event starts in the shown range
                            addObjectToWeekDay(eventWeekIl, startWeekNumberEV, observeEventDate, calEvent);
                        }
                        // go to next day
                        observeEventDate = addDays(observeEventDate, 1);
                    }

                }
            }
        }
        return eventWeekIl;
});
const addObjectToWeekDay = function(weekIt, startWeekNumberEV, observeDate, object) {
    let startDayEv = getDay(observeDate);
    let weekKey = "wd" + startDayEv;
    if (startWeekNumberEV in weekIt) {
        // add to existing week object
        let extWeekDaysEvDict = weekIt[startWeekNumberEV];
        if (weekKey in extWeekDaysEvDict) {
            // day has already event
            extWeekDaysEvDict[weekKey].push(object);
        } else {
            // day has no event
            extWeekDaysEvDict[weekKey] = [object];
        }
    } else {
        let weekDaysEvDict = {};
        weekDaysEvDict[weekKey] = [object];
        weekIt[startWeekNumberEV] = weekDaysEvDict;
    }
};
class CalendarMonthView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {calendarBoundaries, fctWeekSumElement, calEvents, denyEdit} = this.props;
        const {weekKeys, weekDict} = calendarBoundaries;
        const filteredEvents = filterEvents(calEvents, calEvents.length, calendarBoundaries);
        return <React.Fragment>
            <Table responsive striped>
                <thead>
                <tr>
                    <th><I18n code="KW"/></th>
                    <th><I18n code="MO"/></th>
                    <th><I18n code="DI"/></th>
                    <th><I18n code="MI"/></th>
                    <th><I18n code="DO"/></th>
                    <th><I18n code="FR"/></th>
                    <th><I18n code="SA"/></th>
                    <th><I18n code="SO"/></th>
                </tr>
                </thead>
                <tbody>
                {
                    weekKeys.map(weekNumber => {
                        return <CalendarWeek key={"KW_" + weekNumber} weekNumber={weekNumber} denyEdit={denyEdit}
                                             dayList={ weekDict[weekNumber]} fctWeekSumElement={fctWeekSumElement}
                                             weekClubEvents={filteredEvents[weekNumber]}/>
                    })
                }
                </tbody>
            </Table>
        </React.Fragment>
    }
}
CalendarMonthView.propTypes = {
    calendarBoundaries:PropTypes.object.isRequired,
    calEvents: PropTypes.array,
    eventLoading: PropTypes.bool.isRequired,
    fctWeekSumElement: PropTypes.func,
    denyEdit: PropTypes.bool
};
const CalendarWeek = ({dayList, denyEdit, weekNumber, weekClubEvents, fctWeekSumElement}) => {
    function weekSumElement(weekNumber) {
        if (fctWeekSumElement) {
            return fctWeekSumElement(weekNumber)
        } else {
            return "(" + weekNumber + ")"
        }
    }
    return (
        <tr>
            <td style={{width: "40px"}}>
                {weekSumElement(weekNumber)}
            </td>
            {
                Object.keys(dayList).map(dayKey => {
                    let weekday = dayList[dayKey];
                    return <CalendarWeekDay key={"KW_" + weekNumber + "_day_" + weekday.getDate()} denyEdit={denyEdit} weekday={weekday}
                                            weekDayClubEvents={weekClubEvents ? weekClubEvents[dayKey] : null}/>
                })
            }
        </tr>
    );
};
CalendarWeek.propTypes = {
    dayList: PropTypes.object.isRequired,
    weekNumber: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired
    ]),
    weekClubEvents: PropTypes.object,
    fctWeekSumElement: PropTypes.func,
    denyEdit: PropTypes.bool
};
const CalendarWeekDay = ({weekday, weekDayClubEvents, denyEdit}) => {
    const modalBasics = React.useContext(ModalBasicContext);
    function handleClickSelect() {
        if (!denyEdit) {
            let nCalEvent = new EventTraining();
            let today = new Date();
            nCalEvent.setDate(new Date(weekday.getFullYear(), weekday.getMonth(),  weekday.getDate(), today.getHours(), today.getMinutes(), 0))
            modalBasics.openObjectFct(nCalEvent, "create")
        }
    }
    let backgroundC = isToday(weekday) ? "grey" : "transparent";
    return (<td style={{minHeight:"180px" }}><ListGroup>
                <ListGroupItem style={{backgroundColor: backgroundC, width:"100%", textAlign: "left"}}
                               onClick={handleClickSelect}>
                    <strong>{weekday.getDate()}</strong>
                </ListGroupItem>
            {
                weekDayClubEvents != null ? <CalendarWeekDayEvents calEvents={weekDayClubEvents} denyEdit={denyEdit}/> : null
            }
            </ListGroup></td>
    );
};
CalendarWeekDay.propTypes = {
    weekday: PropTypes.object.isRequired,
    weekDayClubEvents: PropTypes.array,
    selectedObject: PropTypes.object,
    denyEdit: PropTypes.bool
};
const CalendarWeekDayEvents = ({calEvents, denyEdit}) =>{
    const modalBasics = React.useContext(ModalBasicContext);
    function handleClickSelect(calEvent) {
        modalBasics.openObjectFct(calEvent, "create")
    }
    function handleClickDelete(calEvent) {
        modalBasics.openObjectFct(calEvent, "delete")
    }
    return <React.Fragment>
        {
            calEvents.map(calEvent => {
                return <React.Fragment key={calEvent.id}>
                    <ListGroupItem  style={{backgroundColor: calEvent.getCalBackground(), padding: "5px"}} key={calEvent.id}>
                        <h4>
                            {
                                calEvent.getCalLink() ? <a href={linkUtil.getLink(calEvent.getCalLink())} target="_blank">
                                            <StrikeOutText strikeOut={calEvent.isDeleted()}>{calEvent.getCalName()}</StrikeOutText>
                                    </a>
                                    : <StrikeOutText strikeOut={calEvent.isDeleted()}>{calEvent.getCalName()}</StrikeOutText>
                            }
                            {
                                calEvent.getCalcAllowEdit() && !denyEdit ? <ButtonGroup className="button-right" >
                                    <Button onClick={() => handleClickSelect(calEvent)}>
                                        <FontAwesome icon="pencil-alt"/>
                                    </Button>
                                    <Button onClick={() => handleClickDelete(calEvent)}>
                                        <FontAwesome icon="trash"/>
                                    </Button>
                                </ButtonGroup> : null
                            }
                            <hr/>
                        </h4>
                        <IconTextEllipse icon="medal" text={calEvent.getCalAdditional()} />
                        <br/>
                        <IconTextEllipse icon="comment" text={calEvent.getCalDescription()} />
                    </ListGroupItem>
                </React.Fragment>
            })
        }
    </React.Fragment>;
}
CalendarWeekDayEvents.propTypes = {
    calEvents: PropTypes.array.isRequired,
    denyEdit: PropTypes.bool
};
module.exports = CalendarMonthView;
