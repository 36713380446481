const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Panel, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    ImageGalleryTeaser = require("components/widgets/ImageGalleryTeaser"),
    ImageBanner = require("components/widgets/ImageBanner"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    PanelCollapse = require("components/widgets/PanelCollapse"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SearchInputField = require("components/form/SearchInputField"),
    DecisionButton = require("components/form/DecisionButton"),
    TournamentResult = require("components/tournament/TournamentResult"),
    TournamentDetailPanel = require("components/tournament/TournamentDetailPanel"),
    TournamentAttendees = require("components/tournament/TournamentAttendees"),
    TournamentDashboardButton = require("components/tournamentmanage/TournamentDashboardButton"),
    TournamentUserRegistration = require("components/tournamentregister/TournamentUserRegistration"),
    TournamentRegistrationPairs = require("components/tournamentregister/TournamentRegistrationPairs"),
    TournamentAttendeesPairs = require("components/tournamentregister/TournamentAttendeesPairs");

const {BaseContext} = require("context/BaseContext");

const tournamentStore = require("stores/TournamentStore"),
    tournamentActions = require("actions/TournamentActions"),
    tournamentUserStore = require("stores/TournamentUserStore"),
    tournamentUserActions = require("actions/TournamentUserActions"),
    tournamentResultActions = require("actions/TournamentResultActions"),
    notificationActions = require("components/notification/NotificationActions"),
    userInfoActions = require("actions/UserInfoActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class TournamentDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        console.log("initial state tournament " + this.props.id);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);

        this.state = {
            tournamentStore: tournamentStore.getState(),
            tournamentUserStore: tournamentUserStore.getState(),
        }
    }
    
    componentDidMount() {
        tournamentStore.listen(this.handleTournamentChange);
        tournamentUserStore.listen(this.handleTournamentUserChange);
        notificationActions.reset();
        tournamentActions.showProgress();
        console.log("mount tournament " + this.props.tournamentId);
        tournamentActions.showDetails.defer(this.props.tournamentId);
    }
    componentWillUnmount() {
        tournamentActions.resetTournamentDetails.defer();
        tournamentUserActions.resetTournamentUsers.defer();
        tournamentStore.unlisten(this.handleTournamentChange);
        tournamentUserStore.unlisten(this.handleTournamentUserChange);
    }
    componentDidUpdate(prevProps) {
        const newID = this.props.tournamentId;
        if (newID !== prevProps.tournamentId) {
            console.log("ReceiveProps tournamentDetail ID " + newID);
            tournamentResultActions.resetTournamentResults.defer();
            tournamentUserActions.resetTournamentUsers.defer();
            tournamentActions.showDetails.defer(newID);
        }
    }
    handleTournamentChange(state) {
        this.setState({tournamentStore: state});
        if (state.tournamentDetail != null) {
            document.title = state.tournamentDetail.getName();
        }
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserStore: state});
    }
    render() {
        if (this.state.tournamentStore.tournamentDetail == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </Grid>)
        } else if (this.state.tournamentStore.tournamentDetail != null && this.state.tournamentStore.tournamentDetail.isDeleted()) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>)
        }
        return (<TournamentDetails
            secret={this.props.secret}
            tournament={this.state.tournamentStore.tournamentDetail}
            childTournaments={this.state.tournamentStore.childTournaments}
            tournamentUsers={this.state.tournamentUserStore.tournamentUsers}
            allTournamentUsers={this.state.tournamentUserStore.allTournamentUsers}
            tournamentUserTeams={this.state.tournamentUserStore.tournamentUserTeams}
            allTournamentUserTeams={this.state.tournamentUserStore.allTournamentUserTeams}
            searchParams={this.state.tournamentUserStore.searchParams}
            paidTournamentUserAmount={this.state.tournamentUserStore.paidTournamentUserAmount} />)
    }
}
TournamentDetailsPage.propTypes = {
    tournamentId: PropTypes.string.isRequired,
    secret: PropTypes.string
};

const TournamentDetails = ({tournament, secret, tournamentUsers, allTournamentUsers, tournamentUserTeams, allTournamentUserTeams, childTournaments, searchParams, paidTournamentUserAmount}) => {
    const { user } = React.useContext(BaseContext);
    const isPairRegistration = tournament.isTypePairRegistration();
    const showPOIBanner = tournament.isRegistrationPublished() || tournament.isCanceled() || !tournament.hasManagementID();
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            <Breadcrumb link="/tournament" code={tournament.getName()} active/>
                        </Breadcrumbs>
                </Col>
                <Col sm={12}>
                    <PageHeader><StrikeOutText strikeOut={tournament.isCanceled()}>{tournament.getName()}</StrikeOutText>
                        {
                            tournament.isManaged() && tournament.isEditor(user) ? <div className="pull-right">
                                <TournamentDashboardButton bsSize="large" tournament={tournament}/> </div>: null
                        }
                    </PageHeader>

                </Col>

            </Row>
            <Row>
                <ImageBanner imageObject={tournament.getBannerImageID()}/>
            </Row>
            <br/><br/>
            <Row>
                <Col lg={12}>
                    <TournamentDetailPanel user={user} tournament={tournament} childTournaments={childTournaments}/>
                </Col>
            </Row>
            {
                tournament.isManaged() ? null:
                <TournamentManagementTakeOver user={user} tournament={tournament}/>
            }
            {tournament.isRegistrationOpened() || tournament.isRegistrationFull() ?
                <Row>
                    {
                        tournament.checkSecret(secret) ? <Col lg={12}>
                                {
                                    isPairRegistration ?
                                        <TournamentRegistrationPairs user={user}
                                                                     tournament={tournament}
                                                                     tournamentUserTeams={allTournamentUserTeams}/>
                                        :
                                        <PanelCollapse header={<h3><I18n code="tournament.register.panel.title"/></h3>}>
                                            <React.Fragment>
                                                {
                                                    tournament.isRegistrationFull() ?
                                                        <Row><Col  xs={12} >
                                                            <Alert bsStyle="warning"><I18n code="tournament.register.full"/></Alert>
                                                        </Col></Row> : null

                                                }
                                                <TournamentUserRegistration user={user}
                                                                            tournament={tournament}/>
                                            </React.Fragment>
                                        </PanelCollapse>
                                }
                            </Col>
                            :
                            <SecretChecker tournament={tournament} />
                    }
                </Row>
                : ""
            }
            {
                tournament.getSponsorImageID() ?
                    <Row><ImageBanner imageObject={tournament.getSponsorImageID()}/></Row>: null
            }
            {
                showPOIBanner ? <PointInterestBanner/> : null
            }

            {tournament.showTournamentAttendees() ?
                <Row>
                    <Col lg={12}>
                        {
                            isPairRegistration ?
                                <TournamentAttendeesPairs user={user}
                                                       tournament={tournament}
                                                       tournamentUserTeams={tournamentUserTeams}
                                                       allTournamentUserTeams={allTournamentUserTeams}
                                                       tournamentUserSearchParams={searchParams}/>
                                :
                                <TournamentAttendees user={user}
                                                     tournament={tournament}
                                                     tournamentUsers={tournamentUsers}
                                                     allTournamentUsers={allTournamentUsers}
                                                     tournamentUserSearchParams={searchParams}
                                                     paidTournamentUserAmount={paidTournamentUserAmount}/>
                        }
                    </Col>
                </Row>
                : ""
            }
            <ErrorBoundary>
                <ImageGalleryTeaser tournament={tournament} user={user}/>
            </ErrorBoundary>
           {tournament.showResults() && (tournamentUsers.length > 0 || isPairRegistration)?
                <Row>
                    <Col lg={12}>
                        <TournamentResult user={user} tournament={tournament}/>
                    </Col>
                </Row>
                : ""
            }
        </Grid>)
}
TournamentDetails.propTypes = {
    secret: PropTypes.string,
    tournament:PropTypes.object,
    searchParams:PropTypes.object,
    paidTournamentUserAmount: PropTypes.number,
    tournamentUsers: PropTypes.array,
    allTournamentUsers: PropTypes.array,
    tournamentUserTeams: PropTypes.array,
    allTournamentUserTeams: PropTypes.array,
    childTournaments:PropTypes.array.isRequired
};
const SecretChecker = ({tournament}) => {
    const [secretCheckResult, setSecretCheckResult] = React.useState(null);
    function checkSecretClicked(secret) {
        if (tournament.checkSecret(secret.trim())) {
            setSecretCheckResult("Gleich beim ersten mal")
            window.location.replace(linkUtil.getLink("tournament/" + tournament.id + "/private/" + secret.trim()));
        } else {
            setSecretCheckResult("Nice try :-)")
        }
    }
    return <Col lg={12}>
        <PanelCollapse header={<h3><I18n code="tournament.register.panel.title"/></h3>}>
            <Alert bsStyle="info"><I18n code="tournament.register.private.info"/></Alert>
            {
                secretCheckResult != null ?
                    <Alert bsStyle="warning">{secretCheckResult}</Alert>
                    :
                    <SearchInputField placeholder={messages.get("tournament.register.private.enter")} type="text"
                                                    addonBefore={<FontAwesome icon="question-circle"/>}
                                                    startSearch={checkSecretClicked} />
            }

        </PanelCollapse>
    </Col>
}

const TournamentManagementTakeOver = ({user, tournament}) => {
    const takeTournamentManagement = () => {
        if (user != null && tournament != null) {
            userInfoActions.setTournamentRole(user);
            tournamentActions.setMainEditor(tournament, user);
        } else {
            console.log("WTF user or tournament null");
        }
    };
    return (<Row>
        <Col lg={12}>
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col md={12}>
                            <h4>{messages.get("tournament.manager.takeManagement")}</h4>
                        </Col>
                        <Col md={6}>
                            <p>{messages.get("tournament.manager.free") + " "}<br/>
                                {messages.get("tournament.manager.chargeable")}<br/><br/>
                                <strong><a href={linkUtil.getLink("solutions")}> {messages.get("management.request.form.info.solutions")}</a></strong>
                            </p>
                        </Col>
                        <Col md={6}>
                            <DecisionButton block bsSize="large" bsStyle="info"
                                user={user}
                                buttonText={messages.get("tournament.button.becameMainEditor")}
                                buttonIcon={"briefcase"}
                                titleCode={"tournament.button.becameMainEditor"}
                                messageCode={"modal.decision.body.becameMainEditor"}
                                okButtonCode={"modal.button.take"}
                                cancelButtonCode={"modal.button.cancel"}
                                handleOK={takeTournamentManagement}/>
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        </Col>
    </Row>)
};
module.exports = TournamentDetailsPage;
