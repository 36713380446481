const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert} = require("react-bootstrap");
var I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    EventPlayerList = require("components/profile/EventPlayerList");

const {BaseContext} = require("context/BaseContext");

var eventStore = require("stores/EventStore"),
    eventActions = require("actions/EventActions"),
    notificationActions = require("components/notification/NotificationActions");

class TabEventList extends React.Component {
    constructor(props) {
        super(props);
        this.handleEventChange = this.handleEventChange.bind(this);

        this.state = {
            eventStore: eventStore.getState(),
        }
    }
    componentDidMount() {
        const { user } = this.context;
        eventStore.listen(this.handleEventChange);
        notificationActions.reset();
        eventActions.reset();
        eventActions.queryEventList(user, this.props.year);
    }
    componentWillUnmount() {
        eventStore.unlisten(this.handleEventChange);
    }
    handleEventChange(state) {
        this.setState({eventStore: state});
    }
    render() {
        const { user } = this.context;
        if (this.state.eventStore.loading === true || this.state.eventStore.eventPlayers == null) {
            return (<Row><Col xs={12}><Loading/></Col></Row>)
        } else if (this.state.eventStore.loading === false && this.state.eventStore.eventPlayers.length <= 0){
            return (<Row><Col xs={12}><Alert bsStyle="warning"><I18n code="profile.events.empty"/></Alert></Col></Row>);
        } else if (this.state.eventStore.loading === false && this.state.eventStore.eventPlayers.length > 0) {
            return (<EventPlayerList eventPlayers={this.state.eventStore.eventPlayers} adminPage={false} user={user}/>);
        } else {
            console.log("WTF - never should be here");
            return null;
        }
    }
}
TabEventList.contextType = BaseContext;
TabEventList.propTypes = {
    year: PropTypes.number.isRequired
};
module.exports = TabEventList;
