const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Label} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    LoginButton = require("components/widgets/LoginButton"),
    Loading = require("components/widgets/Loading");

const {BaseContext} = require("context/BaseContext");

const notificationActions = require("components/notification/NotificationActions"),
    parcoursActions = require("actions/ParcoursActions"),
    parcoursStore = require("stores/ParcoursStore");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ParcoursLoader extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.state = {
            parcoursStore: parcoursStore.getState()
        }
    }
    componentDidMount() {
        const { user, checkUserAdmin } = this.context;
        parcoursStore.listen(this.handleParcoursChange);
        notificationActions.reset();
        const ratingUser = this.props.rating ? user : null;
        // edit check: F -> no check, T - mandatory check, A = check but only for managed courses
        const editUser = this.props.editCheck !== "F" ? user : null;
        parcoursActions.queryParcours(this.props.onlineID, ratingUser, editUser, this.props.editCheck === "A");
        if (this.props.tstat === "admin") {
            checkUserAdmin();
        }
    }
    componentWillUnmount() {
        parcoursStore.unlisten(this.handleParcoursChange);
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    render() {
        const { user, userIsAdmin, userInfoState:{userDetail, userFeatures, userLoaded} } = this.context;
        if (this.props.loginMandatory && user == null) {
            return (
                <Grid>
                    <br/><br/>
                    <Alert bsStyle="warning"><I18n code="parcours.edit.message.noLogin"/>: <LoginButton/></Alert>
                </Grid>)
        }
        if (userIsAdmin !== true && this.state.parcoursStore.invalidUser == true) {
            return (
                <Grid>
                    <br/><br/>
                    <Alert bsStyle="danger"><I18n code="parcours.invalidUser"/><br/>{this.props.invalidUserDesc}</Alert>
                </Grid>)
        }
        if (this.state.parcoursStore.loading === true) {
            return (
                <Grid>
                    <Loading/>
                </Grid>)
        }
        if (this.state.parcoursStore.parcours == null && this.state.parcoursStore.parseError == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>
            )
        }
        return <Grid>
            {React.cloneElement(this.props.children,
                { parcours: this.state.parcoursStore.parcours,
                    user: user,
                    userFeatures: userFeatures,
                    userDetail: userDetail,
                    userDetailLoading: !userLoaded,
                    ownRating: this.state.parcoursStore.ownRating,
                    parcoursArticles: this.state.parcoursStore.parcoursArticles,
                    showEventLink: userIsAdmin
                } )}
            </Grid>
    }
}
ParcoursLoader.contextType = BaseContext;
ParcoursLoader.propTypes = {
    onlineID:PropTypes.string.isRequired,
    editCheck:PropTypes.string.isRequired,
    loginMandatory: PropTypes.bool,
    rating:PropTypes.bool,
    invalidUserDesc:PropTypes.object,
    tstat: PropTypes.string
};
module.exports = ParcoursLoader;
