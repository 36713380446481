const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const LoadingCircle = require("components/widgets/LoadingCircle");
const FontAwesome = require("components/widgets/FontAwesome");

class SubmitButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handlePropClick = this.handlePropClick.bind(this);
        this.startLoading = this.startLoading.bind(this);
        this.resetLoading = this.resetLoading.bind(this);
        this.state = {
            loading: false,
            confirmButtons: false
        }
    }
    componentDidMount() {
        if (this.props.autoClickMS > 0) {
            setTimeout(() =>  {
                this.handleClick(null)
            }, this.props.autoClickMS);
        }
    }
    handleClick(event) {
        if (this.props.confirm) {
            this.setState({confirmButtons: true});
        } else {
            this.handlePropClick(event);
        }
    }
    handleCancel() {
        this.setState({confirmButtons: false});
    }
    handlePropClick(event) {
        if (!this.state.loading) {
            console.log("handle click ");
            this.setState({loading: true});
            if (this.props.onClick) {
                this.props.onClick(event);
            }
        }
    }
    startLoading() {
        this.setState({loading: true});
    }
    resetLoading() {
        this.setState({loading: false});
    }
    render() {
        const {bsSize,bsStyle, block, disabled, icon, text} = this.props;
        const {loading, confirmButtons} = this.state;
        if (confirmButtons) {
            return (<span>
                <Button onClick={this.handlePropClick}><FontAwesome icon="check-circle"/>{text}</Button>
                <Button onClick={this.handleCancel}><FontAwesome icon="times-circle"/></Button>
            </span>)
        } else {
            return(
                <Button bsSize={bsSize}
                        bsStyle={bsStyle ? bsStyle : "primary"}
                        block={block}
                        disabled={disabled}
                        onClick={this.handleClick}>
                    {
                        loading ?
                            <LoadingCircle />
                            :
                            <div>{icon}{text} </div>
                    }
                </Button>)
        }
    }
}
SubmitButton.propTypes = {
    onClick:PropTypes.func.isRequired,
    autoClickMS: PropTypes.number,
    disabled:PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    bsSize:PropTypes.string,
    bsStyle:PropTypes.string,
    block:PropTypes.bool,
    confirm: PropTypes.bool
};
module.exports = SubmitButton;
