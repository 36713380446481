const alt = require("AltInit");
const managementActions = require("actions/ManagementActions");

class ManagementStore {
    constructor() {
        this.management = null;
        this.showSpinner = false;
        this.club = null;
        this.bindListeners({
            onRegister: managementActions.register,
            onRegisterSuccess: managementActions.registerSuccess,
            onInitRequestParcoursManagePage: managementActions.initRequestParcoursManagePage,
            onInitRequestParcoursManagePageSuccess: managementActions.initRequestParcoursManagePageSuccess,
            onInitBusinessRegisterPage: managementActions.initBusinessRegisterPage,
            onInitBusinessRegisterPageSuccess: managementActions.initBusinessRegisterPageSuccess,
            onRequestParcoursManagement: managementActions.requestParcoursManagement,
            onRequestParcoursManagementSuccess: managementActions.requestParcoursManagementSuccess,
            onCancelManagementRequest: managementActions.cancelManagementRequest,
            onCancelManagementRequestSuccess: managementActions.cancelManagementRequestSuccess
        });
    }

    onRegister(management) {
        this.management = null;
    }

    onRegisterSuccess(management) {
        this.management = management;
    }

    onInitRequestParcoursManagePage(data) {
        this.management = null;
    }

    onInitRequestParcoursManagePageSuccess(data) {
        this.management = data.management;
    }

    onInitBusinessRegisterPage() {
        this.showSpinner = true;
        this.management = null;
    }

    onInitBusinessRegisterPageSuccess(data) {
        this.showSpinner = false;
        this.management = data.management;
        this.club = data.club;
    }

    onRequestParcoursManagement() {
        this.showSpinner = true;
    }

    onRequestParcoursManagementSuccess(management) {
        this.showSpinner = false;
        this.management = management;
    }

    onCancelManagementRequest() {
        this.showSpinner = true;
    }

    onCancelManagementRequestSuccess(management) {
        this.showSpinner = false;
        this.management = management;
    }

}

module.exports = alt.createStore(ManagementStore);
