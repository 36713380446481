const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    TextCenter = require("components/widgets/TextCenter"),
    ModalBasic = require ("components/modals/ModalBasic"),
    CCLogTable= require("components/payment/CCLogTable");

const msgActions = require("actions/MessageActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const MailLogModal = ({sender, management, buttonStyle}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code="club.button.mail.log"/></TextCenter>}
            desc={<div>
                <I18n code="modal.message.sender"/> {sender} <br/>
                {
                    management != null ? <I18n code="modal.message.receiver"> {management.getMailReceivers().join(",")}</I18n> : null
                }
            </div>}
            buttonStyle={buttonStyle}
            buttonChildren={<I18n code="club.button.mail.log"/>}>
            <MailLogForm sender={sender} management={management} />
        </ModalBasic>
    )
};
MailLogModal.propTypes = {
    sender: PropTypes.string.isRequired,
    management: PropTypes.object,
    buttonStyle: PropTypes.string.isRequired
};
class MailLogForm extends React.Component {
    constructor(props) {
        super(props);
        this.clickedSave = this.clickedSave.bind(this);
        this.updateCCLogList = this.updateCCLogList.bind(this);
        this.state = {
            loading: true,
            ccLogs: null
        }
    }
    componentDidMount() {
        //load email cclogs for sender
        msgActions.loadMailLogs(this.props.sender, this.props.management, ccLogs => {
            this.updateCCLogList(ccLogs)
        })
    }
    updateCCLogList(ccLogs) {
        this.setState({
            loading: false,
            ccLogs: ccLogs
        });
    }
    clickedSave(e) {
        const {closeFct} = this.context;
        e.preventDefault();
        closeFct();
    }
    render() {
        const {loading, ccLogs} = this.state;
        return (
            <form action="#">
                <Row>
                    {
                        loading ? <Col xs={12}><Loading/></Col> : null
                    }
                    {
                        ccLogs != null ? <CCLogTable ccLogs={ccLogs} bodyKey="subject"/> : null
                    }

                    <Col xs={12}>
                        <Button onClick={this.clickedSave} block bsStyle="success">
                            <I18n code={"modal.button.close"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
MailLogForm.propTypes = {
    sender: PropTypes.string.isRequired,
};
MailLogForm.contextType = ModalBasicContext;
module.exports = MailLogModal;
