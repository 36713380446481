const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Panel, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    ImageBanner = require("components/widgets/ImageBanner"),
    ErrorSuspenseBoundary = require("components/widgets/ErrorSuspenseBoundary"),
    messages = require("i18n/messages"),
    TournamentResult = require("components/tournament/TournamentResult"),
    TournamentDashboardButton = require("components/tournamentmanage/TournamentDashboardButton"),
    TournamentUserDetailPanel = require("components/tournament/TournamentUserDetailPanel"),
    TournamentDetailPanel = require("components/tournament/TournamentDetailPanel"),
    TournamentCupTable = require("components/tournament/TournamentCupTable");

const TournamentCupUserCardModal = React.lazy(() =>
    import(/* webpackChunkName: "TournamentResultPrint" */ "components/tournamentmodal/TournamentCupUserCardModal")
);

const {BaseContext} = require("context/BaseContext");

const tournamentStore = require("stores/TournamentStore"),
    tournamentActions = require("actions/TournamentActions"),
    tournamentUserStore = require("stores/TournamentUserStore"),
    tournamentUserActions = require("actions/TournamentUserActions"),
    notificationActions = require("components/notification/NotificationActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class TournamentCupUserLinkPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);
        this.findReferenceTournamentUser = this.findReferenceTournamentUser.bind(this);
        let today = new Date();
        this.state = {
            tournamentStore: tournamentStore.getState(),
            tournamentUserStore: tournamentUserStore.getState(),
            refTournamentUser: null,
            year:  today.getFullYear()
        }
    }
    componentDidMount() {
        tournamentStore.listen(this.handleTournamentChange);
        tournamentUserStore.listen(this.handleTournamentUserChange);
        notificationActions.reset();
        //cup/:buCode/:licNumber
        // eg: http://www.3dturnier.com/de/cup/HST/10000
        this.loadCupDetails(this.props.buCode, this.state.year, this.props.licNumber);
    }
    loadCupDetails(buCode, year, licNumber) {
        tournamentActions.showCupDetails(buCode, year, licNumber);
    }
    componentWillUnmount() {
        tournamentActions.resetTournamentDetails();
        tournamentActions.resetTournamentDetails();
        tournamentUserActions.resetTournamentUsers();
        tournamentStore.unlisten(this.handleTournamentChange);
        tournamentUserStore.unlisten(this.handleTournamentUserChange);
    }
    handleTournamentChange(state) {
        this.setState({tournamentStore: state});
        if (state.tournamentDetail) {
            document.title = state.tournamentDetail.getName();
        }
    }
    handleTournamentUserChange(state) {
        this.setState({
            tournamentUserStore: state,
            refTournamentUser: state.childTournamentUsers.length > 0 ? this.findReferenceTournamentUser(state.childTournamentUsers) : null
        });
    }
    findReferenceTournamentUser(childTournamentUsers) {
        let refTUser = null;
        let refCounter = 0;
        let primeGroupCounters = {};
        childTournamentUsers.map(tournamentUser => {
            let primeGroupID = tournamentUser.getTournamentConfigBow().id;
            let counterObj = primeGroupCounters[primeGroupID];
            if (counterObj) {
                counterObj.counter++;
            } else {
                primeGroupCounters[primeGroupID] = {counter: 1, tUser: tournamentUser};
            }
        });
        Object.keys(primeGroupCounters).map(primeGroupID => {
            let counterObj = primeGroupCounters[primeGroupID];
            if (counterObj.counter > refCounter) {
                refCounter = counterObj.counter;
                refTUser = counterObj.tUser;
            }
        });
        return refTUser;
    }
    render() {
        if (this.state.tournamentStore.tournamentDetail == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </Grid>)
        } else if (this.state.tournamentStore.tournamentDetail != null && this.state.tournamentStore.tournamentDetail.isDeleted()) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>)
        }
        return (<TournamentDetails
            showMasterResult={this.props.licNumber == null}
            tournament={this.state.tournamentStore.tournamentDetail}
            refTournamentUser={this.state.refTournamentUser}
            childTournaments={this.state.tournamentStore.childTournaments}
            childTournamentUsers={this.state.tournamentUserStore.childTournamentUsers}
        />)
    }
}
TournamentCupUserLinkPage.propTypes = {
    buCode: PropTypes.string.isRequired,
    licNumber: PropTypes.number,
};
const TournamentDetails = ({tournament, refTournamentUser, childTournamentUsers, childTournaments, showMasterResult}) => {
    const { user } = React.useContext(BaseContext);
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            <Breadcrumb link="/tournament" code={tournament.getName()} active/>
                        </Breadcrumbs>
                </Col>
                <Col lg={12}>
                    <PageHeader>{tournament.getName()}
                        {
                            tournament.isManaged() && tournament.isEditor(user) ? <div className="pull-right">
                                <TournamentDashboardButton bsSize="large" tournament={tournament}/> </div>: null
                        }
                    </PageHeader>
                </Col>
            </Row>
            <Row>
                <ImageBanner imageObject={tournament.getBannerImageID()}/>
            </Row>
            <Row>
                <Col lg={12}>
                    <TournamentDetailPanel user={user} tournament={tournament}/>
                </Col>
            </Row>
            {
                childTournaments != null && childTournaments.length > 0 ?
                    <Panel>
                        <Panel.Heading>
                            <Row><Col xs={12}>
                                <h3><I18n code="tournament.info.cupChilds"/></h3>
                            </Col></Row>
                        </Panel.Heading>
                        <Panel.Body>
                            <Row>
                                {
                                    refTournamentUser ? <React.Fragment>
                                        <Col xs={12} sm={6}>
                                            <TournamentUserDetailPanel tournamentUser={refTournamentUser}/>
                                            <br/>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <h3>{messages.get("tournament.modal.cupcard")}</h3>
                                            <ErrorSuspenseBoundary>
                                            <TournamentCupUserCardModal tournament={tournament} tournamentUser={refTournamentUser} />
                                            </ErrorSuspenseBoundary>
                                        </Col>
                                    </React.Fragment>: null
                                }
                                <Col xs={12}>
                                    <TournamentCupTable showActions={false}
                                                        tournament={tournament}
                                                        refTournamentUser={refTournamentUser}
                                                        childTournamentUsers={childTournamentUsers}
                                                        childTournaments={childTournaments}/>
                                </Col>
                            </Row>
                        </Panel.Body>
                    </Panel>
                    : null
            }
            {
                tournament.getSponsorImageID() ?
                    <Row><ImageBanner imageObject={tournament.getSponsorImageID()}/></Row>: null
            }
            {tournament.showResults() && showMasterResult?
                <Row>
                    <Col lg={12}>
                        <TournamentResult user={user} tournament={tournament}/>
                    </Col>
                </Row>
                : ""
            }
        </Grid>)
};
TournamentDetails.propTypes = {
    user:PropTypes.object,
    tournament:PropTypes.object,
    refTournamentUser: PropTypes.object,
    childTournamentUsers: PropTypes.array,
    childTournaments:PropTypes.array
};
module.exports = TournamentCupUserLinkPage;
