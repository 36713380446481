const Parse = require("ParseInit");

const TournamentOption = Parse.Object.extend("TournamentOption", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        objectId: "objectId",
        status: "status",
        options: "options",
        optionLabel: "optionLabel",
        exclOption: "exclOption",
        exclOptionLabel: "exclOptionLabel",
        desc:"desc",
        showResultFilter: "showResultFilter",
        updatedAt: "updatedAt",
    },
    status: {
        active: "A",
        inactive: "I"
    },
    hasOptions() {
        return this.getOptions() != null && this.getOptions().length > 0;
    },
    showResultFilter() {
        return this.get(this.col.showResultFilter);
    },
    setShowResultFilter(filter) {
        return this.set(this.col.showResultFilter, filter);
    },
    getSelectString() {
        return this.getOptionLabel()
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getOptions() {
        return this.get(this.col.options)
    },
    setOptions(options) {
        return this.set(this.col.options, options)
    },
    setExclOptionLabel(optionLabel) {
        return this.set(this.col.exclOptionLabel, optionLabel)
    },
    getExclOptionLabel() {
        return this.get(this.col.exclOptionLabel)
    },
    setExclOption(option) {
        return this.set(this.col.exclOption, option)
    },
    getExclOption() {
        // will query tUser but exclude this option
        return this.get(this.col.exclOption)
    },
    getOptionLabel() {
        return this.get(this.col.optionLabel)
    },
    setOptionLabel(label) {
        this.set(this.col.optionLabel, label)
    },
    getDescription() {
        return this.get(this.col.desc)
    },
    setDescription(desc) {
        this.set(this.col.desc, desc)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    isInactive() {
        return this.status.inactive === this.getStatus();
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    cloneTOption(tOption, status) {
        if (status) {
            this.setStatus(status);
        } else {
            this.setStatus(tOption.getStatus());
        }
        this.setOptions(tOption.getOptions());
        this.setOptionLabel(tOption.getOptionLabel())
        this.setDescription(tOption.getDescription())
        this.setShowResultFilter(tOption.showResultFilter())
        this.setExclOption(tOption.getExclOption())
        this.setExclOptionLabel(tOption.getExclOptionLabel())
    }
});

module.exports = TournamentOption;
