const React = require("react"),
    PropTypes = require('prop-types');
const {InputGroup, Button} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");
const {Typeahead, Menu, MenuItem} = require('react-bootstrap-typeahead');

const DateGroupInputTypeAhead = ({objectList, defaultValue, placeholder, disabled, handleNoObjectFound, handleSelectedObject}) => {
    const [value, setValue] = React.useState(null);
    function handleSubmitE(event) {
        event.preventDefault();
        if (handleNoObjectFound) {
            if (value) {
                handleNoObjectFound(value.trim());
            } else {
                handleNoObjectFound("");
            }

        }
    }
    function handleChange(selObjectsArr) {
        if (selObjectsArr.length == 1) {
            handleSelectedObject(selObjectsArr[0]);
        }
    }
    function onInputChange(event) {
        setValue(event)
    }
    let month = 0;
    return  (
        <form onSubmit={handleSubmitE}>
            <InputGroup>
                <InputGroup.Addon>
                    <FontAwesome icon="search"/>
                </InputGroup.Addon>
                <Typeahead defaultInputValue={defaultValue}
                           disabled={disabled || objectList == null}
                           minLength={2}
                           labelKey={object => object.getDatalistText ? object.getDatalistText() : ""}
                           renderMenu={(options, menuProps) => (
                               <Menu id={"DGITA"}{...menuProps}>
                                   {
                                       options.map((option, index) => {
                                           if (typeof option.getDatalistDate !== "undefined") {
                                               const date = option.getDatalistDate();
                                               if (month !== (date.getMonth() + 1)) {
                                                   month = date.getMonth() + 1;
                                                   return (
                                                       <React.Fragment key={"div" + option.id}>
                                                           <h4>&nbsp;{messages.get("montPager.month." + month) + " " + date.getFullYear()}</h4>
                                                           <MenuItem option={option} position={index}>
                                                               {option.getDatalistText()}
                                                           </MenuItem>
                                                       </React.Fragment>);
                                               } else {
                                                   return (<MenuItem option={option} position={index}>
                                                       {option.getDatalistText()}
                                                   </MenuItem>);
                                               }
                                           }
                                       })
                                   }
                               </Menu>
                           )}
                           emptyLabel={false}
                           multiple={false}
                           options={objectList}
                           placeholder={placeholder}
                           onChange={handleChange}
                           onInputChange={onInputChange}
                           submitFormOnEnter={handleNoObjectFound != null}
                />
                {
                    handleNoObjectFound != null ?
                        <InputGroup.Button>
                            <Button bsStyle="primary" type="submit">
                                <FontAwesome icon="level-down-alt" rotation={90}/>
                            </Button>
                        </InputGroup.Button> : null
                }

            </InputGroup>
        </form>)
}
DateGroupInputTypeAhead.propTypes = {
    placeholder:PropTypes.string,
    defaultValue: PropTypes.string,
    disabled:PropTypes.bool,
    handleNoObjectFound:PropTypes.func.isRequired,
    handleSelectedObject:PropTypes.func.isRequired,
    objectList:PropTypes.array.isRequired
};
module.exports = DateGroupInputTypeAhead;
