const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel, Alert, Button, Table, FormControl, FormGroup, InputGroup} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    ValidInput = require("components/form/ValidInput"),
    RadioGroupInput = require("components/form/RadioGroupInput"),
    SubmitButton = require("components/form/SubmitButton"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    Utility = require("util/Utility");

const {BaseContext} = require("context/BaseContext");

const UserActions = require("actions/UserActions"),
    sKillBoardStore = require("stores/SKillBoardStore"),
    sKillBoardActions = require("actions/SKillBoardActions");

const {CountType, CountTypeDtl, CountTypeGrp} = require("parse/_Domain");

class CountTypeCreate extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refName = React.createRef();
        this.refDesc = React.createRef();
        this.refMiss = React.createRef();
        
        this.handleSKillBoardChange = this.handleSKillBoardChange.bind(this);
        this.openCountTypeForm3D = this.openCountTypeForm3D.bind(this);
        this.openCountTypeFormDisc = this.openCountTypeFormDisc.bind(this);
        this.closeCountTypeForm = this.closeCountTypeForm.bind(this);
        this.clickChangeArrowSumUp = this.clickChangeArrowSumUp.bind(this);
        this.clickChangeArrows = this.clickChangeArrows.bind(this);
        this.clickChangeZones = this.clickChangeZones.bind(this);
        this.valid = this.valid.bind(this);
        this.validationError = this.validationError.bind(this);
        this.handleCountTypeDtl = this.handleCountTypeDtl.bind(this);
        this.validateUnique = this.validateUnique.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.int2Array = this.int2Array.bind(this);

        this.state = {
            sKillBoardStore: sKillBoardStore.getState(),
            form3D: true,
            error: null,
            arrowAmount: [1],
            zoneAmount: [1],
            arrowSumUp: false
        }
    }
    componentDidMount() {
        sKillBoardStore.listen(this.handleSKillBoardChange);
    }
    componentWillUnmount() {
        sKillBoardStore.unlisten(this.handleSKillBoardChange);
    }
    handleSKillBoardChange(state) {
        this.setState({sKillBoardStore: state});
    }
    openCountTypeForm3D() {
        const { user } = this.context;
        // validate user
        if (user !== null) {
            if (!Utility.hasWriteLock(user, false)) {
                this.setState({
                    form3D: true,
                    arrowSumUp: false
                });
                sKillBoardActions.updateShowForm(true);
            }
        } else {
            UserActions.showLoginModal();
        }
    }
    openCountTypeFormDisc() {
        const { user } = this.context;
        // validate user
        if (user !== null) {
            this.setState({
                form3D: false,
                arrowSumUp: true
            });
            sKillBoardActions.updateShowForm(true);
        } else {
            UserActions.showLoginModal();
        }
    }
    closeCountTypeForm() {
        sKillBoardActions.updateShowForm(false);
        this.validationError({error: null});
    }
    clickChangeArrowSumUp(value) {
        console.log("ArrowSumUp " + value);
        const valArr = value.split(".");
        this.setState({arrowSumUp: (valArr[2] == 'true')});
    }
    clickChangeArrows(value) {
        const amount = parseInt(value.currentTarget.selectedOptions[0].value);
        console.log("Arrows " + amount);
        this.setState({arrowAmount: this.int2Array(amount)});
    }
    clickChangeZones(value) {
        const amount = parseInt(value.currentTarget.selectedOptions[0].value);
        console.log("Zones " + amount);
        this.setState({zoneAmount: this.int2Array(amount)});
    }
    valid() {
        const that = this;
        let valid = true;
        let required = [
            this.refName.current.getValue().trim(),
            this.refMiss.current.getValue().trim()
        ];
        // check zone and arrow length 1
        if (that.state.zoneAmount.length == 1 && that.state.arrowAmount.length == 1) {
            this.validationError({error: messages.get("countType.error.arrowZonesToLess")});
            valid = false;
        } else {
            that.state.zoneAmount.map(function(zoneNr) {
                that.state.arrowAmount.map(function(arrowNr) {
                    const key = "P" + arrowNr + "Z" + zoneNr;
                    required.push(that.refs[key].getValue().trim());
                });
            });
        }
        //
        for (var i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.validationError({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    validationError(stateError) {
        this.refSubmitButton.current.resetLoading();
        this.setState(stateError);
    }
    handleCountTypeDtl(points, arrowNr, zoneNr, zoneName) {
        const length = this.state.sKillBoardStore.countTypeDetails.length;
        for (let i = 0; i < length; i++) {
            const countTypeDtl = this.state.sKillBoardStore.countTypeDetails[i];
            if (countTypeDtl.equalsValues(points, arrowNr, zoneNr, zoneName)) {
                console.log("FOUND " + countTypeDtl.id + " arrowNr:" + arrowNr + "  killNr:" + zoneNr + "  points:" + points + "  zoneName: " + zoneName);
                return countTypeDtl;
            }
        }
        // didnt found - create new one
        console.log("CREATE: arrowNr:" + arrowNr + "  killNr:" + zoneNr + "  points:" + points + "  zoneName: " + zoneName);
        return CountTypeDtl.prototype.buildCountTypeDetail(points, arrowNr, zoneNr, zoneName)
    }
    validateUnique(name, missPoints, countTypeDetails) {
        const that = this;
        return sKillBoardActions.validateUniqueCountType(name, missPoints, countTypeDetails, this.state.sKillBoardStore.allCountTypes,
            function (errorCode, countType) {
                that.validationError({error: messages.get(errorCode) + ": " + messages.get(countType.getName())});
            });
    }
    clickedSave(e) {
        const { user } = this.context;
        const that = this;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({error: null});
        // validate different zone Names for each Zone
        const zoneKeys = [];
        const zoneLength = that.state.zoneAmount.length;
        const arrowLength = that.state.arrowAmount.length;
        for(let zL1 = 0; zL1 < zoneLength; zL1++) {
            const zKey = "SEL_PZ" + that.state.zoneAmount[zL1];
            const zoneSelect = that.refs[zKey].getValue();
            for (let z = 0; z < zoneKeys.length; z++) {
                if (zoneKeys[z] == zoneSelect.id) {
                    // found same zone
                    that.validationError({error: messages.get("countType.error.duplicateZone")});
                    return
                }
            }
            zoneKeys.push(zoneSelect.id);
        }
        // build countType Details
        const countTypeDetails = [];
        let highestSinglePoints = 0;
        that.state.arrowAmount.map(function(arrowNr) {
            that.state.zoneAmount.map(function(zoneNr) {
                const zKey = "SEL_PZ" + zoneNr;
                const zoneSelect = that.refs[zKey].getValue();
                const key = "P" + arrowNr + "Z" + zoneNr;
                const val = parseInt(that.refs[key].getValue().trim());
                if (val > highestSinglePoints) {
                    highestSinglePoints = val;
                }
                // find correct detail and add to array
                countTypeDetails.push(that.handleCountTypeDtl(val, arrowNr, zoneNr, zoneSelect.getName()));
            });
        });
        const name = this.refName.current.getValue().trim();
        const missPoints = parseInt(this.refMiss.current.getValue().trim());
        const arrowSum = arrowLength == 1 ? false : this.state.arrowSumUp;
        let onlineID = arrowLength + "P" + zoneLength + "Z_" + highestSinglePoints + "_" + arrowSum.toString()[0];
        let typeID =  CountTypeGrp.prototype.typeIDs.custom;
        if (!this.state.form3D) {
            // adapt for disc scoring
            onlineID = "D" + zoneLength + "Z_" + highestSinglePoints + "_" + arrowSum.toString()[0];
            typeID =  CountTypeGrp.prototype.typeIDs.disc;
        }
        let maxTargetPoints = 0;
        if (arrowSum) {
            maxTargetPoints = highestSinglePoints * arrowLength;
        } else {
            maxTargetPoints = highestSinglePoints
        }

        // check for same arrow and zones and points
        if (!this.validateUnique(name, missPoints, countTypeDetails)) {
            // not unique
            return;
        }
        // unique - build countType Object
        const countType = CountType.prototype.buildCountType(user, name, onlineID,
            this.refDesc.current.getValue().trim(), arrowSum, missPoints, maxTargetPoints, typeID);
        countType.setCountTypeDtls(countTypeDetails);
        // create it - add it to list
        that.setState({error: null});
        sKillBoardActions.createCountType(countType);
    }
    int2Array(maxValue) {
        const array = [];
        for (let i = 1; i <= maxValue; i++) {
            array.push(i);
        }
        return array
    }
    render() {
        // resize table depending amount of arrows
        const {form3D, arrowSumUp} = this.state;
        const that = this;
        const colClass = "col-xs-" + (5 - this.state.arrowAmount.length).toString();
        if (this.state.sKillBoardStore.showForm) {
            var validateNow = this.state.error && this.state.error.length > 0;
            return (<Col xs={12} >
                <Panel>
                    <Panel.Heading>
                        <Row>
                            <Col xs={12}>
                                {
                                    form3D ? <h2><I18n code="countType.button.create3D"/></h2>
                                        : <h2><I18n code="countType.button.createDisc"/></h2>
                                }

                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body>
                        <form>
                            <Row>
                                <Col sm={12}>
                                    <ValidInput ref={this.refName}
                                                valid={{maxLength: 500, check:['isRequired']}}
                                                validateNow={validateNow}
                                                placeholder={messages.get("tournament.table.column.name")}/>
                                    <ValidInput ref={this.refDesc}
                                                placeholder={messages.get("club.event.create.message.note")}
                                                componentClass="textarea"
                                                valid={{maxLength: 1500}}/>
                                    <ValidInput ref={this.refMiss}
                                                valid={{maxLength: 5, check:['isRequired', 'isNumber', 'point4comma']}}
                                                placeholder={messages.get("Count_type_miss_points")}
                                                validateNow={validateNow}/>
                                    <RadioGroupInput default={"countType.arrowSumUp." + arrowSumUp}
                                                     disabled={!form3D}
                                                     list={["countType.arrowSumUp.false", "countType.arrowSumUp.true"]}
                                                     onAfterChange={that.clickChangeArrowSumUp}/>
                                </Col>
                                <Col xs={12}>
                                    <br/>
                                    <h4>{messages.get("countType.desc.arrowZones")}</h4>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><I18n code="tournament.table.column.arrows"/></InputGroup.Addon>
                                            <FormControl componentClass="select" onChange={this.clickChangeArrows}
                                            disabled={!form3D}>
                                                {[1, 2, 3].map(function(fct) {
                                                    return <option key={fct} value={fct}>{messages.get(fct)}</option>
                                                })}
                                            </FormControl>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><I18n code="Anzahl der Zonen"/></InputGroup.Addon>
                                            <FormControl componentClass="select" onChange={this.clickChangeZones}>
                                                {[1, 2, 3, 4, 5, 6].map(function(fct) {
                                                    return <option key={fct} value={fct}>{messages.get(fct)}</option>
                                                })}
                                            </FormControl>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                                this.state.arrowAmount.length > 1 || this.state.zoneAmount.length > 1 ?
                                    <Row>
                                        <Col xs={12}>
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th className="col-xs-3"><I18n code="ResultStatBean_zone"/></th>
                                                    {
                                                        that.state.arrowAmount.map(function(arrowNr) {
                                                            return <th className={colClass} key={"arrow" + arrowNr}>{messages.get("ResultStatBean_arrow") + " " + arrowNr}</th>
                                                        })
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    that.state.zoneAmount.map(function(zoneNr) {
                                                        const zKey = "PZ" + zoneNr;
                                                        return <tr key={zKey}>
                                                            <td>
                                                                <DropDownParseObject ref={"SEL_" + zKey}
                                                                                     default={that.state.sKillBoardStore.zoneNames[0]}
                                                                                     objectList={that.state.sKillBoardStore.zoneNames}/>
                                                            </td>
                                                            {
                                                                that.state.arrowAmount.map(function(arrowNr) {
                                                                    const key = "P" + arrowNr + "Z" + zoneNr;
                                                                    return <td key={key}>
                                                                        <ValidInput ref={"P" + arrowNr + "Z" + zoneNr}
                                                                                    valid={{maxLength: 5, check:['isRequired', 'isNumber', 'point4comma']}}
                                                                                    validateNow={validateNow}/>
                                                                    </td>
                                                                })
                                                            }
                                                        </tr>
                                                    })
                                                }
                                                {

                                                }


                                                </tbody>

                                            </Table>
                                        </Col>
                                    </Row>
                                    :null
                            }

                            <Row>
                                {this.state.error ?
                                    <Alert bsStyle="warning">{this.state.error}</Alert>
                                    : ""}
                                <br/>
                                <Col xs={12}>
                                    <Alert bsStyle="info">{messages.get("countType.save.info")}</Alert>
                                </Col>
                                <br/><br/>
                                <Col xs={6}>
                                    <SubmitButton ref={this.refSubmitButton} block
                                                  onClick={this.clickedSave} disabled={false}
                                                  text={messages.get("modal.button.save")}
                                                  bsStyle="success"/>
                                </Col>
                                <Col xs={6}>
                                    <Button onClick={this.closeCountTypeForm} block bsStyle="default"><I18n code={"modal.button.cancel"}/></Button>
                                </Col>
                            </Row>
                        </form>
                    </Panel.Body>
                </Panel></Col>)
        } else {
             return <Col sm={6} >
                    <Button block bsStyle="primary" onClick={this.openCountTypeForm3D}
                            disabled={this.state.sKillBoardStore.zoneNames == null
                            || this.state.sKillBoardStore.countTypes == null
                            || this.state.sKillBoardStore.countTypeDetails == null}>
                        <FontAwesome icon="plus"/><I18n code="countType.button.create3D"/>
                    </Button>
                     {/*<Button block bsStyle="primary" onClick={this.openCountTypeFormDisc}*/}
                             {/*disabled={this.state.sKillBoardStore.zoneNames == null*/}
                             {/*|| this.state.sKillBoardStore.countTypes == null*/}
                             {/*|| this.state.sKillBoardStore.countTypeDetails == null}>*/}
                         {/*<FontAwesome icon="plus"/><I18n code="countType.button.createDisc"/>*/}
                     {/*</Button>*/}
                </Col>
        }

    }
}
CountTypeCreate.contextType = BaseContext;
module.exports = CountTypeCreate;
