const React = require("react");
const {BaseContext} = require("context/BaseContext");
const UserFeatures = require("beans/UserFeatures");

const notificationActions = require("components/notification/NotificationActions");
const userInfoActions = require("actions/UserInfoActions");
const languageActions = require("actions/LanguageActions");
const ParseListUtility = require("util/ParseListUtility");

const cookieUtil = require("util/cookieUtil");
// const {func} = require("prop-types");
const supportedLanguages = ['en', 'de']

const BaseContextProvider = ({children}) =>  {
    let defaultLanguage = "de";
    let defaultType = "A";
    let cookie = cookieUtil.read();
    if (cookie.language) {
        defaultLanguage = cookie.language;
    }
    if (cookie.imageDeviceType) {
        defaultType = cookie.imageDeviceType
    }

    const [user, setUser] = React.useState(null);
    const [userInfoState, setStateUserInfo] = React.useState({
        userDetail: null,
        userAbos: null,
        userFeatures: new UserFeatures(),
        userFavorite: null,
        userLoaded: false
    })
    const [languageState, setStateLanguage] = React.useState({
        language: defaultLanguage,
        supportedLanguages: supportedLanguages,
        imageDirPrefix: "",
        imageDeviceType: defaultType
    });
    const [tournamentMenuId, setTournamentIdForMenu] = React.useState(null);
    const [userIsAdmin, setUserAdmin] = React.useState(null);
    function updateLanguage(urlLanguage) {
        if (languageState.supportedLanguages.indexOf(urlLanguage) > -1) {
            // only handle validated language
            cookieUtil.update("language", urlLanguage);
            setStateLanguage({
                language: urlLanguage,
                supportedLanguages: supportedLanguages,
                imageDirPrefix: urlLanguage !== "de" ? "/" + urlLanguage : "",
                imageDeviceType: defaultType
            })
            languageActions.switchLanguage(urlLanguage);
        }
    }
    function updateImageDeviceType(type) {
        cookieUtil.update("imageDeviceType", type);
        defaultType = type;
        setStateLanguage(prevState => ({
            ...prevState,
            imageDeviceType: type
        }))
    }
    function initUser(user) {
        if (user != null) {
            userInfoActions.refreshUser(user, callbackUser => {
                updateUser(callbackUser)
            })
        } else {
            // read only user
            setStateUserInfo({
                userDetail: null,
                userAbos: null,
                userFeatures: new UserFeatures(),
                userFavorite: null,
                userLoaded: true
            })

        }

    }
    function updateUser(user) {
        // console.log("Provider.setUser", user)
        setUser(user)
        fetchUserInfos(user).then()
        // TODO handle userEmail Verification
        // notificationStore.verifyUserEmail
        // TODO handle Abo pause
        // Todo handle change any of user or userDetail
    }
    async function fetchUserInfos(user) {
        let nUserInfo = await userInfoActions.fetchUserInfos(user);
        // console.log("nUserInfo", nUserInfo)
        setStateUserInfo(nUserInfo)
    }
    function updateUserInfoState(updatedObjects) {
        if (updatedObjects.reloadUser === true) {
            // user object need to be reloaded
            initUser(user);
            return;
        }
        let changed = false;
        let userInfos = {
            userDetail: userInfoState.userDetail,
            userAbos: userInfoState.userAbos,
            userFeatures: userInfoState.userFeatures,
            userFavorite: userInfoState.userFavorite,
            userLoaded: true
        }
        if (updatedObjects.user) {
            setUser(updatedObjects.user)
        }
        if (updatedObjects.usrDetail) {
            changed = true;
            userInfos.userDetail = updatedObjects.usrDetail
        }
        if (updatedObjects.userFavorite) {
            changed = true;
            userInfos.userFavorite = updatedObjects.userFavorite
        }
        if (updatedObjects.sUserAbo) {
            changed = true;
            // single userAbo changed - update List and userFeatures
            ParseListUtility.updateOrPush(userInfos.userAbos, updatedObjects.sUserAbo);
            let nFeat = new UserFeatures();
            nFeat.setUserFeatures(userInfos.userAbos);
            userInfos.userFeatures = nFeat;
        }
        if (changed) {
            setStateUserInfo(userInfos);
        }
    }
    function checkUserAdmin(callback) {
        userInfoActions.checkIsAdmin(result => {
            console.log("Check user admin", result)
            setUserAdmin(result)
            if (callback) {
                callback(result)
            }
        })
    }
    return <BaseContext.Provider value={{ user, userInfoState, initUser, updateUserInfoState, userIsAdmin, checkUserAdmin,
        tournamentMenuId, setTournamentIdForMenu,
        languageState, updateLanguage, updateImageDeviceType}}>
        {children}
    </BaseContext.Provider>
};
module.exports = BaseContextProvider;