const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Well} = require("react-bootstrap");
const {BaseContext} = require("context/BaseContext");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    PaymentBrandSelect = require("components/payment/PaymentBrandSelect"),
    PaymentAddressForm = require("components/payment/PaymentAddressForm"),
    CheckboxInput = require("components/form/CheckboxInput"),
    SubmitButton = require("components/form/SubmitButton"),
    Loading = require("components/widgets/Loading");

const Utility = require("util/Utility");
const messages = require("i18n/messages");
const paymentElementsStore = require("stores/PaymentElementsStore"),
    paymentElementsActions = require("actions/PaymentElementsActions");
const transactionActions = require("actions/TransactionActions");

class PaymentElements extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refPaymentAddress = React.createRef();

        this.handlePayEleStoreChange = this.handlePayEleStoreChange.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.addressFormChanged = this.addressFormChanged.bind(this);
        this.updatePayCode = this.updatePayCode.bind(this);
        this.handleRuleSwitch = this.handleRuleSwitch.bind(this);
        this.startPendingTransaction = this.startPendingTransaction.bind(this);
        this.createTransaction = this.createTransaction.bind(this);

        let ruleSwitch = true;
        if (this.props.ruleImageID != null
            && this.props.ruleImageID.get("image") != null) {
            ruleSwitch = false;
        }
        this.state = {
            paymentElementsStore: paymentElementsStore.getState(),
            addressChanged: false,
            help: null,
            rSwitch: ruleSwitch,
        }
    }
    componentDidMount() {
        paymentElementsStore.listen(this.handlePayEleStoreChange);
    }
    componentWillUnmount() {
        paymentElementsStore.unlisten(this.handlePayEleStoreChange);
    }
    handlePayEleStoreChange(state) {
        this.setState({paymentElementsStore: state});
    }
    showHelpMessage(message) {
        this.refSubmitButton.current.resetLoading();
        this.setState({
            help: message
        });
        setTimeout(() => {
            this.showHelpMessage(null);
        }, 5000);
    }
    addressFormChanged(changed) {
        this.setState({addressChanged: changed});
    }
    updatePayCode(pPayCode) {
        paymentElementsActions.updatePayCode(pPayCode);
    }
    handleRuleSwitch(value) {
        this.setState({rSwitch: value})
    }
    startPendingTransaction() {
        const {clubInvoice} = this.props;
        let address = null;
        if (clubInvoice != null && clubInvoice.getAddressID() != null) {
            address = clubInvoice.getAddressID().buildTransactionAddress();
        } else if (this.refPaymentAddress.current && this.refPaymentAddress.current.valid()) {
            address = this.refPaymentAddress.current.buildTransactionAddress();
        }
        if (address == null) {
            this.showHelpMessage(messages.get("checkout.error.address"));
            return;
        }
        // handle start transaction for clubInvoice
        const params = {
            address: address,
            clInvID: clubInvoice.id
        };
        transactionActions.startPendingTransaction(params, "startClInvoiceTransaction")
    }
    createTransaction() {
        const {surcharge, payCode, articles, discount, afterPaymentObject, hideInAppPayMethod} = this.state.paymentElementsStore;
        const {clubInvoice} = this.props;
        // build article dic
        if (articles == null) {
            this.showHelpMessage(messages.get("checkout.error.articles"));
            return;
        }
        let address = null;
        if (clubInvoice != null && clubInvoice.getAddressID() != null) {
            address = clubInvoice.getAddressID().buildTransactionAddress();
        } else if (this.refPaymentAddress.current && this.refPaymentAddress.current.valid()) {
            address = this.refPaymentAddress.current.buildTransactionAddress();
        }
        if (address == null) {
            this.showHelpMessage(messages.get("checkout.error.address"));
            return;
        }
        const articleAmounts = [];
        Object.keys(articles).map(articleId => {
            const amount = articles[articleId];
            if (amount > 0) {
                if (this.props.tournament) {
                    articleAmounts.push({
                        tUserId: articleId,
                        amount: 1
                    });
                } else {
                    articleAmounts.push({
                        articleId: articleId,
                        amount: amount
                    });
                }

            }
        });
        if (articleAmounts.length <= 0) {
            this.showHelpMessage(messages.get("checkout.error.articles"));
            return;
        }
        if (this.props.clubInvoice) {
            // handle start transaction for clubInvoice
            const params = {
                address: address,
                clInvID: this.props.clubInvoice.id
            };
            if (payCode != null) {
                params.paymentCode = payCode;
            } else if (!hideInAppPayMethod){
                this.showHelpMessage(messages.get("checkout.error.paycode"));
                return;
            }
            if (surcharge > 0) {
                params.surcharge = surcharge;
            }
            transactionActions.startTransaction(params, "startClInvoiceTransaction")
        } else {
            // start transaction
            const params = {
                parcoursID: this.props.parcours.id,
                parcoursOwnerID: this.props.parcours.getParcoursOwnerID().id,
                articles: articleAmounts,
                address: address,
                discount: discount,
                allowPending: true,
                device: "web-pc"
            };
            if (Utility.isAndroid()) {
                params.device = "web-android";
            } else if (Utility.isIOS()) {
                params.device = "web-ios";
            }
            if (this.props.parcours.getParcoursOwnerID().getManagementID()) {
                params.managementID=  this.props.parcours.getParcoursOwnerID().getManagementID().id;
            }
            if (this.props.tournament) {
                params.tournamentID= this.props.tournament.id;
                params.managementID= this.props.tournament.getManagementID().id;
            }
            if (afterPaymentObject) {
                params.afterPayment = afterPaymentObject;
            }
            if (payCode != null) {
                params.paymentCode = payCode;
            } else if (!hideInAppPayMethod){
                this.showHelpMessage(messages.get("checkout.error.paycode"));
                return;
            }
            if (surcharge > 0) {
                params.surcharge = surcharge;
            }
            transactionActions.startTransaction(params, "startTransaction")
        }
    }
    render() {
        const {sum, surcharge, payCode, paymentName, invalidPayments, hideInAppPayMethod} = this.state.paymentElementsStore;
        const {articleElement, ruleImageID, clubInvoice, openInvoiceInfo} = this.props;
        const { user, userInfoState:{ userDetail, userLoaded} } = this.context;
        const {rSwitch, addressChanged, help} = this.state;
        let ruleLink = null;
        if (ruleImageID && ruleImageID.getImage()) {
            ruleLink = <a href={ruleImageID.getImage()._url} target="_blank">{" " + messages.get("checkout.info.rules")}</a>
        }
        return (<React.Fragment>
            <Row>
                {articleElement}
                {
                    clubInvoice != null && clubInvoice.getAddressID() != null ? null :
                        <Col xs={12} md={8} mdOffset={2}>
                            <h2><FontAwesome icon="user"/><I18n code="profile.account.user.data.header"/></h2>
                            <p><I18n code="checkout.info.profile"/></p>
                            {
                                !userLoaded ? <Loading/> :
                                    <PaymentAddressForm ref={this.refPaymentAddress} showPhone={false}
                                                        userDetail={userDetail}
                                                        user={user}
                                                        changedCallback={this.addressFormChanged}/>
                            }
                            <br/>
                        </Col>
                }
                {
                    hideInAppPayMethod ? null : <Col xs={12} md={8} mdOffset={2}>
                        <h2><FontAwesome icon="money-bill-wave"/><I18n code="checkout.info.choose.brand"/></h2>
                        {
                            surcharge > 0 ?
                                <p><strong>{messages.get("tournament.table.column.surcharge") + ": "}</strong>{surcharge.toFixed(2) + " EUR" }</p>
                                : null
                        }
                        <PaymentBrandSelect payCode={payCode} handleBrandButton={this.updatePayCode} invalidPayments={invalidPayments}/>
                    </Col>
                }

                <Col xs={12} md={8} mdOffset={2}>
                    <br/>
                    {
                        ruleLink ?
                            <Well>
                                <CheckboxInput default={rSwitch}
                                               onAfterChange={value => {
                                                   this.handleRuleSwitch(value);
                                               }}
                                               message={messages.get("checkout.info.accept.rules")}
                                               addonAfter={ruleLink}
                                />
                            </Well> : null
                    }
                </Col>
                <Col xs={8} xsOffset={2} style={{"textAlign": "center"}}>
                    {
                        help == null ? null : <Alert bsStyle="warning">{help}</Alert>
                    }
                    <SubmitButton ref={this.refSubmitButton} block bsSize="large"
                                  disabled={sum <= 0 || (!hideInAppPayMethod && payCode == null)  || rSwitch === false || (user != null && addressChanged)}
                                  onClick={this.createTransaction}
                                  text={messages.get("checkout.button.payment") + ": " + (sum + surcharge).toFixed(2) + " EUR"}/>
                </Col>
            </Row>
                {
                    openInvoiceInfo ? <OpenInvoicePanel openInvoiceInfo={openInvoiceInfo} onClick={this.startPendingTransaction}/> : null

                }
            <Row>
                <Col xs={12}>
                    <br/>
                    <hr/>
                    {
                        paymentName ?
                            <small>
                                <h4>{messages.get("checkout.info.mail")}</h4>
                            </small>
                            :
                            <React.Fragment>
                                <h4>{messages.get("checkout.info.mail")}</h4>
                                <small>
                                    <br/>
                                    {messages.get("checkout.info.forward")}
                                </small>
                            </React.Fragment>

                    }

                </Col>
            </Row></React.Fragment>)
    }
}
PaymentElements.contextType = BaseContext;
PaymentElements.propTypes = {
    tournament:PropTypes.object,
    clubInvoice:PropTypes.object,
    openInvoiceInfo:PropTypes.object,
    parcours:PropTypes.object.isRequired,
    articleElement:PropTypes.object.isRequired,
    ruleImageID:PropTypes.object
};
const OpenInvoicePanel = ({openInvoiceInfo, onClick}) => {
    return (
        <Row>
            <Col x={12}><hr/></Col>
            <Col xs={8} xsOffset={2}>
                {openInvoiceInfo}
            </Col>
            <Col xs={8} xsOffset={2} style={{"textAlign": "center"}}>
                <SubmitButton block bsSize="large" bsStyle="default"
                              onClick={onClick}
                              text={messages.get("club.invoice.open.pay")}/>
            </Col>
        </Row>)
};
OpenInvoicePanel.propTypes = {
    clubInvoice: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};
module.exports = PaymentElements;
