const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Panel} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    AddressInfo = require("components/widgets/AddressInfo"),
    FontAwesome = require("components/widgets/FontAwesome"),
    PaymentElements = require("components/payment/PaymentElements");

const ClubInvoiceArticleTable = require("components/clubaccounting/ClubInvoiceArticleTable");

const notificationActions = require("components/notification/NotificationActions");

const clubInvoiceActions = require("actions/ClubInvoiceActions"),
    clubInvoiceStore = require("stores/ClubInvoiceStore");
    paymentElementsStore = require("stores/PaymentElementsStore"),
    paymentElementsActions = require("actions/PaymentElementsActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubInvoiceCheckoutPage extends React.Component {

    constructor(props) {
        super(props);
        this.handlePayEleStoreChange = this.handlePayEleStoreChange.bind(this);
        this.handleClubChange = this.handleClubChange.bind(this);
        this.state = {
            paymentElementsStore: paymentElementsStore.getState(),
            clubInvoiceStore: clubInvoiceStore.getState(),
        };
    }

    componentDidMount() {
        clubInvoiceStore.listen(this.handleClubChange);
        paymentElementsStore.listen(this.handlePayEleStoreChange);
        notificationActions.reset();
        console.log("pay clubInvoice " + this.props.invid);
        paymentElementsActions.initBasket(null);
        clubInvoiceActions.queryInvoiceCheckout(this.props.invid);
    }
    componentWillUnmount() {
        clubInvoiceStore.unlisten(this.handleClubChange);
        paymentElementsStore.unlisten(this.handlePayEleStoreChange);
    }
    handlePayEleStoreChange(state) {
        this.setState({paymentElementsStore: state});
    }
    handleClubChange(state) {
        this.setState({clubInvoiceStore: state});
    }
    render() {
        const {paymentElementsStore, clubInvoiceStore} = this.state;
        console.log("render ClubInvoiceCheckoutPage");
        if (clubInvoiceStore.clubInvoice == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </Grid>)
        } else if (clubInvoiceStore.clubInvoice != null && clubInvoiceStore.clubInvoice.isDeleted()) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/club" code="header.nav.clubs"/>
                                <Breadcrumb link={"/club/" + clubInvoiceStore.clubInvoice.getClubID().id} code={clubInvoiceStore.clubInvoice.getClubID().getName()}/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>)
        }
        return (<ClubInvoiceDetails
            clubInvoice={clubInvoiceStore.clubInvoice}
            club={clubInvoiceStore.clubInvoice.getClubID()}/>)
    }
}
ClubInvoiceCheckoutPage.propTypes = {
    invid: PropTypes.string.isRequired
};
const ClubInvoiceDetails = ({clubInvoice, club}) => {
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/club" code="header.nav.clubs"/>
                                <Breadcrumb link={"/club/" + club.id} code={club.getName()}/>
                                <Breadcrumb code="club.invoice.checkout" active/>
                            </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <ClubInvoiceInfo clubInvoice={clubInvoice}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <ClubInvoiceCheckout
                            club={club}
                            clubInvoice={clubInvoice}
                        />
                    </Col>
                </Row>
            </Grid>)
};
ClubInvoiceDetails.propTypes = {
    club:PropTypes.object.isRequired,
    clubInvoice:PropTypes.object.isRequired
};
const ClubInvoiceInfo = ({clubInvoice}) => {
    return (
        <Panel>
            <Panel.Heading>
                <Row>
                    <Col sm={8}>
                        <h3>{clubInvoice.getName()}</h3>
                    </Col>
                </Row>
            </Panel.Heading>
            <Panel.Body>
                <Row>
                    <Col xs={12}>
                        <h4><FontAwesome icon="home"/><I18n code="modal.message.receiver"/></h4>
                    </Col>
                    <Col md={6}>

                        {
                            clubInvoice.getAddressID() != null ? <AddressInfo address={clubInvoice.getAddressID()}/> : null
                        }
                    </Col>
                    <Col md={6}>
                        <h4><FontAwesome icon="hashtag"/>{clubInvoice.getInvoiceNumber()}</h4>
                        <h4><FontAwesome icon="calendar-alt"/>{clubInvoice.getInvoiceDate()}</h4>
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
ClubInvoiceInfo.propTypes = {
    clubInvoice: PropTypes.object.isRequired
};
const OpenInvoicePanel = ({paymentTarget}) => {
    return (
        <React.Fragment>
                <h2><I18n code="club.invoice.open"/></h2>
                <p>
                    <strong>{paymentTarget.getInvoicePaymentCondition()}</strong><br/>
                    <strong>IBAN: </strong>{paymentTarget.getCardIBAN()}<br/>
                    <strong>BIC: </strong>{paymentTarget.getCardBIC()}
                </p>
        </React.Fragment>)
};
OpenInvoicePanel.propTypes = {
    paymentTarget: PropTypes.object.isRequired
};
const ClubInvoiceCheckout = ({club, clubInvoice}) => {
    console.log("render TournamentUserPanels");
    return (<React.Fragment>
        <Panel>
            <Panel.Body>
                <PaymentElements clubInvoice={clubInvoice}
                                 openInvoiceInfo={club.getPaymentTargetID() ? <OpenInvoicePanel paymentTarget={club.getPaymentTargetID()}/> : null}
                                 articleElement={
                                     <Row><Col xs={12} md={8} mdOffset={2}>
                                         <ClubInvoiceArticleTable
                                             articlesList={clubInvoice.getParcoursArticles()} currency={clubInvoice.getCurrency()}
                                             sum={clubInvoice.getPrice()} discount={clubInvoice.getDiscount()}/>
                                     </Col></Row>}
                                 ruleImageID={null}/>
            </Panel.Body>
        </Panel>
    </React.Fragment>)
};
ClubInvoiceCheckout.propTypes = {
    club:PropTypes.object.isRequired,
    clubInvoice: PropTypes.object.isRequired,
    articles: PropTypes.object.isRequired
};
module.exports = ClubInvoiceCheckoutPage;
