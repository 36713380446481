const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Table, Button, Label, Alert} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SubmitButton = require("components/form/SubmitButton"),
    messages = require("i18n/messages"),
    AddressInfo = require("components/widgets/AddressInfo"),
    LoadingCircle = require("components/widgets/LoadingCircle"),
    ClubInvoiceTable = require("components/clubaccounting/ClubInvoiceTable"),
    ClubLoader = require("components/club/ClubLoader");

const Utility = require("util/Utility");

const ClubInvoice = require("parse/ClubInvoice");
const TransactionPosition = require("parse/TransactionPosition");

const paymentElementsStore = require("stores/PaymentElementsStore"),
    paymentElementsActions = require("actions/PaymentElementsActions"),
    userActions = require("actions/UserActions"),
    clubInvoiceActions = require("actions/ClubInvoiceActions");

class ClubAboInvoicePage extends React.Component {
    render() {
        // Club details will be loaded as soon as club and clubUsers will be loaded
        return <ClubLoader clubID={this.props.clubID} clubUserRole={null} loginMandatory={true}>
            <ClubInvoiceDetails mgmtid={this.props.mgmtid}/>
        </ClubLoader>
    }
}
ClubAboInvoicePage.propTypes = {
    clubID: PropTypes.string.isRequired,
    mgmtid: PropTypes.string.isRequired
};
class ClubInvoiceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.handlePayEleStoreChange = this.handlePayEleStoreChange.bind(this);
        this.createClubInvoice = this.createClubInvoice.bind(this);
        this.state = {
            paymentElementsStore: paymentElementsStore.getState(),
            aboEndDate: null
        };
    }
    componentDidMount() {
        document.title = this.props.club.getName();
        paymentElementsStore.listen(this.handlePayEleStoreChange);
        paymentElementsActions.initBasket.defer(null);
        paymentElementsActions.loadManagement.defer(this.props.mgmtid, this.props.club.getArticles())
        userActions.getIsAdmin.defer();
    }
    componentWillUnmount() {
        paymentElementsStore.unlisten(this.handlePayEleStoreChange);
    }
    handlePayEleStoreChange(state) {
        this.setState({paymentElementsStore: state});
        if (this.state.aboEndDate == null && state.management != null) {
            this.setState({aboEndDate: this.getNewAboEndDate(state.management)});
        }
    }
    getNewAboEndDate(management) {
        // if there is a endDate use this and add one Year - otherwise startDate is used with one year
        let refDate = management.getAboEndDate() ? management.getAboEndDate() : management.getAboStartDate();
        let newEndDate = new Date(refDate);
        newEndDate.setFullYear(newEndDate.getFullYear() +1);
        return newEndDate;
    }
    createClubInvoice() {
        const {club, user} = this.props;
        const { paymentElementsStore, aboEndDate} = this.state;
        const clubArticles = club.getArticles();

        let editInvoice = new ClubInvoice();
        editInvoice.setStatus(ClubInvoice.prototype.status.created);
        editInvoice.setCreator(user);
        editInvoice.setClubID(club);
        // edit the elements
        if (paymentElementsStore.maxPaymentPrice != null) {
            editInvoice.setPrice(paymentElementsStore.maxPaymentPrice);
            editInvoice.setDiscount(paymentElementsStore.maxPaymentPrice - paymentElementsStore.sum);
        } else {
            editInvoice.setPrice(paymentElementsStore.sum)
        }
        editInvoice.setInOrOut(true);
        editInvoice.setCurrency("EUR");
        editInvoice.setAmount(0);
        // only set price -> amount is for already paid sum
        // editInvoice.setInvoiceNumber() - > will be set from beforeSave
        editInvoice.setDate(new Date());
        editInvoice.setName("Abo " + paymentElementsStore.management.getName());
        editInvoice.setAddressID(paymentElementsStore.management.getBusinessPartnerID().getAddressID());
        // build articlePositions
        let articlePositions = [];
        Object.keys(paymentElementsStore.articles).map(articleId => {
            let amount = paymentElementsStore.articles[articleId];
            let pos = new TransactionPosition();
            pos.setStatus(ClubInvoice.prototype.status.created);
            pos.setAmount(amount);
            // get parcoursArticleElement
            for (let i = 0; i < clubArticles.length; i++) {
                if (clubArticles[i].id === articleId) {
                    // found fitting article
                    pos.setName(clubArticles[i].getName());
                    pos.setSinglePrice(clubArticles[i].getPrice());
                    pos.setSum(amount * clubArticles[i].getPrice());
                    pos.setArticle(clubArticles[i]);
                    break;
                }
            }
            articlePositions.push(pos);
        });
        editInvoice.setParcoursArticles(articlePositions);
        editInvoice.setAfterPayment({
            clubAbo: true,
            sendMail: true,
            mgmtid: paymentElementsStore.management.id,
            endDate: aboEndDate
        });
        editInvoice.setTax(0);
        clubInvoiceActions.createClubInvoice(editInvoice, paymentElementsStore.management);
    }
    render() {
        const {club, user, isAdmin} = this.props;
        const { paymentElementsStore, aboEndDate} = this.state;
        let hasUnpaidInvoices = paymentElementsStore.management ? paymentElementsStore.management.hasUnpaidInvoices() : false;
        let denyExtendAbo = paymentElementsStore.management ? !(paymentElementsStore.management.allowExtendAbo(user) || isAdmin) : true;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <h1>3DTurnier - <I18n code="header.nav.invoices"/></h1>
                    </Col>
                    {
                        paymentElementsStore.management ? <React.Fragment>
                            <Col lg={12}>
                                <ClubInvoiceTable invoices={paymentElementsStore.management.getClubInvoiceIDs()}/> :
                            </Col>
                            {
                                hasUnpaidInvoices ? <Col lg={12}><Alert bsStyle="warning"><I18n code="management.feature.yearsub.payopen"/></Alert></Col> : null
                            }
                            {
                                denyExtendAbo ? <Col lg={12}><Alert bsStyle="warning"><I18n code="management.feature.yearsub.noEditor"/></Alert></Col> : null
                            }
                        </React.Fragment> : <LoadingCircle />
                    }
                </Row>
                {
                    hasUnpaidInvoices || denyExtendAbo ? null : <ClubExtentAbo club={club}
                                                              paymentElementsStore={paymentElementsStore}
                                                              aboEndDate={aboEndDate}>
                        <SubmitButton ref={this.refSubmitButton} block bsSize="large"
                                      disabled={paymentElementsStore.management == null && aboEndDate != null}
                                      onClick={this.createClubInvoice}
                                      text={messages.get("management.feature.yearsub.renew")}/>
                    </ClubExtentAbo>


                }

            </Grid>)
    }
}
ClubInvoiceDetails.propTypes = {
    club: PropTypes.object.isRequired,
    mgmtid: PropTypes.string.isRequired
};
const ClubExtentAbo = ({club, paymentElementsStore, aboEndDate, children}) => {
    const currency = "EUR";
    return <React.Fragment>
        <Row>
            <Col lg={12}>
                <h2>3DTurnier - <I18n code="management.feature.yearsub.renew"/></h2>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                {
                    paymentElementsStore.management ? <ManagementAddress management={paymentElementsStore.management}/> : <LoadingCircle />
                }
            </Col>
        </Row>
        {
            club.getArticles() != null ?
                <ClubInvoiceArticles club={club} sum={paymentElementsStore.sum} disabled={paymentElementsStore.packed} currency={currency}
                                     articles={paymentElementsStore.articles} maxPaymentPrice={paymentElementsStore.maxPaymentPrice}/>: null
        }
        <Row>
            <Col lg={12}>
                {
                    aboEndDate ? <p><strong><I18n code="profile.abo.newEndDate"/> {Utility.getReadableDate(aboEndDate)}</strong></p> : null
                }
            </Col>
        </Row>
        <br/><br/>
        <Row>
            <Col xs={8} xsOffset={2} style={{"textAlign": "center"}}>
                {children}
            </Col>
        </Row>
    </React.Fragment>
}
const ClubInvoiceArticles = ({club, sum, articles, maxPaymentPrice, disabled, currency}) => {
    let articlesList = club.getArticles().filter(article => {
        return article.isGroupInvoice() && article.isActive()
    });
    return (<Table>
        <thead>
        <tr>
            <th className="col-xs-6 col-md-6"><I18n code="tournament.table.column.article"/></th>
            <th className="col-xs-3 col-md-3"><I18n code="parcours.articles.form.price"/></th>
            <th className="col-xs-3 col-md-3"><I18n code="tournament.table.column.amount"/></th>
        </tr>
        </thead>
        <tbody>
            {
                articlesList.map(article => {
                    if (article.getShortCode() != null) {
                        let amount = articles[article.id];
                        return <ParcoursArticle key={article.id} article={article} amount={amount ? amount : 0} disabled={disabled}/>
                    }
                })
            }
            {
                maxPaymentPrice != null && maxPaymentPrice < sum ?

                    <React.Fragment>
                        <tr>
                            <td></td>
                            <td><I18n code="tournament.table.column.article.sum"/></td>
                            <td>{currency + " " + sum.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><I18n code="tournament.table.column.discount"/></td>
                            <td>{currency + " " + (maxPaymentPrice - sum).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><I18n code="tournament.table.column.sum"/></td>
                            <td>{currency + " " + maxPaymentPrice.toFixed(2)}</td>
                        </tr>
                    </React.Fragment>
                    :
                    <tr>
                        <td></td>
                        <td><I18n code="tournament.table.column.sum"/></td>
                        <td>{currency + " " + sum.toFixed(2)}</td>
                    </tr>
            }

        </tbody>
    </Table>)
};
ClubInvoiceArticles.propTypes = {
    club:PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    maxPaymentPrice: PropTypes.number
};
const ManagementAddress = ({management}) => {
    const businessPartner = management.getBusinessPartnerID();
    return <AddressInfo address={businessPartner.getAddressID()}/>
};
ManagementAddress.propTypes = {
    management: PropTypes.object.isRequired
};
const ParcoursArticle = ({article, amount, disabled})=> {
    const onButtonDecr = () => {
        if (amount > 0) {
            paymentElementsActions.updateTransactionArticle(article.id, amount - 1);
            paymentElementsActions.updateSum(article.getPrice()  * (-1));
        }
    };
    const onButtonIncr = () => {
        paymentElementsActions.updateTransactionArticle(article.id, amount + 1);
        paymentElementsActions.updateSum(article.getPrice());
    };
    return (
        <tr>
            <td>{article.getName()}</td>
            <td>{article.getCurrency() + " " + article.getPrice().toFixed(2)}</td>
            <td>
                <div style={{"display":"inline-block", "fontSize": "25px"}}>
                    <Button bsStyle="primary" onClick={onButtonDecr} disabled={amount <= article.getMinAmount() || disabled}>
                        <FontAwesome icon="minus"/>
                    </Button>
                    <Label bsStyle="default">{amount}</Label>
                    <Button bsStyle="primary"onClick={onButtonIncr} disabled={disabled}>
                        <FontAwesome icon="plus"/>
                    </Button>
                </div>
            </td>
        </tr>
    )
};
ParcoursArticle.propTypes = {
    article:PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    amount: PropTypes.number.isRequired
};
module.exports = ClubAboInvoicePage;
