var Parse = require("ParseInit");

var TournamentPrice = Parse.Object.extend("TournamentPrice", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        currency: "currency",
        discountAmount: "discountAmount",
        discountCode: "discountCode",
        email: "email",
        objectId: "objectId",
        pay4Start: "pay4Start",
        payStatus: "payStatus",
        regMailCC: "regMailCC",
        paymentCondition: "paymentCondition",
        price: "price",
        surprice: "surprice",
        status: "status",
        updatedAt: "updatedAt",
    },
    payStatus: {
        active: "A",
        inactive: "I"
    },
    isOnlinePaymentEnabled() {
        var payStatus = this.get(this.col.payStatus);
        return payStatus === "A";
    },
    isInValid() {
        return this.getEmail() == null;
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getCurrency() {
        return this.get(this.col.currency)
    },
    setCurrency(currency) {
        return this.set(this.col.currency, currency)
    },
    getDiscountAmount() {
        return this.get(this.col.discountAmount)
    },
    setDiscountAmount(discountAmount) {
        return this.set(this.col.discountAmount, discountAmount)
    },
    getDiscountCode() {
        return this.get(this.col.discountCode)
    },
    setDiscountCode(discountCode) {
        return this.set(this.col.discountCode, discountCode)
    },
    getEmail() {
        return this.get(this.col.email)
    },
    setEmail(email) {
        return this.set(this.col.email, email)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPay4Start() {
        return this.get(this.col.pay4Start)
    },
    setPay4Start(pay4Start) {
        return this.set(this.col.pay4Start, pay4Start)
    },
    getRegistrationMailCC() {
        return this.get(this.col.regMailCC)
    },
    setRegistrationMailCC(regMailCC) {
        return this.set(this.col.regMailCC, regMailCC)
    },
    getPaymentCondition() {
        return this.get(this.col.paymentCondition)
    },
    setPaymentCondition(paymentCondition) {
        return this.set(this.col.paymentCondition, paymentCondition)
    },
    getPrice() {
        return parseFloat(this.get(this.col.price))
    },
    setPrice(price) {
        return this.set(this.col.price, price)
    },
    getSurprice() {
        return parseFloat(this.get(this.col.surprice))
    },
    setSurprice(surprice) {
        return this.set(this.col.surprice, surprice)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    setPayStatus(payStatus) {
        return this.set(this.col.payStatus, payStatus)
    },
    getPayStatus() {
        return this.get(this.col.payStatus)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    cloneTPrice(tPrice, status) {
        if (status) {
            this.setStatus(status);
        } else {
            this.setStatus(tPrice.getStatus());
        }
        this.setCurrency(tPrice.getCurrency())
        this.setDiscountAmount(tPrice.getDiscountAmount())
        this.setDiscountCode(tPrice.getDiscountCode())
        this.setPay4Start(tPrice.getPay4Start())
        this.setPayStatus(tPrice.getPayStatus())
        this.setEmail(tPrice.getEmail())
        this.setRegistrationMailCC(tPrice.getRegistrationMailCC())
        this.setPaymentCondition(tPrice.getPaymentCondition())
        this.setPrice(tPrice.getPrice())
        this.setSurprice(tPrice.getSurprice())
    }
});

module.exports = TournamentPrice;
