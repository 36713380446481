const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    linkUtil = require("linkUtil"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    CheckboxInput = require("components/form/CheckboxInput"),
    DateInput = require("components/form/DateInput"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput");

const {Tournament} = require("parse/_Domain");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");
const {isBefore} = require("date-fns");

const tournamentActions = require("actions/TournamentActions");

const TournamentCopyModal = ({tournament, user}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code="tournament.modal.copy"/></TextCenter>}
            buttonStyle="success"
            buttonDisabled={!tournament.hasManagementID()}
            buttonChildren={<FontAwesome icon="id-card">{"COPY"}</FontAwesome>}>
            <TournamentCopyForm tournament={tournament} user={user}/>
        </ModalBasic>
    )
};
TournamentCopyModal.propTypes = {
    tournament:PropTypes.object.isRequired,
};
class TournamentCopyForm extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refName = React.createRef();
        this.refDate = React.createRef();
        this.refEndDate = React.createRef();
        this.refDays = React.createRef();
        this.refCBRounds = React.createRef();
        this.refCBParcours = React.createRef();
        this.refCBCupMaster = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refName.current.getValue(),
            this.refDate.current.getValue(),
            this.refEndDate.current.getValue(),
            this.refDays.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        const startDateParts = new Date(this.refDate.current.getValue());
        const startDate = new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), 4, 0, 0, 0);

        const endDateParts = new Date(this.refEndDate.current.getValue());
        const endDate = new Date(endDateParts.getFullYear(), endDateParts.getMonth(), endDateParts.getDate(), 23, 59, 0, 0);
        if (isBefore(endDate, startDate)) {
            this.setState({error: messages.get("tournament.create.date.before")});
            return false;
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, disableSubmitFct} = this.context;
        const {tournament, user} = this.props;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);
        let copySettings = {
            cbParcours: this.refCBParcours.current.getValue() == true,
            cbCupMaster: this.refCBCupMaster.current.getValue() == true,
            cbRounds: this.refCBRounds.current.getValue() == true}
        let nTournament = new Tournament()
        // fill with local info
        nTournament.setCreator(user);
        nTournament.setName(this.refName.current.getValue());
        const startDateParts = new Date(this.refDate.current.getValue());
        nTournament.setDate(new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), 4, 0, 0, 0));
        const endDateParts = new Date(this.refEndDate.current.getValue());
        nTournament.setEndDate(new Date(endDateParts.getFullYear(), endDateParts.getMonth(), endDateParts.getDate(), 23, 59, 0, 0));
        nTournament.setDuration(parseInt(this.refDays.current.getValue()));

        // save
        tournamentActions.createTournamentCopy(nTournament, tournament, copySettings).then(sTournament => {
            console.log("Saved tournament", copySettings, sTournament)
            window.open(linkUtil.getLink("tournament/" + sTournament.id, '_blank'))
            closeFct();
        });
    }

    handleStartDateChange(value) {
        this.refEndDate.current.setValue(value);
    }
    handleEndDateChange(value) {
        const startDate= new Date(this.refDate.current.getValue());
        const endDate= new Date(value);
        // calc duration in days
        const diff = parseInt((endDate - startDate) / 86400000);
        if (diff >= 0) {
            this.refDays.current.setValue(diff + 1);
        } else {
            this.refEndDate.current.setValue(this.refDate.current.getValue());
            this.refDays.current.setValue(1);
        }

    }
    render() {
        const {error, submitDisabled} = this.context;
        const validateNow = error && (error.message != null || error.length > 0);
        const {tournament} = this.props;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refName}
                                    default={tournament.getName()}
                                    valid={{maxLength: 500, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("tournament.create.name")}/>
                    </Col>

                    <Col sm={5}>
                        <DateInput ref={this.refDate}
                                   default={new Date()}
                                   label={messages.get("tournament.create.date")}
                                   onAfterChange={this.handleStartDateChange}/>
                    </Col>
                    <Col sm={5}>
                        <DateInput ref={this.refEndDate}
                                   default={new Date()}
                                   label={messages.get("tournament.create.enddate")}
                                   onAfterChange={this.handleEndDateChange}/>
                    </Col>
                    <Col sm={2}>
                        <ValidInput ref={this.refDays}
                                    type="number"
                                    valid={{check: ['isNumber','isRequired']}}
                                    validateNow={validateNow}
                                    label={messages.get("tournament.create.days")}
                                    default={1}/>
                    </Col>
                    <Col xs={12}>
                        <p>Copy also following elements</p>
                        <CheckboxInput ref={this.refCBParcours}
                                       default={tournament.getParcoursID() != null}
                                       disabled={tournament.getParcoursID() == null}
                                       message={messages.get("management.feature.PA")} />
                        <CheckboxInput ref={this.refCBCupMaster}
                                       default={false}
                                       // disabled={!tournament.isTypeCupChild()}
                                        disabled={true}
                                       message={messages.get("bowUnion.type.CM")} />
                        <CheckboxInput ref={this.refCBRounds}
                                       default={false}
                                       disabled={true}
                                       message={messages.get("tournament.round.type.S.defRoundName")} />
                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.save"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentCopyForm.propTypes = {
    tournament:PropTypes.object.isRequired,
};
TournamentCopyForm.contextType = ModalBasicContext;
module.exports = TournamentCopyModal;
