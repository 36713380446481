const React = require("react");
const {BaseContext} = require("context/BaseContext");

const RowNoUserWarning = ({children}) => {
    const { user } = React.useContext(BaseContext);
    if (user == null) {
        return children
    }
    return null
};
module.exports = RowNoUserWarning;
