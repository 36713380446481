const Parse = require("ParseInit");
const parseDao = require("actdao/ParseBaseDao");
const {TournamentUser, TournamentUserGroup, TournamentUserGroupResult, TournamentUserTeam,
    TournamentRoundUser, Tournament, CCLog} = require("parse/_Domain");
const ConfigKeys = require("ConfigKeys");

class TournamentUserDao {

    loadAllTournamentUsers(tournament, hideDeleted) {
        const query = new Parse.Query(TournamentUser);
        if (hideDeleted) {
            query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        }
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUser.prototype.col.tournamentGroupID);
        query.include(TournamentUser.prototype.col.tournamentRoundUserIDs);
        query.include(TournamentUser.prototype.col.tRegSlotID);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        query.descending(TournamentUser.prototype.col.createdAt);
        query.limit(1000);
        return query.find();
    };

    reloadTournamentUser(tUser) {
        const query = new Parse.Query(TournamentUser);
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUser.prototype.col.tournamentGroupID);
        query.include(TournamentUser.prototype.col.tRegSlotID)
        return query.get(tUser.id);
    };

    loadAllTournamentUserGroups(tournament, hideDeleted) {
        const query = new Parse.Query(TournamentUserGroup);
        if (hideDeleted) {
            query.notEqualTo(TournamentUserGroup.prototype.col.status, TournamentUserGroup.prototype.status.deleted);
        }
        query.equalTo(TournamentUserGroup.prototype.col.tournamentID, tournament);
        query.include(TournamentUserGroup.prototype.col.tournamentUserIDs);
        query.include(TournamentUserGroup.prototype.col.tournamentRoundID);
        query.addAscending(TournamentUserGroup.prototype.col.startPosition);
        query.addAscending(TournamentUserGroup.prototype.col.name);
        query.addDescending(TournamentUserGroup.prototype.col.createdAt);
        query.limit(1000);
        return query.find();
    };

    loadAllTournamentUserGroupsResult(tournament, tRound) {
        const query = new Parse.Query(TournamentUserGroupResult);
        query.notEqualTo(TournamentUserGroupResult.prototype.col.status, TournamentUserGroupResult.prototype.status.deleted);
        query.equalTo(TournamentUserGroupResult.prototype.col.tournamentID, tournament);
        query.equalTo(TournamentUserGroupResult.prototype.col.tournamentRoundID, tRound);
        query.include(TournamentUserGroupResult.prototype.col.userID);
        query.limit(1000);
        return query.find();
    };

    loadTournamentGroupCCLog(tUserGroup) {
        const query = new Parse.Query(CCLog);
        query.equalTo(CCLog.prototype.col.tournamentGroupID, tUserGroup);
        query.descending(CCLog.prototype.col.createdAt);
        return query.find();
    }

    loadPaidTournamentUsers(tournament) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUser.prototype.col.tRegSlotID)
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        query.equalTo(TournamentUser.prototype.col.paid, true);
        query.exists(TournamentUser.prototype.col.payDate);
        query.ascending(TournamentUser.prototype.col.payDate + "," + TournamentUser.prototype.col.createdAt);
        query.limit(1000);
        return query.find();
    };

    loadCupTournamentUsers(buCode, licNumber, tournamentIds) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.licenseUnionCode, buCode);
        query.equalTo(TournamentUser.prototype.col.licenseNumber, licNumber);
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUser.prototype.col.tRegSlotID)
        query.containedIn(TournamentUser.prototype.col.tournamentStringID, tournamentIds);
        query.ascending(TournamentUser.prototype.col.createdAt);
        return query.find();
    }

    loadCupResultTournamentUsers(tournamentIds, callbackSuccess) {
        const callbackError = function(error) {
            console.log("CUP RESULT ERROR ", error);
            // notificationActions.parseError(error);
        };
        const notify = function(info) {
            console.log("CUP RESULT INFO ", info)
            //notificationActions.info(error);
        };
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.containedIn(TournamentUser.prototype.col.tournamentStringID, tournamentIds);
        query.exists(TournamentUser.prototype.col.licenseNumber);
        query.descending(TournamentUser.prototype.col.licenseNumber + "," + TournamentUser.prototype.col.cupGroup + "," + TournamentUser.prototype.col.cupPoints+ "," + TournamentUser.prototype.col.sumPoints);
        parseDao.queryMoreEntryLists(query, notify, callbackError, callbackSuccess);
    }

    loadYearStatTournamentRoundUsers(year, callbackSuccess) {
        const startDate = new Date(year, 0 ,1, 0, 0, 0, 0);
        const endDate = new Date(year, 11 ,31,23,59,59,0);
        const callbackError = function(error) {
            console.log("YEAR STAT RESULT ERROR ", error);
        };
        const notify = function(info) {
            console.log("YEAR STAT INFO ", info)
            //notificationActions.info(error);
        };
        const query = new Parse.Query(TournamentRoundUser);
        query.equalTo(TournamentRoundUser.prototype.col.status, TournamentRoundUser.prototype.status.finished);
        query.include(TournamentRoundUser.prototype.col.tournamentRoundID);
        query.include(TournamentRoundUser.prototype.col.countTypeID);
        query.include(TournamentRoundUser.prototype.col.tournamentUserID);
        query.include(TournamentRoundUser.prototype.col.tournamentUserID + "." + TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentRoundUser.prototype.col.tournamentUserID + "." + TournamentUser.prototype.col.tournamentConfigBow);

        query.exists(TournamentRoundUser.prototype.col.sumPoints);
        query.exists(TournamentRoundUser.prototype.col.tournamentRoundID);
        query.exists(TournamentRoundUser.prototype.col.tournamentUserID);
        query.greaterThanOrEqualTo(TournamentRoundUser.prototype.col.createdAt, startDate)
        query.lessThanOrEqualTo(TournamentRoundUser.prototype.col.createdAt, endDate)
        parseDao.queryMoreEntryLists(query, notify, callbackError, callbackSuccess);
    }

    loadTournamentUserTeams(tournament, hideDeleted) {
        const query = new Parse.Query(TournamentUserTeam);
        if (hideDeleted) {
            query.notEqualTo(TournamentUserTeam.prototype.col.status, TournamentUserTeam.prototype.status.deleted);
        }
        query.include(TournamentUserTeam.prototype.col.tournamentUserIDs);
        query.include(TournamentUserTeam.prototype.col.tournamentUserIDs + "." + TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUserTeam.prototype.col.tournamentUserIDs + "." + TournamentUser.prototype.col.tournamentConfigBow);
        query.equalTo(TournamentUserTeam.prototype.col.tournamentID, tournament);
        query.ascending(TournamentUserTeam.prototype.col.createdAt);
        query.limit(1000);
        return query.find();
    };

    loadTournamentUsersGrouping(tournament, params) {
        const query = new Parse.Query(TournamentUser);
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUser.prototype.col.tRegSlotID)
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        // do not load tUsers with groups
        query.doesNotExist(TournamentUser.prototype.col.tournamentGroupID)
        if (params.paidOnly) {
            query.equalTo(TournamentUser.prototype.col.paid, true);
        }
        query.descending(TournamentUser.prototype.col.createdAt);
        query.limit(1000);
        return query.find();
    }

    loadUnPaidTournamentUsers(tournament) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUser.prototype.col.tRegSlotID)
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        query.notEqualTo(TournamentUser.prototype.col.paid, true);
        query.ascending(TournamentUser.prototype.col.createdAt);
        query.limit(1000);
        return query.find();
    };

    loadTournamentUsersFromEditor(editor) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status,
            TournamentUser.prototype.status.deleted);
        query.notEqualTo(TournamentUser.prototype.col.playerEmail, editor.getEmail());
        query.equalTo(TournamentUser.prototype.col.editor, editor);
        query.descending(TournamentUser.prototype.col.updatedAt);
        query.include(TournamentUser.prototype.col.tRegSlotID)
        query.limit(100);
        return query.find();
    };

    loadTournamentUsersAddress(tournament) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        query.include(TournamentUser.prototype.col.userID);
        query.include(TournamentUser.prototype.col.userID + ".userDetailID");
        query.include(TournamentUser.prototype.col.userID + ".userDetailID.addressID");
        query.include(TournamentUser.prototype.col.transactionID);
        query.ascending(TournamentUser.prototype.col.payDate + "," + TournamentUser.prototype.col.createdAt);
        query.limit(1000);
        return query.find();
    }

    queryTournamentUsers(mail) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status,
            TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.playerEmail, mail);
        query.include(TournamentUser.prototype.col.tournamentID);
        query.descending(TournamentUser.prototype.col.updatedAt);
        query.limit(1000);
        return query.find();
    };

    queryTournamentUser(tournamentID, mail) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status,
            TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.playerEmail, mail);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournamentID);
        query.include(TournamentUser.prototype.col.tournamentConfigBow)
        query.descending(TournamentUser.prototype.col.createdAt);
        return query.first();
    };

    queryMMTournamentUser(searchParam, bowUnionCode) {
        let mainQuery;

        const nameQuery = new Parse.Query(TournamentUser);
        nameQuery.matches(TournamentUser.prototype.col.name, "(" + searchParam + ")", "i");

        const surnameQuery = new Parse.Query(TournamentUser);
        surnameQuery.matches(TournamentUser.prototype.col.surname, "(" + searchParam + ")", "i");
        if (bowUnionCode != null) {
            nameQuery.containedIn(TournamentUser.prototype.col.licenseUnionCode, [null, bowUnionCode]);
            surnameQuery.containedIn(TournamentUser.prototype.col.licenseUnionCode, [null, bowUnionCode]);
            // search by licenseNumber
            const licQuery = new Parse.Query(TournamentUser);
            licQuery.equalTo(TournamentUser.prototype.col.licenseUnionCode, bowUnionCode);
            licQuery.matches(TournamentUser.prototype.col.licenseNumber, "(" + searchParam + ")", "i");
            mainQuery = new Parse.Query.or(nameQuery, surnameQuery, licQuery);
            mainQuery.descending(TournamentUser.prototype.col.licenseUnionCode + "," + TournamentUser.prototype.col.licenseNumber + ",updatedAt" );
            mainQuery.limit(1000);
        } else {
            mainQuery = new Parse.Query.or(nameQuery, surnameQuery);
            mainQuery.descending("updatedAt" );
            mainQuery.limit(100);
        }
        mainQuery.notEqualTo(TournamentUser.prototype.col.status, "D");
        mainQuery.include(TournamentUser.prototype.col.tournamentConfigAge);
        mainQuery.include(TournamentUser.prototype.col.tournamentConfigBow);
        return mainQuery.find();
    };

    queryForCupTournamentUser(searchParam, tournament, bowUnionCode, onlyWithoutGroup) {
        // search by names
        const nameQuery = new Parse.Query(TournamentUser);
        nameQuery.matches(TournamentUser.prototype.col.name, "(" + searchParam + ")", "i");
        const surnameQuery = new Parse.Query(TournamentUser);
        surnameQuery.matches(TournamentUser.prototype.col.surname, "(" + searchParam + ")", "i");
        // search by licenseNumber
        const licQuery = new Parse.Query(TournamentUser);
        licQuery.equalTo(TournamentUser.prototype.col.licenseNumber, searchParam);
        if (bowUnionCode != null) {
            nameQuery.equalTo(TournamentUser.prototype.col.licenseUnionCode, bowUnionCode);
            surnameQuery.equalTo(TournamentUser.prototype.col.licenseUnionCode, bowUnionCode);
            licQuery.equalTo(TournamentUser.prototype.col.licenseUnionCode, bowUnionCode);
        }
        if (tournament != null) {
            nameQuery.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
            surnameQuery.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
            licQuery.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        }
        if (onlyWithoutGroup) {
            nameQuery.doesNotExist(TournamentUser.prototype.col.tournamentGroupID)
            surnameQuery.doesNotExist(TournamentUser.prototype.col.tournamentGroupID)
            licQuery.doesNotExist(TournamentUser.prototype.col.tournamentGroupID)
        }

        const mainQuery = new Parse.Query.or(nameQuery, surnameQuery, licQuery);
        mainQuery.descending("updatedAt");
        mainQuery.include(TournamentUser.prototype.col.tournamentConfigAge);
        mainQuery.include(TournamentUser.prototype.col.tournamentConfigBow);
        mainQuery.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted)
        mainQuery.limit(100);
        return mainQuery.find();
    }

    loadOwnTournamentUsers(user, year) {
        // parse query for email
        const queryMail = new Parse.Query(TournamentUser);
        queryMail.notEqualTo(TournamentUser.prototype.col.status, "D");
        queryMail.equalTo(TournamentUser.prototype.col.playerEmail, user.getEmail());
        // parse query for user
        const queryUserID = new Parse.Query(TournamentUser);
        queryUserID.notEqualTo(TournamentUser.prototype.col.status, "D");
        queryUserID.equalTo(TournamentUser.prototype.col.userID, user);

        const startDate = new Date(year,0,1);
        const endDate = new Date(year,12,0,23,59,59,0);

        const tQuery = new Parse.Query(Tournament);
        tQuery.notEqualTo(Tournament.prototype.col.status,"D");
        tQuery.greaterThanOrEqualTo(Tournament.prototype.col.endDate, startDate);
        tQuery.lessThanOrEqualTo(Tournament.prototype.col.endDate, endDate);
        tQuery.limit(1000);

        const query = Parse.Query.or(queryMail, queryUserID);
        query.include(TournamentUser.prototype.col.tournamentConfigAge);
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentID);
        query.include(TournamentUser.prototype.col.tRegSlotID)
        query.descending(TournamentUser.prototype.col.updatedAt);
        query.matchesQuery(TournamentUser.prototype.col.tournamentID, tQuery);
        return query.find();
    }

    loadTournamentRoundResult(tournamentRound) {
        const query = new Parse.Query(TournamentRoundUser);
        query.equalTo(TournamentRoundUser.prototype.col.tournamentRoundID, tournamentRound);
        query.exists(TournamentRoundUser.prototype.col.tournamentGroupID)
        query.include(TournamentRoundUser.prototype.col.tournamentUserID)
        query.include(TournamentRoundUser.prototype.col.tournamentGroupID)
        return query.find();
    }

    validateTournamentUserEmail(tournament, mail) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.playerEmail, mail);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        return query.count();
    }

    validateTournamentUserLicense(tournament, licenseNumber) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.licenseNumber, licenseNumber);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        return query.count();
    }

    querySimilarTUser(tUser, tournaments) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.notEqualTo(TournamentUser.prototype.col.licenseNumber, tUser.getLicenseNumber());
        // TODO - ignore case for name - but how??
        query.equalTo(TournamentUser.prototype.col.name, tUser.getName());
        query.equalTo(TournamentUser.prototype.col.surname, tUser.getSurname());
        query.equalTo(TournamentUser.prototype.col.tournamentConfigBow, tUser.getTournamentConfigBow());
        query.equalTo(TournamentUser.prototype.col.tournamentConfigAge, tUser.getTournamentConfigAge());
        query.containedIn(TournamentUser.prototype.col.tournamentID, tournaments);
        return query.find();
    }

    deleteTournamentUsers(tournamentID) {
        return Parse.Cloud.run('delTournamentUsers', {tournamentID: tournamentID.id});
    }
    // create tournamentUser ParseObject from given data - does not validate anything!
    registerTournamentUser(regTUserObject) {
        // const tUserObject = {
        //    name:"",
        //    surname:"",
        //    tuMail:"",
        //    sex:0,
        //    tConfigAge:"",
        //    tConfigBow:"",
        //    union:null,
        //    option:null,
        //    licNumber:null,
        //    licUnionCode:null,
        //    note:null,
        //    price:0,
        //    parcoursID:"",
        //    tournamentID:"",
        //    status:"R",
        //    selfRegistration:true};
        return Parse.Cloud.run('registerTournamentUser', {syncVers: ConfigKeys.tournamentVersion, tUserObject: regTUserObject});
    }

    handleUserDetailTournamentLic(userBowUnionLicObject) {
        // const userBowUnionLicObject = {
        //     bowUnionID: "",
        //     saveLic: true,
        //     licNumber: "",
        //     bowID:"",
        //     ageID:"",
        //     name:"",
        //     surname:"",
        //     sex:0,
        //     union:null};
        return Parse.Cloud.run('userDetailTournamentLic', {syncVers: ConfigKeys.tournamentVersion, userBowUnionLicObject: userBowUnionLicObject});
    }
}

module.exports = new TournamentUserDao();
