const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Button, Panel, Well} = require("react-bootstrap");
var I18n = require("components/widgets/I18n"),
    Title = require("components/widgets/Title"),
    FontAwesome = require("components/widgets/FontAwesome"),
    LoginButton = require("components/widgets/LoginButton");

var parcoursStore = require("stores/ParcoursStore"),
    userStore = require("stores/UserStore"),
    managementActions = require("actions/ManagementActions"),
    managementStore = require("stores/ManagementStore");

var BusinessPartnerForm = require("components/business/BusinessPartnerForm"),
    ManagementDetail = require("components/business/ManagementDetail"),
    ParcoursOwnerInfo = require("components/parcours/ParcoursOwnerInfo");

class ParcoursManageRequestPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleManagementChange = this.handleManagementChange.bind(this);
        
        this.state = {
            parcoursStore: parcoursStore.getState(),
            userStore: userStore.getState(),
            managementStore: managementStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        managementStore.listen(this.handleManagementChange);
        parcoursStore.listen(this.handleParcoursChange);
        managementActions.initRequestParcoursManagePage(this.state.userStore.user, this.props.ownerID);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        managementStore.unlisten(this.handleManagementChange);
        parcoursStore.unlisten(this.handleParcoursChange);
    }
    handleManagementChange(state) {
        this.setState({managementStore:state});
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    handleUserChange(user) {
        this.setState({userStore: user});
    }
    isAlreadyManager() {
        const {management} = this.state.managementStore;
        const {parcoursOwner} = this.state.parcoursStore;
        return management != null && management.isActive() && (management.getParcoursOwnerID() != null
            && management.getParcoursOwnerID().filter(po => po.id == parcoursOwner.id).length > 0)
    }
    render() {
        const {user} = this.state.userStore;
        const {management, showSpinner} = this.state.managementStore;
        const {parcoursOwner} = this.state.parcoursStore;
        let body;
        if (user == null) {
            body =(<Row>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="management.request.form.info.login"/> <LoginButton/></Alert>
                        </Col>
                    </Row>)
        } else if (this.isAlreadyManager()) {
            body = (<Row>
                    <Col xs={12}>
                        <Alert bsStyle="success"><I18n code="management.request.form.info.already"/></Alert>
                    </Col>
                </Row>)
        } else {
            body = <ParcoursManageRequest parcoursOwner={parcoursOwner} management={management} showSpinner={showSpinner}/>
        }
        return (
            <Grid>
                <Title code="businessDetail.request.title"/>
                {body}
            </Grid>)
    }
}
ParcoursManageRequestPage.propTypes = {
    ownerID:PropTypes.string.isRequired,
};
const ParcoursManageRequest = ({management, parcoursOwner, showSpinner}) => {
        return (
            <Row>
                <Col xs={12}>
                    <h3><I18n code="management.request.form.header.host"/></h3>
                    <p><I18n code="management.request.form.info.host"/></p>
                </Col>
                <Col xs={12} md={6}>
                    <Well>
                        <div style={{
                            float: "left",
                            width: "65px",
                            marginTop: "8px",
                            marginLeft: "10px"}}>
                            <FontAwesome icon="map-marker-alt" size="5x"/>
                        </div>
                        <ParcoursOwnerInfo parcoursOwner={parcoursOwner}/>
                        <div style={{clear: "both"}}></div>
                    </Well>
                </Col>
                <Col xs={12}>
                    <BusinessPartnerFormOrExisting management={management} parcoursOwner={parcoursOwner} showSpinner={showSpinner}/>
                </Col>
                <Col xs={12}>
                    <p><I18n code="management.request.form.info.contact"/></p>
                </Col>
            </Row>)
};
ParcoursManageRequest.propTypes = {
    management: PropTypes.object,
    parcoursOwner: PropTypes.object,
    showSpinner: PropTypes.bool
};
const BusinessPartnerFormOrExisting = ({management, parcoursOwner, showSpinner}) => {
    function handleFormCallback(management) {
        managementActions.requestParcoursManagement(management, parcoursOwner)
    }
    if (management) {
        return (<ExistingBusinessPartner management={management} parcoursOwner={parcoursOwner} showSpinner={showSpinner}/>)
    }
    return (
        <Panel>
            <Panel.Heading>
                <h3><I18n code={"businessDetail.request.form.header"}/></h3>
            </Panel.Heading>
            <Panel.Body>
                <BusinessPartnerForm callback={handleFormCallback}/>
            </Panel.Body>
        </Panel>)
};
BusinessPartnerFormOrExisting.propTypes = {
    management: PropTypes.object,
    parcoursOwner: PropTypes.object,
    showSpinner: PropTypes.bool
};
const ExistingBusinessPartner = ({management, parcoursOwner, showSpinner}) => {
    function requestManagement() {
        managementActions.requestParcoursManagement(management, parcoursOwner);
    }
    function filterCurRequest() {
        var reqs = management.getManagementRequestIDs();
        if (!reqs) {
            return null
        }
        var filtered = reqs.filter(r => {
            return r != null
                && r.getStatus() === "A"
                && r.getParcoursOwnerID() != null
                && r.getParcoursOwnerID().id == parcoursOwner.id
        });
        if (filtered.length != 0) {
            return filtered[0];
        }
        return null;
    }
    function alreadyRequested() {
        return filterCurRequest() != null
    }
    function cancelRequest() {
        var managementRequest = filterCurRequest();
        managementActions.cancelManagementRequest(management, managementRequest);
    }
    return (
        <React.Fragment>
            <h3><I18n code="businessDetail.request.exists.header"/></h3>
            <Row>
                <Col xs={12} md={6}>
                    <ManagementDetail management={management}/>
                </Col>
                <Col xs={12} md={6}>
                    {alreadyRequested() ?
                        <div>
                            <Alert bsStyle="info">
                                <h4><I18n code="parcours.management.already.requested"/></h4>
                                <Button onClick={cancelRequest}>
                                    <I18n code="parcours.management.request.cancel"/>
                                </Button>
                            </Alert>
                        </div>
                        :
                        <Button block bsSize="large" bsStyle="primary" onClick={requestManagement}>
                            {showSpinner ?
                                <FontAwesome icon="sync" spin/>
                                :
                                <FontAwesome icon="inbox"/>
                            }&nbsp;<I18n code="businessDetail.request.form.submit"/>
                        </Button>
                    }
                </Col>
            </Row>
        </React.Fragment>

    )
};
ExistingBusinessPartner.propTypes = {
    management: PropTypes.object,
    parcoursOwner: PropTypes.object,
    showSpinner: PropTypes.bool
};

module.exports = ParcoursManageRequestPage;
