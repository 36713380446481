const Parse = require("ParseInit");

const Club = Parse.Object.extend("club", {
    status: {
        published: "A",
        deleted: "D"
    },
    manageStatus: {
        enabled: "A",
        disabled: "I"
    },
    col: {
        name: "name",
        articles: "articles",
        bannerImageID: "bannerImageID",
        addressID: "addressID",
        managementID: "managementID",
        manageStatus: "manageStatus",
        email: "email",
        emailVerified: "emailVerified",
        status: "status",
        groups: "groups",
        invoiceTypes: "invoiceTypes",
        paymentTargetID: "paymentTargetID",
        addEditor: "addEditor",
        mainEditor: "mainEditor",
        creator: "creator",
        mainGroupPlaceID: "mainGroupPlaceID",
        groupPlaceID: "groupPlaceID",
        zip: "zip",
        place: "place",
        weblink: "weblink",
        objectId: "objectId",
        config: "config",
        updatedAt: "updatedAt",
        createdAt: "createdAt"
    },
    clubConfigs: {
        birthdaySummayMailReceivers : "birthdaySumMailRec", // String[]
        secondClubUserAddress: "bSecCUAddr", // bool
        hideJoinClubButton: "bHideJoinBtn" // bool


    },
    // Builder
    buildClub(name, url, mail, mainGroupPlace, regionGroupPlace, zip, place, user, parcoursOwner) {
        const club = new Club();
        club.setName(name);
        if (url) {
            club.setWeblink(url);
        }
        club.setEmail(mail);
        club.setMainGroupPlaceID(mainGroupPlace);
        club.setGroupPlaceID(regionGroupPlace);
        club.setZip(zip);
        club.setPlace(place);
        club.setCreator(user);
        club.setMainEditor(user);
        club.setStatus(this.status.published);
        if (parcoursOwner) {
            // owner ok
            if (parcoursOwner.getBannerImageID()) {
                // use banner
                club.setBannerImageID(parcoursOwner.getBannerImageID());
            }
            const mgmt = parcoursOwner.getManagementID();
            if (mgmt) {
                // set values from management
                club.setManagementID(mgmt);
                club.setMainEditor(mgmt.getMainEditor());
                club.setAddEditor(mgmt.getAddEditor());
            }
        }
        return club;
    },
    //
    // custom
    //
    hasManagementID() {
        return this.get(this.col.manageStatus) == "A" || this.get(this.col.manageStatus) == "I";
    },
    hasClubManagement() {
        return this.get(this.col.manageStatus) == "A"
    },
    getManagementID() {
        return this.get(this.col.managementID);
    },
    setManagementID(managementID) {
        this.set(this.col.managementID, managementID);
        if (managementID.hasClubFeature()) {
            this.set(this.col.manageStatus, "A")
        } else {
            this.set(this.col.manageStatus, "I")
        }

    },
    getFilterGroups() {
        return this.getGroups() ? this.getGroups() : [];
    },
    getFilteredArticles() {
        let result = [];
        let articles = this.getArticles();
        if (articles) {
            for (let i = 0; i < articles.length; i++) {
                /// add member articles for groups
                if (articles[i].isGroupMember()) {
                    result.push(articles[i].getName());
                }
            }
        }
        return result
    },
    getBannerImageUrl() {
        const bannerImageID = this.get(this.col.bannerImageID);
        if (bannerImageID) {
            return bannerImageID.get("image").url();
        }
        return "";
    },
    isEditor(user) {
        const that = this;
        let result = false;
        if (that.get('mainEditor') && user) {
            if (user.id == that.get('mainEditor').id) {
                result = true;
            } else {
                var addEditors = that.get('addEditor');
                if (addEditors) {
                    addEditors.map(editor =>{
                        if (user.id == editor.id) {
                            result = true;
                        }
                    });
                }
            }
        }
        return result;
    },
    isDeleted() {
        return this.status.deleted === this.get('status');
    },
    showJoinClubButton() {
        let config = this.getConfig();
        if (config && config[this.clubConfigs.hideJoinClubButton] != null) {
            return !config[this.clubConfigs.hideJoinClubButton]
        }
        return true;
    },
    show2ndAddress() {
        let config = this.getConfig();
        if (config) {
            return config[this.clubConfigs.secondClubUserAddress] === true
        }
        return false;
    },
    //
    // getter/setter
    //
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAddressID() {
        return this.get(this.col.addressID)
    },
    setAddressID(addressID) {
        return this.set(this.col.addressID, addressID)
    },
    getAddEditor() {
        return this.get(this.col.addEditor)
    },
    setAddEditor(addEditor) {
        return this.set(this.col.addEditor, addEditor)
    },
    getArticles() {
        return this.get(this.col.articles)
    },
    setArticles(articles) {
        return this.set(this.col.articles, articles)
    },
    getBannerImageID() {
        return this.get(this.col.bannerImageID)
    },
    setBannerImageID(bannerImageID) {
        return this.set(this.col.bannerImageID, bannerImageID)
    },
    getConfig() {
        return this.get(this.col.config)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getGroups() {
        return this.get(this.col.groups)
    },
    setGroups(groups) {
        return this.set(this.col.groups, groups)
    },
    addGroup(group) {
        let groups = this.getGroups();
        if (groups == null) {
            groups = [];
        }
        groups.push(group);
        return this.setGroups(groups);
    },
    removeGroup(group) {
        let groups = this.getGroups();
        if (groups != null) {
            return this.setGroups(groups.filter(function (u) {
                return u != group;
            }));
        }
        return null;
    },
    getInvoiceTypes() {
        return this.get(this.col.invoiceTypes)
    },
    setInvoiceTypes(types) {
        return this.set(this.col.invoiceTypes, types)
    },
    addInvoiceType(type) {
        let invTypes = this.getInvoiceTypes();
        if (invTypes == null) {
            invTypes = [];
        }
        invTypes.push(type);
        return this.setInvoiceTypes(invTypes);
    },
    removeInvoiceType(type) {
        let invTypes = this.getInvoiceTypes();
        if (invTypes != null) {
            return this.setInvoiceTypes(invTypes.filter(function (u) {
                return u != type;
            }));
        }
        return null;
    },
    getCreator() {
        return this.get(this.col.creator)
    },
    setCreator(creator) {
        return this.set(this.col.creator, creator)
    },
    getEmail() {
        return this.get(this.col.email)
    },
    setEmail(email) {
        return this.set(this.col.email, email)
    },
    isEmailVerified() {
        return this.get(this.col.emailVerified) === true;
    },
    setEmailVerified(emailVerified) {
        return this.set(this.col.emailVerified, emailVerified)
    },
    getGroupPlaceID() {
        return this.get(this.col.groupPlaceID)
    },
    setGroupPlaceID(groupPlaceID) {
        return this.set(this.col.groupPlaceID, groupPlaceID)
    },
    getMainEditor() {
        return this.get(this.col.mainEditor)
    },
    setMainEditor(mainEditor) {
        return this.set(this.col.mainEditor, mainEditor)
    },
    getMainGroupPlaceID() {
        return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID(mainGroupPlaceID) {
        return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPlace() {
        return this.get(this.col.place)
    },
    setPlace(place) {
        return this.set(this.col.place, place)
    },
    getPaymentTargetID() {
        return this.get(this.col.paymentTargetID)
    },
    setPaymentTargetID(paymentTargetID) {
        return this.set(this.col.paymentTargetID, paymentTargetID)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getWeblink() {
        return this.get(this.col.weblink)
    },
    setWeblink(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    getZip() {
        return this.get(this.col.zip)
    },
    setZip(zip) {
        return this.set(this.col.zip, zip)
    }
});
module.exports = Club;
