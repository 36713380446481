const React = require("react");
const AppState = {
    user: null,
    userDetail: null,
    userFeatures: null,
    tournamentMenuId: null,
    userAbos: null
}


export const BaseContext = React.createContext(AppState);