const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Button, Panel, Well} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Title = require("components/widgets/Title"),
    Loading = require("components/widgets/Loading"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    LoginButton = require("components/widgets/LoginButton");

const {BaseContext} = require("context/BaseContext");

const managementActions = require("actions/ManagementActions"),
    managementStore = require("stores/ManagementStore");

const BusinessPartnerForm = require("components/business/BusinessPartnerForm"),
    ManagementDetail = require("components/business/ManagementDetail"),
    ParcoursOwnerInfo = require("components/parcours/ParcoursOwnerInfo");

class ParcoursManageRequestPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleManagementChange = this.handleManagementChange.bind(this);
        
        this.state = {
            managementStore: managementStore.getState()
        }
    }
    componentDidMount() {
        const { user } = this.context;
        managementStore.listen(this.handleManagementChange);
        managementActions.initRequestParcoursManagePage(user, this.props.ownerID);
    }
    componentWillUnmount() {
        managementStore.unlisten(this.handleManagementChange);
    }
    handleManagementChange(state) {
        this.setState({managementStore:state});
    }
    render() {
        const { user } = this.context;
        const {allManagements, management, parcoursOwner} = this.state.managementStore;
        let body;
        if (user == null) {
            body = (<Row>
                <Col xs={12}>
                    <Alert bsStyle="warning"><I18n code="management.request.form.info.login"/> <LoginButton/></Alert>
                </Col>
            </Row>)
        } else if (parcoursOwner != null && allManagements != null) {
              if (management && parcoursOwner.hasManagementID()) {
                  body = (<Row>
                      <Col xs={12}>
                          <Alert bsStyle="success">{management.getName()} - <I18n
                              code="management.request.form.info.already"/></Alert>
                      </Col>
                  </Row>)
              } else if(parcoursOwner.hasManagementID()) {
                  body = (<Row>
                      <Col xs={12}>
                          <Alert bsStyle="warning"> <I18n code="management.request.form.info.other"/></Alert>
                      </Col>
                  </Row>)
              } else {
                  body = <ParcoursManageRequest parcoursOwner={parcoursOwner} user={user}
                                                management={management} managements={allManagements}/>
              }
        } else {
            body = <Loading/>
        }
        return (
            <Grid>
                <Title code="businessDetail.request.title"/>
                <Row>
                    <Col xs={12}>
                        <h3><I18n code="management.request.form.header.host"/></h3>
                        <p><I18n code="management.request.form.info.host"/></p>
                    </Col>
                    <Col xs={12} md={6}>
                        <Well>
                            <div style={{
                                float: "left",
                                width: "65px",
                                marginTop: "8px",
                                marginLeft: "10px"}}>
                                <FontAwesome icon="map-marker-alt" size="5x"/>
                            </div>
                            <ParcoursOwnerInfo parcoursOwner={parcoursOwner}/>
                            <div style={{clear: "both"}}/>
                        </Well>
                    </Col>
                </Row>
                {body}
            </Grid>)
    }
}
ParcoursManageRequestPage.contextType = BaseContext;
ParcoursManageRequestPage.propTypes = {
    ownerID:PropTypes.string.isRequired,
};
const ParcoursManageRequest = ({managements, management, parcoursOwner, user}) => {
    function handleFormCallback(pManagement) {
        managementActions.requestParcoursManagement(pManagement, parcoursOwner)
    }
    let managementRequested = management && !parcoursOwner.hasManagementID()
    return <React.Fragment>
        {
            managements.length > 0 ? <Row>
                <h3><I18n code="businessDetail.request.exists.header"/></h3><br/>
                {
                    managements.map(management => {
                        return (<ExistingBusinessPartner management={management} parcoursOwner={parcoursOwner}
                                                         ownManagementRequest={management.getManageRequestedIDParcoursOwner(parcoursOwner)}
                                                         managementRequested={managementRequested}/>)
                    })
                }
            </Row> : null
        }
        {
            !managementRequested ? <Row>
                <h3><I18n code="businessDetail.request.new.header"/></h3><br/>
                <Col xs={12}>
                    <Panel>
                        <Panel.Heading>
                            <h3><I18n code={"businessDetail.request.form.header"}/></h3>
                        </Panel.Heading>
                        <Panel.Body>
                            <BusinessPartnerForm callback={handleFormCallback} parcoursOwner={parcoursOwner} user={user}/>
                        </Panel.Body>
                    </Panel>
                </Col>
                <Col xs={12}>
                    <p><I18n code="management.request.form.info.contact"/></p>
                </Col>
            </Row> : null
        }
    </React.Fragment>
};
ParcoursManageRequest.propTypes = {
    managements: PropTypes.array,
    parcoursOwner: PropTypes.object,
    management: PropTypes.object
};

const ExistingBusinessPartner = ({management, parcoursOwner, ownManagementRequest, managementRequested}) => {
    function requestManagement() {
        managementActions.requestParcoursManagement(management, parcoursOwner);
    }
    function cancelRequest() {
        managementActions.cancelManagementRequest(management, ownManagementRequest);
    }
    return (
        <Col xs={12}>
        <Alert>
            <Row>
                <Col xs={12} md={6}>
                    <ManagementDetail management={management}/>
                </Col>
                <Col xs={12} md={6}>
                    {ownManagementRequest != null ?
                        <div>
                            <Alert bsStyle="success">
                                <h4><I18n code="parcours.management.already.requested"/></h4>
                                <Button onClick={cancelRequest}>
                                    <I18n code="parcours.management.request.cancel"/>
                                </Button>
                            </Alert>
                        </div>
                        :
                        <SubmitButton block  bsSize="large" bsStyle="primary"
                                      disabled={managementRequested} icon={<FontAwesome icon="inbox"/>}
                                      onClick={requestManagement}
                                      text={<I18n code="businessDetail.request.form.submit"/>}/>
                    }
                </Col>
            </Row>
        </Alert></Col>
    )
};
ExistingBusinessPartner.propTypes = {
    management: PropTypes.object,
    parcoursOwner: PropTypes.object,
    ownManagementRequest: PropTypes.object,
    managementRequested: PropTypes.bool
};

module.exports = ParcoursManageRequestPage;
