const Parse = require("ParseInit");

const {Tournament, TournamentCupOption, GroupPlace, BowUnion, Parcours} = require("parse/_Domain");

class TournamentDao {

    searchTournaments(searchParams) {
        const queries = [];
        const query = new Parse.Query(Tournament);
        const mQuery = new Parse.Query(Tournament);
        query.limit(1000);
        this.applyAndFilter(searchParams, query, true);
        this.applyAndFilter(searchParams, mQuery, false);
        queries.push(query);
        if (searchParams.searchText != null) {
            // search for name
            query.matches("name", "(" + searchParams.searchText + ")", "i");
            mQuery.matches("name", "(" + searchParams.searchText + ")", "i");
            const zip = parseInt(searchParams.searchText);
            if (zip > 0) {
                // search by zip
                const orZipQuery = new Parse.Query(Tournament);
                this.applyAndFilter(searchParams, orZipQuery, true);
                orZipQuery.matches("zip", "(" + zip + ")", "i");
                queries.push(orZipQuery);
            } else {
                // search by place
                const orPlaceQuery = new Parse.Query(Tournament);
                this.applyAndFilter(searchParams, orPlaceQuery, true);
                orPlaceQuery.matches("place", "(" + searchParams.searchText + ")", "i");
                queries.push(orPlaceQuery);
            }
        }
        if (searchParams.maxDuration  > 0) {
            // add master tournaments to ignore maxDuration
            mQuery.exists(Tournament.prototype.col.tournamentCupOptionID)
            mQuery.doesNotExist(Tournament.prototype.col.cupMasterTournamentID)
            queries.push(mQuery);
        }


        const superQuery = Parse.Query.or.apply(Parse.Query, queries);
        if (searchParams.dateFilter === "current") {
            superQuery.ascending(Tournament.prototype.col.date);
        } else if (searchParams.dateFilter === "past") {
            superQuery.descending(Tournament.prototype.col.date);
        }
        superQuery.limit(1000);
        return superQuery.find();
    }

    applyAndFilter(searchParams, query, useMaxDuration) {
        if (searchParams.mainGroupPlaceID != null) {
            const mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = searchParams.mainGroupPlaceID;
            query.equalTo(Tournament.prototype.col.mainGroupPlaceID, mainGroupPlace);
        }
        if (searchParams.regionGroupPlaceID != null) {
            const regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = searchParams.regionGroupPlaceID;
            query.equalTo(Tournament.prototype.col.groupPlaceID, regionGroupPlace);
        }
        query.notEqualTo(Tournament.prototype.col.status, Tournament.prototype.status.deleted);
        if (useMaxDuration && searchParams.maxDuration  > 0) {
            // but only for not master tournaments ???
            query.lessThan(Tournament.prototype.col.duration, searchParams.maxDuration)
        }
        const now = new Date();
        if (searchParams.dateFilter === "current") {
            query.greaterThanOrEqualTo(Tournament.prototype.col.endDate, new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
        } else if (searchParams.dateFilter === "past") {
            query.lessThanOrEqualTo(Tournament.prototype.col.endDate, new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0));
            query.exists(Tournament.prototype.col.mainEditor);
            query.notEqualTo(Tournament.prototype.col.regStatus, Tournament.prototype.registrationStatus.published);
        } else {
            throw "missing arg: dateFilter";
        }
    }

    initTeaser(maxDuration) {
        // find all which started within the last month
        const queryDate = new Parse.Query(Tournament);
        queryDate.exists(Tournament.prototype.col.mainEditor);
        queryDate.notEqualTo(Tournament.prototype.col.status,
            Tournament.prototype.status.deleted);
        queryDate.notEqualTo(Tournament.prototype.col.regStatus,
            Tournament.prototype.registrationStatus.published);
        const dateFilter = new Date();
        dateFilter.setMonth(dateFilter.getMonth() - 1);
        queryDate.greaterThanOrEqualTo(Tournament.prototype.col.date, dateFilter);
        // find all which ended within the last month
        const queryEndDate = new Parse.Query(Tournament);
        queryEndDate.exists(Tournament.prototype.col.mainEditor);
        queryEndDate.notEqualTo(Tournament.prototype.col.status,
            Tournament.prototype.status.deleted);
        queryEndDate.notEqualTo(Tournament.prototype.col.regStatus,
            Tournament.prototype.registrationStatus.published);
        const dateEndFilter = new Date();
        dateEndFilter.setMonth(dateFilter.getMonth());
        queryEndDate.greaterThanOrEqualTo(Tournament.prototype.col.endDate, dateEndFilter);

        const mainQuery = Parse.Query.or(queryDate, queryEndDate);
        mainQuery.ascending(Tournament.prototype.col.date);
        mainQuery.lessThan(Tournament.prototype.col.duration, maxDuration)
        return mainQuery.find();
    }

    getTournamentsForParcoursHome(parcoursOwner, parcoursList) {
        const queryMgmt = new Parse.Query(Tournament);
        queryMgmt.equalTo(Tournament.prototype.col.managementID, parcoursOwner.getManagementID());
        queryMgmt.notEqualTo(Tournament.prototype.col.status,
            Tournament.prototype.status.deleted);

        const queryParc = new Parse.Query(Tournament);
        queryParc.containedIn(Tournament.prototype.col.parcoursID, parcoursList);
        queryParc.notEqualTo(Tournament.prototype.col.status,
            Tournament.prototype.status.deleted);

        const mainQuery = Parse.Query.or(queryMgmt, queryParc);
        mainQuery.descending(Tournament.prototype.col.date);
        return mainQuery.find();
    }

    loadTournamentDetails(id) {
        const query = new Parse.Query(Tournament);
        query.include(Tournament.prototype.col.imageID);
        query.include(Tournament.prototype.col.resultImageID);
        query.include(Tournament.prototype.col.bannerImageID);
        query.include(Tournament.prototype.col.parcoursID);
        query.include(Tournament.prototype.col.mainEditor);
        query.include(Tournament.prototype.col.tournamentConfigAge);
        query.include(Tournament.prototype.col.tournamentConfigBow);
        query.include(Tournament.prototype.col.tournamentConfigBow + "." + Tournament.prototype.col.countTypeId);
        query.include(Tournament.prototype.col.tournamentPriceID);
        query.include(Tournament.prototype.col.tournamentOptionID);
        query.include(Tournament.prototype.col.bowUnionID);
        query.include(Tournament.prototype.col.tournamentCupOptionID);
        query.include(Tournament.prototype.col.tournamentCupOptionID+ "." + TournamentCupOption.prototype.col.tConfigCombinedAgeID);
        query.include(Tournament.prototype.col.sponsorImageID);
        return query.get(id);
    }

    loadTournamentTConfigDetails(id) {
        const query = new Parse.Query(Tournament);
        query.include(Tournament.prototype.col.tournamentConfigAge);
        query.include(Tournament.prototype.col.tournamentConfigBow);
        return query.get(id);
    }

    loadTournamentsByEditors(user, year) {
        const startDate = new Date(year,0,1);
        const endDate = new Date(year,12,0,23,59,59,0);

        let meQuery = new Parse.Query(Tournament);
        meQuery.notEqualTo(Tournament.prototype.col.status, Tournament.prototype.status.deleted);
        meQuery.equalTo(Tournament.prototype.col.mainEditor, user);
        meQuery.greaterThanOrEqualTo(Tournament.prototype.col.endDate, startDate);
        meQuery.lessThanOrEqualTo(Tournament.prototype.col.endDate, endDate);

        let aeQuery = new Parse.Query(Tournament);
        aeQuery.notEqualTo(Tournament.prototype.col.status, Tournament.prototype.status.deleted);
        aeQuery.equalTo(Tournament.prototype.col.addEditor, user);
        aeQuery.greaterThanOrEqualTo(Tournament.prototype.col.endDate, startDate);
        aeQuery.lessThanOrEqualTo(Tournament.prototype.col.endDate, endDate);

        let query = Parse.Query.or(meQuery, aeQuery);
        query.ascending(Tournament.prototype.col.date);
        return query.find()
    }

    loadTournamentMasterCup(buCode, startDate) {
        const buQuery = new Parse.Query(BowUnion);
        buQuery.equalTo(BowUnion.prototype.col.code, buCode);

        const query = new Parse.Query(Tournament);
        query.include(Tournament.prototype.col.bannerImageID);
        query.include(Tournament.prototype.col.mainEditor);
        query.include(Tournament.prototype.col.tournamentPriceID);
        query.include(Tournament.prototype.col.tournamentOptionID);
        query.include(Tournament.prototype.col.bowUnionID);
        query.include(Tournament.prototype.col.sponsorImageID);
        query.include(Tournament.prototype.col.tournamentConfigAge)
        query.greaterThanOrEqualTo(Tournament.prototype.col.date, startDate);
        query.matchesQuery(Tournament.prototype.col.bowUnionID, buQuery);
        query.exists(Tournament.prototype.col.mainEditor);
        query.exists(Tournament.prototype.col.tournamentCupOptionID);
        query.doesNotExist(Tournament.prototype.col.cupMasterTournamentID)
        query.notEqualTo(Tournament.prototype.col.status, Tournament.prototype.status.deleted);
        query.descending(Tournament.prototype.col.date);
        return query.first()
    }

    loadTournamentBuCodeParcoursList(searchParams, bowUnion, startDate) {
        const tQuery = new Parse.Query(Tournament);
        if (searchParams.needCupMaster) {
            tQuery.exists(Tournament.prototype.col.cupMasterTournamentID)
        }
        tQuery.equalTo(Tournament.prototype.col.bowUnionID, bowUnion);
        tQuery.notEqualTo(Tournament.prototype.col.status, Tournament.prototype.status.deleted);
        tQuery.ascending(Tournament.prototype.col.date);
        tQuery.include(Tournament.prototype.col.parcoursID);
        tQuery.include(Tournament.prototype.col.parcoursID + "." + Parcours.prototype.col.parcoursOwnerID);
        tQuery.greaterThanOrEqualTo(Tournament.prototype.col.date, startDate);
        if (searchParams.mainGroupPlaceID != null) {
            const mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = searchParams.mainGroupPlaceID;
            tQuery.equalTo(Parcours.prototype.col.mainGroupPlaceID, mainGroupPlace);
        }
        if (searchParams.regionGroupPlaceID != null) {
            const regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = searchParams.regionGroupPlaceID;
            tQuery.equalTo(Parcours.prototype.col.groupPlaceID, regionGroupPlace);
        }
        return tQuery.find();
    }

    loadBowUnion(buCode) {
        const buQuery = new Parse.Query(BowUnion);
        buQuery.equalTo(BowUnion.prototype.col.code, buCode);
        return buQuery.first();

    }

    setMainEditor(tournament, user) {
        return Parse.Cloud.run("setMainEditorToTournament", {tournamentObjectId: tournament.id});
    }
}

module.exports = new TournamentDao();
