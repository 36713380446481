const Parse = require("ParseInit");
const Utility = require("util/Utility");
const EventPlayer = Parse.Object.extend("EventPlayer", {
    col: {
        ACL: "ACL",
        arrowToTarget: "arrowToTarget",
        countTypeID: "countTypeID",
        countTypeOnlineID: "countTypeOnlineID",
        createdAt: "createdAt",
        eventID: "eventID",
        eventResults: "eventResults",
        eventStringID: "eventStringID",
        killCounts: "killCounts",
        killValue: "killValue",
        maxTargetPoints: "maxTargetPoints",
        objectId: "objectId",
        playerEmail: "playerEmail",
        playerName: "playerName",
        status: "status",
        visStatus: "visStatus",
        sumPoints: "sumPoints",
        bowArrowProfileID: "baProfileID",
        bowTypeId: "bowTypeId",
        tournamentStatus: "tournamentStatus",
        tournamentUserID: "tournamentUserID",
        updatedAt: "updatedAt",
    },
    status: {
        active: "A",
        deleted: "D"
    },
    visible: {
        visible: "V",
        invisible: "I"
    },
    getEventDate() {
        if (this.getEventID()) {
            return this.getEventID().getDate();
        } else {
            return "";
        }
    },
    getReadableDate() {
        return this.getEventDate();
    },
    getCalName() {
        if (this.getEventID()) {
            return this.getEventID().getName();
        } else {
            return "-";
        }
    },
    getCalLink() {
        if (this.getEventID()) {
            return "profile/eventdetail/" + this.getEventID().id
        }
        return "profile"
    },
    getCalPlace() {
        return null;
    },
    getCalAdditional() {
        return "(" + this.getArrows() + ", " + this.getDuration() + "min)"
    },
    getCalDescription() {
        return "P:" + this.getSumPoints() + ", DPS:" + this.getPlayerDPS()
    },
    getCalStartDate() {
        if (this.getEventID()) {
            return this.getEventID().getEventInsstmpDate();
        }
        return new Date()
    },
    getCalEndDate() {
        if (this.getEventID()) {
            return this.getEventID().getEventInsstmpDate();
        }
        return new Date()
    },
    isTrainingParcours() {
        return this.getEventID().isNormalEvent() || this.getEventID().isAnyTraining()
    },
    isTrainingTournament() {
        return this.getEventID().isTournament()
    },
    getCalcAllowEdit() {
        return false;
    },
    getCalBackground() {
        return "transparent"
    },
    getArrows() {
        // get the real shoot arrows!!
        let resultArrows = 0;
        let killCountsStr = this.getKillCounts();
        if (killCountsStr) {
            let arrowSumUp = killCountsStr.trim().startsWith("t,");
            let killCounts = killCountsStr.split(",");
            killCounts.forEach(function (killCountString) {
                let killCnt = killCountString.split(':');
                if (killCnt.length > 1) {
                    // found count eg "1001:1"
                    let arrZo = killCnt[0];
                    let amount = parseInt(killCnt[1]);
                    if (arrZo % 1000 != 0) {
                        // hit a zone
                        if (arrowSumUp) {
                            resultArrows += amount;
                        } else {
                            var arrow = arrZo.substring(0,1);
                            resultArrows += (parseInt(arrow) * amount);
                        }
                    } else {
                        // miss
                        if (arrowSumUp) {
                            resultArrows += amount;
                        } else {
                            let arrow = parseInt(arrZo.substring(0,1)) - 1;
                            if (arrow === 0) {
                                arrow = 1;
                            }
                            resultArrows += (arrow * amount);
                        }
                    }

                }
            });
        }
        return resultArrows;
    },
    getDuration() {
        let event = this.getEventID();
        if (event != null) {
            if (event.getDuration() > 0) {
                return event.getDuration()
            }
        } else {
            console.log("WTF, No event for event player: ", this.id);
        }
        return 0
    },
    getKillCounts2String() {
        return Utility.buildKillCounts2String(this.getKillCounts());
    },
    getShootCounter() {
        let counter = 0;
        var killCountsStr = this.getKillCounts();
        if (killCountsStr) {
            var killCounts = killCountsStr.split(",");
            killCounts.forEach(function (killCountString) {
                var killCnt = killCountString.split(':');
                if (killCnt.length > 1) {
                    // found count
                    counter += parseInt(killCnt[1]);

                }
            });
        }
        return counter;
    },
    getPlayerDPS() {
        const sumPoints = this.getSumPoints();
        const shootCounter = this.getShootCounter();
        if (shootCounter > 0 && sumPoints > 0 ) {
            let dps = sumPoints / shootCounter;
            dps = +dps.toFixed(2); // needed to return as number
            return dps;
        } else {
            return 0;
        }
    },
    getPointPercentDPSString() {
        var sumPoints = this.getSumPoints();
        if (sumPoints > 0) {
            var shootCounter = this.getShootCounter();
            var arrow2Target = this.getArrowToTarget();
            var maxTargetPoints = this.getMaxTargetPoints();
            if (shootCounter > 0 && arrow2Target >= 0 && maxTargetPoints > 0) {
                var perc = (sumPoints * 100) / ((shootCounter / arrow2Target) * maxTargetPoints);
                var dps = sumPoints / shootCounter;
                return sumPoints + " (" + perc.toFixed(0) +  "%)" + " DPS: "+ dps.toFixed(2);
            } else {
                return sumPoints;
            }
        } else {
            return "---"
        }

    },
    isDeleted() {
        return "D" === this.get('status');
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getArrowToTarget() {
        return this.get(this.col.arrowToTarget)
    },
    getAmountArrowToTarget() {
        let arrow2Target = this.getArrowToTarget();
        if (arrow2Target == null || arrow2Target <= 0) {
            arrow2Target = 1;
        } else if (arrow2Target < 3) {
            // use standard values for disc scores
            let countTypeOnlineID = this.getCountTypeOnlineID();
            if (countTypeOnlineID != null) {
                if (countTypeOnlineID === "D4Z_5_t") {
                    arrow2Target = 4;
                } else if (countTypeOnlineID.indexOf("D") == 0) {
                    arrow2Target = 3;
                }
            }
        }
        return arrow2Target
    },
    setArrowToTarget(arrowToTarget) {
        return this.set(this.col.arrowToTarget, arrowToTarget)
    },
    getBAProfileID() {
        return this.get(this.col.bowArrowProfileID)
    },
    setBAProfileID(baProfileID) {
        if (baProfileID != null) {
            this.set(this.col.bowTypeId, baProfileID.getBowTypeId())
            this.set(this.col.bowArrowProfileID, baProfileID)
        } else {
            this.unset(this.col.bowTypeId)
            this.unset(this.col.bowArrowProfileID)
        }
    },
    getBowTypeId() {
        return this.get(this.col.bowTypeId)
    },
    getCountTypeID() {
        return this.get(this.col.countTypeID)
    },
    setCountTypeID(countTypeID) {
        return this.set(this.col.countTypeID, countTypeID)
    },
    getCountTypeOnlineID() {
        return this.get(this.col.countTypeOnlineID)
    },
    setCountTypeOnlineID(countTypeOnlineID) {
        return this.set(this.col.countTypeOnlineID, countTypeOnlineID)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getEventID() {
        return this.get(this.col.eventID)
    },
    setEventID(eventID) {
        return this.set(this.col.eventID, eventID)
    },
    getValidEventResults() {
        let results = this.getEventResults();
        if (results != null) {
            results = results.filter(evResult => {
                return evResult.isResultTypeValid()
            })
            if (results.length > 0 && results[0].getTargetIndex() !== 1) {
                // sort results if round did not start with target index 1
                results = results.sort(function(evResultA, evResultB){
                    if (evResultA.getTargetIndex() === evResultB.getTargetIndex()) {
                        // sort by arrow number
                        return  evResultA.getArrowNr() - evResultB.getArrowNr();
                    }
                    // sort by target index
                    return evResultA.getTargetIndex() - evResultB.getTargetIndex();
                })
            }
            return results;
        }
        return [];
    },
    getEventResults() {
        return this.get(this.col.eventResults)
    },
    setEventResults(eventResults) {
        return this.set(this.col.eventResults, eventResults)
    },
    getTargetAmounts() {
        if (this.getEventResults() != null) {
            let arr2target = this.getAmountArrowToTarget();
            if (arr2target > 1) {
                return this.getEventResults().length / arr2target;
            } else {
                return this.getEventResults().length
            }
        }
        return 0;
    },
    getEventStringID() {
        return this.get(this.col.eventStringID)
    },
    setEventStringID(eventStringID) {
        return this.set(this.col.eventStringID, eventStringID)
    },
    getKillCounts() {
        return this.get(this.col.killCounts)
    },
    setKillCounts(killCounts) {
        return this.set(this.col.killCounts, killCounts)
    },
    getKillValue() {
        return this.get(this.col.killValue)
    },
    setKillValue(killValue) {
        return this.set(this.col.killValue, killValue)
    },
    getMaxTargetPoints() {
        return this.get(this.col.maxTargetPoints)
    },
    setMaxTargetPoints(maxTargetPoints) {
        return this.set(this.col.maxTargetPoints, maxTargetPoints)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPlayerEmail() {
        return this.get(this.col.playerEmail)
    },
    setPlayerEmail(playerEmail) {
        return this.set(this.col.playerEmail, playerEmail)
    },
    getPlayerNameWithBowArrow() {
        if (this.getBAProfileID()) {
            return this.getPlayerName() + " (" + this.getBAProfileID().getName() + ")"
        }
        return this.getPlayerName()
    },
    getPlayerName() {
        return this.get(this.col.playerName)
    },
    setPlayerName(playerName) {
        return this.set(this.col.playerName, playerName)
    },
    getVisibileStatus() {
        return this.get(this.col.visStatus)
    },
    setVisibileStatus(status) {
        return this.set(this.col.visStatus, status)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getSumPoints() {
        return this.get(this.col.sumPoints)
    },
    setSumPoints(sumPoints) {
        return this.set(this.col.sumPoints, sumPoints)
    },
    getTournamentStatus() {
        return this.get(this.col.tournamentStatus)
    },
    setTournamentStatus(tournamentStatus) {
        return this.set(this.col.tournamentStatus, tournamentStatus)
    },
    getTournamentUserID() {
        return this.get(this.col.tournamentUserID)
    },
    setTournamentUserID(tournamentUserID) {
        return this.set(this.col.tournamentUserID, tournamentUserID)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = EventPlayer;
