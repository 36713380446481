const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert} = require("react-bootstrap");

const ParcoursStatistics = React.lazy(() =>
    import(/* webpackChunkName: "ChartComponents" */ "components/parcoursmanage/ParcoursStatistics")
);

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    ErrorSuspenseBoundary = require("components/widgets/ErrorSuspenseBoundary"),
    YearPager= require("components/widgets/YearPager");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const {BaseContext} = require("context/BaseContext");
const analyticsStore = require("stores/AnalyticsStore"),
    analyticsActions = require("actions/AnalyticsActions");

const messages = require("i18n/messages");

class ParcoursStatisticPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleAnalyticsChange = this.handleAnalyticsChange.bind(this);
        this.handleChangedDate = this.handleChangedDate.bind(this);

        // start with year from url this year
        let year = this.props.year;
        if (year == null) {
            const today = new Date();
            year = today.getFullYear();
        }
        this.state = {
            analyticsStore: analyticsStore.getState(),
            year: year
        }
    }
    componentDidMount() {
        const { user, checkUserAdmin } = this.context;
        document.title = messages.get("statistics.header");
        analyticsStore.listen(this.handleAnalyticsChange);
        if (this.props.tstat === "admin") {
            checkUserAdmin(callbackIsAdmin => {
                analyticsActions.startUpParcoursStats(user, null, this.props.onlineID, this.state.year, callbackIsAdmin);
            })
        } else {
            analyticsActions.startUpParcoursStats(user, null, this.props.onlineID, this.state.year, false);
        }

    }
    componentWillUnmount() {
        analyticsStore.unlisten(this.handleAnalyticsChange);
    }
    handleAnalyticsChange(state) {
        this.setState({analyticsStore: state});
    }
    handleChangedDate(year) {
        const { user, userIsAdmin } = this.context;
        this.setState({year: year});
        analyticsActions.startUpParcoursStats.defer(user, this.state.analyticsStore.parcours, this.props.onlineID, year, userIsAdmin);
    }
    render() {
        return (
            <Grid>
                <Row>
                    <Col lg={12} xsHidden>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/mgmtparcours" code="profile.managedParcours.header" />
                            <Breadcrumb code="statistics.header" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                {
                    this.state.analyticsStore.invalidUser || this.state.analyticsStore.parcours == null ? null :
                        <YearPager year={this.state.year} headerText={this.state.analyticsStore.parcours.buildNames() + " " + messages.get("statistics.header")} handleChangedDate={this.handleChangedDate}/>
                }
                {
                    this.state.analyticsStore.loading ? <Loading /> :
                        this.state.analyticsStore.invalidUser ?
                            <Alert bsStyle="warning"><I18n code="parse.error.119"/></Alert>
                        :
                            this.state.analyticsStore.parcoursStatDic != null ?
                                <ErrorSuspenseBoundary>
                                    <ParcoursStatistics parcoursStatDic={this.state.analyticsStore.parcoursStatDic}/>
                                </ErrorSuspenseBoundary>
                            :
                                <Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert>
                }

            </Grid>
        )
    }
}
ParcoursStatisticPage.contextType = BaseContext;
ParcoursStatisticPage.propTypes = {
    onlineID: PropTypes.string.isRequired,
    tstat: PropTypes.string,
    year: PropTypes.number
};
module.exports = ParcoursStatisticPage;
