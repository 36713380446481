const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
const {UserBowUnionLic, BowType, AgeType} = require("parse/_Domain");

const I18n = require("components/widgets/I18n"),
    TextCenter = require("components/widgets/TextCenter"),
    LoadingWrapper = require("components/widgets/LoadingWrapper"),
    DateInput = require("components/form/DateInput"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    ValidInput = require("components/form/ValidInput");

const messages = require("i18n/messages");

const {BaseContext} = require("context/BaseContext");

const licenseStore = require("stores/LicenseStore"),
    licenseActions = require("actions/LicenseActions");

class LicenseSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.refLic = React.createRef();
        this.refUnion = React.createRef();
        this.refEndDate = React.createRef();

        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.close = this.close.bind(this);
        this.resetState = this.resetState.bind(this);
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.openAdd = this.openAdd.bind(this);
        this.openEdit = this.openEdit.bind(this);
        this.handleBowUnionChange = this.handleBowUnionChange.bind(this);
        this.handleBowTypeChange = this.handleBowTypeChange.bind(this);
        this.handleAgeTypeChange = this.handleAgeTypeChange.bind(this);
        this.getDefaultLicNr = this.getDefaultLicNr.bind(this);
        this.getDefaultValidDate = this.getDefaultValidDate.bind(this);

        this.state = {
            licenseStore: licenseStore.getState(),
            bowUnion: null,
            submitDisabled: false
        }
    }
    componentDidMount() {
        licenseStore.listen(this.handleLicenseChange);
    }
    componentWillUnmount() {
        licenseStore.unlisten(this.handleLicenseChange);
    }
    handleLicenseChange(state) {
        if (state.bowUnions != null && state.bowUnions[0].getSortIndex() == 0) {
            //console.log("Remove element: Vereinseigene Reglement")
            state.bowUnions.shift()
        }
        this.setState({licenseStore: state});
    }
    close() {
        this.resetState();
        licenseActions.showModal(false);
    }
    resetState() {
        this.setState({editUsrLic: null, bowUnion: null, ageType: null, bowType: null, error: null, submitDisabled: false});
    }
    openAdd() {
        if (this.state.licenseStore.loading === true) {
            licenseActions.loadBowUnionsAndTypes(null);
        }
        this.resetState();
        licenseActions.showModal(true);
    }
    openEdit(editUsrLic) {
        if (this.state.licenseStore.loading === true) {
            licenseActions.loadBowUnionsAndTypes(null);
        }
        this.setState({
            editUsrLic: editUsrLic,
            bowUnion: editUsrLic.getBowUnionID(),
            ageType: editUsrLic.getAgeTypeID(),
            bowType: editUsrLic.getBowTypeID(),
            error: null});
        licenseActions.showModal(true);
    }
    valid() {
        let valid = true;
        const required = [];
        if (this.state.bowUnion.isLicenseNumberMandatory()) {
            required.push(this.refLic.current.getValue());
        }
        for (var i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        const { updateUserInfoState } = this.context;
        //
        let usrLic = this.state.editUsrLic;
        if (usrLic == null) {
            usrLic = new UserBowUnionLic();
            usrLic.setStatus("A");
        }
        usrLic.setBowUnionID(this.state.bowUnion);
        if (this.state.bowType != null) {
            usrLic.setBowTypeID(this.state.bowType)
        } else {
            usrLic.unset(UserBowUnionLic.prototype.col.bowTypeID);
        }
        if (this.state.ageType != null) {
            usrLic.setAgeTypeID(this.state.ageType)
        } else {
            usrLic.unset(UserBowUnionLic.prototype.col.ageTypeID);
        }
        if (this.state.bowUnion.isLicenseNumberMandatory()) {
            usrLic.setLicenseNumber(this.refLic.current.getValue().trim());
        } else {
            usrLic.unset(UserBowUnionLic.prototype.col.licenseNumber);
        }
        if (this.refEndDate.current != null && this.refEndDate.current.getValue() != null && this.refEndDate.current.getValue().length > 0) {
            const endDateParts = new Date(this.refEndDate.current.getValue());
            usrLic.setValidEndDate(new Date(endDateParts.getFullYear(), endDateParts.getMonth(), endDateParts.getDate(), 23, 59, 0, 0));
        } else {
            usrLic.unset(UserBowUnionLic.prototype.col.validEnd);
        }
        if (this.refUnion.current != null && this.refUnion.current.getValue() != null) {
            usrLic.setUnion(this.refUnion.current.getValue())
        }


        if (this.state.editUsrLic != null) {
            // just save it
            licenseActions.saveUserBowLicense(usrLic, this.props.clubUser, this.props.userDetail, callbackUserInfoObject => {
                updateUserInfoState(callbackUserInfoObject)
            });
        } else {
            // create new one
            licenseActions.createUserBowLicense(usrLic, this.props.clubUser, this.props.userDetail, callbackUserInfoObject => {
                updateUserInfoState(callbackUserInfoObject)
            });
        }
        this.close();
    }
    handleBowUnionChange(bowUnionID) {
        this.setState({bowUnion: bowUnionID});
    }
    handleBowTypeChange(type) {
        this.setState({bowType: type});
    }
    handleAgeTypeChange(type) {
        this.setState({ageType: type});
    }
    getDefaultLicNr() {
        if (this.state.editUsrLic != null) {
            return this.state.editUsrLic.getLicenseNumber();
        }
        return null;
    }
    getDefaultValidDate() {
        if (this.state.editUsrLic != null) {
            return this.state.editUsrLic.getValidEndDate();
        }
        return null;
    }
    render() {
        const {error, submitDisabled, bowUnion, bowType, ageType, licenseStore, editUsrLic} = this.state;
        const validateNow = error && error.length > 0;
        return (
            <Modal backdrop="static" show={this.state.licenseStore.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter>{messages.get("modal.license.header")}</TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.licenseStore.showModal ?
                                <LoadingWrapper loading={licenseStore.loading}>
                                    <Row>
                                        {error ?
                                            <Alert bsStyle="warning">{error}</Alert>
                                            : ""}
                                        {
                                           licenseStore.bowUnions != null && !licenseStore.loading ?
                                                <Col sm={12}>
                                                    <DropDownParseObject default={bowUnion}
                                                        placeholder={<option key={"nullkey"}
                                                                             value={"null"}>{messages.get("modal.license.chooseUnion")}</option>}
                                                        onAfterChange={this.handleBowUnionChange}
                                                        objectList={licenseStore.bowUnions}/>
                                                </Col>: null
                                        }
                                        {
                                            bowUnion != null && !licenseStore.loading ?
                                                <Col sm={12}>
                                                    <DropDownParseObject default={bowType}
                                                                         placeholder={<option key={"nullkey"}
                                                                                              value={"null"}>{messages.get("modal.license.chooseBow")}</option>}
                                                                         onAfterChange={this.handleBowTypeChange}
                                                                         objectList={licenseStore.bowTypes.filter(fBowType => {
                                                                             if (fBowType.getUnions() != null) {
                                                                                 return fBowType.getUnions().indexOf(bowUnion.getCode()) > -1;
                                                                             }
                                                                             return false;
                                                                         })}/>
                                                    <DropDownParseObject default={ageType}
                                                                         placeholder={<option key={"nullkey"}
                                                                                              value={"null"}>{messages.get("modal.license.chooseAge")}</option>}
                                                                         onAfterChange={this.handleAgeTypeChange}
                                                                         objectList={licenseStore.ageTypes.filter(fAgeType => {
                                                                             if (fAgeType.getUnions() != null) {
                                                                                 return fAgeType.getUnions().indexOf(bowUnion.getCode()) > -1;
                                                                             }
                                                                             return false;
                                                                         })}/>
                                                    <ValidInput ref={this.refUnion} default={editUsrLic ? editUsrLic.getUnion() : null}
                                                                type="text"
                                                                label={messages.get("tournament.table.column.union")}/>
                                                    {
                                                        bowUnion.isLicenseNumberMandatory() ?
                                                            <React.Fragment>
                                                                <br/>
                                                                <ValidInput ref={this.refLic} default={this.getDefaultLicNr()}
                                                                            type="text"
                                                                            label={messages.get("modal.license")}
                                                                            valid={{check:['isRequired']}}
                                                                            validateNow={validateNow}/>

                                                                <DateInput ref={this.refEndDate} default={this.getDefaultValidDate()}
                                                                           label={messages.get("modal.license.validUntil")}/>

                                                            </React.Fragment> : null
                                                    }

                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </LoadingWrapper> : null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col xs={12}>
                                <Button onClick={this.clickedSave} block bsStyle="success"
                                        disabled={submitDisabled || bowUnion == null || licenseStore.loading} >
                                    <I18n code={"modal.button.save"}/>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
LicenseSelectModal.contextType = BaseContext;
module.exports = LicenseSelectModal;
