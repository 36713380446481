const React = require("react");
const {Row, Col, Image} = require("react-bootstrap");
const bundle = require("../../../package.json");
const {Link} = require("react-router-dom");

const ExtLink = require("components/links/ExtLink"),
    I18n = require("components/widgets/I18n"),
    ListUnstyled = require("components/widgets/ListUnstyled"),
    linkUtil = require("linkUtil");

const MenuFooter = () => {
    const getLangUrl = () => {
        return window.location.pathname.substring(3, window.location.pathname.length);
    };
    const year = new Date().getFullYear();
    return (
        <div className="container">
            <footer>
                <hr/>
                <Row>
                    <FooterLinkCol header="Unternehmen">
                        <FooterLinkEntry route="contact" code="header.nav.contact"/>
                        <FooterLinkEntry route="impressum" code="footer.impressum"/>
                        <FooterLinkEntry route="policy" code="header.nav.policy"/>
                        <FooterLinkEntry route="agb" code="§ AGBs"/>
                    </FooterLinkCol>
                    <FooterLinkCol header="3D Turnier">
                        <FooterLinkEntry route="/solutions" code="Pakete & Lösungen"/>
                        <FooterLinkEntry route="/mgmtparcours" code="profile.managedParcours.header"/>
                        <li><ExtLink href="https://3dturnier.freshdesk.com/support/home"><I18n code="skillboard.knowledge.base"/></ExtLink></li>
                    </FooterLinkCol>
                    <FooterLinkCol header="3D SKill Board">
                        <FooterLinkEntry route="/skillboard/premium/buy" code="Buy Premium"/>
                        <FooterLinkEntry route="/skillboard/tab/faq" code="skillboard.tab.faq"/>
                        <FooterLinkEntry route="/skillboard/tab/scores" code="skillboard.tab.scores"/>
                        <FooterLinkEntry route="/skillboard/tab/releasenotes" code="skillboard.tab.releasenotes"/>
                        <li><ExtLink href="https://3dturnier.freshdesk.com/support/home"><I18n code="skillboard.knowledge.base"/></ExtLink></li>
                    </FooterLinkCol>

                    <FooterLinkCol header="Turniere">
                        <FooterLinkEntry route="/tournament/search/filter/current" code="tournament.filter.current"/>
                        <FooterLinkEntry route="/tournament/search/filter/past" code="tournament.filter.results"/>
                        <FooterLinkEntry route="/tournament/rules" code="Regeln & Altersklassen"/>
                    </FooterLinkCol>
                    <FooterLinkCol header="Cup & Trophy">
                        <FooterLinkEntry route="/cup/HST" code="Hotspot Trophy"/>
                        <FooterLinkEntry route="/cup/NC" code="Nordcup"/>
                        <FooterLinkEntry route="/cup/AAA" code="3D Archery Association"/>
                        <FooterLinkEntry route="/cup/AAC" code="Austrian Archery Cup"/>
                        <FooterLinkEntry route="/cup/yoa" code="Youth of Archery"/>
                        <FooterLinkEntry route="/cup/WMC" code="Wald-Mostviertel Cup"/>
                    </FooterLinkCol>
                    <FooterLinkCol header="Mein Profil">
                        <FooterLinkEntry route="/account" code="header.nav.account"/>
                        <FooterLinkEntry route={"/profile/tournaments/" + year} code="header.nav.tournaments"/>
                        <FooterLinkEntry route={"/profile/events/" + year} code="header.nav.events"/>
                        <FooterLinkEntry route={"/profile/statistics/" + year} code="statistics.header"/>
                    </FooterLinkCol>

                </Row>
                <Row>
                    <Col lg={12}>
                        <p>
                            <span>
                                Copyright © 3DTurnier 2020
                                { bundle ? " | v" + bundle.version : ""}&nbsp;
                            </span>
                            <span>
                                <I18n code="footer.language"/>:&nbsp;
                                <a href={"/de" + getLangUrl()}>
                                    <Image rounded src="/img/flag/de.png"
                                           width="24" height="24"/>
                                </a>&nbsp;
                                <a href={"/en" + getLangUrl()}>
                                    <Image rounded src="/img/flag/en.png"
                                           width="24" height="24"/>
                                </a>
                            </span>
                        </p>
                    </Col>
                </Row>
            </footer>
        </div>
    );
};

const FooterLinkCol = ({header, children}) => {
    return  <Col lg={2} md={4} xs={4}>
        <h4>{header}</h4>
        <ListUnstyled>
            {children}
        </ListUnstyled>
    </Col>
}
const FooterLinkEntry = ({route, code}) => {
    return <li><Link to={linkUtil.getLink(route)}><I18n code={code}/></Link></li>
}
module.exports = MenuFooter;
