const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const { Table, Label} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    DecisionButton = require("components/form/DecisionButton"),
    Utility = require("util/Utility"),
    AboFeatureLabel = require("components/widgets/AboFeatureLabel"),
    TransactionDetailLink = require("components/links/TransactionDetailLink"),
    Loading = require("components/widgets/Loading");

const {BaseContext} = require("context/BaseContext");

const userInfoActions = require("actions/UserInfoActions");

const UserAboInfo = ({user, userAbos, userFeatures, userAboLoading}) => {
    if (userAboLoading) {
        return (<Loading/>)
    } else if (userAbos && userAbos.length > 0){
        const ranking = userFeatures.isRankingFeatureActive();
        const gps = userFeatures.isGPSFeatureActive();
        const stat = userFeatures.isStatisticFeatureActive();
        const training = userFeatures.isTrainingFeatureActive();
        const target = userFeatures.isTargetFeatureActive();
        return (<React.Fragment>
                <h3>
                    <Label bsStyle={stat ? "success" : "default"}><AboFeatureLabel type="S"/></Label>&nbsp;
                    <Label bsStyle={gps ? "success" : "default"}><AboFeatureLabel type="G"/></Label>&nbsp;
                    <Label bsStyle={ranking ? "success" : "default"}><AboFeatureLabel type="R"/></Label>&nbsp;
                    <Label bsStyle={target ? "success" : "default"}><AboFeatureLabel type="Z"/></Label>&nbsp;
                    <Label bsStyle={training ? "success" : "default"}><AboFeatureLabel type="T"/></Label>&nbsp;
                </h3>
                <br/>
                <Table>
                    <thead>
                    <tr>
                        <th><I18n code="management.feature.startdate"/></th>
                        <th><I18n code="management.feature.enddate"/></th>
                        <th><I18n code="tournament.table.column.type"/></th>
                        <th><I18n code="tournament.table.column.status"/></th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        userAbos.map(userAbo => {
                            return <AboElementInfo key={userAbo.id} user={user} userAbo={userAbo}/>
                        })
                    }
                    </tbody>
                </Table>
            </React.Fragment>
        )
    } else {
        return <Label bsStyle="warning">{messages.get("profile.abo.null")} <a href={linkUtil.getLink("skillboard/premium")}> {messages.get("header.nav.3dskillboard.premium")}.</a></Label>
    }
};
UserAboInfo.propTypes = {
    user:PropTypes.object.isRequired,
    userAbos: PropTypes.array,
    userFeatures:PropTypes.object.isRequired,
    userAboLoading:PropTypes.bool.isRequired
};
const AboElementInfo=({user, userAbo})=> {
    return <tr>
        <td>{userAbo.getAboStartDateReadable()}</td>
        <td>{userAbo.getAboEndDateReadable()}</td>
        <td><strong>{userAbo.hasTraining() ? "Premium + Training - " : "Premium - "}</strong>
                {
                    userAbo.isTypePaid() && userAbo.getTransaction() ?
                        <TransactionDetailLink transactionID={userAbo.getTransaction().id} status={"ACK"} label={messages.get("userAbo.type.P")}/> :
                        messages.get("userAbo.type." + userAbo.getType())
                }
        </td>
        <td>{
                userAbo.isInactive() ?
                    <Label>{messages.get("status.I") + " " + Utility.getReadableDate(userAbo.getPauseStartDate()) }</Label>
                    :
                    userAbo.isAboEndDateValid() ?
                        <Label bsStyle="success"><I18n code="status.A"/></Label> :
                        <Label><I18n code="status.EXP"/></Label>
            }
        </td>
        <td><AboActionHandling userAbo={userAbo} user={user}/></td>
    </tr>
};
AboElementInfo.propTypes = {
    user:PropTypes.object.isRequired,
    userAbo:PropTypes.object.isRequired
};
const AboActionHandling = ({user, userAbo}) => {
    const { updateUserInfoState } = React.useContext(BaseContext);
    function onPauseAbo() {
        userInfoActions.updateUserAboPause(userAbo, "I", sUserAbo => {
            // updated userAbo
            updateUserInfoState({sUserAbo: sUserAbo})
        });
    }
    function onResumeAbo() {
        userInfoActions.updateUserAboPause(userAbo, "A", sUserAbo => {
            // updated userAbo
            updateUserInfoState({sUserAbo: sUserAbo})
        });
    }
    return <React.Fragment>
        {
            userAbo.isInactive() ?
                <DecisionButton
                    user={user} bsStyle="primary"
                    buttonIcon={null}
                    buttonText={messages.get("profile.abo.button.reactivate")}
                    titleCode={"profile.abo.button.reactivate"}
                    messageCode={"profile.abo.button.message.reactivate"}
                    okButtonCode={"profile.abo.button.reactivate"}
                    cancelButtonCode={"modal.button.cancel"}
                    objectName={Utility.getReadableDate(userAbo.getAboEndDateWithPause(new Date()))}
                    handleOK={onResumeAbo}/> : null
        }
        {
            userAbo.isTypePaid() && userAbo.isAboEndDateValid() && userAbo.getPauseStartDate() == null ?
                <DecisionButton bsStyle="primary"
                                user={user}
                                buttonIcon={null}
                                buttonText={messages.get("profile.abo.button.deactivate")}
                                titleCode={"profile.abo.button.deactivate"}
                                messageCode={"profile.abo.button.message.deactivate"}
                                okButtonCode={"profile.abo.button.deactivate"}
                                cancelButtonCode={"modal.button.cancel"}
                                objectName={""}
                                handleOK={onPauseAbo}/> : null
        }
    </React.Fragment>
};
AboActionHandling.propTypes = {
    user:PropTypes.object.isRequired,
    userAbo:PropTypes.object.isRequired
};
module.exports = UserAboInfo;
