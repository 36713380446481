const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Label, Table,Popover, OverlayTrigger, ButtonGroup, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    ListUnstyled = require("components/widgets/ListUnstyled"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ClubMonthCalendar = require("components/club/ClubMonthCalendar"),
    DecisionModal= require("components/modals/DecisionModal"),
    MailLogModal= require("components/modals/MailLogModal"),
    ClubMessageModal = require("components/clubmodal/ClubMessageModal"),
    ClubEventCreateModal = require("components/clubmodal/ClubEventCreateModal");

const clubStore = require("stores/ClubStore"),
    clubActions = require("actions/ClubActions");

class ClubCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.handleClubChange = this.handleClubChange.bind(this);

        this.state = {
            clubStore: clubStore.getState()
        }
    }
    componentDidMount() {
        clubStore.listen(this.handleClubChange);
    }
    componentWillUnmount() {
        clubStore.unlisten(this.handleClubChange);
    }
    handleClubChange(state) {
        this.setState({clubStore: state});
    }
    render() {
        if (this.state.clubStore.clubDetail == null) {
            return null;
        }
        return <ClubCalendarDetail club={this.state.clubStore.clubDetail}
                                   clubUsers={this.state.clubStore.allClubUsers}
                                   ownClubUser={this.state.clubStore.ownClubUser}
                                   eventLoading={this.state.clubStore.eventLoading}
                                   clubEvents={this.state.clubStore.clubEvents}
                                   searchParams={this.state.clubStore.searchParams}/>
    }
}
ClubCalendar.propTypes = {
    user:PropTypes.object
};
class ClubCalendarDetail extends React.Component {
    constructor(props) {
        super(props);
        this.refCreateEventModal = React.createRef();
        this.refMessageModal = React.createRef();
        this.refDelClubEventModal = React.createRef();

        this.handleCalendarType = this.handleCalendarType.bind(this);
        this.openEventCreateModal = this.openEventCreateModal.bind(this);
        this.openEventDateCreateModal = this.openEventDateCreateModal.bind(this);
        this.openMessageModal = this.openMessageModal.bind(this);
        this.editEvent = this.editEvent.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.pClubEvent = null;
        this.state = {
            calType: "calendar"
        }
    }
    componentDidMount() {
        let today = new Date();
        let searchParams = {
            year: today.getFullYear(),
            text: ""
        };
        clubActions.loadClubEvents.defer(this.props.club, this.props.ownClubUser, searchParams);
    }
    handleCalendarType(type) {
        this.setState({calType: type})
    }
    openEventCreateModal() {
        this.refCreateEventModal.current.open();
    }
    openEventDateCreateModal(weekday) {
        this.refCreateEventModal.current.newEvent(weekday);
    }
    openMessageModal() {
        this.refMessageModal.current.open();
    }
    editEvent(clubEvent) {
        this.refCreateEventModal.current.editEvent(clubEvent);
    }
    openDeleteModal(clubEvent) {
        this.refDelClubEventModal.current.openNamedHeader(clubEvent.getName());
        this.pClubEvent = clubEvent;
    }
    handleDelete() {
        if (this.pClubEvent != null) {
            clubActions.deleteEvent(this.pClubEvent);
            this.pClubEvent = null;
        }
    }
    render() {
        const {club, clubEvents, ownClubUser, clubUsers, eventLoading, searchParams} = this.props;
        const {calType} = this.state;
        const hasMessaging = ownClubUser != null && ownClubUser.hasMessaging();
        return (<React.Fragment>
            <Row>
                <Col lg={12}><h3><I18n code="club.detail.calendar"/></h3></Col>
                <Col sm={6} smPush={6}>
                    <br/>
                {
                    hasMessaging && clubUsers ?
                            <ButtonGroup>
                                <Button bsStyle="primary" onClick={this.openEventCreateModal}>
                                    <FontAwesome icon="calendar-plus"/><I18n code="club.button.newEntry"/>
                                </Button>
                                <Button bsStyle="primary" onClick={this.openMessageModal}>
                                    <FontAwesome icon="envelope"/><I18n code="club.button.newMessage"/>
                                </Button>
                                <MailLogModal buttonStyle="default" sender={club.getEmail()} />
                                <ClubEventCreateModal ref={this.refCreateEventModal} club={club}  clubUsers={clubUsers} ownClubUser={ownClubUser}/>
                                <ClubMessageModal ref={this.refMessageModal} club={club} clubUsers={clubUsers}/>
                            </ButtonGroup> : null
                }
                </Col>
                <Col sm={6} smPull={6}>
                    <br/>
                    <ButtonGroup>
                        <Button onClick={()=> {this.handleCalendarType("calendar")}}
                                className={calType === "calendar" ? "active" : ""}><FontAwesome icon="calendar-alt"/>
                        </Button>
                        <Button onClick={()=> {this.handleCalendarType("list")}}
                                className={calType === "list" ? "active" : ""}><FontAwesome icon="list"/>
                        </Button>
                    </ButtonGroup>
                </Col>
                <DecisionModal ref={this.refDelClubEventModal}
                               titleCode={"club.event.modal.delete.header"}
                               messageCode={"club.event.modal.delete.ask"}
                               okButtonCode={"modal.button.delete"}
                               cancelButtonCode={"modal.button.cancel"}
                               handleOK={this.handleDelete}/>
            </Row>
            {
                calType == "calendar" ?
                    <Row>
                        <Col xs={12}>
                            <ClubMonthCalendar club={club}
                                               clubUsers={clubUsers}
                                               ownClubUser={ownClubUser}
                                               eventLoading={eventLoading}
                                               clubEvents={clubEvents}
                                               handleNewEvent={this.openEventDateCreateModal}
                                               handleEditEvent={this.editEvent}
                                               handleDeleteEvent={this.openDeleteModal}/>
                        </Col>
                    </Row> :
                    <Row>
                        <Col lg={12}>
                            <br/>
                            {
                                eventLoading ? <Loading/> :
                                    <Table responsive striped hover>
                                        <thead>
                                        <tr>
                                            <th><I18n code="tournament.table.column.date"/></th>
                                            <th><I18n code="tournament.table.column.name"/>,&nbsp;<I18n code="club.event.create.place.title"/></th>
                                            <th><I18n code="club.event.column.answer"/></th>
                                            <th><I18n code="parcours.details.button.actions"/></th>
                                        </tr>
                                        </thead>
                                        <ClubCalenderTableBody hasMessaging={hasMessaging}
                                                               club={club}
                                                               clubEvents={clubEvents}
                                                               editEvent={this.editEvent}
                                                               deleteEvent={this.openDeleteModal}/>
                                    </Table>
                            }
                        </Col>
                    </Row>
            }
        </React.Fragment>);
    }
}
ClubCalendarDetail.propTypes = {
    club:PropTypes.object.isRequired,
    ownClubUser:PropTypes.object,
    clubUsers: PropTypes.array,
    searchParams: PropTypes.object,
    clubEvents: PropTypes.array,
    eventLoading: PropTypes.bool.isRequired
};
const ClubCalenderTableBody = ({club, clubEvents, deleteEvent, editEvent, hasMessaging}) => {
    if (clubEvents.length == 0) {
        return (
            <tbody>
            <tr>
                <td></td>
                <td><I18n code="list.entry.empty"/></td>
                <td></td>
                <td></td>
            </tr>
            </tbody>
        );
    } else {
        return (
            <tbody>
            {
                clubEvents.map(clubEvent => {
                    return (<ClubCalendarTableEntry key={clubEvent.id} clubEvent={clubEvent} club={club}
                                                    hasMessaging={hasMessaging}
                                                    editEvent={editEvent}
                                                    deleteEvent={deleteEvent}/>)
                })
            }
            </tbody>
        );
    }
};
ClubCalenderTableBody.propTypes = {
    club:PropTypes.object.isRequired,
    deleteEvent:PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
    clubEvents: PropTypes.array,
    hasMessaging: PropTypes.bool.isRequired
};
const ClubCalendarTableEntry = ({club, clubEvent, deleteEvent, editEvent, hasMessaging}) => {
    const handleEditEvent = () => {
        editEvent(clubEvent);
    };
    const handleDeleteEvent = () => {
        deleteEvent(clubEvent);
    };
    return (<tr>
        <td>{clubEvent.getEventStartDate()}<br/>{clubEvent.getEventEndDate()}</td>
        <td><a href={linkUtil.getLink("/club/event/" + clubEvent.id)} target="_blank">{clubEvent.getName()}</a>
            &nbsp;{clubEvent.isDraft()? <Label>{messages.get("club.event.create.draft")}</Label> : null}
            &nbsp;{clubEvent.isPublic()? <Label>{messages.get("event.vis.public")}</Label> : null}<br/>
            {clubEvent.getPlace()}
        </td>
        <td>{clubEvent.getOptions() != null ?
            clubEvent.getOptions().map(evOption => {
                return <ClubCalendarTableEntryOptionList  key={"EV_" + evOption} clubEvent={clubEvent} evOption={evOption}/>
            }) : null}
        </td>
        <td>
            {
                hasMessaging ?
                    <ButtonGroup>
                        <Button onClick={handleEditEvent}><FontAwesome icon="pencil-alt"/></Button>
                        <Button onClick={handleDeleteEvent}><FontAwesome icon="trash"/></Button>
                    </ButtonGroup> : null
            }

        </td>
    </tr>)
};
ClubCalendarTableEntry.propTypes = {
    club:PropTypes.object.isRequired,
    clubEvent:PropTypes.object.isRequired,
    deleteEvent:PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
    hasMessaging: PropTypes.bool.isRequired
};
const ClubCalendarTableEntryOptionList = ({clubEvent, evOption}) => {
    let optResList = clubEvent.getOptionResultList(evOption);
    let popOver = (
        <Popover id="popover-positioned-bottom" title={evOption}>
            <ListUnstyled>
                {optResList.map(optionRes => {
                    return <li key={optionRes["name"]}>{optionRes["name"]}</li>
                })}
            </ListUnstyled>
        </Popover>);
    return (
        <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popOver}>
            <Button>{evOption + ": " + optResList.length}</Button>
        </OverlayTrigger>)
};
ClubCalendarTableEntryOptionList.propTypes = {
    evOption:PropTypes.string.isRequired,
    clubEvent:PropTypes.object.isRequired
};
module.exports = ClubCalendar;
