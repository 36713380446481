const alt = require("AltInit"),
    userActions = require("actions/UserActions");
const {User} = require("parse/_Domain");

class UserStore {

    constructor() {
        this.displayName = "UserStore";
        this.isLoginModalVisible = false;
        this.loginKind = "login";
        this.messageCode = null;
        this.bindListeners({
            showLoginModal: userActions.showLoginModal,
            showLoginCreateModal: userActions.showLoginCreateModal,
            hideLoginModal: userActions.hideLoginModal,
            showLoginError: userActions.showLoginError,
            removeLoginError: userActions.removeLoginError,
        });
    }


    showLoginModal() {
        this.loginKind = "login";
        this.isLoginModalVisible = true;
    }

    showLoginCreateModal() {
        this.loginKind = "register";
        this.isLoginModalVisible = true;
    };

    hideLoginModal() {
        this.messageCode = null;
        this.isLoginModalVisible = false;
    }

    showLoginError(messageCode) {
        this.messageCode = messageCode;
    }

    removeLoginError() {
        this.messageCode = null;
    }
}
module.exports = alt.createStore(UserStore);
