const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Panel, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    messages = require("i18n/messages");

const ChangePasswordModal = require("components/modals/ChangePasswordModal"),
    LoginButton = require("components/widgets/LoginButton");

const userActions = require("actions/UserActions"),
    clubActions = require("actions/ClubActions"),
    notificationActions = require("components/notification/NotificationActions");

class UserTokenPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateUserToken = this.updateUserToken.bind(this);
        this.handleTokenUsed = this.handleTokenUsed.bind(this);
        this.state = {
            action: "loading",
            userToken: null,
            tokenUsed: false,
            user: null
        };
    }
    componentDidMount() {
        document.title = "3D Turnier";
        if (this.props.tokenID) {
            userActions.loadUserToken(this.props.tokenID, userToken => {
                if (userToken.isDeleted() || userToken.isTokenTimeout()) {
                    notificationActions.warning("modal.login.error.userToken.used");
                    this.updateUserToken(userToken, null);
                } else {
                    notificationActions.reset();
                    this.updateUserToken(userToken, userToken.getUserID())
                }
            })
        }
    }
    updateUserToken(userToken, user) {
        this.setState({
            action: userToken.getAction(),
            userToken: userToken,
            user: user
        })
    }
    handleTokenUsed() {
        this.state.userToken.setStatus("D");
        userActions.saveObject(this.state.userToken);
        this.setState({
            tokenUsed: true,
            user: null
        })
    }
    render() {
        const {userToken, user, tokenUsed, action} = this.state;
        return (
            <Grid>
                <Row>
                    <br/><br/>
                    <Col xs={12} md={6} mdOffset={3}>
                        <Panel>
                            <Panel.Heading>
                                <I18n code={"token.panel.header." + action}/>
                            </Panel.Heading>
                            <Panel.Body>
                                {
                                    userToken == null ? <Loading /> : <TokenDetail userToken={userToken} user={user} tokenUsed={tokenUsed}
                                                                                   handleTokenUsed={this.handleTokenUsed}/>
                                }
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>
        )
    }
}
UserTokenPage.propTypes = {
    tokenID: PropTypes.string.isRequired
};
const TokenDetail = ({userToken, user, tokenUsed, handleTokenUsed}) => {
    function authenticateUser() {
        // set emailVerifiedFlag
        userActions.setVerifiedEmailFlag(user, callback => {
            // TODO maybe refresh user
            console.log(callback);
            handleTokenUsed();
        });
    }
    function authenticateClub() {
        // set emailVerifiedFlag
        clubActions.setVerifiedEmailFlag(userToken.getClubID(), callback => {
            console.log(callback);
            handleTokenUsed();
        });
    }


    if (userToken.isActionPassword()) {
        return <ActionPassword userToken={userToken} user={user} tokenUsed={tokenUsed} handleTokenUsed={handleTokenUsed}/>
    } else if (userToken.isActionUserEMail()) {
        return <ActionEmailAuthent userToken={userToken} user={user} tokenUsed={tokenUsed} handleAuthenticate={authenticateUser}/>
    } else if (userToken.isActionClubEmail()) {
        return <ActionEmailAuthent userToken={userToken} user={user} tokenUsed={tokenUsed} handleAuthenticate={authenticateClub}/>
    }
    return  null;
};
TokenDetail.propTypes = {
    userToken: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    tokenUsed: PropTypes.bool.isRequired,
    handleTokenUsed: PropTypes.func.isRequired
};
const ActionPassword = ({user, tokenUsed, handleTokenUsed}) => {
    return <React.Fragment>
        {
            user != null ? <ChangePasswordModal user={user} successPasswordChanged={handleTokenUsed} open={true} /> : null
        }
        {
            tokenUsed ? <React.Fragment>
                    <Alert bsStyle="success"> <I18n code="modal.password.reset.success"/></Alert>
                    <LoginButton/>
                </React.Fragment>
                : null
        }
    </React.Fragment>;
};
ActionPassword.propTypes = {
    tokenUsed: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    handleTokenUsed: PropTypes.func.isRequired
};
const ActionEmailAuthent = ({userToken, user, tokenUsed, handleAuthenticate}) => {
    if (tokenUsed) {
        return <Alert bsStyle="success"> <I18n code="modal.password.reset.mailACK"/></Alert>
    } else if (user != null) {
        return <SubmitButton ref={this.refSubmitButton} autoClickMS={3000}
                             onClick={handleAuthenticate} bsStyle="primary"
                             icon={<FontAwesome icon="check"/>}
                             text={messages.get("token.button.UserMailAuthent") + userToken.getTokenMail()} />;
    } else {
        return null;
    }
};
ActionEmailAuthent.propTypes = {
    userToken: PropTypes.object.isRequired,
    tokenUsed: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    handleAuthenticate: PropTypes.func.isRequired
};
module.exports = UserTokenPage;
