const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col,Grid, Table, Alert, ButtonGroup, Button, Label, FormControl, PageHeader, MenuItem} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    SortTableText = require("components/form/SortTableText"),
    ClubMailModal = require("components/clubmodal/ClubMailModal"),
    ClubUserAddressModal= require("components/clubmodal/ClubUserAddressModal"),
    ClubUserModal= require("components/clubmodal/ClubUserModal"),
    ClubArticlePayModal = require("components/clubmodal/ClubArticlePayModal"),
    ClubLoader = require("components/club/ClubLoader"),
    ClubUserPaymentInfo = require("components/club/ClubUserPaymentInfo"),
    TypeListDefinitionModal = require("components/modals/TypeListDefinitionModal"),
    ClubArticlesButton = require("components/links/ClubArticlesButton"),
    ClubGroupRoleModal = require("components/clubmodal/ClubGroupRoleModal"),
    messages = require("i18n/messages");

const clubActions = require("actions/ClubActions"),
    clubUserActions = require("actions/ClubUserActions");
const ClubUser = require("parse/ClubUser");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const functionList = [ClubUser.prototype.role.owner, ClubUser.prototype.role.member, ClubUser.prototype.role.exMember,
    ClubUser.prototype.role.address, ClubUser.prototype.role.userHandling,ClubUser.prototype.role.messaging,ClubUser.prototype.role.invoice];

const currentYear = new Date().getFullYear();

class ClubUsersPage extends React.Component {
    render() {
        return <ClubLoader clubID={this.props.clubID} clubUserRole={ClubUser.prototype.role.userHandling} loadClubUsers={true} loginMandatory={true}>
            <ClubDetails />
        </ClubLoader>
    }
}
ClubUsersPage.propTypes = {
    clubID: PropTypes.string.isRequired
};
const ClubDetails = ({club, user, ownClubUser, clubUsers, searchParams}) => {
    document.title = club.getName() + " - " + messages.get("cl_role_user");
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                            <Breadcrumb code="cl_role_user" active/>
                        </Breadcrumbs>
                </Col>
            </Row>
            <ClubUserTable user={user}  club={club}
                           ownClubUser={ownClubUser}
                           clubUsers={clubUsers}
                           searchParams={searchParams}/>
        </Grid>)
};
ClubDetails.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    clubUsers:PropTypes.array.isRequired,
    searchParams: PropTypes.object.isRequired,
    ownClubUser:PropTypes.object
};
var timeoutLock = {
    num: 0,
    getNext() {
        this.num++;
        return this.num;
    }
};
 class ClubUserTable extends React.Component {
    constructor(props) {
        super(props);
        this.refGroupRoleModal = React.createRef();
        this.refClubUserModal = React.createRef();
        this.refClubPayModal = React.createRef();
        this.refClubMailModal = React.createRef();
        this.refClubUserAddrModal = React.createRef();
        this.refGroupDefModal = React.createRef();

        this.handleModal = this.handleModal.bind(this);
        this.exportClubUsers = this.exportClubUsers.bind(this);
        this.openInvitation = this.openInvitation.bind(this);
        this.openUserCreateModal = this.openUserCreateModal.bind(this);
        this.openGroupDefinitions = this.openGroupDefinitions.bind(this);
        this.addTypeFunction = this.addTypeFunction.bind(this);
        this.removeTypeFunction = this.removeTypeFunction.bind(this);
        this.handleSendInvitationMail = this.handleSendInvitationMail.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleMemberChange = this.handleMemberChange.bind(this);
        this.handleFunctionChange = this.handleFunctionChange.bind(this);
        this.handleTableSort = this.handleTableSort.bind(this);

        this.state = {}
    }
    handleModal(modalType, clubUser) {
        if (modalType === "grp") {
            this.refGroupRoleModal.current.open(clubUser);
        } else if (modalType === "cu") {
            this.refClubUserModal.current.editClubUser(clubUser);
        } else if (modalType === "pay") {
            this.refClubPayModal.current.editClubUser(clubUser);
        }
    }
    exportClubUsers() {
        clubUserActions.exportAllClubUsers(this.props.clubUsers);
    }
    openInvitation() {
        this.refClubMailModal.current.openInvitationMode();
    }
    openUserCreateModal() {
        let nextCUNumber = 0;
        // find highest userNumber
        for (let i = this.props.clubUsers.length - 1; i >= 0; i--) {
            let cuNumber = this.props.clubUsers[i].getUserNumber();
            if (cuNumber && parseInt(cuNumber) > nextCUNumber) {
                nextCUNumber = parseInt(cuNumber);
            }
        }
        nextCUNumber++;
        this.refClubUserAddrModal.current.openCreate(nextCUNumber.toString());
    }
    openGroupDefinitions() {
        this.refGroupDefModal.current.open();
    }
    addTypeFunction(type) {
        clubActions.updateClubGroups(this.props.club, type, null);
    }
    removeTypeFunction(type) {
        clubActions.updateClubGroups(this.props.club, null, type);
        clubActions.removeClubGroupFromClubUsers(this.props.clubUsers, type)
    }
    handleSendInvitationMail(mails) {
        if (mails != null && mails.length > 0) {
            clubActions.sendInvitationMails(this.props.club, mails);
        }
    }
    handleTextChange(e) {
        var search = e.target.value;
        var num = timeoutLock.getNext();
        setTimeout(() => {
            if (num == timeoutLock.num) {
                let sParams = this.props.searchParams;
                sParams.searchText = search
                clubActions.searchClubUser(sParams);
            }
        }, 300);
    }
    handleGroupChange(e) {
        let group = e.target.value;
        if (group === "All") {
            group = null;
        }
        let sParams = this.props.searchParams;
        sParams.searchGroup = group;
        clubActions.searchClubUser(sParams);
    }
     handleMemberChange(e) {
         let member = e.target.value;
         if (member === "All") {
             member = null;
         }
         let sParams = this.props.searchParams;
         // reset all search params
         sParams.searchMember = null;
         sParams.searchPayedYear = null;
         sParams.searchNotPayed = null;
         // set new search params
         if (member != null && member.indexOf(currentYear) > -1) {
             if (member.indexOf("!") === 0) {
                 sParams.searchNotPayed = member.substr(1, member.length - 1)
             } else {
                 sParams.searchPayedYear = member

             }
         } else {
             sParams.searchMember = member;
         }
         clubActions.searchClubUser(sParams);
     }
    handleFunctionChange(e) {
        let fct = e.target.value;
        if (fct === "All") {
            fct = null;
        }
        let sParams = this.props.searchParams;
        sParams.searchFunct = fct;
        clubActions.searchClubUser(sParams);
    }
    handleTableSort(sortKey, direction) {
        console.log("Clicked sort by " + sortKey + " dir: " + direction)
        let sParams = this.props.searchParams;
        sParams.sortParams.sortKey = sortKey
        sParams.sortParams.sortDir = direction
        clubActions.searchClubUser(sParams);
    }
    render() {
        const {club, clubUsers, ownClubUser, searchParams} = this.props;
        const hasClubManagement = club.hasClubManagement();
        let alert = null;
        if (club.hasManagementID()) {
            if (!hasClubManagement) {
                alert = <Col xs={12}><Alert bsStyle="warning">{messages.get("club.business.noClubFeature.desc")}</Alert></Col>;
            }
        } else {
            alert = <Col xs={12}><Alert bsStyle="warning">{messages.get("club.business.link2BusinessAccount.desc")}</Alert></Col>;
        }
        const userLimitReached = !hasClubManagement && this.props.clubUsers.length > 10;
        const groups = club.getFilterGroups();
        const articles = club.getFilteredArticles();
        return (<Row>
            <Col  xs={12}>
                <PageHeader><I18n code="club.member.header"/> <Label>{clubUsers.length}</Label></PageHeader>
            </Col>
            {alert}
            <Col xs={12} sm={6}>
                <Button disabled={userLimitReached} bsStyle="primary" onClick={this.openInvitation}><FontAwesome icon="envelope"/>{messages.get("club.member.button.invite")}</Button>&nbsp;
                <Button disabled={userLimitReached} bsStyle="primary" onClick={this.openUserCreateModal}><FontAwesome icon="plus"/>{messages.get("club.member.button.create")}</Button>&nbsp;
                <ErrorBoundary><ClubUserAddressModal ref={this.refClubUserAddrModal} club={club}/></ErrorBoundary>
                <ErrorBoundary><ClubUserModal ref={this.refClubUserModal}  club={club} /></ErrorBoundary>
                <ErrorBoundary><ClubArticlePayModal ref={this.refClubPayModal}  club={club} /></ErrorBoundary>
                <ErrorBoundary><ClubMailModal ref={this.refClubMailModal} handleAction={this.handleSendInvitationMail}/></ErrorBoundary>
                <ErrorBoundary><ClubGroupRoleModal ref={this.refGroupRoleModal} club={club} ownClubUser={ownClubUser}/></ErrorBoundary>
            </Col>
            <Col xs={12} sm={6}>
                <Button bsStyle="primary" disabled={!ownClubUser.isActiveMember()} onClick={this.openGroupDefinitions}>
                    <FontAwesome icon="users"/><I18n code="club.group.modal.title"/>
                </Button>
                <ClubArticlesButton disabled={userLimitReached} bsStyle={"primary"} club={club} group={"member"} />
                &nbsp;<Button bsStyle="primary" onClick={this.exportClubUsers}><FontAwesome icon="download"/><I18n code="club.button.exportCU"/></Button>
                <TypeListDefinitionModal ref={this.refGroupDefModal}
                                         header={messages.get("club.group.modal.title")}
                                         inputText={messages.get("club.group.modal.input")}
                                         desc={messages.get("club.group.modal.desc")}
                                         list={club.getGroups()}
                                         addTypeFunction={this.addTypeFunction}
                                         removeTypeFunction={this.removeTypeFunction}/>
            </Col>
            <Col xs={12}>
                <br/><br/>
                <Table responsive striped hover>
                    <thead>
                    <tr>
                        <th className="col-xs-3">
                            <SortTableText sortKey="nr" sortParams={searchParams.sortParams} handleSort={this.handleTableSort}>Nr</SortTableText>&nbsp;&nbsp;
                            <SortTableText sortKey="fullName" sortParams={searchParams.sortParams} handleSort={this.handleTableSort}><I18n code="tournament.table.column.name"/></SortTableText>
                        </th>
                        <th className="col-xs-3"><I18n code="tournament.table.column.address"/></th>
                        <th className="col-xs-2"><I18n code="club.group.modal.functions"/></th>
                        <th className="col-xs-2"><I18n code="club.group.modal.groups"/></th>
                        <th className="col-xs-2"><I18n code="club.member.article.header"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td  colSpan="2">
                            <FormControl type="text"
                                         placeholder={messages.get("club.member.button.search")}
                                         onChange={this.handleTextChange}/></td>
                        <td>
                            <FormControl componentClass="select" placeholder="select" onChange={this.handleFunctionChange}>
                                <option value="All">Alle</option>
                                {functionList.map(function(fct) {
                                    return <option key={fct} value={fct}>{messages.get(fct)}</option>
                                })}

                            </FormControl>

                        </td>
                        <td>
                            { groups != null ?
                                <FormControl componentClass="select" placeholder="select" onChange={this.handleGroupChange}>
                                    <option value="All">Alle</option>
                                    {groups.map(function(group) {
                                        return <option key={group} value={group}>{group}</option>
                                    })}

                                </FormControl> : null
                            }
                        </td>
                        <td>
                            { articles != null ?
                                <FormControl componentClass="select" placeholder="select" onChange={this.handleMemberChange}>
                                    <option value="All">Alle</option>
                                    {articles.map(function(article) {
                                        return <option key={article} value={article}>{article}</option>
                                    })}
                                    {/*<option style="font-size: 1pt; background-color: #000000;" disabled>&nbsp;</option>*/}
                                    <option key={currentYear} value={currentYear}>{currentYear}&nbsp;{messages.get("tournament.attendee.paid")}</option>
                                    <option key={currentYear} value={"!" + currentYear}>{currentYear}&nbsp;{messages.get("tournament.attendee.notpaid")}</option>
                                </FormControl> : null
                            }
                        </td>
                    </tr>
                    {
                        clubUsers.map(clubUser => {
                            return <ErrorBoundary key={"CUPTE_" + clubUser.id}>
                                <ClubUserPendingTableEntry userLimitReached={userLimitReached}
                                                               clubUser={clubUser} club={club}
                                                               handleModal={this.handleModal}/>
                            </ErrorBoundary>
                        })
                    }
                    </tbody>
                </Table>
            </Col>
        </Row>);
    }
}
ClubUserTable.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    clubUsers:PropTypes.array.isRequired,
    searchParams: PropTypes.object.isRequired,
    ownClubUser:PropTypes.object
};
const ClubUserPendingTableEntry = ({club, clubUser, handleModal, userLimitReached}) => {
    function handleAcceptRequest() {
        clubActions.acceptClubUser(clubUser, club);
    }
    function handleDeclineRequest() {
        clubActions.declineClubUser(clubUser, club);
    }
    function handleGrouping() {
        handleModal("grp", clubUser);
    }
    function handleClubUser() {
        handleModal("cu", clubUser);
    }
    function handleClubPay() {
        handleModal("pay", clubUser);
    }

    const hasAccount = clubUser.getUserID() != null;
    let buttons = [];
    let lblStyle = "success";
    let cuRole = clubUser.getRole();
    if (clubUser.isReqStatusRequested()) {
        buttons.push(<Button disabled={userLimitReached} key="ack" onClick={handleAcceptRequest}><FontAwesome icon="check"/></Button>);
        buttons.push(<Button key="dec" onClick={handleDeclineRequest}><FontAwesome icon="times"/></Button>);
    } else if (clubUser.hasClubAddressOnly()) {
        buttons.push(<Button key="edtLnk" href={linkUtil.getLink("club/" + club.id + "/members/" + clubUser.id)} target="_blank"><FontAwesome icon="pencil-alt"/></Button>);
    } else {
        buttons.push(<Button key="edtLnk" href={linkUtil.getLink("club/" + club.id + "/members/" + clubUser.id)} target="_blank"><FontAwesome icon="pencil-alt"/></Button>);
        buttons.push(<Button key="cu" onClick={handleClubUser}><FontAwesome icon="university"/></Button>);
        buttons.push(<Button key="grp" onClick={handleGrouping}><FontAwesome icon="users"/></Button>);
        buttons.push(<Button key="pay" onClick={handleClubPay}><FontAwesome icon="euro"/></Button>);
    }
    if (!clubUser.isReqStatusAccepted()) {
        lblStyle ="warning";
    } else if (clubUser.isExMember()) {
        lblStyle = "default"
    }
    let mail = clubUser.getEmail();
    let udCheck = null;

    let address = null;
    let sex = "";
    if (clubUser.getUserDetailID() != null) {
        if (hasAccount && clubUser.getUserID().get("userDetailID")) {
            if (clubUser.getUserDetailID().id !=  clubUser.getUserID().get("userDetailID").id) {
                udCheck = <FontAwesome icon="bug"/>
            }
        }
        address = clubUser.getUserDetailID().getAddressID();
        if (clubUser.getUserDetailID().getSex() === 0) {
            sex = <FontAwesome icon="mars" size="lg"/>
        } else if (clubUser.getUserDetailID().getSex() === 1) {
            sex = <FontAwesome icon="venus" size="lg"/>
        }
    }
    let number = clubUser.getUserNumber();
    if (number == null) {
        number = "#"
    }
    if (clubUser.hasClubAddressOnly() && clubUser.getAddressTwoID()) {
        mail = clubUser.getAddressTwoID().getMail();
        address = clubUser.getAddressTwoID();
    }
    return (<tr>
        <td>
            <StrikeOutText strikeOut={clubUser.isExMember()}>{udCheck}{number + ") " + clubUser.getCompany() + " " + clubUser.getFullName()}</StrikeOutText>&nbsp;{sex}<br/>
            {hasAccount ? <FontAwesome icon="user-circle">{clubUser.getUserID().getUsername()}</FontAwesome>: null } {mail != null ? <a href={"mail:"+mail}>{mail}</a> : null}<br/>
            <ButtonGroup>{buttons}</ButtonGroup>
            </td>
        {
            address == null ?
                <td>{messages.get("club.detail.noUserDetail")}</td>
                :
                <td>
                    {address.getStreet()}<br/>
                    {address.getZipPlace()}<br/>
                    {address.getPhone() != null ? <a href={"tel:"+address.getPhone()}>{address.getPhone()}</a> : null}
                </td>
        }
        <td><Label bsStyle={lblStyle}>{messages.get(cuRole)}</Label><br/>
            {
                clubUser.getFunctions() != null ?
                    clubUser.getFunctions().map(fct => {
                        return <FontAwesome  key={"grp_" + fct} icon={clubUser.getFaIcon(fct)}/>
                    }) : null

        }
        </td>
        <td>
            {
                clubUser.getGroups() != null ?
                    clubUser.getGroups().map(group => {
                        return <span key={"grp_" + group}><Label>{group}</Label><br/></span>
                    }) : null
            }
        </td>
        <td>
            {
                clubUser.getArticleID() ?
                    <span><Label bsStyle="success">{clubUser.getArticleID().getName()}</Label><br/></span> : null
            }
            <ClubUserPaymentInfo club={club} clubUser={clubUser} currentYear={currentYear}/>
        </td>
    </tr>)
};
ClubUserPendingTableEntry.propTypes = {
    club:PropTypes.object.isRequired,
    clubUser:PropTypes.object.isRequired,
    handleModal:PropTypes.func.isRequired,
    userLimitReached:PropTypes.bool.isRequired
};
module.exports = ClubUsersPage;
