const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Panel, Button, Carousel, CarouselItem, Label} = require("react-bootstrap");
const {Link} = require ('react-router-dom');
const I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    Title = require("components/widgets/Title"),
    Loading = require("components/widgets/Loading"),
    ImageBanner = require("components/widgets/ImageBanner"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    SwitchButtonGroup = require("components/form/SwitchButtonGroup"),
    TournamentTeaserItem= require("components/tournament/TournamentTeaserItem"),
    TournamentLink = require("components/tournament/TournamentLink"),
    ParcoursOwnerHomeLink = require("components/parcours/ParcoursOwnerHomeLink"),
    ParcoursLink= require("components/parcours/ParcoursLink");

const {Tournament} = require("parse/_Domain");

const tournamentActions = require("actions/TournamentActions"),
    parcoursActions = require("actions/ParcoursActions"),
    newsActions = require("actions/NewsActions"),
    linkUtil = require("linkUtil");

const white = {color: "#E8E8E8"};
const height = (window.innerHeight - 50) * .75;
const tournamentFilterEntries = [
    {status:Tournament.prototype.status.published, bsStyle:"info", code:"tournament.status.register.open"},
    {status:Tournament.prototype.status.running, bsStyle:"success", code:"tournament.status.running"},
    {status:Tournament.prototype.status.closed, bsStyle:"primary", code:"tournament.status.closed"}
];

class FrontPage extends React.Component {
    componentDidMount() {
        document.title = "3D Turnier";
    }
    render() {
        return (<React.Fragment>
            {/*<TopCarousel/>*/}
            <Grid>
                <Title code="home.title"/>
                <TournamentTeaser/>
                <PointInterestBanner />
                <ParcoursTeaser/>
                <NewsTeaser/>
                <br/>
                <hr/>
                <ContentTeaser/>
            </Grid>
        </React.Fragment>)
    }
}
const TopCarousel = () => {
    return (
        <header style={{height: height +"px"}}>
            <Carousel>
                <CarouselItem>
                    <div className="fill" style={{backgroundImage: "url(/img/slide/app_screenshot.jpg)"}}/>
                    <Carousel.Caption>
                        <h2 style={white}><I18n code="home.carousel.skillboard2"/></h2>
                        <Link to={linkUtil.getLink("skillboard")}>
                            <Button bsStyle="success"><I18n code="home.panel.skillboard.download"/></Button>
                        </Link>
                        <br/><br/>
                    </Carousel.Caption>
                </CarouselItem>
                {/*<CarouselItem>*/}
                {/*    <div className="fill hidden-xs" style={{backgroundImage: "url(/img/slide/Premium.jpg)"}}/>*/}
                {/*    <div className="fill hidden-lg hidden-md hidden-sm" style={{backgroundImage: "url(/img/slide/training_games_xs_banner.jpg)"}}/>*/}
                {/*    <Carousel.Caption>*/}
                {/*        <h2 style={white}></h2>*/}
                {/*        <Link to={linkUtil.getLink("skillboard/premium")}>*/}
                {/*            <Button bsStyle="success"><I18n code="skillboard.premium.prices"/></Button>*/}
                {/*        </Link>*/}
                {/*        <br/><br/>*/}
                {/*    </Carousel.Caption>*/}
                {/*</CarouselItem>*/}
                <CarouselItem>
                    <div className="fill hidden-xs" style={{backgroundImage: "url(/img/slide/3d_banner_asen1.jpg)"}}/>
                    <div className="fill hidden-lg hidden-md hidden-sm" style={{backgroundImage: "url(/img/slide/3d_aktion_banner_xs.png)"}}/>
                    <Carousel.Caption>
                        <h2 style={white}></h2>
                            <Button href={linkUtil.getExternalLink("https://www.3dturnier.com/shop")}  target="_blank"
                                    bsStyle="success"><I18n code="home.carousel.shop.link"/></Button>
                        <br/><br/>
                    </Carousel.Caption>
                </CarouselItem>
            </Carousel>
        </header>)
};

class Teaser extends React.Component {
    constructor(props) {
        super(props);
        this.updateFilterStatus = this.updateFilterStatus.bind(this);
        this.showAll = this.showAll.bind(this);
        this.state = {
            list: null,
            visibleAmount: this.props.preVisibleAmount,
            filterStatus: this.props.preSelectedFilter
        };
    }
    componentDidMount() {
        this.props.queryTeaser(list => {
            this.setState({list: list});
        })
    }
    updateFilterStatus(status) {
        this.setState({filterStatus: status, visibleAmount: this.props.preVisibleAmount})
    }
    showAll() {
        this.setState({visibleAmount: 999})
    }
    filterList(list, filterStatus) {
        if (list != null) {
            if (filterStatus != null) {
                return list.filter(entry => {
                    if (filterStatus === entry.getStatus()) {
                        if (Tournament.prototype.status.published === filterStatus && entry instanceof Tournament) {
                            // check tournament for registration open
                            if (Tournament.prototype.registrationStatus.open === entry.getRegistrationStatus()) {
                                return true
                            }
                        } else {
                            return true;
                        }
                    }
                    return false;
                })
            }
            return list;
        }
        return null
    }
    render() {
        const {list, filterStatus, visibleAmount} = this.state;
        const {header, childFunction} = this.props;
        let filteredList = this.filterList(list, filterStatus)
        return <React.Fragment>
            <Row>{React.cloneElement(header, {updateFilter: this.updateFilterStatus}) }</Row>
            <Row>
            {
                list == null ? <Loading/> :
                    filteredList.map((entry, index) => {
                        if (index < visibleAmount) {
                            return childFunction(entry)
                        }
                    })
            }
            </Row>
            {
                filteredList != null && filteredList.length > visibleAmount ? <Row>
                    <Col xs={12}>
                        <Button onClick={this.showAll}><I18n code="tournament.button.show.more"/></Button>
                    </Col>
                </Row> : null
            }

        </React.Fragment>
    }
}
Teaser.propTypes = {
    queryTeaser: PropTypes.func.isRequired,
    childFunction: PropTypes.func.isRequired,
    header: PropTypes.any.isRequired,
    preSelectedFilter: PropTypes.string,
    preVisibleAmount: PropTypes.number.isRequired
};
const ParcoursTeaser = () => {
    const buildParcoursTeaser = (parcoursOwner) => {
        return (<Col key={parcoursOwner.id} md={6}><ParcoursOwnerTeaserItem parcoursOwner={parcoursOwner}/></Col>)
    };
    return <Teaser queryTeaser={parcoursActions.queryParcoursOwnerTeaser} preVisibleAmount={10}
            header={<Col lg={12}>
                <h2><I18n code="home.parcours.title"/></h2>
                <Link to={linkUtil.getLink("parcours")}>
                    <p><I18n code="home.panel.parcours.all"/></p>
                </Link>
            </Col>}
            childFunction={buildParcoursTeaser}

    />
};
const ParcoursOwnerTeaserItem = ({parcoursOwner}) => {
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <ImageBanner imageObject={parcoursOwner.getBannerImageID()} height={100}/>
                    <Col sm={12}>
                        <h4>
                            <ParcoursOwnerHomeLink parcoursOwner={parcoursOwner}/>
                        </h4>
                    </Col>
                    <Col xs={12}>
                        {parcoursOwner.getZipPlace()}
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
ParcoursOwnerTeaserItem.propTypes = {
    parcoursOwner: PropTypes.object.isRequired
};

const TournamentTeaser = () => {
    const buildTournamentTeaser = (tournament) => {
        return (<Col key={tournament.id} lg={12}><TournamentTeaserItem tournament={tournament}/></Col>)
    };
    return <Teaser queryTeaser={tournamentActions.initTeaser} preSelectedFilter={Tournament.prototype.status.published} preVisibleAmount={10}
                   header={<TournamentTeaserHeader />}
                   childFunction={buildTournamentTeaser}

    />
};
const TournamentTeaserHeader = ({updateFilter}) => {
    return <React.Fragment>
        <Col lg={12}>
            <h2><I18n code="home.tournaments.title"/></h2>
        </Col>
        <Col md={6} xs={5}>
            <Link to={linkUtil.getLink("tournament")}>
                <p><I18n code="home.panel.tournament.all"/></p>
            </Link>
        </Col>
        <Col md={6} xs={7}>
            <SwitchButtonGroup onClickButton={updateFilter} dropDownEntries={tournamentFilterEntries} preSelectIndex={0}/>
        </Col>
</React.Fragment>
};
const NewsTeaser = () => {
    const buildNewsTeaser = (news) => {
        return (<Col key={news.id} lg={12}><NewsTeaserItem news={news}/></Col>)
    };
    return <Teaser queryTeaser={newsActions.initTeaser} preVisibleAmount={10}
                   header={<Col lg={12}>
                       <h2><I18n code="news.title"/></h2>
                       <Link to={linkUtil.getLink("news")}>
                           <p><I18n code="news.all"/></p>
                       </Link>
                   </Col>}
                   childFunction={buildNewsTeaser}

    />
};
const NewsTeaserItem = ({news}) => {
    const getObjectLink = () => {
        if (news.getTournamentID()) {
            return <TournamentLink tournament={news.getTournamentID()}
                                   label={news.getTournamentID().getName() + " - "
                                   + news.getTournamentID().getTournamentDate()}/>
        } else if (news.getParcoursID()) {
            return <ParcoursLink parcours={news.getParcoursID()} label={news.getParcoursID().buildNames()}/>
        } else if (news.getWeblink()) {
            return <a href={news.getWeblink()}>{news.getMessage()}</a>
        } else {
            return news.getMessage();
        }
    };
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={8} md={8} lg={8}>
                        <h4>{getObjectLink()}</h4>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                        {news.getTranslationCode() != null ? <Label><I18n code={"news." + news.getTranslationCode()}/></Label> : null}
                    </Col>
                    <Col xs={6} md={2} lg={2}>
                        {news.getNewsDate()}
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
NewsTeaserItem.propTypes = {
    news: PropTypes.object.isRequired
};
const ContentTeaser = () => {
    return (
        <Row>
            <Col xs={12}>
                <Panel>
                    <Panel.Heading><h3><I18n code="home.panel.solutions.header"/></h3></Panel.Heading>
                    <Panel.Body>
                        <I18nHtml code="home.panel.solutions.html"/>
                        <Link to={linkUtil.getLink("/solutions")}>
                            <Button bsStyle="success"><I18n code="home.panel.solutions.link"/></Button>
                        </Link>
                    </Panel.Body>
                </Panel>
            </Col>
            <Col xs={12} lg={4}>
                <Panel>
                    <Panel.Heading><h3><I18n code="home.panel.tournament.header"/></h3></Panel.Heading>
                    <Panel.Body>
                        <I18nHtml code="home.panel.tournament.html"/>
                        <ul>
                            <li><I18n code="management.feature.TM.online"/></li>
                            <li><I18n code="management.feature.TM.app"/></li>
                            <li><I18n code="management.feature.TM.live"/></li>
                            <li><I18n code="management.feature.TM.start"/></li>
                        </ul>
                        <Link to={linkUtil.getLink("tournament")}>
                            <Button>
                                <I18n code="home.panel.tournament.link"/>
                            </Button>
                        </Link>
                    </Panel.Body>
                </Panel>
            </Col>
            <Col xs={12} lg={4}>
                <Panel>
                    <Panel.Heading><h3><I18n code="home.panel.skillboard.header"/></h3></Panel.Heading>
                    <Panel.Body>
                        <I18nHtml code="home.panel.skillboard.html"/>
                        <Link to={linkUtil.getLink("skillboard")}>
                            <Button>
                                <I18n code="home.panel.skillboard.download"/>
                            </Button>
                        </Link>
                    </Panel.Body>
                </Panel>
            </Col>
            <Col xs={12} lg={4}>
                <Panel>
                    <Panel.Heading><h3><I18n code="home.panel.parcours.header"/></h3></Panel.Heading>
                    <Panel.Body>
                        <I18nHtml code="home.panel.parcours.html"/>
                        <a href={linkUtil.getLink("parcours")}>
                            <Button>
                                <I18n code="home.panel.parcours.link"/>
                            </Button>
                        </a>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>)
};

module.exports = FrontPage;
