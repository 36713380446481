const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Button, ButtonGroup} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    MapFrame = require("components/maps/MapFrame"),
    SelectInputTypeAhead  = require("components/form/SelectInputTypeAhead"),
    ListLayout = require("components/widgets/ListLayout");

const ParcoursItem = require("components/parcours/ParcoursItem");

const messages = require("i18n/messages");

const notificationActions = require("components/notification/NotificationActions"),
    layoutActions = require("actions/LayoutActions"),
    layoutStore = require("stores/LayoutStore"),
    parcoursActions = require("actions/ParcoursActions"),
    parcoursStore = require("stores/ParcoursStore");

const analyticsActions = require("actions/AnalyticsActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ParcoursPaymentPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);

        this.state = {
            parcoursStore: parcoursStore.getState(),
            layoutStore: layoutStore.getState()
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.parcours");
        parcoursStore.listen(this.handleParcoursChange);
        layoutStore.listen(this.handleLayoutChange);
        // init
        layoutActions.displayTypeChanged("LIST");
        notificationActions.reset();
        const searchParams = this.state.parcoursStore.searchParams;
        searchParams.payment = true;
        // TODO think about activating nearby search
        parcoursActions.startUp(searchParams);
    }
    componentWillUnmount() {
        parcoursActions.resetParcours();
        parcoursStore.unlisten(this.handleParcoursChange);
        layoutStore.unlisten(this.handleLayoutChange);
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    handleLayoutChange(state) {
        this.setState({layoutStore: state});
    }
    render() {
        const {displayType} = this.state.layoutStore;
        const {parcoursList, searchParams, allParcoursList} = this.state.parcoursStore;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            <Breadcrumb code="profile.managedParcours.payment.A" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <ListLayout.Wrapper>
                    <ListLayout.Filter>
                        <ParcoursFilter displayType={displayType} searchParams={searchParams} allParcoursList={allParcoursList}/>
                        <PointInterestBanner />
                    </ListLayout.Filter>
                    {
                        displayType === "MAP" ?
                            <ListLayout.List>
                                <MapFrame parcours={parcoursList}/>
                            </ListLayout.List> : null
                    }
                    {
                        displayType === "LIST" ?
                            <ListLayout.List>
                                <ParcoursList list={parcoursList}/>
                            </ListLayout.List> : null
                    }
                </ListLayout.Wrapper>
            </Grid>)
    }
}
class ParcoursFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handleSearchTextFoundElement = this.handleSearchTextFoundElement.bind(this);
        this.state = {}
    }

    handleFilterChange(displayType) {
        if (this.props.displayType != displayType) {
            layoutActions.displayTypeChanged(displayType);
        }
    }
    handleSearchTextChange(searchText) {
        analyticsActions.trackParcoursSearch(searchText);
        const params = this.props.searchParams;
        params.searchText = searchText;
        parcoursActions.searchParcoursList(params);
    }
    handleSearchTextFoundElement(element) {
        // forward to selected parcours
        if (element.className === 'parcours') {
            window.location = linkUtil.getLink("parcours/" + element.getOnlineID());
        } else {
            window.location = linkUtil.getExternalLink(element.getWeblink());
        }

    }
    render() {
        const {displayType, searchParams, allParcoursList} = this.props;
        const hasGeo = "geolocation" in navigator;
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <h4><strong><I18n code="parcours.button.search"/></strong></h4>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={12}>
                        <SelectInputTypeAhead
                            defaultValue={searchParams.searchText}
                            objectList={allParcoursList}
                            handleSelectedObject={this.handleSearchTextFoundElement}
                            handleNoObjectFound={this.handleSearchTextChange}
                            placeholder={messages.get("parcours.button.search.placeholder")}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} sm={3} md={3} lg={12}>
                        <h4><I18n code="filter.header.display"/></h4>
                        <ButtonGroup>
                            <Button onClick={() => this.handleFilterChange("LIST")}
                                    className={displayType === "LIST" ? "active" : ""}>
                                <FontAwesome icon="list"/><I18n code="filter.button.list"/>
                            </Button>
                            <Button onClick={() => this.handleFilterChange("MAP")}
                                    className={displayType === "MAP" ? "active" : ""}>
                                <FontAwesome icon="globe"/><I18n code="filter.button.map"/>
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <br/>
            </div>)
    }
}
ParcoursFilter.propTypes = {
    displayType: PropTypes.string.isRequired,
    searchParams: PropTypes.object.isRequired,
    allParcoursList: PropTypes.array
};
const ParcoursList = ({list}) => {
    let userLoc = null;
    if (list == null) {
        return (<Loading/>)
    } else if (list.length == 0) {
        return (<Alert bsStyle="warning"><I18n code="parcours.result.empty"/></Alert>)
    }
    return (
        <React.Fragment>
            {list.map(item => {
                if (item.className === 'parcours') {
                    return <ParcoursItem key={item.id || item.objectId} parcours={item} userLoc={userLoc}/>
                }
            })}
        </React.Fragment>
    )
};
module.exports = ParcoursPaymentPage;
