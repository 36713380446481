var Parse = require("ParseInit");

var {News, GroupPlace} = require("parse/_Domain");

class NewsDao {

    searchNews(searchParams) {
        var queries = [];
        var query = new Parse.Query(News);
        this.applyAndFilter(searchParams, query);
        queries.push(query);
        if (searchParams.searchText != null) {
            // search for name
            query.matches("message", "(" + searchParams.searchText + ")", "i");
            var zip = parseInt(searchParams.searchText);
            if (zip > 0) {
                // search by zip
                var orZipQuery = new Parse.Query(News);
                this.applyAndFilter(searchParams, orZipQuery);
                orZipQuery.matches("zip", "(" + zip + ")", "i");
                queries.push(orZipQuery);
            } else {
                // search by place
                var orPlaceQuery = new Parse.Query(News);
                this.applyAndFilter(searchParams, orPlaceQuery);
                orPlaceQuery.matches("place", "(" + searchParams.searchText + ")", "i");
                queries.push(orPlaceQuery);
            }
        }
        var superQuery = Parse.Query.or.apply(Parse.Query, queries);
        superQuery.include(News.prototype.col.tournamentID);
        superQuery.include(News.prototype.col.parcoursID);
        superQuery.include(News.prototype.col.parcoursID + ".parcoursOwnerID");
        superQuery.descending(News.prototype.col.createdAt);
        return superQuery.find();
    }

    applyAndFilter(searchParams, query) {
        if (searchParams.mainGroupPlaceID != null) {
            var mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = searchParams.mainGroupPlaceID;
            query.equalTo(News.prototype.col.mainGroupPlaceID, mainGroupPlace);
        }
        if (searchParams.regionGroupPlaceID != null) {
            var regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = searchParams.regionGroupPlaceID;
            query.equalTo(News.prototype.col.groupPlaceID, regionGroupPlace);
        }
        query.notEqualTo(News.prototype.col.status, News.prototype.status.deleted);
    }

    initTeaser() {
        let query = new Parse.Query(News);
        query.notEqualTo(News.prototype.col.status,
            News.prototype.status.deleted);
        query.descending(News.prototype.col.createdAt);
        query.include(News.prototype.col.tournamentID);
        query.include(News.prototype.col.parcoursID);
        query.include(News.prototype.col.parcoursID + ".parcoursOwnerID");
        query.limit(50);
        return query.find();
    }
}

module.exports = new NewsDao();
