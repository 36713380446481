const React = require("react"),
    PropTypes = require('prop-types');
const {Grid, Alert, Panel, Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    BowTypeIcon = require("components/widgets/BowTypeIcon"),
    MonthPagerBasic = require("components/widgets/MonthPagerBasic");

const messages = require("i18n/messages"),
    I18nCountType=require("i18n/I18nCountType");

const EventDetailLink = require("components/links/EventDetailLink");

const {BaseContext} = require("context/BaseContext");

const parcoursStore = require("stores/ParcoursStore"),
    parcoursAction = require("actions/ParcoursActions"),
    notificationActions = require("components/notification/NotificationActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ParcoursRankingPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.handleChangedDate = this.handleChangedDate.bind(this);

        let year = this.props.year;
        let month = this.props.month;
        if (month == null) {
            const today = new Date();
            year = today.getFullYear();
            month = today.getMonth() + 1
        }
        this.state = {
            parcoursStore: parcoursStore.getState(),
            month: month,
            year: year
        }
    }
    componentDidMount() {
        parcoursStore.listen(this.handleParcoursChange);
        notificationActions.reset();
        if (this.props.onlineID != null && this.props.onlineID != "null") {
            parcoursAction.startRanking(this.props.onlineID, this.state.month, this.state.year);
        } else {
            notificationActions.warning.defer("parcours.result.empty");
        }
    }
    componentWillUnmount() {
        parcoursStore.unlisten(this.handleParcoursChange);
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    handleChangedDate(month, year, yearChanged) {
        this.setState({
            month: month,
            year: year
        });
        // TODO pushState or replaceState to update url
        parcoursAction.resetParcoursRanking();
        parcoursAction.queryRankingOfParcours(this.state.parcoursStore.parcours, month, year);
    }
    render() {
        const {parcoursStore, month, year} = this.state;
        return (
            <Grid>
                <Breadcrumbs>
                    <Breadcrumb link="/" code="header.nav.home"/>
                    <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                    {
                        parcoursStore.parcours ?
                            <Breadcrumb link={"/parcours/" + parcoursStore.parcours.getOnlineID() } code={parcoursStore.parcours.buildNames()}/>
                            : null
                    }

                    <Breadcrumb code="parcours.ranking.header" active/>
                </Breadcrumbs>
                {
                    parcoursStore.parcours ?
                        <MonthPagerBasic year={year} month={month} headerText={messages.get("parcours.ranking.header")} handleChangedDate={this.handleChangedDate}/>
                        : null
                }
                <hr/>
                {parcoursStore.rankLoading === true ?
                    <Loading/> : ""}
                {parcoursStore.rankLoading === false && parcoursStore.countTypes.length <= 0 ?
                    <Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert> : ""
                }
                {  parcoursStore.rankLoading === false && parcoursStore.countTypes.length > 0 && parcoursStore.parcours != null ?
                    <RankingCountTypeLoop
                        parcours={parcoursStore.parcours}
                        countTypes={parcoursStore.countTypes}
                        eventPlayerRanking={parcoursStore.eventPlayerRanking} />
                     : ""
                }
            </Grid>
        )
    }
}
ParcoursRankingPage.propTypes = {
    onlineID: PropTypes.string.isRequired,
    year: PropTypes.number,
    month: PropTypes.number
};
const RankingCountTypeLoop = ( {parcours, countTypes, eventPlayerRanking }) => {
    const { user, userInfoState:{ userFeatures }} = React.useContext(BaseContext);
    let alertText = null;
    let parcoursFeature = false;
    let hideMoreHitScores = false;
    parcoursFeature = parcours.hasRankingEnabled();
    if (!parcoursFeature) {
        alertText = messages.get("parcours.ranking.noParcoursFeature");
        if (user != null && userFeatures.isRankingFeatureActive()) {
            // pro user override
            alertText = null;
            parcoursFeature = true;
        }
    } else {
        hideMoreHitScores = parcours.isConstraintOneHitOnly();
    }
    return (<React.Fragment>
            {
                alertText ? <Alert bsStyle="info">{alertText}</Alert> : null
            }
            {
                countTypes.map(function(countType) {
                    return <EventPlayer4CountTypePanel key={countType} hideMoreHitScores={hideMoreHitScores}
                                                       parcoursFeature={parcoursFeature}
                                                       countTypeOnlineID={countType}
                                                       user={user}
                                                       eventPlayers={eventPlayerRanking[countType]}/>
                })
            }
        </React.Fragment>)
};
RankingCountTypeLoop.propTypes = {
    parcours:PropTypes.object.isRequired,
    eventPlayerRanking:PropTypes.object.isRequired,
    countTypes:PropTypes.array.isRequired,
};
const EventPlayer4CountTypePanel = ({eventPlayers, countTypeOnlineID, parcoursFeature, hideMoreHitScores, user}) => {
    if (hideMoreHitScores && countTypeOnlineID.indexOf("_t") > 0 && !countTypeOnlineID.startsWith("D")) {
        console.log("Hide EventPlayer4CountTypePanel", countTypeOnlineID, hideMoreHitScores)
        return null;
    }
    let index = 0;
    return (
        <div>
            <Panel>
                <Panel.Heading>
                    <h3><I18nCountType countTypeOnlineID={countTypeOnlineID}/></h3>
                </Panel.Heading>
                <Panel.Body>
                    <Table fill>
                        <thead>
                        <tr>
                            <th>Pos</th>
                            <th><I18n code="tournament.table.column.bow"/></th>
                            <th><I18n code="tournament.table.column.name"/></th>
                            <th><I18n code="tournament.table.column.date"/></th>
                            <th>DPS</th>
                            <th><I18n code="tournament.table.column.points"/></th>
                            <th>Kills</th>
                        </tr>
                        </thead>
                        <tbody>
                        {eventPlayers.map(function (eventPlayer) {
                            index++;
                            return (<EventPlayerRow key={eventPlayer.id} eventPlayer={eventPlayer} index={index}
                                                    user={user}
                                                    parcoursFeature={parcoursFeature}/>);
                        })}
                        </tbody>
                    </Table>
                </Panel.Body>
            </Panel>
        </div>
    )
};
EventPlayer4CountTypePanel.propTypes = {
    countTypeOnlineID:PropTypes.string.isRequired,
    eventPlayers:PropTypes.array.isRequired,
    parcoursFeature:PropTypes.bool.isRequired,
    user:PropTypes.object
};
const EventPlayerRow = ({index, eventPlayer, parcoursFeature, user}) => {
    function buildName() {
        let className = "textBlur";
        let eventLink = null;
        let name;
        if (parcoursFeature) {
            // readable
            className = "";
            name = eventPlayer.getPlayerName();
            if (user && user.getEmail() == eventPlayer.getPlayerEmail()) {
                // logged in user show link to event
                eventLink = <p><EventDetailLink eventID={eventPlayer.getEventStringID()}/></p>
            }
        } else {
            name = eventPlayer.getPlayerName().replace(/\D/g, "X");
        }
        if (eventPlayer.getEventID() != null && !eventPlayer.getEventID().isRankingVisible()) {
            className = "textBlur";
            name = eventPlayer.getPlayerName().replace(/\D/g, "X");
        }
        return <div className={className}>{name}{eventLink}</div>
    }
    return (
        <tr id={eventPlayer.id}>
            <td>{index}</td>
            <td><BowTypeIcon bowTypeId={eventPlayer.getBowTypeId()} /></td>
            <td>{buildName()}</td>
            <td>{eventPlayer.getEventDate()}</td>
            <td>{eventPlayer.getPlayerDPS()}</td>
            <td>{eventPlayer.getSumPoints()}</td>
            <td>{eventPlayer.getKillCounts2String()}</td>
        </tr>)
};
EventPlayerRow.propTypes = {
    index:PropTypes.number.isRequired,
    eventPlayer:PropTypes.object.isRequired,
    parcoursFeature:PropTypes.bool.isRequired,
    user:PropTypes.object
};
module.exports = ParcoursRankingPage;
