var alt = require("AltInit");
var dao = require("actdao/LicenseDao");
var notificationActions = require("components/notification/NotificationActions");
var parseDao = require("actdao/ParseBaseDao");
var clubActions = require("actions/ClubActions");

class LicenseActions {


    loadBowUnionsAndTypes(code) {
        dao.loadBowTypes(code)
            .then(bowUnions => {
                this.updateBowTypes(bowUnions);
            })
            .catch(error => {
                error.trace = "LICA.loadBowType";
                notificationActions.parseError(error);
            });
        dao.loadAgeTypes(code)
            .then(bowUnions => {
                this.updateAgeTypes(bowUnions);
            })
            .catch(error => {
                error.trace = "LICA.loadAgeType";
                notificationActions.parseError(error);
            });
        dao.loadBowUnion(code)
            .then(bowUnions => {
                this.updateBowUnion(bowUnions);
            })
            .catch(error => {
                error.trace = "LICA.loadBowUnion";
                notificationActions.parseError(error);
            });
        return {};
    };

    showModal(show) {
        return show;
    };

    createUserBowLicense(usrBowLicense, clubUser, userDetail, callback) {
        parseDao.saveObject(usrBowLicense)
            .then(sUsrBowLicense => {
                // update UserDetail with new Link
                if (clubUser != null && clubUser.getUserDetailID() != null) {
                    // used from club editor
                    var usrBowLicIDs = clubUser.getUserDetailID().getLicenseArrayIDs();
                    usrBowLicIDs.push(sUsrBowLicense.id);
                    dao.updateClubUserLicenseIDs(clubUser.getUserDetailID(), usrBowLicIDs)
                        .then(sUserDetail => {
                            clubUser.setUserDetailID(sUserDetail);
                            clubActions.setEditClubUser(clubUser);
                        })
                        .catch(error => {
                            error.trace = "LICA.updClubUsrDetailLicIDs";
                            notificationActions.parseError(error);
                        });
                } else if (userDetail != null) {
                    // used from account editor
                    var usrBowLicIDs = userDetail.getLicenseArrayIDs();
                    usrBowLicIDs.push(sUsrBowLicense.id);
                    dao.updateClubUserLicenseIDs(userDetail, usrBowLicIDs)
                        .then(sUserDetail => {
                            callback({usrDetail: sUserDetail})
                        })
                        .catch(error => {
                            error.trace = "LICA.updAccountUsrDetailLicIDs";
                            notificationActions.parseError(error);
                        });
                } else {
                    console.log("WTF - no userDetails")
                }

            })
            .catch(error => {
                error.trace = "LICA.createUserBowLic";
                notificationActions.parseError(error);
            });
        return {};
    };

    deleteUserBowLicense(delUsrLic, clubUser, userDetail, callback) {
        parseDao.deleteObject(delUsrLic)
            .then(sUsrLic => {
                if (clubUser != null && clubUser.getUserDetailID() != null) {
                    // used from club editor
                    let usrBowLicIDs = clubUser.getUserDetailID().getLicenseArrayIDsExclude(sUsrLic);
                    dao.updateClubUserLicenseIDs(clubUser.getUserDetailID(), usrBowLicIDs)
                        .then(sUserDetail => {
                            clubUser.setUserDetailID(sUserDetail);
                            clubActions.setEditClubUser(clubUser);
                        })
                        .catch(error => {
                            error.trace = "LICA.delClubUsrDetailLicIDs";
                            notificationActions.parseError(error);
                        });
                } else if (userDetail != null) {
                    // used from account editor
                    let usrBowLicIDs = userDetail.getLicenseArrayIDsExclude(sUsrLic);
                    dao.updateClubUserLicenseIDs(userDetail, usrBowLicIDs)
                        .then(sUserDetail => {
                            callback({usrDetail: sUserDetail})
                        })
                        .catch(error => {
                            error.trace = "LICA.delAccountUsrDetailLicIDs";
                            notificationActions.parseError(error);
                        });
                } else {
                    console.log("WTF - no userDetails")
                }
            })
            .catch(error => {
                error.trace = "LICA.delUserBowLic";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveUserBowLicense(usrBowLicense, clubUser, userDetail, callback) {
        parseDao.saveObject(usrBowLicense)
            .then(sUsrBowLicense => {
                if (clubUser != null) {
                    clubActions.setEditClubUser(clubUser);
                } else if (userDetail != null) {
                    callback({usrDetail: userDetail})
                }
            })
            .catch(error => {
                error.trace = "LICA.saveUserBowLic";
                notificationActions.parseError(error);
            });
        return {};
    };

    updateBowUnion(pBowUnions) {
        return pBowUnions;
    }
    updateBowTypes(pBowTypes) {
        return pBowTypes;
    }
    updateAgeTypes(pAgeTypes) {
        return pAgeTypes;
    }
}

module.exports = alt.createActions(LicenseActions);
