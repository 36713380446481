const Parse = require("ParseInit");
const {User, UserAbo, UserDetail, Address , UserFavorite, UserToken} = require("parse/_Domain");


class UserDao {
    queryEmailLogin(usernameOrEmail) {
        return Parse.Cloud.run('emailLogin', {email: usernameOrEmail});
    }

    fetchUserDetail(user) {
        const query = new Parse.Query(UserDetail);
        query.include(UserDetail.prototype.col.addressID);
        query.include(UserDetail.prototype.col.licenseIDs);
        query.include(UserDetail.prototype.col.licenseIDs + ".bowUnionID");
        query.include(UserDetail.prototype.col.licenseIDs + ".ageTypeID");
        query.include(UserDetail.prototype.col.licenseIDs + ".bowTypeID");
        return query.get(user.get(User.prototype.col.userDetailID).id);
    }

    refreshUser(user) {
        return user.fetch();
    }

    fetchUserAbos(user) {
        const query = new Parse.Query(UserAbo);
        query.notEqualTo(UserAbo.prototype.col.status, "D");
        query.equalTo(UserAbo.prototype.col.userID, user);
        query.descending(UserAbo.prototype.col.aboEndDate);
        return query.find();
    }

    setTournamentRole(user) {
        return Parse.Cloud.run('setTournamentUser', {username: user.get("username")});
    };

    updateUserAboPause(userAbo, status) {
        return Parse.Cloud.run('handleUserAboPause', {userAboID: userAbo.id, status: status});
    }

    fetchUserFavorite(user) {
        const query = new Parse.Query(UserFavorite);
        query.notEqualTo(UserFavorite.prototype.col.status, "D");
        query.equalTo(UserFavorite.prototype.col.userID, user);
        return query.first();
    }

    fetchFavoritesHavingUsers() {
        const query = new Parse.Query(UserFavorite);
        query.notEqualTo(UserFavorite.prototype.col.status, "D");
        query.exists(UserFavorite.prototype.col.users);
        return query.find();
    }

    setClubUserRole(user) {
        return Parse.Cloud.run('setClubRole', {username: user.get(User.prototype.col.username)});
    }

    createEmptyUserDetail(user) {
        const usrDetail = new UserDetail();
        usrDetail.setStatus(UserDetail.prototype.status.A);
        usrDetail.buildACL(user);
        const address = new Address();
        address.setStatus(Address.prototype.status.A);
        address.setMail(user.get("email"));
        address.buildACL(user, "club", null);
        usrDetail.setAddressID(address);
        return usrDetail.save(null);
    }

    createEmptyUserAddress(user, userDetail) {
        const address = new Address();
        address.setStatus(Address.prototype.status.A);
        address.setMail(user.get("email"));
        address.buildACL(user, "club", null);
        userDetail.setAddressID(address);
        return userDetail.save(null);
    }

    queryUser(searchParams) {
        let superQuery;
        if (searchParams.searchText && searchParams.searchText.length > 0) {
            const queries = [];
            const nameQuery = new Parse.Query(User);
            nameQuery.matches(User.prototype.col.username, "(" + searchParams.searchText + ")", "i");
            queries.push(nameQuery);
            const mailQuery = new Parse.Query(User);
            mailQuery.matches(User.prototype.col.email, "(" + searchParams.searchText + ")", "i");
            queries.push(mailQuery);
            const idQuery = new Parse.Query(User);
            idQuery.equalTo(User.prototype.col.objectId, searchParams.searchText);
            queries.push(idQuery);

            superQuery = Parse.Query.or.apply(Parse.Query, queries);
        } else {
            superQuery = new Parse.Query(User);
        }
        if (searchParams.aboType) {
            const aboTypeQuery = new Parse.Query(UserAbo);
            aboTypeQuery.equalTo(UserAbo.prototype.col.type, searchParams.aboType);
            superQuery.matchesQuery(User.prototype.col.userAbos, aboTypeQuery);
        }
        superQuery.include(User.prototype.col.userDetailID);
        superQuery.include(User.prototype.col.userAbos);
        if (searchParams.hasAbo) {
            superQuery.exists(User.prototype.col.userAbos);
        }
        superQuery.limit(searchParams.limit ? searchParams.limit : 100);
        superQuery.descending(User.prototype.col.createdAt);
        return superQuery.find()
    }

    queryFindUser(email) {
        const mailQuery = new Parse.Query(User);
        mailQuery.equalTo(User.prototype.col.email, email);
        mailQuery.include(User.prototype.col.userDetailID);
        mailQuery.include(User.prototype.col.userDetailID + "." + UserDetail.prototype.col.licenseIDs);
        return mailQuery.first()
    }

    queryUserMail(skbUser) {
        return Parse.Cloud.run('qUserMail', {userID: skbUser.id});
    }

    saveUserImageFile(userDetail, parseFile) {
        return Parse.Cloud.run('updUserImage', {udID: userDetail.id, img: parseFile});
    }

    editUserCCP(params) {
        return Parse.Cloud.run('updateSKBUser', params);
    }

    generateUserToken(email, action) {
        return Parse.Cloud.run('generateUserToken', {email: email, action: action});
    }

    loadUserToken(userTokenID) {
        return Parse.Cloud.run('queryUserToken', {userTokenID: userTokenID});
    }

    resendAuthenticationMail(userID, userEmail) {
        return Parse.Cloud.run('generateUserToken', {email: userEmail, action: UserToken.prototype.action.userMail});
    }

    resendClubAuthenticationMail(club, clubEmail) {
        return Parse.Cloud.run('generateUserToken', {email: clubEmail, clubID: club.id, action: UserToken.prototype.action.clubMail});
    }

    queryUserInstallations(user) {
        return Parse.Cloud.run('query4UserInstallation', {userID: user.id})
    }

    queryUserSession(user) {
        return Parse.Cloud.run('query4UserSession', {userID: user.id})
    }

    deleteUserSession(user) {
        return Parse.Cloud.run('deleteUserSession', {userID: user.id})
    }

    deleteUserAndContent(user, destroyUser) {
        return Parse.Cloud.run('deleteUserElements', {userID: user.id, destroyUser: destroyUser})
    }

    unlinkUserContent(user) {
        return Parse.Cloud.run('unlinkUserElements', {userID: user.id})
    }
}
module.exports = new UserDao();
