const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert, Panel, Table, Label, Button, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    FontAwesome = require("components/widgets/FontAwesome"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    ValidInput = require("components/form/ValidInput"),
    ProfileLicensePanel = require("components/profile/ProfileLicensePanel"),
    ClubLoader = require("components/club/ClubLoader"),
    UserImageHandler = require("components/profile/UserImageHandler"),
    ClubUserAddressModal= require("components/clubmodal/ClubUserAddressModal"),
    PaymentTargetDetail = require("components/business/PaymentTargetDetail"),
    AddressModal = require("components/modals/AddressModal"),
    ClubUserModal= require("components/clubmodal/ClubUserModal"),
    ClubGroupRoleModal = require("components/clubmodal/ClubGroupRoleModal"),
    messages = require("i18n/messages"),
    Utility = require("util/Utility");

const clubActions = require("actions/ClubActions");
const ClubUser = require("parse/ClubUser");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubUserDetailPage extends React.Component {
    render() {
        return <ClubLoader clubID={this.props.clubID} clubUserRole={ClubUser.prototype.role.userHandling} loadClubUsers={false} loginMandatory={true} clubUserId={this.props.clubUserID}>
            <ClubDetails/>
        </ClubLoader>
    }
}
ClubUserDetailPage.propTypes = {
    clubID: PropTypes.string.isRequired,
    clubUserID: PropTypes.string.isRequired
};
const ClubDetails = ({club, user, ownClubUser, clubUser}) => {
    if (clubUser == null) {
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                            <Breadcrumb link={"/club/" + club.id + "/members"} code={"cl_role_user"} />
                        </Breadcrumbs>
                    </Col>
                    <Col xs={12}>
                        <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                    </Col>
                </Row>
            </Grid>)
    }
    document.title = club.getName() + " - " + clubUser.getFullName();
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                            <Breadcrumb link={"/club/" + club.id + "/members"} code={"cl_role_user"} />
                            <Breadcrumb code={clubUser.getFullName()} active/>
                        </Breadcrumbs>
                </Col>
            </Row>
            <ClubUserLayout user={user}
                           club={club}
                           ownClubUser={ownClubUser}
                           clubUser={clubUser}
                           clubUserDetail={clubUser.getUserDetailID()}/>
        </Grid>)
};
ClubDetails.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object,
    clubUser: PropTypes.object,
    ownClubUser:PropTypes.object
};
const ClubUserLayout = ({club, user, ownClubUser, clubUser, clubUserDetail}) => {
    function handleDelete(doDelete) {
        if (doDelete) {
            clubActions.deleteClubUser(club, clubUser);
        }
    }
    const addressOnly = clubUser.hasClubAddressOnly()
    return (<Row>
        <Col  xs={12}>
            <ClubUserHeader clubUser={clubUser} clubUserDetail={clubUserDetail} />
        </Col>
        <Col xs={12} sm={4}>
            { !addressOnly ? <ClubUserMainAddress club={club} clubUser={clubUser} clubUserDetail={clubUserDetail}/> : null}
            { club.show2ndAddress() ? <ClubUserAddressTwo clubUser={clubUser} ownClubUser={ownClubUser} /> : null}
            { !addressOnly ? <ClubUserImage clubUser={clubUser} clubUserDetail={clubUserDetail} /> : null}
            <ClubUserPaymentInfo clubUser={clubUser} />
            <ClubUserInfo club={club} clubUser={clubUser} />
            { !addressOnly ? <ClubUserGrouping club={club} clubUser={clubUser} ownClubUser={ownClubUser}/> : null}
        </Col>
        <Col xs={12} sm={8}>
            <ClubUserComment clubUser={clubUser}/>
        </Col>
        <Col xs={12} sm={8}>
            {
                !addressOnly ? <ProfileLicensePanel clubUser={clubUser} clubUserDetail={clubUserDetail}/> : null
            }
        </Col>
        <hr/>
        <Col xs={12} >
            <DeleteDecisionModal messageCode="club.member.modal.delete.ask"  titleCode="club.member.modal.delete.header"
                                 tooltip={messages.get("club.member.modal.delete.header")}
                                 entryName={clubUser.getFullName()} handleDelete={handleDelete}/>
        </Col>

    </Row>);
}
ClubUserLayout.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object,
    clubUser: PropTypes.object,
    ownClubUser:PropTypes.object,
    clubUserDetail:PropTypes.object
};
const ClubUserHeader = ({clubUser, clubUserDetail}) => {
    let sex = "";
    if (clubUserDetail != null) {
        if (clubUserDetail.getSex() === 0) {
            sex = <FontAwesome icon="mars" size="lg"/>
        } else if (clubUserDetail.getSex() === 1) {
            sex = <FontAwesome icon="venus" size="lg"/>
        }
    }
    let number = clubUser.getUserNumber();
    if (number == null) {
        number = "#"
    }
    return <PageHeader><StrikeOutText strikeOut={clubUser.isExMember()}>
        {number + ") " + clubUser.getCompany() + " " + clubUser.getFullName()}&nbsp;{sex}
    </StrikeOutText></PageHeader>
}
const ClubUserImage = ({clubUser, clubUserDetail}) => {
    function userDetailUpdated(userDetail) {
        clubUser.setUserDetailID(userDetail);
        clubActions.updateClubUser(clubUser);
    }
    return <Panel>
        <Panel.Heading>
            <FontAwesome icon="home"/><I18n code="profile.account.user.image"/>
        </Panel.Heading>
        <Panel.Body>
            {
                clubUserDetail != null ?
                    <UserImageHandler userDetail={clubUserDetail} allowEdit={true} onAfterChange={userDetailUpdated}/>
                    :
                    <React.Fragment>{messages.get("club.detail.noUserDetail")}</React.Fragment>
            }
        </Panel.Body>
    </Panel>
}
const ClubUserPaymentInfo = ({clubUser}) => {
    function addPaymentTarget(paymentTargetID) {
        clubUser.setPaymentTargetID(paymentTargetID);
        clubActions.saveClubUser(clubUser)
    }
    return <Panel>
        <Panel.Heading>
            <FontAwesome icon="money-bill-wave"/><I18n code="profile.account.business.paymentTarget.header"/>
        </Panel.Heading>
        <Panel.Body>
            <PaymentTargetDetail paymentTarget={clubUser.getPaymentTargetID()} handleSaveNewObject={addPaymentTarget}/>
        </Panel.Body>
    </Panel>
}
class ClubUserMainAddress extends React.Component {
    constructor(props) {
        super(props);
        this.refClubUserAddrModal = React.createRef();
        this.handleEditAddressMain = this.handleEditAddressMain.bind(this);

    }
    handleEditAddressMain() {
        this.refClubUserAddrModal.current.openUser(this.props.clubUser);
    }
    render() {
        const {club, clubUser, clubUserDetail} = this.props;
        let mail = clubUser.getEmail();
        let birthday = null;
        let addressMain = null;
        if (clubUserDetail != null) {
            birthday = clubUserDetail.getBirthday();
            addressMain = clubUserDetail.getAddressID();
        }
        return <Panel>
            <Panel.Heading>
                <FontAwesome icon="home"/><I18n code="profile.account.user.data.header"/>
            </Panel.Heading>
            <Panel.Body>
                <ErrorBoundary><ClubUserAddressModal ref={this.refClubUserAddrModal} club={club}/></ErrorBoundary>
                {
                    addressMain == null ?
                        <React.Fragment>{messages.get("club.detail.noUserDetail")}</React.Fragment>
                        :
                        <React.Fragment>
                            {addressMain.getStreet()}<br/>
                            {addressMain.getZipPlace()}<br/><br/>
                            <FontAwesome icon="phone"/>{addressMain.getPhone() != null ? <a href={"tel:"+addressMain.getPhone()}>{addressMain.getPhone()}</a> : "-x-"}<br/>
                            <FontAwesome icon="envelope"/>{mail != null ? <a href={"mail:"+mail}>{mail}</a> : "-x-"}<br/>
                            <FontAwesome icon="birthday-cake"/>{birthday != null ? Utility.getReadableDate(birthday) : "-x-"}
                        </React.Fragment>
                }
            </Panel.Body>
            <Panel.Footer>
                <Button onClick={this.handleEditAddressMain} disabled={clubUser.hasClubAddressOnly()}>
                    <FontAwesome icon="pencil-alt"/>{messages.get("modal.button.edit")}
                </Button>
            </Panel.Footer>
        </Panel>
    }
}
class ClubUserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.refClubUserModal = React.createRef();
        this.handleEditClubUser = this.handleEditClubUser.bind(this);
    }
    handleEditClubUser() {
        this.refClubUserModal.current.editClubUser(this.props.clubUser);
    }
    render() {
        const {club, clubUser} = this.props;
        let number = clubUser.getUserNumber();
        if (number == null) {
            number = "#"
        }
        return <Panel>
            <Panel.Heading>
                <FontAwesome icon="university"/><I18n code="profile.account.clubuser.data.header"/>
            </Panel.Heading>
            <Panel.Body>
                <Table style={{margin: "0"}}>
                    <tbody>
                    <tr>
                        <th><I18n code="address.userNr"/></th>
                        <td>{number}</td>
                    </tr>
                    <tr>
                        <th><I18n code="address.entryDate"/></th>
                        <td>{clubUser.getEntryDate() != null ? Utility.getReadableDate(clubUser.getEntryDate()) : "-x-"}</td>
                    </tr>
                    </tbody>
                </Table>
            </Panel.Body>
            <Panel.Footer>
                <Button onClick={this.handleEditClubUser}><FontAwesome icon="pencil-alt"/>{messages.get("modal.button.edit")}</Button>
                <ErrorBoundary><ClubUserModal ref={this.refClubUserModal}  club={club} /></ErrorBoundary>
            </Panel.Footer>
        </Panel>
    }
}
class ClubUserGrouping extends React.Component {
    constructor(props) {
        super(props);
        this.refGroupRoleModal = React.createRef();
        this.handleGrouping = this.handleGrouping.bind(this);
    }

    handleGrouping() {
        this.refGroupRoleModal.current.open(this.props.clubUser);
    }
    render() {
        const {club, clubUser, ownClubUser} = this.props;
        let lblStyle = "success";
        let cuRole = clubUser.getRole();
        if (!clubUser.isReqStatusAccepted()) {
            lblStyle ="warning";
        } else if (clubUser.isExMember()) {
            lblStyle = "default"
        }
        return  <Panel>
            <Panel.Heading>
                <FontAwesome icon="users"/><I18n code="club.group.modal.functions"/>,&nbsp;<I18n code="club.group.modal.groups"/>
            </Panel.Heading>
            <Panel.Body>
                <ErrorBoundary><ClubGroupRoleModal ref={this.refGroupRoleModal} club={club} ownClubUser={ownClubUser}/></ErrorBoundary>
                <Col xs={12} sm={8}>
                    <Label bsStyle={lblStyle}>{messages.get(cuRole)}</Label><br/><br/>
                    {
                        clubUser.getFunctions() != null ?
                            clubUser.getFunctions().map(fct => {
                                return <span key={"grp_" + fct}>
                                        <FontAwesome icon={clubUser.getFaIcon(fct)}> {messages.get(fct)}<br/></FontAwesome>
                                </span>
                            }) : null

                    }
                </Col>
                <Col xs={12} sm={4}>
                    {
                        clubUser.getArticleID() ?
                            <span><Label bsStyle={lblStyle}>{clubUser.getArticleID().getName()}</Label><br/></span> : null
                    }
                    {
                        clubUser.getGroups() != null ?
                            clubUser.getGroups().map(group => {
                                return <span key={"grp_" + group}><Label>{group}</Label><br/></span>
                            }) : null
                    }
                </Col>
            </Panel.Body>
            <Panel.Footer>
                <Button onClick={this.handleGrouping}><FontAwesome icon="pencil-alt"/>{messages.get("modal.button.edit")}</Button>
            </Panel.Footer>
        </Panel>
    }
}
class ClubUserComment extends React.Component {
    constructor(props) {
        super(props);
        this.refComment = React.createRef();

        this.handleSave = this.handleSave.bind(this);
        this.setChanged = this.setChanged.bind(this);

        this.state = {
            hasChanged: false
        }
    }
    handleSave() {
        let comment = this.refComment.current.getValue().trim();
        this.props.clubUser.setComment(comment);
        clubActions.saveClubUser(this.props.clubUser);
        this.setChanged(false);
    }
    setChanged(changed) {
        this.setState({hasChanged: changed});
    }
    render() {
        const {clubUser} = this.props;
        return (<Panel>
                <Panel.Heading>
                    <FontAwesome icon="comment"/><I18n code="club.detail.comment"/>
                </Panel.Heading>
                <Panel.Body>
                    <ValidInput ref={this.refComment}
                                placeholder={messages.get("club.detail.comment")}
                                componentClass="textarea"
                                onAfterChange={() => this.setChanged(true)}
                                default={clubUser.getComment()}
                                valid={{maxLength: 1500}}/>
                </Panel.Body>
                <Panel.Footer>
                    <Button disabled={!this.state.hasChanged} onClick={this.handleSave}><FontAwesome icon="save"/>{messages.get("modal.button.save")}</Button>
                </Panel.Footer>
            </Panel>
        );
    }
}
ClubUserComment.propTypes = {
    clubUser: PropTypes.object.isRequired
};
const ClubUserAddressTwo = ({ ownClubUser, clubUser}) => {
    function handleCreateAddressTwo() {
        let ownUser = null;
        if (ownClubUser) {
            ownUser = ownClubUser.getUserID();
        }
        clubActions.createAddressTwo(clubUser, ownUser);
    }
    function updatedAddress(address) {
        clubUser.setAddressTwoID(address);
        clubActions.updateClubUser(clubUser)
    }
    let addressTwo = clubUser.getAddressTwoID();
    return (<Panel>
        <Panel.Heading>
            <React.Fragment><FontAwesome icon="home"/><I18n code="tournament.table.column.address"/> 2</React.Fragment>
        </Panel.Heading>
        <Panel.Body>
            {
                addressTwo == null ?
                    <React.Fragment>{messages.get("club.detail.noUserDetail")}</React.Fragment>
                    :
                    <React.Fragment>
                        {addressTwo.getCompany()}<br/>
                        {addressTwo.getFullName()}<br/>
                        {addressTwo.getStreet()}<br/>
                        {addressTwo.getZipPlace()}<br/><br/>
                        <FontAwesome icon="phone"/>{addressTwo.getPhone() != null ? <a href={"tel:"+addressTwo.getPhone()}>{addressTwo.getPhone()}</a> : "-x-"}<br/>

                    </React.Fragment>
            }
        </Panel.Body>
        <Panel.Footer>
            {
                addressTwo == null ? <Button onClick={handleCreateAddressTwo}><FontAwesome icon="plus"/>{messages.get("profile.account.user.createDetail")}</Button>
                    : <AddressModal address={addressTwo} buttonStyle="default"  hideName={!clubUser.hasClubAddressOnly()}
                                    businessMode={clubUser.hasClubAddressOnly()}
                                    handleAddressSaved={updatedAddress}/>
            }

        </Panel.Footer>
    </Panel>)
}
ClubUserAddressTwo.propTypes = {
    clubUser: PropTypes.object.isRequired,
    ownClubUser:PropTypes.object
};
module.exports = ClubUserDetailPage;
