const Parse = require("ParseInit");

const {Management, Parcours, ParcoursOwner, Club, ManagementRequest, BusinessPartner} = require("parse/_Domain");

const parcoursDao = require("actdao/ParcoursDao");

class ManagementDao {

    loadManagementAdmin(id) {
        return Parse.Cloud.run("loadFullManagement", {managementId: id});
    }

    get(id) {
        return new Parse.Query(Management)
            .include(Management.prototype.col.businessPartnerID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.addressID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.paymentTargetID)
            .include(Management.prototype.col.clubInvoiceIDs)
            .include(Management.prototype.col.managementRequestIDs)
            .get(id);
    }

    getAllManagementsForUser(user) {
        return new Parse.Query(Management)
            .include(Management.prototype.col.businessPartnerID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.addressID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.paymentTargetID)
            .include(Management.prototype.col.managementRequestIDs)
            .notEqualTo(Management.prototype.col.status, "D")
            .equalTo(Management.prototype.col.mainEditor, user)
            .find();
    }

    getForUser(user) {
        return new Parse.Query(Management)
            .include(Management.prototype.col.businessPartnerID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.addressID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.paymentTargetID)
            .include(Management.prototype.col.managementRequestIDs)
            .notEqualTo(Management.prototype.col.status, "D")
            .equalTo(Management.prototype.col.mainEditor, user)
            .limit(1)
            .first();
    }

    find() {
        return new Parse.Query(Management)
            .include(Management.prototype.col.businessPartnerID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.addressID)
            .include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.paymentTargetID)
            .include(Management.prototype.col.parcoursOwnerID)
            .include(Management.prototype.col.managementRequestIDs)
            .include(Management.prototype.col.managementRequestIDs + "." + ManagementRequest.prototype.col.parcoursOwnerID)
            .include(Management.prototype.col.managementRequestIDs + "." +  ManagementRequest.prototype.col.clubID)
            .include(Management.prototype.col.mainEditor)
            .include(Management.prototype.col.addEditor)
            .notEqualTo(Management.prototype.col.status, "D")
            .limit(1000)
            .addAscending(Management.prototype.col.status)
            .addDescending(Management.prototype.col.aboPrice)
            .find();
    }

    queryManagedParcours(management) {
        const pQuery = new Parse.Query(Parcours);
        pQuery.containedIn("parcoursOwnerID", management.getParcoursOwnerID());
        return pQuery.find();
    }

    queryManagedClubs(management) {
        const cQuery = new Parse.Query(Club);
        cQuery.equalTo(Club.prototype.col.managementID, management);
        return cQuery.find();
    }

    queryManagementsForEditor(user) {
        const mEQuery = new Parse.Query(Management);
        mEQuery.equalTo(Management.prototype.col.mainEditor, user);
        mEQuery.equalTo(Management.prototype.col.status, "A");


        const aEQuery = new Parse.Query(Management);
        aEQuery.equalTo(Management.prototype.col.addEditor, user);
        aEQuery.equalTo(Management.prototype.col.status, "A");

        const poQuery = Parse.Query.or(mEQuery, aEQuery);
        poQuery.include(Management.prototype.col.parcoursOwnerID);
        poQuery.include(Management.prototype.col.clubID);
        poQuery.ascending(Management.prototype.col.name);
        return poQuery.find()
    }

    activate(management) {
        return management
            .set({status: "A"})
            .save();
    }

    sendManagementAcceptMail(management) {
        return Parse.Cloud.run("sendManagementAcceptMail", {
            managementId: management.id,
        });
    }

    activateManagementRequest(management) {
        var newParcoursOwnerIDs = [];
        var newClubIDs = [];
        if (management.getManagementRequestIDs() != null) {
            var updatedManagementRequests = management.getManagementRequestIDs()
                .map(req => {
                    if (req.get("status") == "A") {
                        var parcoursOwner = req.getParcoursOwnerID();
                        var club = req.getClubID();
                        if (parcoursOwner != null) {
                            newParcoursOwnerIDs.push(parcoursOwner);
                        }
                        if (club != null) {
                            newClubIDs.push(club);
                        }
                        req.set("status", "C");
                    }
                    return req;
                });
            management.setManagementRequestIDs(updatedManagementRequests);
        }
        // update parcours owner array
        let existingParcoursOwnerIDs = management.getParcoursOwnerID() || [];

        let existingWithoutDuplicates = existingParcoursOwnerIDs.filter(epo =>
            'undefined' === typeof newParcoursOwnerIDs.find(upo => upo.id == epo.id
            )
        );
        let updatedParcoursOwnerIDs = newParcoursOwnerIDs.concat(existingWithoutDuplicates);
        management.setParcoursOwnerID(updatedParcoursOwnerIDs);

        // update club array
        var exitClubs = management.getClubIDs() || [];
        var exitClubsWODupl = exitClubs.filter(eCl =>
            'undefined' === typeof newClubIDs.find(uCl => uCl.id == eCl.id
            )
        );
        var updatedClubIDs = newClubIDs.concat(exitClubsWODupl);
        management.setClubIDs(updatedClubIDs);
        // save elements
        let promises = [];
        let saveParcoursPromises = newParcoursOwnerIDs.map(parcoursOwner => {
            return parcoursDao.getParcoursForParcoursOwner(parcoursOwner)
                .then(fParcours => {
                    for (let i = 0; i < fParcours.length; i++) {
                        fParcours[i].setEditor(management.getMainEditor());
                        if (management.hasParcoursFeature()) {
                            fParcours[i].setRankingStatus(Parcours.prototype.payStatus.enabled)
                        }
                    }
                    return Parse.Object.saveAll(fParcours);
                })
        });
        promises.push(saveParcoursPromises);
        updatedParcoursOwnerIDs.map(parcoursOwner => {
            if (!parcoursOwner.getSortIndex() > 0) {
                // init sortIndex
                parcoursOwner.setSortIndex(2000)
            }
            parcoursOwner.setManagementID(management);
            parcoursOwner.setEditor(management.getMainEditor());
            parcoursOwner.setManageStatus(ParcoursOwner.prototype.mgmtStatus.active);
        });
        let saveParcoursOwnerPromises = Parse.Object.saveAll(updatedParcoursOwnerIDs);
        promises.push(saveParcoursOwnerPromises);

        return Promise.all(promises)
            .then(_ => management.save());
    }

    deactivate(management) {
        let parcoursOwners = management.getParcoursOwnerID();
        let savePromise = new Promise((resolve, reject) => {
            if (parcoursOwners != null && parcoursOwners.length > 0) {
                // unset managementStatus for parcours
                for (let i = 0; i < parcoursOwners.length; i++) {
                    parcoursOwners[i].setManageStatus(ParcoursOwner.prototype.mgmtStatus.inactive)
                }
                Parse.Object.saveAll(parcoursOwners)
                    .then(sParcoursOwners => {
                        resolve()
                    })
                    .catch(error => {
                        console.log("Could not save parcoursOwners manage status ", error);
                        resolve()
                    })

            } else {
                resolve()
            }
        });
        return savePromise.then(_ => {
                management.setStatus("I");
                return management.save()
            });
    }

    delete(management) {
        management.setStatus("D");
        if (management.getBusinessPartnerID() != null) {
            management.getBusinessPartnerID().setStatus("D");
            if (management.getBusinessPartnerID().getPaymentTargetID() != null) {
                management.getBusinessPartnerID().getPaymentTargetID().setStatus("D")
            }
        }
        return management.save();
    }

    register(address, features) {
        return Parse.Cloud
            .run("registerForManagement", {
                address: address,
                features: features
            });
    }

    requestParcoursManagement(management, parcoursOwner) {
        return Parse.Cloud.run("requestParcoursManagement", {
            managementId: management.id,
            parcoursOwnerId: parcoursOwner.id
        });
    }

    requestClubManagement(management, club) {
        return Parse.Cloud.run("requestClubManagement", {
            managementId: management.id,
            clubID: club.id
        });
    }

    sendAboReminderMail(management) {
        return Parse.Cloud.run("sendAboRemindMail", {
            managementId: management.id,
        });
    }


    addClubInvoice(management, sInvoice) {
        return Parse.Cloud.run("addClubInvoice", {
            managementId: management.id,
            clubInvoiceId: sInvoice.id
        });
    }

    cancelManagementRequest(management, managementRequest) {
        return Parse.Cloud.run("cancelManagementRequest", {
            managementId: management.id,
            managementRequestId: managementRequest.id
        })
    }

}

module.exports = new ManagementDao();
