const alt = require("AltInit"),
    linkUtil = require("linkUtil");
const parseDao = require("actdao/ParseBaseDao");
const tournamentDao = require("actdao/TournamentDao");
const tournamentManDao = require("actdao/TournamentManagerDao");
const notificationActions = require("components/notification/NotificationActions");
const changeLogActions = require("actions/ChangeLogActions");
const tournamentUserActions = require("actions/TournamentUserActions");
const {Tournament, TournamentCupOption, TournamentConfig, TournamentPrice} = require("parse/_Domain");

class TournamentActions {

    // sets loading to true
    showProgress() {
        return {};
    };

    initTeaser(callback) {
        parseDao.getParseConfig()
            .then(config => {
                let maxDuration = config != null ? config.get("TournamentSearchMaxDuration") : 0
                tournamentDao.initTeaser(maxDuration)
                    .then(tournaments => {
                        const today = new Date();
                        let result = tournaments.filter(tournament => {
                            // remove which are from past
                            if (tournament.getDate() < today) {
                                if (tournament.isCanceled()) {
                                    // and canceled
                                    return false;
                                }
                                if (tournament.isRegistrationClosed() && tournament.isPublished()) {
                                    // but registration still closed and not started
                                    return false;
                                }
                            }
                            return true;
                        });
                        callback(result)
                    })
            })
            .catch(error => {
                callback([]);
                error.trace = "TOUA.initTeaser";
                notificationActions.parseError(error);
            });
        return {};
    };

    showDetails(id) {
        tournamentDao
            .loadTournamentDetails(id)
            .then(pTournamentDetail => {
                if (pTournamentDetail.isTypePairRegistration()) {
                    tournamentUserActions.loadTournamentUserTeams(pTournamentDetail);
                } else {
                    // standard
                    tournamentUserActions.loadTournamentUsers(pTournamentDetail);
                }
                if (pTournamentDetail.isTypeCupMaster()) {
                    // load child tournaments
                    this.loadTournamentCupChilds(pTournamentDetail);
                } else {
                    this.updateCupChildTournaments([]);
                }
                this.updateTournamentDetail(pTournamentDetail);
            })
            .catch(error => {
                error.trace = "TOUA.showDetails";
                notificationActions.parseError(error);
            });
        return {};
    }
    showCupDetails(buCode, year, licNumber) {
        const startDate = new Date(year, 0, 1, 0, 0 ,0, 0);
        // find latest master tournament
        tournamentDao.loadTournamentMasterCup(buCode, startDate)
            .then(cupMaster => {
                if (cupMaster != null) {
                    this.updateTournamentDetail(cupMaster);
                    // load child tournaments
                    tournamentManDao.loadTournamentCupChilds(cupMaster)
                        .then(tournaments => {
                            this.updateCupChildTournaments(tournaments);
                            let tournamentIds = [];
                            for (let i = 0; i < tournaments.length; i++) {
                                tournamentIds.push(tournaments[i].id);
                            }
                            if (licNumber != null) {
                                tournamentUserActions.loadCupTournamentUsers(buCode, licNumber, tournamentIds);
                            }
                        })
                        .catch(error => {
                            error.trace = "TOUA.loadCupChilds";
                            notificationActions.parseError(error);
                        });
                } else if (year > 2017) {
                    // try to load the year before - but not forever
                    this.showCupDetails(buCode, year -1, licNumber);
                }
            })
            .catch(error => {
                error.trace = "TOUA.showDetails";
                notificationActions.parseError(error);
            });
        return {};
    }
    showCheckoutDetails(id, callbackParcoursOnlineId) {
        tournamentDao
            .loadTournamentDetails(id)
            .then(pTournamentDetail => {
                if (callbackParcoursOnlineId != null && pTournamentDetail.getParcoursID() != null) {
                    callbackParcoursOnlineId(pTournamentDetail.getParcoursID().getOnlineID())
                }
                if (pTournamentDetail.isOnlinePaymentEnabled()) {
                    tournamentUserActions.loadTournamentUsersUnpaid(pTournamentDetail);
                }
                this.updateTournamentDetail(pTournamentDetail);
            })
            .catch(error => {
                error.trace = "TOUA.checkoutDetails";
                notificationActions.parseError(error);
            });
        return {};
    }
    searchTournaments(searchParams) {
        tournamentDao
            .searchTournaments(searchParams)
            .then(tournaments => {
                this.updateTournamentList(tournaments);
            })
            .catch(error => {
                error.trace = "TOUA.search";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchCupTournaments(searchParams) {
        const startDate = new Date((new Date().getFullYear()), 0, 1, 0, 0 ,0, 0);
        tournamentDao.loadBowUnion(searchParams.buCode)
            .then(bowUnion => {
                if (bowUnion != null) {
                    this.updateBowUnion(bowUnion)
                    tournamentDao.loadTournamentBuCodeParcoursList(searchParams, bowUnion, startDate)
                        .then(tournaments => {
                            this.updateTournamentList(tournaments);
                        })
                }
            })
            .catch(error => {
                error.trace = "TOUA.search";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadTournamentCupChilds(cupMaster) {
        tournamentManDao.loadTournamentCupChilds(cupMaster)
            .then(tTournaments => {
                this.updateCupChildTournaments(tTournaments);
            })
            .catch(error => {
                error.trace = "TOUA.loadCupChilds";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchTournamentList(params) {
        return params
    }


    updateTournamentDetail(pTournamentDetail) {
        return pTournamentDetail;
    }

    updateBowUnion(bowUnion) {
        return bowUnion
    }

    updateTournamentList(pTournamentList) {
        return pTournamentList;
    }

    updateDateFilter(dateFilter) {
        return dateFilter;
    };

    createTournament(tournament) {
        parseDao.saveObject(tournament)
            .then(sTournament => {
                window.location.href = linkUtil.getLink("tournament/" + sTournament.id);
            })
            .catch(error => {
                error.trace = "TOUA.createT";
                notificationActions.parseError(error);
            });
        return {};
    };

    async createTournamentCopy(nTournament, tournament, copySettings) {
        nTournament.setStatus(Tournament.prototype.status.published);
        nTournament.setRegistrationStatus(Tournament.prototype.registrationStatus.published);

        nTournament.setUnion(tournament.getUnion());
        nTournament.setUnionLink(tournament.getUnionLink())
        nTournament.setWeblink(tournament.getWeblink())

        nTournament.setMainGroupPlaceID(tournament.getMainGroupPlaceID());
        nTournament.setGroupPlaceID(tournament.getGroupPlaceID());
        nTournament.setZip(tournament.getZip());
        nTournament.setPlace(tournament.getPlace());
        // cup and more
        nTournament.setType(tournament.getType())
        // group settings
        nTournament.setShowTUGroups(tournament.isShowTUGroups())
        nTournament.setFixedTUInGroup(tournament.isFixedTUInGroup())
        nTournament.setSelectableTRoundsForGroup(tournament.isSelectableTRoundsForGroup())
        // basic settings
        nTournament.setRegMaxUser(tournament.getRegMaxUser());
        nTournament.setDescription(tournament.getDescription());
        // extended settings
        nTournament.setTournamentOptionID(tournament.getTournamentOptionID())
        nTournament.setRegistrationType(tournament.getRegistrationType())
        //parcours settings
        if (copySettings.cbParcours && tournament.getParcoursID()) {
            nTournament.setParcoursID(tournament.getParcoursID())
        }
        nTournament.setAllowManualPoints(tournament.getAllowManualPoints())
        nTournament.setChooseTUserGroup(tournament.getChooseTUserGroup());
        nTournament.setNoTUserGroups(tournament.getNoTUserGroups());

        if (tournament.getImageID()) {
            nTournament.setImageID(tournament.getImageID())
        }
        if (tournament.getBannerImageID()) {
            nTournament.setBannerImageID(tournament.getBannerImageID())
        }
        // management and editors
        if (tournament.getManagementID()) {
            nTournament.setManagementID(tournament.getManagementID())
        }
        if (tournament.getAddEditor()) {
            nTournament.setAddEditor(tournament.getAddEditor())
        }
        nTournament.setMainEditor(tournament.getMainEditor());
        // initial save
        nTournament = await parseDao.saveObject(nTournament)
        // external object handling
        // price settings
        let tPrice = tournament.getTournamentPriceID()
        if (tPrice) {
            // clone object
            let nPrice = new TournamentPrice();
            nPrice.cloneTPrice(tPrice, TournamentPrice.prototype.payStatus.A)
            nTournament.setTournamentPriceID(nPrice)
        }
        nTournament = await parseDao.saveObject(nTournament)


        const isCupChild = tournament.isTypeCupChild();
        // cup master
        if (!isCupChild) {
            if (tournament.getTournamentCupOptionID()) {
                let tCupOption = new TournamentCupOption();
                tCupOption.cloneTCupOption(tournament.getTournamentCupOptionID(), "A")
                nTournament.setTournamentCupOptionID(tCupOption)
            }
            nTournament.setCupGroup(tournament.getCupGroup())
            // bowUnion
            nTournament.setBowUnionID(tournament.getBowUnionID())
            // check empty type null for old tournaments
            if (nTournament.getType() == null) {
                nTournament.setType(tournament.getBowUnionID().isTypeCup() ? Tournament.prototype.buType.cup : Tournament.prototype.single)
            }
        } else if (copySettings.cbCupMaster) {
            // copy same cup master
            nTournament.setCupMasterTournamentID(tournament.getCupMasterTournamentID())
            nTournament.setTournamentCupOptionID(tournament.getTournamentCupOptionID());
            // bowUnion
            nTournament.setBowUnionID(tournament.getBowUnionID())
        }
        if (!isCupChild) {
            // tConfigAge
            let tConfigAges = tournament.getTournamentConfigAge();
            if (tConfigAges != null) {
                let nTConfigAges = [];
                tConfigAges.forEach(tConfigAge => {
                    let nTConfig = new TournamentConfig();
                    nTConfig.cloneTConfig(tConfigAge, TournamentConfig.prototype.status.A)
                    nTConfigAges.push(nTConfig)
                })
                nTournament.setTournamentConfigAge(nTConfigAges)
            }
            // tConfigBow
            let tConfigBows = tournament.getTournamentConfigBow();
            if (tConfigBows != null) {
                let nTConfigBows = [];
                tConfigBows.forEach(tConfigBow => {
                    let nTConfig = new TournamentConfig();
                    nTConfig.cloneTConfig(tConfigBow, TournamentConfig.prototype.status.A)
                    nTConfigBows.push(nTConfig)
                })
                nTournament.setTournamentConfigBow(nTConfigBows)
            }
        } else if (copySettings.cbCupMaster) {
            let tCupMaster = await tournamentDao.loadTournamentTConfigDetails(tournament.getCupMasterTournamentID().id)
            console.log("tCupMaster", tCupMaster)
            if (tCupMaster != null) {
                console.log("tCupMaster", tCupMaster , tCupMaster.getTournamentConfigBow(), tCupMaster.getTournamentConfigAge())
                nTournament.setTournamentConfigBow(tCupMaster.getTournamentConfigBow())
                nTournament.setTournamentConfigAge(tCupMaster.getTournamentConfigAge())
            }
        }

        nTournament = await parseDao.saveObject(nTournament)
        // TODO tRounds
        return nTournament
    }

    setMainEditor(tournament, user) {
        changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.mainEditor, null, user.get("username"))
        console.log("user " + user.get("username") + " gets mainEditor for " + tournament.get("name"));
        tournamentDao.setMainEditor(tournament, user)
            .then(result => {
                console.log("User set as mainEditor");
                this.showDetails(tournament.id);
            })
            .catch(error => {
                error.trace = "TOUA.mainEditor";
                notificationActions.parseError(error);
            });
        return {};
    };

    resetTournamentDetails() {
        return {};
    }

    updateListRenderAmount(amount) {
        return amount;
    }

    updateCupChildTournaments(childTournaments) {
        return childTournaments;
    };
}

module.exports = alt.createActions(TournamentActions);
