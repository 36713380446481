const alt = require("AltInit");
const dao = require("actdao/LanguageDao");
const notificationActions = require("components/notification/NotificationActions");
const parseDao = require("actdao/ParseBaseDao");
const FileDownloadUtility =  require("util/FileDownloadUtility");
const TranslationCl = require("parse/TranslationCl");
const tableActions = require("actions/TableActions");

class LanguageActions {
    switchLanguage (language) {
        return language;
    }

    loadTranslations(user, isAdmin) {
        this.isTranslatorLoggedIn(user, isAdmin, (params) => {
            if (params) {
                this.updateUserParams(params);
                dao.loadTranslations()
                    .then(translations => {
                        tableActions.updateObjectList(translations);
                    })
                    .catch(error => {
                        error.trace = "Lang.loadTransl";
                        notificationActions.parseError(error);
                    });
            } else {
                // no translator or admin user - show empty result
                tableActions.updateObjectList([]);
            }
        });
        return {};
    }

    isTranslatorLoggedIn(user, isAdmin, callbackDef) {
        const params = {};
        params.allowEnvChange = false;
        params.isAdmin = false;
        if (user && user.get("username")) {
            const translatorLang = user.get("trlLang");
            if (translatorLang != null && translatorLang.length > 0 && translatorLang[0] != "admin") {
                params.editLanguages = translatorLang;
                callbackDef(params);
            } else {
                const trlArr = user.get("username").split("_");
                if ("translator_ios" == user.get("username")) {
                    params.editLanguages = ["keyIOS"];
                    params.allowEnvChange = true;
                    callbackDef(params);
                } else if (trlArr.length == 2 && trlArr[0] == "translator") {
                    params.editLanguages = [trlArr[1]];
                    callbackDef(params);
                } else if (isAdmin) {
                    params.editLanguages = null;
                    params.allowEnvChange = true;
                    params.isAdmin = true;
                    callbackDef(params);
                } else {
                    callbackDef(null);
                }
            }
        } else {
            callbackDef(null);
        }
        return {};
    }

    createTranslationObj(translation) {
        parseDao.saveObject(translation)
            .then(sTranslation => {
                tableActions.addObject(sTranslation);
            })
            .catch(error => {
                error.trace = "Lang.createTransl";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTranslationObj(translation, lngCode, newTransl, successCallback) {
        translation.set(lngCode, newTransl);
        if (lngCode == "keyIOS") {
            translation.setForIos(true);
        }
        translation.addTranslationChanged(lngCode);
        parseDao.saveObject(translation)
            .then(sTranslation => {
                tableActions.updateObject(sTranslation);
                if (successCallback) {
                    successCallback();
                }
            })
            .catch(error => {
                error.trace = "Lang.saveTransl";
                notificationActions.parseError(error);
            });
        return {};
    }

    handleToggleEnvironment(envColumn, translation) {
        if (translation.get(envColumn)) {
            translation.set(envColumn, false);
        } else {
            translation.set(envColumn, true);
        }
        parseDao.saveObject(translation)
            .then(sTranslation => {
                tableActions.updateObject(sTranslation);
            })
            .catch(error => {
                error.trace = "Lang.toggleTransl";
                notificationActions.parseError(error);
            });
        return {};
    }

    buildTypeFiles(envColumn, supportedLanguages) {
        dao.loadTranslationsForEnvironment(envColumn)
            .then(translations => {
                if (translations && translations.length > 0) {
                    // search for all
                    console.log("found " + translations.length + " translation " + envColumn);
                    if (TranslationCl.prototype.col.forAndroid == envColumn) {
                        Object.keys(supportedLanguages).map((lngCode, i) => {
                            setTimeout(_=> {
                                this.generateAndroidXML(translations, lngCode);
                            }, i * 1000);
                        });
                    } else if (TranslationCl.prototype.col.forIos == envColumn) {
                        Object.keys(supportedLanguages).map((lngCode, i) => {
                            setTimeout(_=> {
                                this.generateIos(translations, lngCode);
                            }, i * 1000);
                        });
                    } else if (TranslationCl.prototype.col.forWeb == envColumn) {
                        Object.keys(supportedLanguages).map(lngCode => {
                            this.generateWeb(translations, lngCode);
                        });
                    } else if (TranslationCl.prototype.col.forReactN == envColumn) {
                        Object.keys(supportedLanguages).map(lngCode => {
                            this.generateWeb(translations, lngCode);
                        });
                    }
                } else {
                    console.log("found not translation " + envColumn);
                }
            })
            .catch(error => {
                error.trace = "Lang.loadTransEnv";
                notificationActions.parseError(error);
            });
        return {};
    }

    generateAndroidXML(translations, lngCode) {
        var file = [];
        file.push("<resources>");
        translations.map(translObj => {
            var translation = translObj.get(lngCode);
            if (translation == null || translation.length <= 0) {
                translation = translObj.getDefaultTranslation();
            }
            translation = translation.replace(/'/g , "\\'");
            file.push('<string name="' + translObj.getKey() + '">'+ translation + '</string>');
        });
        file.push("</resources>");
        let output = file.join('\n');
        console.log("Export file " + "strings_" + lngCode + ".xml");
        FileDownloadUtility.download(output, "strings_" + lngCode + ".xml" );
        return {};
    }
    generateIos(translations, language) {
        var file = [];
        translations.map(translObj => {
            var key = translObj.getKeyIOS();
            if (key == null || key.length <= 0) {
                key = translObj.getKey();
            }
            var translation = translObj.get(language);
            if (translation == null || translation.length <= 0) {
                translation = translObj.getDefaultTranslation();
            }
            // replace Android int placeholder to iOS
            translation = translation.replace(/%1\$d/g , "%d");
            translation = translation.replace(/%2\$d/g , "%d");
            translation = translation.replace(/%3\$d/g , "%d");
            // replace Android string placeholder to iOS
            translation = translation.replace(/%1\$s/g , "%@");
            translation = translation.replace(/%2\$s/g , "%@");
            translation = translation.replace(/%3\$s/g , "%@");
            file.push('"' + key + '" = "'+ translation + '";');
        });
        let output = file.join('\n');
        FileDownloadUtility.download(output, "Localizable_" + language + ".strings" );
        return {};
    }
    generateWeb(translations, language) {
        var file = [];
        file.push("module.exports = {");
        translations.map(translObj => {
            var translation = translObj.get(language);
            if (translation == null || translation.length <= 0) {
                translation = translObj.getDefaultTranslation();
            }
            file.push('"' + translObj.getKey() + '" : "'+ translation + '",');
        });
        file.push("};");
        let output = file.join('\n');
        FileDownloadUtility.download(output, language + ".js" );
        return {};
    }
    resetTranslationChangedArray(translations) {
        if (translations.length > 0) {
            for (var i = 0; i < translations.length; i++) {
                translations[i].unset(TranslationCl.prototype.col.translationChangedArray);
            }
            parseDao.saveAll(translations)
                .then(sTranslations => {
                    tableActions.updateObjectList(sTranslations);
                })
                .catch(error => {
                    error.trace = "Lang.resetTransChangArr";
                    notificationActions.parseError(error);
                })
        }
        return {}
    }
    updateUserParams(params) {
        return params;
    }
}

module.exports = alt.createActions(LanguageActions);
