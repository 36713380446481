const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Modal, Alert, Button,ButtonGroup} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    TextCenter = require("components/widgets/TextCenter"),
    ValidInput = require("components/form/ValidInput");

const messages = require("i18n/messages");

const tournamentActions = require("actions/TournamentManagerActions"),
    TournamentRound = require("parse/TournamentRound");

class TournamentRoundModal extends React.Component {
    constructor(props) {
        super(props);
        this.refName = React.createRef();
        this.refTargetAmount = React.createRef();
        this.refMemberAmount = React.createRef();
        
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setRoundType = this.setRoundType.bind(this);
        this.getDefaultName = this.getDefaultName.bind(this);
        this.getDefaultAmount = this.getDefaultAmount.bind(this);
        this.getDefaultMember = this.getDefaultMember.bind(this);

        let tType = null;
        if (this.props.tournamentRound) {
            tType = this.props.tournamentRound.getType();
        } else if (this.props.roundTypes.length == 1) {
            tType = this.props.roundTypes[0];
        }
        this.state = {
            showModal: false,
            submitDisabled: false,
            type: tType
        }
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error: null});
    }
    open() {
        this.setState({showModal: true});
    }
    valid() {
        let valid = true;
        let required = [
            this.refName.current.getValue(),
            this.refTargetAmount.current.getValue()
        ];
        if (this.props.showMemberAmount) {
            required.push( this.refMemberAmount.current.getValue())
        }
        if (this.state.type === null) {
            this.setState({error: messages.get("tournament.create.error.required")});
            valid = false;
        }
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let tRound = this.props.tournamentRound;
        if (tRound == null) {
            tRound = new TournamentRound();
            tRound.setStatus(TournamentRound.prototype.status.present);
            tRound.setTournamentID(this.props.tournament);
            if (this.props.index != null) {
                if (this.state.type == TournamentRound.prototype.type.simple || this.state.type == TournamentRound.prototype.type.bestOf) {
                    tRound.setSortIndex((100 + 10 * this.props.index));
                } else if (this.state.type == TournamentRound.prototype.type.final_add || this.state.type == TournamentRound.prototype.type.final_zero) {
                    tRound.setSortIndex((200 + 10 * this.props.index));
                } else {
                    tRound.setSortIndex((300 + 10 * this.props.index));
                }
            }
        }
        tRound.setType(this.state.type);
        tRound.setName(this.refName.current.getValue());
        tRound.setTargetAmounts(parseInt(this.refTargetAmount.current.getValue()));
        if (this.props.showMemberAmount) {
            tRound.setMemberAmount(parseInt(this.refMemberAmount.current.getValue()))
        }
        tournamentActions.saveTournamentRound(tRound);
        this.close();
    }
    setRoundType(roundType) {
        if (this.refName.current.getValue().length <= 0) {
            this.refName.current.setValue(messages.get("tournament.round.type." + roundType + ".defRoundName"));
        }
        if (roundType == TournamentRound.prototype.type.ranking_group) {
            this.refTargetAmount.current.setValue(0);
        }
        this.setState({
            type: roundType
        });

    }
    getDefaultName() {
        if (this.props.tournamentRound != null) {
            return this.props.tournamentRound.getName();
        }
    }
    getDefaultAmount() {
        if (this.props.tournament && this.props.tournament.getParcoursID()) {
            return this.props.tournament.getParcoursID().getTargetAmounts();
        } else if (this.props.tournamentRound != null) {
            return this.props.tournamentRound.getTargetAmounts();
        }
        return "0";
    }
    getDefaultMember() {
        if (this.props.tournamentRound != null) {
            return this.props.tournamentRound.getMemberAmount();
        }
        return "2";
    }
    render() {
        const {roundTypes, showMemberAmount} = this.props;
        const {type, submitDisabled} = this.state;
        const validateNow = this.state.error && this.state.error.length > 0;
        const that = this;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="tournament.manager.round.modal.title"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        <Row>
                            {
                                this.state.showModal ?
                                    <Col sm={12}>
                                        <p>{messages.get("tournament.manager.round.modal.desc")}</p>
                                        <br/>
                                        <ButtonGroup>
                                            {
                                                roundTypes.map(roundType => {
                                                    return(
                                                        <Button key={roundType}
                                                                onClick={function() {that.setRoundType(roundType)}}
                                                                bsStyle={type == roundType ? "success" : "default"}
                                                                active={type == roundType}>
                                                            {messages.get("tournament.round.type." + roundType)}
                                                    </Button>)
                                                })
                                            }
                                        </ButtonGroup>
                                        <br/><br/>
                                        <ValidInput ref={this.refName}
                                                    valid={{maxLength: 20, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultName()}
                                                    label={messages.get("tournament.table.column.name")}/>
                                        <ValidInput ref={this.refTargetAmount}
                                                    valid={{maxLength: 3, check:['isRequired', 'isNumber']}}
                                                    disabled={type === TournamentRound.prototype.type.ranking_group || type === TournamentRound.prototype.type.inklusion}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultAmount()}
                                                    label={messages.get("parcours.details.area.targetamount")}/>
                                        {
                                            showMemberAmount ?
                                                <ValidInput ref={this.refMemberAmount}
                                                     valid={{maxLength: 3, check:['isRequired', 'isNumber']}}
                                                     validateNow={validateNow}
                                                     default={this.getDefaultMember()}
                                                     label={messages.get("tournament.table.column.memberamount")}/> : null
                                        }



                                        <Alert bsStyle="info">{messages.get("tournament.manager.round.modal.alert")}</Alert>
                                    </Col> : null
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        <Button type="submit" disabled={submitDisabled} bsStyle="primary">{messages.get("modal.button.save")}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
TournamentRoundModal.propTypes = {
    index:PropTypes.number,
    tournament:PropTypes.object,
    tournamentRound:PropTypes.object,
    roundTypes:PropTypes.array.isRequired,
    showMemberAmount: PropTypes.bool.isRequired,
};
module.exports = TournamentRoundModal;
