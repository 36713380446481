const Parse = require("ParseInit");
const messages = require("i18n/messages");

const CountType = Parse.Object.extend("countType", {
    col: {//TADpWTROeb
        ACL: "ACL",
        arrowSumUp: "arrowSumUp",
        countTypeDtls: "countTypeDtls",
        createdAt: "createdAt",
        maxTargetPoints: "maxTargetPoints",
        missPoints: "missPoints",
        missColors: "misscolors",
        name: "name",
        description: "description",
        objectId: "objectId",
        onlineID: "onlineID",
        scorepic: "scorepic",
        status: "status",
        creator: "creator",
        typeID: "typeID",
        updatedAt: "updatedAt",
    },
    status: {
        active: "A",
        draft: "R",
        deleted: "D"
    },
    typeIDs : {
        system: 0,
        private: 1,
        disc: 2,
        fiarc: 3,
        custom: 4
    },
    buildCountType(user, name, onlineID, description, arrowSumUp, missPoints, maxTargetPoints, typeID) {
        const obj = new CountType();
        obj.setName(name);
        obj.setOnlineID(onlineID);
        if (description && description.length > 0) {
            obj.setDescription(description);
        }
        obj.setStatus(this.status.draft);
        obj.setArrowSumUp(arrowSumUp);
        obj.setMissPoints(missPoints);
        obj.setMaxTargetPoints(maxTargetPoints);
        obj.setCreator(user);
        obj.setTypeID(typeID);
        return obj;
    },
    equalsCountTypeDetailsID(countTypeDetails) {
        const dtls = this.getCountTypeDtls();
        const tlength = dtls.length;
        const plength = countTypeDetails.length;
        if (tlength === plength) {
            for (let i = 0; i < tlength; i++) {
                if (dtls[i].id != countTypeDetails[i].id) {
                    return false;
                }
            }
            // same countTypeDetails
            return true;
        }
        return false;
    },
    getZonePoints(arrowNr) {
        const dtl = this.getCountTypeDtls();
        if (dtl) {
            let res = [];
            dtl.forEach(function(countTypeDtl) {
                if (countTypeDtl.getArrowNr() == arrowNr) {
                    res.push(messages.get(countTypeDtl.getZoneName()) + ": " + countTypeDtl.getPoints());
                }
            });
            return res.join(", ");
        }
        return null;
    },
    getZonePointsArray(arrowNr) {
        let res = [];
        const dtl = this.getCountTypeDtls();
        if (dtl) {
            dtl.forEach(function(countTypeDtl) {
                if (countTypeDtl.getArrowNr() == arrowNr) {
                    res.push(countTypeDtl.getPoints());
                }
            });
        }
        return res;
    },
    calcPoints(killCounts) {
        let points = 0;
        if (killCounts != null) {
            Object.keys(killCounts).map(key => {
                let count = killCounts[key] ? killCounts[key] : 0;
                const tArrow = parseInt(key / 1000);
                const tZone = key % 1000;
                const dtlPoits =  this.getDtlPoints(tArrow, tZone);
                points += dtlPoits * count;
                // console.log(key + " to " + tArrow + "/" +tZone +  "  -  " + dtlPoits);
            })
        }
        return points;
    },
    getArrowAmount() {
        let amount = 0;
        let lastArrowNr = -1;
        this.getCountTypeDtls().map(countTypeDtl => {
            const tArrow = countTypeDtl.get("arrowNr");
            if (tArrow != lastArrowNr) {
                amount += 1;
                lastArrowNr = tArrow;
            }
        });
        return amount;
    },
    calcHits(killCounts) {
        let hits = 0;
        if (killCounts != null) {
            Object.keys(killCounts).map(key => {
                if (key % 1000 !== 0) {
                    let count = killCounts[key] ? killCounts[key] : 0;
                    hits += count;
                }
            })
        }
        return hits;
    },
    calcKillValue(killCountsDic) {
        const hitZoneMatrix =  [ 100000, 10000, 1000, 100, 10, 1 ];
        const arrowSumUp = this.getArrowSumUp();
        let killValue = 0;
        this.getCountTypeDtls().map(countTypeDtl => {
            const tArrow = countTypeDtl.get("arrowNr");
            const tZone = countTypeDtl.get("killNr");
            const key = tArrow * 1000 + tZone;
            const value = killCountsDic[key];
            if (value != null && value > 0) {
                if (arrowSumUp) {
                    killValue += hitZoneMatrix[tZone - 1] * value;
                } else {
                    // only first arrow counts
                    if (tArrow == 1) {
                        killValue += hitZoneMatrix[tZone - 1] * value;
                    }
                }
            }
        });
        return killValue;
    },
    buildKillCountStringFromDic(killCountsDic, missValue) {
        let killCountsstr = "";
        if (this.getArrowSumUp()) {
            killCountsstr = "t,";
        } else {
            killCountsstr = "f,";
        }
        killCountsDic["4000"] = missValue;
        Object.keys(killCountsDic).map(key => {
            const value = killCountsDic[key];
            if (value > 0) {
                killCountsstr += (key +":" + value + ",");
            }
        });
        return killCountsstr.slice(0,-1);
    },
    getDtlPoints(arrowNr, zone) {
        const dtl = this.getCountTypeDtls();
        if (dtl) {
            for (let i = 0; i < dtl.length; i++) {
                if (dtl[i].getArrowNr() == arrowNr && dtl[i].getKillNr() == zone) {
                    return dtl[i].getPoints();
                }
            }
        }
        return 0;
    },
    getScorepicUrl() {
        const pic = this.getScorepic();
        if (pic) {
            return pic.url();
        }
        return null;
    },
    isDraft() {
        return this.getStatus() === this.status.draft
    },
    isDeleted() {
        return this.getStatus() === this.status.deleted
    },
    getSelectString() {
        return this.getName()
    },
    getLabels() {
        const arrow1 = this.getZonePointsArray(1);
        const draft = this.isDraft() ? "DRAFT" : "";
        return [this.getName(), this.getOnlineID(), this.getDescription(), arrow1.join(",") + "#", draft].join(" ");
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getArrowSumUp() {
        return this.get(this.col.arrowSumUp)
    },
    setArrowSumUp(arrowSumUp) {
        return this.set(this.col.arrowSumUp, arrowSumUp)
    },
    getCountTypeDtls() {
        return this.get(this.col.countTypeDtls)
    },
    setCountTypeDtls(countTypeDtls) {
        return this.set(this.col.countTypeDtls, countTypeDtls)
    },
    getCreator() {
        return this.get(this.col.creator)
    },
    setCreator(creator) {
        return this.set(this.col.creator, creator)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getMaxTargetPoints() {
        return this.get(this.col.maxTargetPoints)
    },
    setMaxTargetPoints(maxTargetPoints) {
        return this.set(this.col.maxTargetPoints, maxTargetPoints)
    },
    getMissPoints() {
        return this.get(this.col.missPoints)
    },
    setMissPoints(missPoints) {
        return this.set(this.col.missPoints, missPoints)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getOnlineID() {
        return this.get(this.col.onlineID)
    },
    setOnlineID(onlineID) {
        return this.set(this.col.onlineID, onlineID)
    },
    getScorepic() {
        return this.get(this.col.scorepic)
    },
    setScorepic(scorepic) {
        return this.set(this.col.scorepic, scorepic)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getTypeID() {
        return this.get(this.col.typeID)
    },
    setTypeID(typeID) {
        return this.set(this.col.typeID, typeID)
    },
    isTypeDisc() {
        return this.typeIDs.disc === this.getTypeID();
    },
    isTypeWADisc() {
        return this.getOnlineID() === "D6Z_6_t"
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = CountType;
