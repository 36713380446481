const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Button, Table, Label} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    PaymentElements = require("components/payment/PaymentElements"),
    RowNoUserWarning = require("components/widgets/RowNoUserWarning"),
    ImageBanner = require("components/widgets/ImageBanner"),
    LoginButton = require("components/widgets/LoginButton"),
    Loading = require("components/widgets/Loading");

const messages = require("i18n/messages");

const notificationActions = require("components/notification/NotificationActions"),
    parcoursActions = require("actions/ParcoursActions"),
    parcoursStore = require("stores/ParcoursStore");

const paymentElementsStore = require("stores/PaymentElementsStore"),
    paymentElementsActions = require("actions/PaymentElementsActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ParcoursCheckoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.handlePayEleStoreChange = this.handlePayEleStoreChange.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.state = {
            parcoursStore: parcoursStore.getState(),
            paymentElementsStore: paymentElementsStore.getState()
        }
    }
    componentDidMount() {
        parcoursStore.listen(this.handleParcoursChange);
        paymentElementsStore.listen(this.handlePayEleStoreChange);
        notificationActions.reset();
        paymentElementsActions.initBasket(this.props.onlineID);
        parcoursActions.queryParcoursCheckout(this.props.onlineID);
    }
    componentWillUnmount() {
        parcoursStore.unlisten(this.handleParcoursChange);
        paymentElementsStore.unlisten(this.handlePayEleStoreChange);
    }
    handlePayEleStoreChange(state) {
        this.setState({paymentElementsStore: state});
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
        if (state.parcours != null) {
            document.title = state.parcours.buildNames();
        }

    }
    render() {
        const {parcoursStore, paymentElementsStore} = this.state;
        if (parcoursStore.loading === true) {
            return (
                <Grid>
                    <Loading/>
                </Grid>)
        }
        if (parcoursStore.parcours == null && parcoursStore.parseError == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>
            )
        }
        return (
            <Grid>
                <ParcoursDetails parcours={parcoursStore.parcours}/>

                <RowNoUserWarning>
                    <Row>
                        <Col xs={12} md={8} mdOffset={2}>
                            <Alert bsStyle="info">
                                <p><strong><I18n code="checkout.info.asklogin"/></strong><br/>
                                    <I18n code="checkout.info.noLogin"/></p>
                                <LoginButton/>
                            </Alert>
                        </Col>
                    </Row>

                </RowNoUserWarning>
                <PaymentElements parcours={parcoursStore.parcours}
                                 articleElement={
                                    <ParcoursArticleTable parcoursArticles={parcoursStore.parcoursArticles.filter(article => article.isActive())}
                                                          sum={paymentElementsStore.sum}
                                                          articles={paymentElementsStore.articles}/>
                                 }
                                 ruleImageID={parcoursStore.parcours ? parcoursStore.parcours.getParcoursOwnerID().getRulesImage() : null}/>

            </Grid>)
    }
}
ParcoursCheckoutPage.propTypes = {
    onlineID: PropTypes.string.isRequired
};
const ParcoursDetails = ({parcours}) => {
        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            <Breadcrumb link={"/parcours/" + parcours.getOnlineID() } code={parcours.buildNames()}/>
                            <Breadcrumb code="parcours.checkout.title" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <ImageBanner imageObject={parcours.getParcoursOwnerID().getBannerImageID()}/>
                </Row>
                <Row>
                    <Col lg={12}  style={{"textAlign": "center"}}>
                        <h2>{messages.get("parcours.checkout.title")}</h2>
                        <p><strong>{parcours.buildNames()}</strong><br/>
                            {parcours.getParcoursOwnerID().getStreet()}<br/>
                            {parcours.getZipPlace()}
                        </p>
                    </Col>
                </Row>
            </React.Fragment>)
};
ParcoursDetails.propTypes = {
    parcours:PropTypes.object.isRequired
};
const ParcoursArticleTable = ({parcoursArticles, sum, articles}) => {
    return (
        <Col xs={12} md={8} mdOffset={2}>
            <h2><FontAwesome icon="shopping-cart"/><I18n code="checkout.info.parcours.cart"/></h2>
            {
                parcoursArticles.length > 0 ?
                    <Table striped responsive>
                        <thead>
                        <tr>
                            <th className="col-xs-6 col-md-6"><I18n code="tournament.table.column.article"/></th>
                            <th className="col-xs-3 col-md-3"><I18n code="parcours.articles.form.price"/></th>
                            <th className="col-xs-3 col-md-3"><I18n code="tournament.table.column.amount"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            parcoursArticles.map(article => {
                                let amount = articles[article.id];
                                return <ParcoursArticle key={article.id} article={article} amount={amount ? amount : 0}/>
                            })
                        }
                        <tr>
                            <td></td>
                            <td><I18n code="tournament.table.column.sum"/></td>
                            <td>{sum.toFixed(2) + " EUR"}</td>
                        </tr>
                        </tbody>
                    </Table>
                    :
                    <Alert bsStyle="warning"><I18n code="checkout.info.parcours.cart.empty"/></Alert>
            }

        </Col>)
};
ParcoursArticleTable.propTypes = {
    parcoursArticles: PropTypes.array.isRequired,
    sum: PropTypes.number.isRequired,
    articles: PropTypes.object.isRequired
};
const ParcoursArticle = ({article, amount}) => {
    const onButtonDecr = () => {
        if (amount > 0) {
            paymentElementsActions.updateTransactionArticle(article.id, amount - 1);
            paymentElementsActions.updateSum(article.getPrice()  * (-1));
        }
    };
    const onButtonIncr = () => {
        paymentElementsActions.updateTransactionArticle(article.id, amount + 1);
        paymentElementsActions.updateSum(article.getPrice());
    };
    return (
        <tr>
            <td>{article.getName()}</td>
            <td>{article.getPrice().toFixed(2) + ' ' + article.getCurrency()}</td>
            <td>
                <div style={{"display":"inline-block", "fontSize": "25px"}}>
                    <Button bsStyle="primary" onClick={onButtonDecr}>
                        <FontAwesome icon="minus"/>
                    </Button>
                    <Label bsStyle="default">{amount}</Label>
                    <Button bsStyle="primary"onClick={onButtonIncr}>
                        <FontAwesome icon="plus"/>
                    </Button>
                </div>
            </td>
        </tr>
    )
};
ParcoursArticle.propTypes = {
    article: PropTypes.object.isRequired,
    amount: PropTypes.number.isRequired
};
module.exports = ParcoursCheckoutPage;
