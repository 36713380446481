const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col} = require("react-bootstrap");
const TournamentPrivate = require("components/tournament/TournamentPrivate"),
    FontAwesome = require("components/widgets/FontAwesome");

const TournamentInfoHeader = ({tournament}) => {
    if (tournament.isTypeCupMaster()) {
        return <Row>
            <Col sm={8} xs={12}>
                <h3>{tournament.getUnion()}</h3>
                <h4><FontAwesome icon="medal"/>{tournament.getTournamentDate()}</h4>
            </Col>
        </Row>
    }
    return <Row>
        <Col sm={8} xs={12}>
            <h3>{tournament.getUnion()}</h3>
            <h4 className="hidden-sm hidden-md hidden-lg"><FontAwesome icon="calendar-alt"/>{tournament.getTournamentDate()}</h4>
            <h4>
                <FontAwesome icon="home"/>{tournament.getZipPlace()}&nbsp;&nbsp;
                <TournamentPrivate tournament={tournament} />
            </h4>
        </Col>
        <Col sm={4} xs={12}>
             <h3 className="hidden-xs">&nbsp;</h3>
            <h4 className="hidden-xs"><FontAwesome icon="calendar-alt"/>{tournament.getTournamentDate()}</h4>
        </Col>
    </Row>
};
TournamentInfoHeader.propTypes = {
    tournament:PropTypes.object.isRequired
};
module.exports = TournamentInfoHeader;