const alt = require("AltInit");
const User = require("parse/User");
const tournamentUserDao = require("actdao/TournamentUserDao");
const tournamentManagerDao = require("actdao/TournamentManagerDao");
const userDao = require("actdao/UserDao");
const parseDao = require("actdao/ParseBaseDao");
const notificationActions = require("components/notification/NotificationActions");
const tournamentUserActions = require("actions/TournamentUserActions");
const userActions = require("actions/UserActions");
const changeLogActions = require("actions/ChangeLogActions");
const {TournamentUser, UserDetail} = require("parse/_Domain");

class TournamentRegisterModalActions {
    openDialogSelfRegister(user, tournament) {
        this.initTournament(tournament);
        // function call into parse user class didn't worked after using revocable sessions
        if (user != null && user.isRealUser()) {
            this.openDialogForAccount(user);
        } else {
            this.openDialogReadOnly()
        }
        return {};
    };
    openDialogPairRegister(user, tournament) {
        this.initTournament(tournament);
        // function call into parse user class didn't worked after using revocable sessions
        if (user != null && user.isRealUser()) {
            this.loadTournamentUsersFromEditor(user);
            this.openDialogForAccount(user);
        } else {
            this.openDialogReadOnly()
        }
        return {};
    };
    openDialogForeignRegister(user, tournament) {
        this.initTournament(tournament);
        this.updateUserDetail(null);
        // function call into parse user class didn't worked after using revocable sessions
        if (user != null && user.isRealUser()) {
            // query for all register of this user
            this.loadTournamentUsersFromEditor(user);
        }
        return {};
    };
    getNextBowUnionNumber(BUCode) {
        tournamentManagerDao.getNextBowUnionNumber(BUCode)
            .then(buNumber => {
                this.updateUserLicenseNumber(buNumber.toString());
            })
            .catch(error => {
                error.trace = "TRMA.loadBUNumber";
                notificationActions.parseError(error);
            });
        return {};
    }
    loadTournamentRegSlots(tournament) {
        tournamentManagerDao.loadTournamentRegSlots(tournament)
            .then(tRegSlots => {
                this.updateTournamentSlots(tRegSlots);
            }).catch(error => {
            error.trace = "TRMA.loadSlots"
            notificationActions.parseError(error);
        });
        return {};

    }
    startStepSelfRegistration(user, tournament) {
        this.initUser(user);
        this.initTournament(tournament);
        this.fetchUserDetail(user);
        return {};
    }
    startStepForeignRegistration(user, tournament) {
        if (user != null && user.isRealUser()) {
            // query for all register of this user
            this.loadTournamentUsersFromEditor(user);
        }
        if (tournament.getBowUnionID() && tournament.getBowUnionID().isAutoLicenseNumber()) {
            this.updateUserDetailLoading(true);
            this.getNextBowUnionNumber(tournament.getBowUnionID().getCode());
        }
        return {};
    }
    loadTournamentUsersFromEditor(user) {
        tournamentUserDao.loadTournamentUsersFromEditor(user)
            .then(tournamentUsers =>{
                var foundMails = [];
                var tUsersSearchResult = [];
                tournamentUsers.map(tUser => {
                    var email = tUser.get(TournamentUser.prototype.col.playerEmail).trim().toLocaleLowerCase();
                    if (email != null && email.length > 0) {
                        if (foundMails.indexOf(email) == -1) {
                            // new user
                            foundMails.push(email);
                            tUsersSearchResult.push(tUser);
                        }
                    }
                });
                console.log(tournamentUsers.length + " reduced to size " + tUsersSearchResult.length);
                if (tUsersSearchResult.length > 0) {
                    this.updateRegisteredTournamentUsers(tUsersSearchResult);
                }
            })
            .catch(error => {
                error.trace = "TRMA.loadTUofEditor";
                notificationActions.parseError(error);
            });
        return {};
    }
    openDialogForAccount(user) {
        // only called from dialog - not from steps
        this.fetchUserDetail(user);
        return {};
    };

    fetchUserDetail(user) {
        if (user && user.get("userDetailID")) {
            userDao.fetchUserDetail(user)
                .then(fUserDetail => {
                    this.updateUserDetail(fUserDetail);
                    this.updateLoading(false);
                })
                .catch(error => {
                    error.trace = "TRMA.fetchUserDetail";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateUserDetail(null);
            this.updateLoading(false);
        }
        return {};
    }
    handleTournamentUserAfterRegistration(userBowUnionLicObject) {
        tournamentUserDao.handleUserDetailTournamentLic(userBowUnionLicObject)
            .then(sUser => {
                if (sUser != null) {
                    userActions.updateUser(sUser);
                    console.log("User saved with detail and license");
                }
            })
            .catch(error => {
                error.trace = "TRMA.handleUserDetailLic";
                notificationActions.parseError(error);
            });
        return;
    }

    handleTournamentUserRegistration(tournament, regTUserObject, errorCallback) {
        tournamentUserDao.registerTournamentUser(regTUserObject)
            .then(tournamentUser => {
                console.log("created " + tournamentUser.id + " name:" + tournamentUser.get("name"));
                this.updateUserLicenseNumber(null);
                this.updateCupTournamenUser(null);
                this.updateUseCupChildRegistration(tournament.isTypeCupChild());
                tournamentUserActions.sentRegistrationConfirmEmail(tournament, tournamentUser, regTUserObject.selfRegistration);
                // show in registration list
                tournamentUserActions.addTournamentUser(tournamentUser);
                // add to registration array of this batch
                this.addTournamentUser(tournamentUser);
            })
            .catch(error => {
                error.trace = "TRMA.saveTURegistrate";
                if (errorCallback) {
                    errorCallback(error)
                } else {
                    notificationActions.parseError(error);
                }
            });
        return {};
    }

    saveTournamentUser(tournamentUser, tuCallback) {
        parseDao.saveObject(tournamentUser)
            .then(pTournamentUser => {
                this.udpateTournamentUser(pTournamentUser);
                tournamentUserActions.updateTournamentUser(tournamentUser);
                if (tuCallback) {
                    tuCallback();
                }
            })
            .catch(error => {
                if (tuCallback) {
                    tuCallback(error);
                }
                error.trace = "TUSA.saveTU";
                notificationActions.parseError(error);
            });
        return {};
    }

    deRegisterFromTournament(tournament, tournamentUser, user) {
        const oldValue = tournamentUser.getStatus();
        tournamentUser.set("status", "D");
        if (tournamentUser.getTournamentRegSlotID() != null && tournamentUser.getTournamentRegSlotID().isDataAvailable()) {
            tournamentUser.getTournamentRegSlotID().decrAmount();
        }
        parseDao.saveObject(tournamentUser)
            .then(pTournamentUser => {
                this.updateCupTournamenUser(null);
                this.updateUseCupChildRegistration(tournament.isTypeCupChild());
                if (user) {
                    tournamentUserActions.sentDeRegistrationEmail(tournament, pTournamentUser, user);
                    changeLogActions.addTournamentUserDeleteCL(tournament, pTournamentUser, user, oldValue);
                }
                tournamentUserActions.removeTournamentUser(tournamentUser);
                // remove from registration array of this batch
                this.removeTournamentUser(tournamentUser);
            })
            .catch(error => {
                error.trace = "TUSA.deregisterRU";
                notificationActions.parseError(error);
            });
        return {};
    };

    validateTUserEmail(tournament, mail, callback) {
        tournamentUserDao.validateTournamentUserEmail(tournament, mail)
            .then(count => {
                if (count > 0) {
                    // already player with this email for this tournament!
                    callback({valid: false, error: null});
                } else {
                    // horray email not used yet
                    callback({valid: true, error: null});
                }
            })
            .catch(error => {
                error.trace = "TRMA.validateMail";
                callback({valid: false, error: error});
            });
        return {};
    }
    validateTUserLicense(tournament, licenseNumber, callback) {
        tournamentUserDao.validateTournamentUserLicense(tournament, licenseNumber)
            .then(count => {
                if (count > 0) {
                    // already player with this license for this tournament!
                    callback({valid: false, error: null});
                } else {
                    // horray email not used yet
                    callback({valid: true, error: null});
                }
            })
            .catch(error => {
                error.trace = "TRMA.validateLicense";
                callback({valid: false, error: error});
            });
        return {};
    }

    queryCupTournamentUser(licenseNumber, bowUnion, callback, errorCallback) {
        tournamentUserDao.queryForCupTournamentUser(licenseNumber, null, bowUnion.getCode(), false)
            .then(tournamentUsers => {
                let searchRes = [];
                let foundLic = [];
                tournamentUsers.map(tUser => {
                    let lic = tUser.getLicenseNumber().trim();
                    if (lic != null && lic.length > 0) {
                        if (foundLic.indexOf(lic) == -1) {
                            // new user
                            foundLic.push(lic);
                            searchRes.push(tUser);
                        }
                    }
                });
                callback(searchRes)
            })
            .catch(error => {
                error.trace = "TRMA.searchCupTUser";
                errorCallback(error);
            });
        return {};
    }

    filterOwnRegistrations(allTournamentUsers) {
        return allTournamentUsers;
    }

    showOverview() {
        this.updateStep(2);
        return {};
    }

    updateStep(step) {
        return step;
    }

    updateLoading(loading) {
        return loading;
    }

    updateUserDetailLoading(loading) {
        return loading;
    }

    udpateTournamentUser(tournamentUser) {
        return tournamentUser
    }

    addTournamentUser(tournamentUser) {
        return tournamentUser;
    }

    removeTournamentUser(tournamentUser) {
        return tournamentUser;
    }

    updateUserDetail(userDetail) {
        return userDetail;
    };
    initTournament(pTournament) {
       return pTournament;
    }
    initUser(user) {
        return user;
    }

    updateCupTournamenUser(cupTournamentUser) {
        return cupTournamentUser;
    }

    startNoCupNumber(bowUnion) {
        this.resetUserLicenseNumber();
        if (bowUnion != null && bowUnion.isAutoLicenseNumber()) {
            //nUserLoading = true; // loading need to be set by bow union number
            this.getNextBowUnionNumber.defer(bowUnion.getCode())
        }
        this.updateUseCupChildRegistration(false)
    }

    updateUseCupChildRegistration(use) {
        return use;
    }

    openDialogReadOnly() {
        this.updateUserDetail(null);
        return {};
    };
    closeDialog() {
        return {};
    };
    updateRegisteredTournamentUsers(tournamentUsers){
        return tournamentUsers;
    };
    resetUserLicenseNumber() {
        return {}
    };
    updateUserLicenseNumber(licenseNumber) {
        return licenseNumber;
    }
    updateSelectedConfigAge(configAge) {
        return configAge;
    }

    updateSelectedOption(option) {
        return option
    }

    updateSelectedRegSlot(regSlotId) {
        return regSlotId;
    }

    updateSelectedConfigBow(configBow) {
        return configBow;
    }

    updateSelectedConfigBow2nd(configBow) {
        return configBow;
    }
    updateTournamentSlots(tSlots) {
        return tSlots;
    }
}

module.exports = alt.createActions(TournamentRegisterModalActions);
