const React = require("react"),
    PropTypes = require('prop-types'),
    I18n = require("components/widgets/I18n");
const {Label} = require("react-bootstrap");
function TournamentPrivate ({tournament}) {
    if (tournament.getLinkSecret() != null) {
        return <Label><I18n code="tournament.register.private.label"/></Label>
    }
    return null
}
TournamentPrivate.propTypes = {
    tournament:PropTypes.object.isRequired
};
module.exports = TournamentPrivate;
