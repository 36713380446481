const alt = require("AltInit");
const notificationActions = require("components/notification/NotificationActions");
const messageDao = require("actdao/MessageDao");

class MessageActions {

    sentEmail(subject, sender, receiver, bcc, htmlText, callback) {
        messageDao.sendMail(subject, sender, receiver, bcc, htmlText, false)
            .then(info => {
                console.log("Mail Success: ", info);
                if (callback) {
                    callback(true);
                }
            })
            .catch(error => {
                error.trace = "MSGA.sendMail";
                console.log("Mail Error: ", error);
                notificationActions.warning("Mail error: " + error.message);
                if (callback) {
                    callback(false);
                }
            });
        return {};
    }

    sentMassEmail(subject, sender, receiver, htmlText, callback) {
        messageDao.sendMail(subject, sender, sender, receiver, htmlText, true)
            .then(info => {
                console.log("Mail Success: ", info);
                if (callback) {
                    callback(true);
                }
            })
            .catch(error => {
                error.trace = "MSGA.sendMail";
                console.log("Mail Error: ", error);
                notificationActions.warning("Mass mail error: " + error.message);
                if (callback) {
                    callback(false);
                }
            });
        return {};
    }

    sentPushMessageToClub(clubID,mailsArray, title, message,link, then) {
        messageDao.pushMessageToClub(clubID, mailsArray, title, message, link)
            .then(result => {
                if (then) {
                    then(result);
                }
            })
            .catch(error => {
                error.trace = "MSGA.pushNotifyClub";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadMailLogs(sender, management, then) {
        messageDao.loadMailCClogs(sender, management)
            .then(result => {
                if (then) {
                    then(result);
                }
            })
            .catch(error => {
                error.trace = "MSGA.logMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    sentPushMessageToFavorite(onlineID, title, message, link, then) {
        messageDao.pushMessageToFavoriteParcours(onlineID, title, message, link)
            .then(result => {
                if (then) {
                    then(result);
                }
            })
            .catch(error => {
                error.trace = "MSGA.pushNotifyFavorite";
                notificationActions.parseError(error);
            });
        return {};
    }

    pushMessageToInstallations(installationIds, title, message, link, then) {
        messageDao.pushMessageToInstallations(installationIds, title, message, link)
            .then(result => {
                if (then) {
                    then(result);
                }
            })
            .catch(error => {
                error.trace = "MSGA.pushNotifyFavorite";
                notificationActions.parseError(error);
            });
        return {};
    }

    slackNotify(message, link, then) {
        messageDao.slackNotify(message, link)
            .then(result => {
                console.log("SLACK: " + result);
                if (then) {
                    then();
                }
            })
            .catch(error => {
                error.trace = "MSGA.pushNotifySlack";
                notificationActions.parseError(error);
            });
        return {};
    }

    slackCustomerNotify(message, link, then) {
        messageDao.slackCustomerNotify(message, link)
            .then(result => {
                console.log("SLACK: " + result);
                if (then) {
                    then();
                }
            })
            .catch(error => {
                error.trace = "MSGA.pushNotifySlack";
                notificationActions.parseError(error);
            });
        return {};
    }

    mailCallbackNotification(success) {
        if (success) {
            // mail sent
            notificationActions.success("mail.sent.success");
        } else {
            // mail not sent
            notificationActions.error("mail.sent.failed", "");
        }
        return {};
    };

    pushCallbackNotification(success) {
        if (success) {
            // mail sent
            notificationActions.success("push.sent.success");
        } else {
            // mail not sent
            notificationActions.error("push.sent.failed", "");
        }
        return {};
    };
}

module.exports = alt.createActions(MessageActions);
