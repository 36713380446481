const Parse = require("ParseInit");
const {GroupPlace} = require("parse/_Domain");

class GroupPlaceDao {

    queryGroupPlace(parentGroupPlaceID) {
        const query = new Parse.Query(GroupPlace);
        query.equalTo(GroupPlace.prototype.col.status, GroupPlace.prototype.status.A);
        if (parentGroupPlaceID) {
            const parent = new GroupPlace();
            parent.id = parentGroupPlaceID;
            query.equalTo("parentGroupID", parent);
        } else {
            query.doesNotExist(GroupPlace.prototype.col.parentGroupID);
        }
        query.descending(GroupPlace.prototype.col.parcoursAmount);
        return query.find()
    }

    queryRegionGroupPlace(parentGroupPlaceID) {
        const query = new Parse.Query(GroupPlace);
        query.equalTo(GroupPlace.prototype.col.status, GroupPlace.prototype.status.A);
        if (parentGroupPlaceID) {
            const parent = new GroupPlace();
            parent.id = parentGroupPlaceID;
            query.equalTo("parentGroupID", parent);
        } else {
            query.exists(GroupPlace.prototype.col.parentGroupID);
            query.limit(1000);
        }
        query.descending(GroupPlace.prototype.col.parcoursAmount);
        return query.find()
    }

    recountGrouPlaceAmount(mainGroupPlace) {
        console.log("Recount " + mainGroupPlace.getName());
        return Parse.Cloud.run("recountParcoursAmount", { objectId: mainGroupPlace.id});
    }
}

module.exports = new GroupPlaceDao();
