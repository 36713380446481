/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

const {BaseContext} = require("context/BaseContext");

const userInfoActions = require("actions/UserInfoActions");
const userActions = require("actions/UserActions");

const ParcoursFavorit = ({parcours}) => {
    const { user, updateUserInfoState, userInfoState:{ userFavorite }} = React.useContext(BaseContext);
    const [isFavorite, setFavorite] = React.useState(checkFavorite());
    function checkFavorite() {
        if (userFavorite) {
            return userFavorite.isInFavorites(parcours.getOnlineID());
        }
        return false;
    }
    function clickedFavorite() {
        if (user == null) {
            userActions.showLoginModal();
        } else {
            userInfoActions.updateParcoursFavorite(parcours.getOnlineID(), !isFavorite, user, callback => {
                // got new userFavorite
                console.log("updated", callback.getParcoursOnlineIDs())
                setFavorite(checkFavorite())
                // TODO do not understand why this is not needed
                // seems as userDao.fetch seems to update object
                // updateUserInfoState({userFavorite: callback})

            });
        }
    }
    return (
        <Button bsStyle={isFavorite ? "success" : "default"} onClick={clickedFavorite}>
            {isFavorite ?  <FontAwesome icon="star"/> :  <FontAwesome icon="star" color="lightgrey"/>}
            &nbsp;<I18n code="Favoriten" />
        </Button>)
}
ParcoursFavorit.propTypes = {
    parcours: PropTypes.object.isRequired
};
module.exports = ParcoursFavorit;